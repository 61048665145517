import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Material  React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import { useAuthContext } from 'useAuthContext';
import UserService from '../../../../services/UserService';
import { useLocales } from "../../../../ui/locales";

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  authOnly,
  ...rest
}) {
  const linkComponent = {
    component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  };

  const routeComponent = {
    component: Link,
    to: "/" + route,
  };

  const { isAuthenticated, user } = useAuthContext();
  const { translate } = useLocales();

  return (
    <>
      <MKBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        opacity={user && authOnly ? 1 : light ? 1 : 0.6}
        sx={{ cursor: "pointer", userSelect: "none" }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        {!authOnly && (
          <>
            <MKTypography
              variant="body2"
              lineHeight={1}
              color="inherit"
              sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
            >
              {icon}
            </MKTypography>
            <MKTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
            >
              {translate(name)}
            </MKTypography>
            <MKTypography variant="body2" color={light ? "white" : "dark"} ml="auto">
              <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
                {collapse && "keyboard_arrow_down"}
              </Icon>
            </MKTypography>
          </>
        )}

        {user && authOnly && (
          <MKBox opacity={1}>
            <MKAvatar src={UserService.getUserAvatarUrl(user)} size="xs" shadow="lg" bgColor="info" />
          </MKBox>
        )}
      </MKBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
// DefaultNavbarDropdown.defaultProps = {
//   children: false,
//   collapseStatus: false,
//   light: false,
//   href: "",
//   route: "",
// };

// // Typechecking props for the DefaultNavbarDropdown
// DefaultNavbarDropdown.propTypes = {
//   name: PropTypes.string.isRequired,
//   // icon: PropTypes.node.isRequired,
//   children: PropTypes.node,
//   collapseStatus: PropTypes.bool,
//   light: PropTypes.bool,
//   href: PropTypes.string,
//   route: PropTypes.string,
//   collapse: PropTypes.bool.isRequired,
// };

export default DefaultNavbarDropdown;
