import Icon from "@mui/material/Icon";

import Iconify from "../ui/components/iconify";
// routes
import { PATH_PAGE, PATH_AUTH, PATH_DASHBOARD } from 'paths';

const routes_dev = [
  {
    name: "navigation.instruments",
    icon: "",
    route: PATH_PAGE.marketplace.root.name,
  },
  {
    name: "navigation.shops",
    icon: "",
    route: PATH_PAGE.seller_finder.name,
  },
  {
    name: "navigation.faqs",
    icon: "",
    route: PATH_PAGE.faqs.name,
  },
  {
    name: "navigation.contact",
    icon: "",
    route: PATH_PAGE.contact.name,
  },
  {
    name: "navigation.about_us",
    icon: "",
    route: PATH_PAGE.about.name,
  },
  {
    name: "navigation.partners",
    icon: "",
    route: PATH_PAGE.sellers.name,
  },
  {
    name: PATH_PAGE.account.name,
    icon: "",
    authOnly: true,
    collapse: [
      {
        name: "navigation.dashboard",
        route: PATH_DASHBOARD.root.path,
        icon: <Iconify icon={"bxs:dashboard"} />,
      },
      {
        name: "navigation.account_settings",
        route: PATH_DASHBOARD.settings.root.path,
        icon: <Iconify icon={"eva:settings-fill"} />,
      },
      {
        name: "navigation.logout",
        route: PATH_AUTH.logout.path,
        icon: <Iconify icon={"majesticons:logout"} />,
        action: "logout",
      },
    ],
  },
];

export default routes_dev;
