// @mui material components
import Divider from "@mui/material/Divider";

// Material Kit 2 React components

// Material Kit 2 React examples
import { Stack, Typography, useTheme } from "@mui/material";
import { m } from 'framer-motion';
import { varFade } from '../../../../../ui/components/animate';
import { primaryTextGradient } from "../../../../../ui/utils/cssStyles";
// Images

function Header({ preTitle, title, secondTitle, subtitle, divider, predivider, allBlack, reversed }) {
  const theme = useTheme();
  return (
    <Stack spacing={3} sx={{ mb: { xs: 6, md: 12 }, textAlign: "center" }}>
      {predivider && <Divider sx={{ mb: 12 }} />}
      {title && (
        <m.div variants={reversed ? varFade().inDown : varFade().inUp}>
          <Typography
            component="div"
            variant="overline"
            sx={{ mb: 2, color: allBlack ? "black.main" : "text.disabled" }}
          >
            {preTitle}
          </Typography>
        </m.div>
      )}
      {(title || secondTitle) && (
        <m.div variants={reversed ? varFade().inUp : varFade().inDown}>
          {title && <Typography variant="h2">{title}</Typography>}
          {secondTitle && (
            <Typography
              variant="h2"
              sx={{
                ...primaryTextGradient(theme.palette.gradients),
              }}
            >
              {secondTitle}
            </Typography>
          )}
        </m.div>
      )}

      {subtitle && (
        <m.div variants={reversed ? varFade().inUp : varFade().inDown}>
          <Typography
            sx={{
              color: allBlack ? "black.main" : "text.secondary",
              whiteSpace: "pre-line",
            }}
          >
            {subtitle}
          </Typography>
        </m.div>
      )}
      {divider && <Divider sx={{ mt: 12 }} />}
    </Stack>
  );
}

export default Header;
