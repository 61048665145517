import PropTypes from 'prop-types';
// @mui
import { Paper, Typography } from '@mui/material';
import { useLocales } from "src/locales";
import { Trans } from 'react-i18next';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};

export default function SearchNotFound({ query, sx, title, description, emptyText, ...other }) {
  const { t } = useLocales();
  return query ? (
    <Paper
      sx={{
        textAlign: "center",
        ...sx,
      }}
      {...other}>
      <Typography variant="h6" paragraph>
        {title ? title : t("not_found")}
      </Typography>

      {description ? (
        <Typography variant="body2">{description}</Typography>
      ) : (
        <Typography variant="body2">
          <Trans i18nKey="no_results_for" >
           <strong>&quot;{{query: query}}&quot;</strong>
          </Trans>
          {/* {translate("intro.action_get_title_full")} */}
          {/* {t("no_results_for", { value: query })} */}
        </Typography>
      )}
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      {title ? title : t("enter_keywords")}
    </Typography>
  );
}
