// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import Information from "pages/Landing/Landing/sections/Information";

import Testimonials from "pages/Landing/Landing/sections/Testimonials";

// Routes
import routes from "routes";

// Images

// import LandingBg from "assets/images/landing-bg.jpg";
import { Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { BlurredBackground, MotionContainer, varFade } from '../../../../ui/components/animate';
import Iconify from '../../../../ui/components/iconify';
import { useScrollToRef } from "../../../../ui/hooks/use-scroll-to-ref";

import useLocales from 'useLocales';

import Header from "layouts/sections/page-sections/page-headers/Header";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { primaryTextGradient } from "../../../../ui/utils/cssStyles";
import InstrumentCategories from "./sections/InstrumentCategories";
import InstrumentDrop from "./sections/InstrumentDrop";
import TrustedBy from "./sections/TrustedBy";
// import bgImage from "assets/images/bg-instruments.avif";
import StillQuestions from "layouts/sections/page-sections/StillQuestions";
import Footer from "../../../../ui/layouts/main/footer";

// const RootStyle = styled(m.div)(({ theme }) => ({
//   position: 'relative',
//   // backgroundColor: theme.palette.grey[400],
//   [theme.breakpoints.up('md')]: {
//     top: 0,
//     left: 0,
//     width: '100%',
//     display: 'flex',
//     alignItems: 'center',
//   },
// }));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  // zIndex: 10,
  // maxWidth: 520,
  // margin: 'auto',
  // textAlign: 'center',
  // position: 'relative',
  // paddingTop: theme.spacing(15),
  // paddingBottom: theme.spacing(15),
  // [theme.breakpoints.up('md')]: {
  //   margin: 'unset',
  //   textAlign: 'left',
  // },
}));


function Landing() {
  const { t } = useLocales();
  const theme = useTheme();

  const instrumentCategoriesRef = useRef(null);
  const instrumentDropRef = useRef(null);

  return (
    <>
      <Helmet>
        <title>Instrumentos Musicales | Alquiler y Compra</title>
      </Helmet>
      <MKBox position="fixed" top="0.75rem" width="100%" style={{ zIndex: "1" }}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/login",
            label: "Login",
            color: "info",
          }}
          relative
        />
      </MKBox>
      <BlurredBackground show={true} bgIndex={0} />
      <MotionContainer>
        {/* <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/login",
            label: "Login",
            color: "info",
          }}
          sticky
        /> */}
        <MKBox
          minHeight="80vh"
          width="100%"
          sx={{
            display: "grid",
            placeItems: "center",
          }}>
          <Container>
            <Grid container item xs={12} lg={12} alignItems="center" justifyContent="center" mx="auto" sx={{ textAlign: "center" }}>
              <Grid item xs={12} lg={12} sx={{ mx: "auto" }} justifyContent="center" alignItems="center">
                <ContentStyle>
                  <m.div variants={varFade().inUp}>
                    <Typography
                      component="h2"
                      variant="header"
                      sx={{
                        color: "common.black",
                        mt: { xs: "5vh", md: 0 },
                      }}>
                      {t("landing.main_title")}
                      <br />
                    </Typography>
                    <Typography
                      component="h2"
                      variant="header"
                      sx={{
                        // color: 'primary.main',
                        ...primaryTextGradient(theme.palette.gradients),
                      }}>
                      &nbsp;{t("landing.main_title_2")}
                    </Typography>
                  </m.div>
                  <m.div variants={varFade().inUp}>
                    <Typography
                      sx={{
                        color: "common.black",
                        fontWeight: { md: "bold" },
                      }}
                      // textAlign="left"
                      variant="subtitle1">
                      {t("landing.main_subheader_b")}
                    </Typography>
                  </m.div>

                  <m.div variants={varFade().inUp}>
                    <Stack direction="row" justifyContent="center" spacing={1.5}>
                      <Button
                        size="large"
                        variant="contained"
                        // component={RouterLink}
                        // to={PATH_PAGE.contact.path}
                        onClick={useScrollToRef(instrumentCategoriesRef, 1.1)}
                        startIcon={<Iconify icon={"eva:music-fill"} width={20} height={20} />}>
                        {t("landing.main_action")}
                      </Button>
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={useScrollToRef(instrumentDropRef, 0.95)}
                        // component={RouterLink}
                        // to={PATH_PAGE.about.patdh}
                        sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                        {t("landing.main_colaborative")}
                      </Button>
                    </Stack>
                  </m.div>
                </ContentStyle>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <m.div variants={varFade().inUp}>
          <Card
            sx={{
              // p: 2,
              p: { xs: 1, md: 2 },
              mx: { xs: 1, md: 3 },
              mt: { xs: "5vh", md: -8 },
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}>
            {/* <Header reversed title={t("landing.subheader")} secondTitle={t("landing.subheader_2")} subtitle={t("landing.subheader_tag")} /> */}
            {/* "Featured Instruments": A section displaying featured instruments for sharing or subscription, with images and brief descriptions. */}
            {/* Features
        call to action */}
            {/* <Economy />
            {/* <Counters /> */}
            <div ref={instrumentCategoriesRef}></div>
            <InstrumentCategories />
            <Header
              // reversed
              predivider={true}
              // preTitle={t("landing.instruments_cat_pretitle")}
              title={t("landing.subheader")}
              secondTitle={t("landing.subheader_2")}
              subtitle={t("landing.subheader_tag")}
            />
            <Information />
            <div ref={instrumentDropRef}></div>
            <InstrumentDrop />
            {/* <Instruments /> */}
            <Testimonials />
            <TrustedBy />
            <StillQuestions />
          </Card>
          {/* <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox> */}
        </m.div>
        <Footer />
      </MotionContainer>
    </>
  );
}

export default Landing;
