import Iconify from '../../components/iconify';
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgColor from '../../components/svg-color';
import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import { ICONS } from 'src/utils/icons';

// ----------------------------------------------------------------------


const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getLocalIcon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const NAV_ICONS = {
  home: getLocalIcon("ic_tour"),
  blog: getLocalIcon("ic_blog"),
  cart: getLocalIcon("ic_cart"),
  chat: getLocalIcon("ic_chat"),
  // mail: getLocalIcon('ic_mail'),
  user: getLocalIcon("ic_user"),
  users: getIcon("ph:user-list-fill"),
  kanban: getLocalIcon("ic_kanban"),
  products: getIcon(ICONS.products),
  instruments: getIcon(ICONS.instruments),
  // banking: getLocalIcon('ic_banking'),
  // booking: getLocalIcon('ic_booking'),
  invoice: getLocalIcon("ic_invoice"),
  // calendar: getLocalIcon('ic_calendar'),
  marketplace: getLocalIcon("ic_ecommerce"),
  analytics: getLocalIcon("ic_analytics"),
  dashboard: getLocalIcon("ic_dashboard"),
  menuItem: getLocalIcon("ic_menu_item"),
  testData: getIcon("academicons:open-data"),
  player: getIcon("eva:headphones-fill"),
  friends: getIcon("fa-solid:users"),
  contracts: getIcon(ICONS.contracts),
  config: getLocalIcon("ic_menu_item"),
  shop: getIcon(ICONS.shop),
};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        subheader: t("navigation.general"),
        items: [
          {
            title: t("navigation.home"),
            path: PATH_DASHBOARD.general.root.path,
            icon: NAV_ICONS.home,
          },
          {
            title: t("navigation.seller_area"),
            icon: NAV_ICONS.shop,
            path: PATH_DASHBOARD.general.seller.root.path,
            roles: ["seller"],
            // children: [
            //   // Seller shps
            //   // { title: t("navigation.shops"), path: PATHS.DASHBOARD_MARKETPLACE },
            //   {
            //     title: t("navigation.overview"),
            //     path: PATH_DASHBOARD.general.seller.seller.path,
            //   },
            //   {
            //     title: t("navigation.services"),
            //     path: PATH_DASHBOARD.general.seller.maintenance.path,
            //   },
            //   // {
            //   //   title: t("navigation.products_to_verify"),
            //   //   roles: ["product_verifier"],
            //   //   path: PATH_DASHBOARD.general.seller.verify.path,
            //   // },
            //   // {
            //   //   title: t("navigation.store_listings"),
            //   //   path: PATH_DASHBOARD.general.seller.root.path,
            //   // },
            //   // {
            //   //   title: t("navigation.product_requests"),
            //   //   path: PATH_DASHBOARD.management.marketplace.product_requests.path,
            //   // },
            // ],
          },
          // {
          //   title: t("navigation.player_area"),
          //   path: PATH_DASHBOARD.general.player.path,
          //   icon: ICONS.player,
          //   // children: [
          //   //   // { title: 'NFT marketplace', path: PATH_DASHBOARD.marketplace.path },
          //   //   { title: NavNames.ADD_PRODUCT, path: RouteNames.DASHBOARD_MARKETPLACE_NEW, sellerOnly: true },
          //   //   // { title: NavNames.ARTISTS, path: RouteNames.DASHBOARD_MARKETPLACE_ARTISTS },
          //   // ],
          // },
          {
            title: t("navigation.products"),
            path: PATH_DASHBOARD.product.root.path,
            icon: NAV_ICONS.instruments,
            children: [
              {
                title: t("navigation.products"),
                path: PATH_DASHBOARD.product.list.path,
              },
              {
                title: t("navigation.products_to_verify"),
                roles: ["product_verifier"],
                path: PATH_DASHBOARD.product.seller.verify.path,
              },
              {
                title: t("navigation.products_stored"),
                roles: ["product_verifier"],
                path: PATH_DASHBOARD.product.seller.stored_products.path,
              },
              // {
              //   title: t("navigation.store_listings"),
              //   path: PATH_DASHBOARD.product.listings.path,
              // },
              {
                title: t("navigation.add_product"),
                path: PATH_DASHBOARD.product.new.path,
              },
            ],
          },
          // {
          //   title: t("navigation.marketplace"),
          //   path: PATH_DASHBOARD.general.marketplace.path,
          //   icon: ICONS.cart,
          //   // children: [
          //   //   // { title: 'NFT marketplace', path: PATH_DASHBOARD.marketplace.path },
          //   //   { title: NavNames.ADD_PRODUCT, path: RouteNames.DASHBOARD_MARKETPLACE_NEW, sellerOnly: true },
          //   //   // { title: NavNames.ARTISTS, path: RouteNames.DASHBOARD_MARKETPLACE_ARTISTS },
          //   // ],
          // },
          // {
          //   title: "test data generator",
          //   path: PATH_DASHBOARD.test_data.path,
          //   icon: NAV_ICONS.testData,
          //   // roles: ["admin"],
          // },
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t("navigation.management"),
        roles: ["admin"],
        items: [
          {
            title: t("navigation.users"),
            path: PATH_DASHBOARD.management.users.root.path,
            icon: NAV_ICONS.users,
            // roles: ["admin"],
          },
          {
            title: t("navigation.marketplace"),
            path: PATH_DASHBOARD.management.marketplace.root.path,
            icon: NAV_ICONS.marketplace,
            // roles: ["admin"],
            children: [
              // Seller shps
              // { title: t("navigation.shops"), path: PATHS.DASHBOARD_MARKETPLACE },
              {
                title: t("navigation.products"),
                path: PATH_DASHBOARD.management.marketplace.product.path,
              },
              // {
              //   title: t("navigation.store_listings"),
              //   path: PATH_DASHBOARD.management.marketplace.listings.path,
              // },
              {
                title: t("navigation.product_requests"),
                path: PATH_DASHBOARD.management.marketplace.product_requests.path,
              },
            ],
          },
          {
            title: t("navigation.contracts"),
            path: PATH_DASHBOARD.management.contracts.root.path,
            icon: NAV_ICONS.contracts,
            // roles: ["admin"],
            children: [
              // Seller shps
              // { title: t("navigation.shops"), path: PATHS.DASHBOARD_MARKETPLACE },
              {
                title: t("navigation.customer_contracts"),
                path: PATH_DASHBOARD.management.contracts.contracts.path,
              },
              {
                title: t("navigation.customer_subcontracts"),
                path: PATH_DASHBOARD.management.contracts.subcontracts.path,
              },
              {
                title: t("navigation.transactions"),
                path: PATH_DASHBOARD.management.contracts.transactions.path,
              },
            ],
          },
          // {
          //   title: t("navigation.invoices"),
          //   path: PATH_DASHBOARD.management.invoices.root.path,
          //   icon: NAV_ICONS.invoice,
          //   // roles: ["admin"],
          //   children: [
          //     { title: t("list"), path: PATH_DASHBOARD.management.invoices.root.path },
          //     { title: t("create"), path: PATH_DASHBOARD.management.invoices.new.path },
          //   ],
          // },
          {
            title: t("navigation.platform_config"),
            path: PATH_DASHBOARD.management.config.root.path,
            icon: NAV_ICONS.config,
            // roles: ["admin"],
            children: [
              // Seller shps
              // { title: t("navigation.shops"), path: PATHS.DASHBOARD_MARKETPLACE },
              {
                title: t("navigation.product_definitions"),
                path: PATH_DASHBOARD.management.config.product_definitions.path,
              },
              {
                title: t("navigation.recommended_contracts"),
                path: PATH_DASHBOARD.management.config.recommended_contracts.path,
              },
              {
                title: t("navigation.discounts"),
                path: PATH_DASHBOARD.management.config.discounts.path,
              },
              {
                title: t("navigation.fees"),
                path: PATH_DASHBOARD.management.config.fees.path,
              },
            ],
          },
        ],
      },
      // USER SETTINGS
      // ----------------------------------------------------------------------
      {
        subheader: t("navigation.account_settings"),
        items: [
          {
            title: t("navigation.settings"),
            path: PATH_DASHBOARD.settings.root.path,
            icon: NAV_ICONS.users,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
