import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import PaymentsService from "services/PaymentsService";
import UserService from "services/UserService";
import ProductContractsService from "../../../services/ProductContractsService";
import TransactionWithItems, { SERVICE_TYPE_MAINTENANCE, SERVICE_TYPE_NAME_MAP, SERVICE_TYPE_VALUATION } from "model/payments/TransactionWithItems";
import ProductsService from "services/ProductsService";
import product from "./product";
import EmailService from "services/EmailService";
import { fPrice } from "src/utils/format-number";
import { TRANSACTION_STATUS_MAP } from "model/payments/TransactionItem";
// ----------------------------------------------------------------------

export const DEFAULT_PRICE_RANGE = [0, 200];
export const PRICE_RANGE_TEXT = "priceRange";


const initialState = {
  isLoading: false,
  error: null,
  transactions: [],
  transaction: null,
  filters: {
    // productDefinition: null,
    // priceRange: DEFAULT_PRICE_RANGE,
    serviceName: null,
    transactionType: null,
    startDate: null,
    modifiedDate: null,
    endDate: null,
  },
  create: {
    activeStep: 0,
  },
  // shops: [],
};

const slice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // --------------- TRANSACTIONS ---------------

    // GET TRANSACTIONS
    getTransactionsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.transactions = action.payload.map((item) => TransactionWithItems.fromJSON(item));
      } else {
        state.transactions = [];
      }
    },

    // GET PRODUCT
    getTransactionSuccess(state, action) {
      state.isLoading = false;
      state.transaction = TransactionWithItems.fromJSON(action.payload);
    },

    deleteTransaction(state, action) {
      state.isLoading = false;
      const updatedTransactions = state.transactions.filter(
        (product) => product.id !== action.payload
      );

      state.transactions = updatedTransactions;
    },

    updateTransaction(state, action) {
      state.isLoading = false;
      const index = state.transactions.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.transactions[index] = action.payload;
      }
    },

    addTransaction(state, action) {
      state.isLoading = false;
      const length = state.transactions.push(action.payload);
    },


    filterTransactions(state, action) {
      // state.filters.productDefinition = action.payload.productDefinition;
      // state.filters.sizes = action.payload.sizes;
      // state.filters.priceRange = action.payload.priceRange;
      state.filters.serviceName = action.payload.location;
      state.filters.transactionType = action.payload.transactionType;
      state.filters.startDate = action.payload.startDate;
      state.filters.modifiedDate = action.payload.modifiedDate;
      state.filters.endDate = action.payload.endDate;
    },

    // --------------- END TRANSACTIONS ---------------

    resetStep(state) {
      state.create.activeStep = 0;
    },

    backStep(state) {
      state.create.activeStep -= 1;
    },

    nextStep(state) {
      state.create.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.create.activeStep = step;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetStep,
  gotoStep,
  backStep,
  nextStep,
  filterTransactions,
} = slice.actions;

// ----------------------------------------------------------------------

export function getTransactions({symphy_backend, filter= {}, withShops, withProducts, listener}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      let response = await PaymentsService.getTransactions(symphy_backend, filter);
      // productIds = response.map((product) => product.id);
      // if (withContracts && response.length > 0) {
      //   response = await ProductContractsService.addContractsToItems(symphy_backend, response, productIds);
      // }

      // if (storeListings && response.length > 0) {
      //   const retrieveListings = !Array.isArray(storeListings);
      //   response = await PaymentsService.addStoreListingToTransactions(symphy_backend, response, productIds, !retrieveListings ? storeListings : null);
      // }

      // if (user && response.length > 0) {
      //   // Add 'user' property to each item
      //   response = response.map((item) => {
      //     return { ...item, user: user };
      //   });
      // }
    if (response.length > 0) {
        let userIds = response.map((item) => item.user_id);
        response = await UserService.addUserSummaryToItems(symphy_backend, response, userIds);
      
        response = response.map((item) => TransactionWithItems.fromJSON(item));
        if (withShops) {
          // Add shops
          let shopIds = response.map((item) => item.getShopId()).filter((id) => id !== null && id !== undefined); // let searchFilter = UserService.createShopSearchFilter({ shopIds: shopIds });
          response = await UserService.addShopsToItems(symphy_backend, response, shopIds, { toSubcontracts: false, isTransaction: true });
        }
        if (withProducts) {
          // Convert the Set back to an array
          const uniqueProductIds = TransactionWithItems.getItemsProductIds(response);
          response = await ProductsService.addProductsToItems(symphy_backend, response, uniqueProductIds);
        }
      }
      if (listener) {
        listener();
      }
      dispatch(slice.actions.getTransactionsSuccess(response));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function getSellerTransactions({ symphy_backend, filter = {}, withShops, withProducts, listener }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    let response = await PaymentsService.getSellerTransactions(symphy_backend, filter);
    if (response.length > 0) {
      let userIds = response.map((item) => item.user_id);
      response = await UserService.addUserSummaryToItems(symphy_backend, response, userIds);
      response = response.map((item) => TransactionWithItems.fromJSON(item));
      if (withShops) {
        // Add shops
        let shopIds = response.map((item) => item.getShopId()).filter((id) => id !== null && id !== undefined); // let searchFilter = UserService.createShopSearchFilter({ shopIds: shopIds });
        response = await UserService.addShopsToItems(symphy_backend, response, shopIds, { toSubcontracts: false, isTransaction: true });
      }
      if (withProducts) {
        // Convert the Set back to an array
        const uniqueProductIds = TransactionWithItems.getItemsProductIds(response);
        response = await ProductsService.addProductsToItems(symphy_backend, response, uniqueProductIds);
      }
      // response = TransactionWithItem.sortTransactionsByDate(response);
    }
    if (listener) {
      listener();
    }
    dispatch(slice.actions.getTransactionsSuccess(response));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}



// ----------------------------------------------------------------------

export function getTransaction(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await PaymentsService.getTransaction(symphy_backend, BigInt(id));
      dispatch(slice.actions.getTransactionSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTransaction(symphy_backend, id, listener, withChildren) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await PaymentsService.deleteTransaction(symphy_backend, id, withChildren);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteTransaction(BigInt(id)));
      if (listener) {
        listener();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refreshTransaction(item) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // eslint-disable-next-line no-undef
    if (item) {
      dispatch(slice.actions.updateTransaction(item));
    }
    // } catch (error) {
    //   console.log("Error updating " + error);
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function updateTransaction(symphy_backend, item, isCurrent, listener, type,t) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    // console.log("Updating product a" + product.id);
    // eslint-disable-next-line no-undef
    let response;
    const isMaintenance = type === SERVICE_TYPE_MAINTENANCE;
    if (isMaintenance || type === SERVICE_TYPE_VALUATION) {
      const serviceType = isMaintenance ? SERVICE_TYPE_NAME_MAP.maintenance : SERVICE_TYPE_NAME_MAP.valuation;

      response = await PaymentsService.updateServiceTransaction(symphy_backend, item, serviceType);
      if (response) {
        if (item.product) {
          const transaction = TransactionWithItems.fromJSON({ ...response, user: item.serviceUser });
          transaction.setProductForItem(item.product);
          console.log("transaction updated ", JSON.stringify(transaction));
          response = transaction;
        } else {
          response = TransactionWithItems.fromJSON({ ...response, user: item.serviceUser });
        }
        const isReady = response.transaction_item.getStatusText() === TRANSACTION_STATUS_MAP.ready.name;
        // const isCompleted = response.transaction_item.getStatusText() === TRANSACTION_STATUS_MAP.completed.name;
        if (isReady && isMaintenance) {
          const shop = item.shop;
          if (!shop) {
            return;
          }
          EmailService.sendOrderEmail({
            // response.email,
            to: item.serviceUser.email,
            subject: t("emails.maintenance_completed"),
            description: t("emails.maintenance_completed_description"),
            headline: t("emails.headline_service_completed", { name: UserService.getUserDisplayName(item.serviceUser, true) }),
            subtitle: t("emails.instrument_deposited_shop"),
            subdescription: t("emails.instrument_deposited_shop_description", { shop: shop.name, address: shop.location.address }),
            subtitle2: t("emails.services_summary"),
            subdescription2: response.info,
            orderTitle: t("emails.transaction_id"),
            orderId: response.id,
            items: [EmailService.transactionToEmailItem(t, item.product, response)],
            totalPrice: response.getPrice() ? fPrice(response.getPrice()) : null,
          });
        }
      }
    } else {
      const updatedTransaction = await PaymentsService.updateTransaction(symphy_backend, item.id, item.info, item.userId, item.createdAt, item.modifiedAt, item.metadata, item.items);
      response = TransactionWithItems.fromJSON({ updatedTransaction, product: item.product, user: item.user});
      // eslint-disable-next-line no-undef
    }
    // console.log("response", JSON.stringify(response));
    // eslint-disable-next-line no-undef
    if (response) {
    dispatch(slice.actions.updateTransaction(response));
    if (isCurrent) {
      dispatch(slice.actions.getTransactionSuccess(response));
    }
    if (listener) {
      listener(response);
    }
    }
    // } catch (error) {
    //   console.log("Error updating " + error);
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function addTransaction(symphy_backend, item, isCurrent, listener, type, t, emailToSeller) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      // eslint-disable-next-line no-undef
    let response;
    const isMaintenance = type === SERVICE_TYPE_MAINTENANCE;
    if (isMaintenance || type === SERVICE_TYPE_VALUATION) {
      response = isMaintenance ? await PaymentsService.addMaintenanceTransaction(symphy_backend, item ) : await PaymentsService.addValuationTransaction(symphy_backend, item);
      if (response) {
        if (item.product && item.serviceUser) {
          const transactionItem = { ...response.transaction_item, product: item.product };
          response = TransactionWithItems.fromJSON({ ...response, user: item.serviceUser, transaction_item: transactionItem });
        } else {
          response = TransactionWithItems.fromJSON(response);
        }
        const shop = item.shop;
        if (shop && shop.hasEmail() && isMaintenance) {
          EmailService.sendOrderEmail({
            // response.email,
            to: emailToSeller ? shop.getEmail() : item.serviceUser.email,
            subject: t(emailToSeller ? "emails.service_requested" : "emails.maintenance_created"),
            description: t(emailToSeller ? "emails.maintenance_created_description_user" : "emails.maintenance_created_description"),
            headline: emailToSeller
              ? t("emails.headline_service_requested", { name: UserService.getUserDisplayName(item.serviceUser, true) })
              : t("emails.headline_service", { name: UserService.getUserDisplayName(shop.name, true) }),
            subtitle: t(emailToSeller ? "emails.instrument_to_deposit_shop" : "emails.instrument_deposited_shop"),
            subdescription: t("emails.instrument_deposited_shop_description", { shop: shop.name, address: shop.location.address }),
            subtitle2: t("emails.services_summary"),
            subdescription2: response.info,
            orderTitle: t("emails.transaction_id"),
            orderId: response.id,
            items: [EmailService.transactionToEmailItem(t, item.product, response, !emailToSeller)],
            totalPrice: !emailToSeller && response.getPrice() ? fPrice(response.getPrice()) : null,
          });
        }
      }
    } else {
      console.log('item', JSON.stringify(item));
      // Item is a subcontract
      // const isSubscription = item.isSubscription();
      // const serviceName = isSubscription ? SERVICE_TYPE_NAME_MAP.subscription : SERVICE_TYPE_NAME_MAP.sale;
      response = await PaymentsService.addShopTransaction(symphy_backend, null, item.product.id, item.id, item.user_id, type, null);
      // response = TransactionWithItem.fromJSON(await PaymentsService.addTransaction(symphy_backend, item));
      // eslint-disable-next-line no-undef
    }
    dispatch(slice.actions.addTransaction(response));
      if (isCurrent) {
        dispatch(slice.actions.getTransactionSuccess(response));
      }
      if (listener) {
        listener(response);
      }
    // } catch (error) {
    //   console.log("Error adding " + JSON.stringify(error) + " " + JSON.stringify(product));
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

