// i18n
import './ui/locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// // lightbox
// import 'yet-another-react-lightbox/plugins/captions.css';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';
// import 'yet-another-react-lightbox/styles.css';

// slick-carousel
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// Overrides such as bigint toJSON
import './ui/utils/overrides';

// editor
// import 'react-quill/dist/quill.snow.css'; // Now in editor.js

// routes
import Router from './ui/routes';
// theme
import ThemeProvider from './ui/theme';
// locales
import ThemeLocalization from './ui/locales';
// components
import { MotionLazyContainer } from './ui/components/animate';
import { CookiesBanner } from './ui/components/cookies';
// import { ThemeSettings } from './ui/components/settings';
import { AuthProvider } from 'src/auth/context/web3auth';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import SnackbarProvider from './ui/components/snackbar';
// redux
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './ui/redux/store';

// components
import ProgressBar from './ui/components/progress-bar';
import { SettingsProvider } from "./ui/components/settings";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { defaultSettings } from 'src/components/settings/config-settings';
import { CheckoutProvider } from 'src/sections/checkout/context';
import FacebookPixelProvider from 'src/components/facebook-pixel/facebook-pixel-provider';

// ----------------------------------------------------------------------

export default function App() {

  const charAt = `

░░░░░░░ ░░    ░░ ░░░    ░░░ ░░░░░░  ░░   ░░ ░░    ░░
▒▒       ▒▒  ▒▒  ▒▒▒▒  ▒▒▒▒ ▒▒   ▒▒ ▒▒   ▒▒  ▒▒  ▒▒
▒▒▒▒▒▒▒   ▒▒▒▒   ▒▒ ▒▒▒▒ ▒▒ ▒▒▒▒▒▒  ▒▒▒▒▒▒▒   ▒▒▒▒
     ▓▓    ▓▓    ▓▓  ▓▓  ▓▓ ▓▓      ▓▓   ▓▓    ▓▓
███████    ██    ██      ██ ██      ██   ██    ██
    `;

  console.info(`%c${charAt}`, 'color: #6500c9');

  useScrollToTop();

  return (
    <AuthProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider defaultSettings={defaultSettings}>
              <FacebookPixelProvider>
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <CheckoutProvider>
                          {/* <SettingsDrawer /> */}
                          <ProgressBar />
                          {/* <AuthConsumer> */}
                          <Router />
                          {/* </AuthConsumer> */}
                          <CookiesBanner />
                        </CheckoutProvider>
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeProvider>
                </MotionLazyContainer>
              </FacebookPixelProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </AuthProvider>
  );
}
