// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { Avatar, ListItemText, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Helmet } from "react-helmet-async";

function DefaultReviewCard({ color, image, name, date, review, rating, itemReviewedName, isService=true }) {
  const ratings = {
    0.5: [
      <Icon key={1}>star_outline</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    1: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_outline</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    1.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star_half</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    2: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_outline</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    2.5: [
      <Icon key={1}>star</Icon>,
      <Icon key={2}>star</Icon>,
      <Icon key={3}>star_half</Icon>,
      <Icon key={4}>star_outline</Icon>,
      <Icon key={5}>star_outline</Icon>,
    ],
    3: [<Icon key={1}>star</Icon>, <Icon key={2}>star</Icon>, <Icon key={3}>star</Icon>, <Icon key={4}>star_outline</Icon>, <Icon key={5}>star_outline</Icon>],
    3.5: [<Icon key={1}>star</Icon>, <Icon key={2}>star</Icon>, <Icon key={3}>star</Icon>, <Icon key={4}>star_half</Icon>, <Icon key={5}>star_outline</Icon>],
    4: [<Icon key={1}>star</Icon>, <Icon key={2}>star</Icon>, <Icon key={3}>star</Icon>, <Icon key={4}>star</Icon>, <Icon key={5}>star_outline</Icon>],
    4.5: [<Icon key={1}>star</Icon>, <Icon key={2}>star</Icon>, <Icon key={3}>star</Icon>, <Icon key={4}>star</Icon>, <Icon key={5}>star_half</Icon>],
    5: [<Icon key={1}>star</Icon>, <Icon key={2}>star</Icon>, <Icon key={3}>star</Icon>, <Icon key={4}>star</Icon>, <Icon key={5}>star</Icon>],
  };

  // JSON-LD structured data for ratings for Google results
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Review",
    itemReviewed: {
      "@type": isService ? "Service" : "Product",
      name: itemReviewedName,
    },
    reviewRating: {
      "@type": "Rating",
      ratingValue: rating,
      bestRating: "5",
    },
    author: {
      "@type": "Person",
      name: name,
    },
    reviewBody: review,
    datePublished: date,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <MKBox
        variant={color === "transparent" ? "contained" : "gradient"}
        bgColor={color}
        borderRadius="xl"
        shadow={color === "transparent" ? "none" : "md"}
        p={3}>
        <Stack direction="row" sx={{ lineHeight: 1 }}>
          <Avatar alt={name} src={image ? image : name} sx={{ mr: 2 }} />
          <ListItemText
            primary={
              <MKTypography
                display="block"
                variant={"button"}
                fontWeight="bold"
                color={color === "transparent" || color === "light" ? "dark" : "white"}
                mb={0.5}>
                {name}
              </MKTypography>
            }
            secondary={
              <MKTypography
                variant={"caption"}
                fontWeight="regular"
                lineHeight={1}
                color={color === "transparent" || color === "light" ? "text" : "white"}
                sx={{ display: "flex", alignItems: "center" }}>
                <Icon>schedule</Icon>&nbsp;
                {date}
              </MKTypography>
            }
          />
        </Stack>
        <MKTypography variant="body2" color={color === "transparent" || color === "light" ? "text" : "white"} mt={3} mb={4}>
          &quot;{review}&quot;
        </MKTypography>
        <MKTypography
          variant="h4"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 0.375,
            "& .material-icons-round": {
              ml: -0.375,
            },
          }}>
          {ratings[rating]}
        </MKTypography>
      </MKBox>
    </>
  );
}

// // Setting default values for the props of DefaultReviewCard
// DefaultReviewCard.defaultProps = {
//   color: "transparent",
//   image: "",
//   itemReviewedName: "Service/Product Name",
//   isService: false,
// };

// // Typechecking props for the DefaultReviewCard
// DefaultReviewCard.propTypes = {
//   color: PropTypes.oneOf(["transparent", "primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
//   image: PropTypes.string,
//   name: PropTypes.string.isRequired,
//   date: PropTypes.string.isRequired,
//   review: PropTypes.string.isRequired,
//   rating: PropTypes.oneOf([0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]).isRequired,
//   itemReviewedName: PropTypes.string.isRequired,
//   isService: PropTypes.bool,
// };

export default DefaultReviewCard;
