// ----------------------------------------------------------------------

import ProductDefinition from "model/marketplace/ProductDefinition";

/**
 * Convert from a Location from GooglePlaces to a backend location object
 * @param {*} googleLocation 
 * @returns 
 */
export function createLocation(googleLocation, asOption = true, metadata = []) {
  if (!googleLocation || (!googleLocation.latitude && !googleLocation.lat)) return asOption ? [] : {};
  const location = {
    lat: googleLocation.latitude ? parseFloat(googleLocation.latitude) : parseFloat(googleLocation.lat),
    lng: googleLocation.longitude ? parseFloat(googleLocation.longitude) : parseFloat(googleLocation.lng),
    address: googleLocation.secondaryText ? googleLocation.secondaryText : googleLocation.description ? googleLocation.description : googleLocation.address,
    metadata: metadata ? metadata : [],
  };
  return asOption ? [location] : location;
}

export function createLocationMetadata({ floor, city, region, zip_code, country, other_info }) {
  const metadata = [];
  if (floor) metadata.push(['floor', floor]);
  if (city) metadata.push(['city', city]);
  if (region) metadata.push(['region', region]);
  if (zip_code) metadata.push(['zip_code', zip_code]);
  if (country) metadata.push(["country", country]);

  if (other_info) metadata.push(['other_info', other_info]);
  return metadata;
}

export function createMadridLocation(metadata, asOption = true) {
  const location = {
    lat: 40.416775,
    lng: -3.70379,
    address: "Madrid",
    metadata: metadata ? metadata : [],
  };
  return asOption ? [location] : location;
}

export function createUserInfoMetadata({ phone, iban }) {
  const metadata = [];
  if (phone) metadata.push(["phone", String(phone)]);
  if (iban) metadata.push(["iban", String(iban)]);
  return metadata;
}

export function createShopMetadata({ taxNumber, iban }) {
  const metadata = [];
  if (taxNumber) metadata.push(["tax_number", String(taxNumber)]);
  if (iban) metadata.push(["iban", String(iban)]);
  return metadata;
}

/**
 * Obtain the value of an object in an array. Useful for extracting data for Enums from rust
 * @param {*} arr 
 * @param {*} key 
 * @returns 
 */
export function getArrayObjectByKey(arr, key) {
  return (
    arr.find((x) => Object.prototype.hasOwnProperty.call(x, key)) || {}
  )[key];
}

/**
 * 
 * @param {*} inputString 
 * @returns 
 */
export function stringToBackendHashmap(inputString) {
  return ProductDefinition.createProductTypeFromString(inputString);
}

export function convertHasmapToInputString(hashMap) {
  if (!hashMap) {
    return '';
  }
  let inputString = '';

  for (const [key, value] of hashMap) {
    // const value = hashMap[key];
    inputString += `${key}=${value}; `;
  }

  return inputString.trim();
}

/**
 * Convert backend hash map to js hashmap
 * The backend format is as follows: "product_type", [["en", "Horn"], ["es", "Cuerno"]]
 * @param {*} nestedArray 
 * @returns 
 */
export function convertToFrontendHashMap(innerArray) {
  const hashmap = {};
  for (const [lang, value] of innerArray) {
    hashmap[lang] = value;
  }
  return hashmap;
}

/**
 * Extracts the value for a particular key useful for matadata such us [["extra", "value"]]
 * @param {*} metadata 
 * @param {*} key 
 * @returns 
 */
export function getMapValueForKey(metadata, key) {
  if(!metadata) return '';
  const entry = metadata.find(([k]) => k === key);
  return entry ? entry[1] : '';
}

export function updateMetadataField(metadata, fieldName, fieldValue) {
  const metadataToUse = metadata ? metadata : [];
  // Create a deep copy of the metadata to avoid mutations
  const metadataCopy = metadataToUse.map((entry) => [...entry]);
  // Find the index of the entry with the specified field name
  const fieldIndex = metadataCopy.findIndex((entry) => entry[0] === fieldName);

  if (fieldValue === null) {
    // If fieldValue is null, remove the entry
    if (fieldIndex > -1) {
      metadataCopy.splice(fieldIndex, 1);
    }
  } else if (fieldIndex > -1) {
    // Update the existing field
    metadataCopy[fieldIndex][1] = String(fieldValue);
  } else {
    // Add the field if it does not exist
    metadataCopy.push([fieldName, String(fieldValue)]);
  }
  return metadataCopy;
}

/**
 * The category has the format { 'Instruments': null }, return the key
 * @param {*} data
 */
export function getEnumVariableText(data) {
  if (!data) return null;
  return Object.keys(data)[0];
} 

export function toEnumVariableText(data) {
  if (!data) return null;
  return { [data]: null };
} 

/**
 * 
 * @param {*} deposit: its a Coin object 
 * @returns 
 */
export function createPriceCalculationMetadata({ deposit }) {
  const metadata = [];
  if (deposit) metadata.push(["deposit", deposit]);
  return metadata;
}

/**
 * Function to get unique values of a specified field from an array of objects
 * @param {Array} array - The initial array of objects
 * @param {String} [field='user_id'] - The field name to map and filter (default is 'user_id')
 * @returns {Array} - An array of unique values for the specified field
 */
export function getUniqueFieldValues(array, field = 'id') {
  return Array.from(
    new Set(
      array
        .map((item) => item[field])
        .filter((value) => value !== null && value !== undefined)
    )
  );
}

export function removeArrayDuplicates(array) {
  return Array.from(new Set(array));
}

export function mergeArrays(array1, array2, uniqueField = "id") {
  // Create a Map to store unique items by their uniqueField
  const itemMap = new Map();

  // Helper function to add items to the Map
  const addItemToMap = (item) => {
    const key = item[uniqueField];
    if (itemMap.has(key)) {
      // If item already exists and the new item has subcontract, replace it
      if (item.subcontract) {
        itemMap.set(key, item);
      }
    } else {
      // If item does not exist, add it to the Map
      itemMap.set(key, item);
    }
  };

  // Add items from both arrays to the Map
  array1.forEach(addItemToMap);
  array2.forEach(addItemToMap);

  // Convert the Map back to an array
  return Array.from(itemMap.values());
};