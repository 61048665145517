
export default class ContactInfo {
  constructor(email, phone) {
    this.email = email ? [email] : [];
    this.phone = phone ? [phone] : [];
  }

  static fromJSON(contactInfoJSON) {
    if (!contactInfoJSON || contactInfoJSON.length === 0) return [];
    const { email, phone } = contactInfoJSON[0];
    return new ContactInfo(email.length > 0 ? email[0] : null, phone.length >0 ? phone[0] : null);
  }

  toJSON() {
    return {
      email: this.email.length > 0 ? this.email[0] : null,
      phone: this.phone.length > 0 ? this.phone[0] : null,
    };
  }

  toBackendObject() {
    return this;
  }

  getPhone(printable = false) {
    return this.phone.length > 0 ? this.phone[0] : printable ? "" : null;
  }

  hasPhone() {
    return this.phone.length > 0;
  }

  getEmail(printable = false) {
    return this.email.length > 0 ? this.email[0] : printable ? "" : null;
  }

  hasEmail() {
    return this.email.length > 0;
  }
}