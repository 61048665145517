// routes
import { SANDBOX_URLS, PRODUCTION_URLS } from 'redsys-easy';
import { PATH_DASHBOARD } from './routes/paths';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


// GOOGLE ANALYTICIS
export const G_ANALYTICS = process.env.REACT_APP_G_ANALYTICS;

export const WEB3_CLIENT_ID = process.env.WEB3_CLIENT_ID;
export const WEB3_CLIENT_ID_PROD = process.env.WEB3_CLIENT_ID_PROD;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.root.path; // as '/dashboard/root'

export const EMAIL_JS_KEY = process.env.EMAIL_JS_KEY;

// True if PROD ENVIRONMENT
export const IS_PROD = process.env.IS_PROD && process.env.IS_PROD === 'true' ? process.env.IS_PROD : false;
export const IS_DEV = !IS_PROD;

// Components in the ui for prod
export const SHOW_PROD_UI = false;
// IF true show II login
// export const EXTRA_LOGIN_OPTIONS = !IS_PROD;
export const EXTRA_LOGIN_OPTIONS = true;

export const FIREBASE_FUNCTIONS_URL_PROD = "https://us-central1-symphy-8dce0.cloudfunctions.net/";
export const FIREBASE_FUNCTIONS_URL_DEV = "http://127.0.0.1:5001/symphy-8dce0/us-central1/";

// export const FIREBASE_FUNCTIONS_URL = FIREBASE_FUNCTIONS_URL_PROD;

// export const FIREBASE_FUNCTIONS_URL = IS_PROD ? FIREBASE_FUNCTIONS_URL_PROD : "http://127.0.0.1:5001/symphy-8dce0/us-central1/";
// In test env use ngrok
// export const FIREBASE_FUNCTIONS_URL = IS_PROD ? FIREBASE_FUNCTIONS_URL_PROD : "https://bf4a-85-233-56-240.ngrok-free.app/";
export const FIREBASE_FUNCTIONS_URL = IS_PROD ? FIREBASE_FUNCTIONS_URL_PROD : FIREBASE_FUNCTIONS_URL_DEV;

// Google maps
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_LIBS = ["places"];


export const BACKEND_HOST = "localhost:8000";
export const HOST = IS_PROD ? "symphy.es" : "localhost:8080";
export const BACKEND_URL = IS_PROD ? "https://symphy.es" : "http://localhost:8080";

export const INFO_EMAIL = "info@symphy.es";

export const FACEBOOK_ADS_KEY = process.env.FACEBOOK_ADS_KEY;
// Storage 
export const BLOB_URL_PATTERN = IS_PROD
  ? "https://{canisterId}.raw.icp0.io/{blobType}/"
  : "http://{canisterId}." + BACKEND_HOST + "/{blobType}/";
// LAYOUT
// ----------------------------------------------------------------------

// export const HEADER = {
//   H_MOBILE: 64,
//   H_MAIN_DESKTOP: 88,
//   H_DASHBOARD_DESKTOP: 92,
//   H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
// };

// export const NAV = {
//   W_BASE: 260,
//   W_DASHBOARD: 280,
//   W_DASHBOARD_MINI: 88,
//   //
//   H_DASHBOARD_ITEM: 48,
//   H_DASHBOARD_ITEM_SUB: 36,
//   //
//   H_DASHBOARD_ITEM_HORIZONTAL: 32,
// };

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// For mock data
export const ASSETS_API = "https://assets.minimals.cc/public/assets/images/mock/";

export const HOST_API = process.env.REACT_APP_HOST_API;

export const CURRENT_ENDPOINT = IS_PROD ? "https://" + HOST : "http://" + HOST;
// export const CURRENT_ENDPOINT = IS_PROD ? "https://symphy.es" : "http://localhost:8080";

// Redsys
// export const REDSYS_URLS = IS_PROD ? PRODUCTION_URLS : SANDBOX_URLS;

export const REDSYS_PROD = IS_PROD;
// export const REDSYS_PROD = false;
export const REDSYS_KEY = REDSYS_PROD ? process.env.REDSYS_KEY_PROD : process.env.REDSYS_KEY;
export const REDSYS_KEY_999 = REDSYS_PROD ? process.env.REDSYS_KEY_PROD_999 : process.env.REDSYS_KEY_999;

export const REDSYS_URLS = REDSYS_PROD ? PRODUCTION_URLS : SANDBOX_URLS;
// export const REDSYS_URLS_PROD = PRODUCTION_URLS;

// export const REDSYS_DEV_MERCHANT_URL = "https://147e-85-233-56-48.ngrok-free.app" + "/processPaymentConfirmation";
export const REDSYS_DEV_MERCHANT_URL = FIREBASE_FUNCTIONS_URL + "processPaymentConfirmation";

export const REDSYS_REDIRECT_URLS = {
  SUCCESS: "/product/checkout/success",
  SUCCESS_OVERVIEW: PATH_DASHBOARD.general.root_ok.path,
  SUCCESS_PRODUCTS: PATH_DASHBOARD.product.list_ok.path,
  SUCCESS_ADMIN: PATH_DASHBOARD.management.contracts.subcontracts_ok.path,
  ERROR: "/product/checkout/error",
  ERROR_OVERVIEW: PATH_DASHBOARD.general.root_nok.path,
  ERROR_PRODUCTS: PATH_DASHBOARD.product.list_nok.path,
  ERROR_ADMIN: PATH_DASHBOARD.management.contracts.subcontracts_nok.path,
  API: "/",
  API_PROD: "/payments",
};

export const REDSYS_MERCHANT_CODE = "361188170";

export const REDSYS_MERCHANT_INFO_PROD = {
  DS_MERCHANT_MERCHANTCODE: REDSYS_MERCHANT_CODE,
  DS_MERCHANT_TERMINAL: "1",
  DS_MERCHANT_MERCHANTNAME: "SYMPHY",
};

export const REDSYS_MERCHANT_INFO_TEST = {
  DS_MERCHANT_MERCHANTCODE: REDSYS_MERCHANT_CODE,
  DS_MERCHANT_TERMINAL: "1",
  // DS_MERCHANT_TERMINAL: IS_PROD ? "999" : "001",
  DS_MERCHANT_MERCHANTNAME: "SYMPHY",
};
export const REDSYS_MERCHANT_INFO = REDSYS_PROD ? REDSYS_MERCHANT_INFO_PROD : REDSYS_MERCHANT_INFO_TEST;

export const IBAN = "ES49 0182 5723 1002 0163 6979";
export const JAVI_SIGNATURE = "https://symphy-platform.s3.eu-central-1.amazonaws.com/resources/firma_javi.jpg";
