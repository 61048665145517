// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  demo: {
    title: `Spanish`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `Usuario`,
  list: `lista`,
  edit: `editar`,
  shop: `Tienda`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `tarjetas`,
  posts: `posts`,
  create: `Crear`,
  general: `general`,
  profile: `perfil`,
  account: `cuenta`,
  invoice: `factura`,
  details: `detalles`,
  calendar: `calendario`,
  default: `predeterminado`,
  only_admin_can_see_this_item: `Solo administradores pueden ver esto`,
  landing: {
    main_title: "Conectando músicos con",
    main_title_2: "instrumentos",
    main_subheader: "Estamos preparando algo increíble, apúntate para encontrar tu instrumento ideal, sin compromisos.",
    main_subheader_b: "Alquiler de instrumentos musicales a precios nunca vistos.",
    main_subheader_c: "Accede a instrumentos de todas las gamas a precios nunca vistos.",
    main_action: "Quiero alquilar",
    main_colaborative: "¡Gana dinero!",
    main_tell_more: "¡Cúentame más!",
    calculate_how_much: "Calcula cuanto",

    subheader: "La Economía Colaborativa",
    subheader_2: "llega al mundo de la música.",
    subheader_tag: "¿Eres un músico profesional o estudiante de música?\nEsto te interesa.",

    intro_action_title: "¿Quieres un instrumento?",
    intro_action_subtitle: "¡Lo tenemos!",
    intro_action_title_2: "Mira el catálogo",
    intro_action_title_2b: "¿Instrumentos en casa?",
    intro_action_subtitle_2: "Sácales partido",
    show_me: "Muéstrame como",
    review: "Revisar",

    features_entry: "Ahorra",
    features_entry_subtitle: "Accede al catálogo de instrumentos de otros músicos y encuentra la combinación perfecta para tus habilidades y objetivos.",
    features_entry2: "Gana dinero",
    features_entry2_subtitle: "Alquila o vende tus instrumentos a otros músicos y gana dinero mientras lo haces.",
    features_entry4: "Seguro y transparente",

    testimonials_title: "Una sinfonía de",
    testimonials_title2: "historias de éxito",
    testimonials_pretitle: "Ve cómo compartir y suscribirse a instrumentos musicales ha transformado la vida de nuestros usuarios",
    testimonials_subtitle: "Testimonios de nuestros usuarios",

    testimonial:
      "Alquilar un violín en Symphy fue lo mejor que pude hacer. Mi hija quería probar, pero no sabíamos si le iba a gustar de verdad. Todo fue fácil y el violín estaba en muy buenas condiciones. ¡Lo recomiendo un montón!",
    testimonial_name: "Sara Mártinez",
    testimonial_date: "Hace 3 días",
    testimonial_item_reviewed_name: "Servicio de alquiler de violín en Symphy",

    testimonial_2:
      "Siempre quise aprender a tocar el violonchelo, pero comprar uno es un pastón. Con Symphy puedo probarlo sin gastarme un dineral. Además, son súper atentos.",
    testimonial_name_2: "José Márquez",
    testimonial_date_2: "Hace 1 semana",
    testimonial_item_reviewed_name_2: "Servicio de alquiler de violonchelo en Symphy",

    testimonial_3:
      "Me daba miedo alquilar, porque en otras tiendas los instrumentos suelen estar hechos polvo... Pero el violín de Symphy estaba impecable y sonaba genial. Son unos profesionales.",
    testimonial_name_3: "David García",
    testimonial_date_3: "Hace 2 semanas",
    testimonial_item_reviewed_name_3: "Calidad de los violines de alquiler en Symphy",

    testimonial_4:
      "Lo que más me gusta es la flexibilidad que ofrecen. Mi hijo ha tenido que cambiar de tamaño de viola dos veces porque no para de crecer, y con Symphy no me preocupo por comprar uno nuevo cada año.",
    testimonial_name_4: "Jacobo Álvarez",
    testimonial_date_4: "Hace 1 mes",
    testimonial_item_reviewed_name_4: "Flexibilidad en el alquiler de viola en Symphy",

    testimonial_5:
      "No tenía claro si mi hijo iba a seguir con el violonchelo después de unos meses. Al final, quiso cambiar a la viola, y en Symphy solo tuvimos que hacer el cambio, fácil y sin líos.",
    testimonial_name_5: "María López",
    testimonial_date_5: "Hace 2 meses",
    testimonial_item_reviewed_name_5: "Servicio de cambio de instrumentos en Symphy",

    testimonial_6: "¡Symphy es increíble! Tenía un violín viejo que no usaba, y ahora me está generando dinero todos los meses. ¡Es genial!",
    testimonial_name_6: "Javier Pérez",
    testimonial_date_6: "Hace 3 meses",
    testimonial_item_reviewed_name_6: "Servicio de alquiler de violín propio en Symphy",

    testimonial_7:
      "Mi violonchelo estaba ahí cogiendo polvo... ¡Estoy tan contenta de haber encontrado Symphy! Ellos se encargan de todo y yo recibo el dinerito extra. Muy fácil.",
    testimonial_name_7: "Carmen Rivera",
    testimonial_date_7: "Hace 1 mes",
    testimonial_item_reviewed_name_7: "Servicio de alquiler de violonchelo propio en Symphy",

    testimonial_8:
      "Symphy tiene un sistema genial. Mi violonchelo por fin salió del armario y se está tocando, y además, gano dinero extra. ¡Nada de que quejarse!",
    testimonial_name_8: "Javier Ramírez",
    testimonial_date_8: "Hace 4 meses",
    testimonial_item_reviewed_name_8: "Sistema de alquiler de violonchelo en Symphy",

    testimonial_9:
      "No estaba usando mi violín para nada, pero ahora está ayudando a alguien a aprender música y yo gano. ¡Me encanta cómo Symphy lo ha hecho posible!",
    testimonial_name_9: "Isabel Pérez",
    testimonial_date_9: "Hace 5 meses",
    testimonial_item_reviewed_name_9: "Servicio de préstamo de violín en Symphy",

    testimony_v_name_1: "Rosalía",
    testimony_v_description_1: "Madre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_2: "Javier",
    testimony_v_description_2: "Padre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_3: "María",
    testimony_v_description_3: "Madre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_4: "Federico",
    testimony_v_description_4: "Padre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_5: "Marta",
    testimony_v_description_5: "Madre de Paula, estudiante de Cello de 15 años.",

    instruments_title: "Explora Nuestra Colección de Instrumentos",
    instruments_pretitle: "Accede a una amplia variedad de instrumentos para todos los niveles",
    instruments_subtitle: "Elige tu instrumento perfecto",
    collaborators_title: "Confían en nosotros",
    collaborators_subtitle: "Múltiples escuelas de música, luthiers, músicos y organizaciones",

    instruments_cat_title: "Tenemos el instrumento que buscas",
    instruments_cat_pretitle: "Gamas de instrumentos simplificadas",
    instruments_cat_subtitle:
      "Opciones para todos, desde principiantes hasta estrellas.\nNos especializamos en instrumentos de cuerda:\nViolines, violas y violonchelos, entre otros.",

    instruments_drop_title: "Sube tu instrumento.",
    instruments_drop_title2: "Gana dinero.",
    instruments_drop_action_title: "Encuentra tu tienda SYMPHY",
    instruments_drop_action_desc: "Llévalo a la tienda más cercana para que lo registren gratis.",
    instruments_drop_action: "Buscar tienda",
    instruments_drop_action2: "Como funciona",

    query: "Consultar",

    all: "Todas",
    features: "Características",
    plan_title1: "Iniciación",
    soon: "Pronto",
    find_your_instrument: "Encuentra tu instrumento",
    we_have_the_instrument: "¡Tenemos exáctamente lo que estás buscando!",
    violins: "Alquiler de Violines",
    violas: "Alquiler de Violas",
    violoncellos: "Alquiler de Violonchelos",
    violins_beginner: "Alquiler de Violines para Principiantes",
    violins_professional: "Alquiler de Violines para Profesionales",
    violas_beginner: "Alquiler de Violas para Principiantes",
    violas_professional: "Alquiler de Violas para Profesionales",
    cellos_beginner: "Alquiler de Violonchelos para Principiantes",
    cellos_professional: "Alquiler de Violonchelos para Profesionales",
    general_keywords:
      "Symphy, alquiler de instrumentos musicales, alquiler de violines, violas, cellos, contrabajos, instrumentos de cuerda, alquiler instrumentos musicales,alquiler instrumentos musicales niños, alquiler instrumentos Madrid, instrumentos musicales para principiantes, instrumentos para profesionales, alquiler violín, alquiler de violines, alquiler viola, alquiler de violas, alquiler violonchelo, alquiler chelo, alquiler de violonchelos, renta de instrumentos, instrumentos musicales Madrid, violines en alquiler, violas para principiantes, instrumentos para músicos, alquiler económico de instrumentos, música clásica, cuerdas, instrumentos sinfónicos, instrumentos para niños, violines para niños, violas para niños, cellos para niños, violonchelos para niños",
    general_desc:
      "Encuentra el alquiler del instrumento que buscas desde 14.99€ al mes. Violines, violas, cellos y mucho más. Para todos los niveles, iniciación, conservatorio y profesional",
    violins_desc:
      "Alquiler de Violines desde 14.99€ al mes con opción a compra. Para todos los niveles: niños y estudiantes, iniciación, conservatorio y profesional",
    violas_desc:
      "Alquiler de Violas desde 14.99€ al mes con opción a compra. Para todos los niveles: niños y estudiantes, iniciación, conservatorio y profesional",
    cellos_desc:
      "Alquiler de Violonchelos desde 14.99€ al mes con opción a compra. Para todos los niveles: niños y estudiantes, iniciación, conservatorio y profesional",
    violins_desc_beginner: "Violines para principiantes desde 14.99€ al mes con opción a compra. Todos los tamaños: 1/4, 1/2, 3/4, 4/4. Incluye arco y funda.",
    violins_desc_professional:
      "Violines para profesionales desde 34.99€ al mes con opción a compra. Asegurados, calidades superiores y verificados por nuestros luthieres.",
    violas_desc_beginner: "Violas para principiantes desde 14.99€ al mes con opción a compra. Todos los tamaños: 1/4, 1/2, 3/4, 4/4. Incluye arco y funda.",
    violas_desc_professional:
      "Violas para profesionales desde 34.99€ al mes con opción a compra. Asegurados, calidades superiores y verificados por nuestros luthieres.",
    cellos_desc_beginner:
      "Violonchelos para principiantes desde 14.99€ al mes con opción a compra. Todos los tamaños: 1/4, 1/2, 3/4, 4/4. Incluye arco y funda.",
    cellos_desc_professional:
      "Violonchelos para profesionales desde 34.99€ al mes con opción a compra. Asegurados, calidades superiores y verificados por nuestros luthieres.",
  },

  navigation: {
    general: "General",
    billing: "Pagos",
    home: "Inicio",
    account_settings: "Ajustes de cuenta",
    settings: "Ajustes",
    seller_area: "Tu tienda",
    my_shop: "Mi tienda",
    my_products: "Mis productos",
    products: "Productos",
    product_definitions: "Definiciones de productos",
    store_listings: "Anuncios de la tienda",
    product_requests: "Solicitudes de instrumentos",
    product_details: "Detalles del producto",
    product_overview: "Resumen del producto",
    product_edit: "Editar producto",
    player_area: "Musician's corner",
    dashboard: "Dashboard",
    add_product: "Añadir producto",
    artists: "Artistas",
    products_to_verify: "Productos por verificar",
    to_verify: "Por verificar",
    products_stored: "Productos almacenados",
    discounts: "Descuentos",
    discount: "Descuento",
    discount_subs: "Descuento cuota",

    fees: "Comisiones",

    management: "Administración",
    users: "Gestión de usuarios",
    marketplace: "Tienda de Instrumentos Musicales",
    to_marketplace: "¡Al catálogo!",
    list: "Lista",
    shops: "Tiendas",
    invoices: "Facturas",
    apps: "Apps",
    chat: "Chat",
    contracts: "Contratos",
    recommended_contracts: "Contratos recomendados",
    customer_contracts: "Contratos de propietarios",
    customer_subcontracts: "Contratos de usuarios",
    platform_config: "Configuración plataforma",

    contact: "Contacto",
    about: "Sobre nosotros",
    about_us: "Sobre nosotros",
    logout: "Cerrar sesión",
    faqs: "FAQs",

    instruments: "Instrumentos",
    partners: "Para socios",

    shops_finder: "Tiends Colaboradoras",
    help_support: "Ayuda y soporte",
    legal: "Legal",
    transactions: "Transacciones",
    to_settings: "A los ajustes",
    overview: "Resumen",
    services: "Servicios",
    details: "Detalles",
  },
  intro: {
    action_title_a: "¿Como te gustaría",
    action_title_b: "continuar en SYMPHY?",
    action_subtitle: "¿Dejar un instrumento para ganar dinero o conseguir un instrumento?",
    action_drop: "Dejarlo",
    action_get: "Obtenerlo",

    action_drop_title_a: "Tus instrumentos",
    action_drop_title_b: "siempre con SYMPHY",
    action_drop_subtitle: "Así es como funciona",

    action_drop_button: "Registrar mi instrumento",

    action_get_title_a: "Hora de decirnos",
    action_get_title_b: "tus necesidades",
    action_get_title_full: "Hora de decirnos <0>tus necesidades</0>",
    action_get_subtitle: "Selecciona tu configuración ideal",

    min_contract_duration: "Duración de contrato mínima:",

    no_data: "No hemos encontrado nada.",
    notify_me: "¿Te gustaría que te notifiquemos cuando lo tengamos?",
    notify_me_action: "Notifícame!",
    no_data_ready: "Todo listo",
    notify_me_ready: "Te notificaremos en cuanto tengamos tu instrumento ideal",
    required_instrument: "Instrumento necesario",
    no_data_adjust: "Intenta ajustar los filtros",
    give_email: "Déjanos tu email",

    upload_your_instrument: "Sube tu instrumento",
    upload_your_instrument_subtitle: "Cuéntanos todo sobre tu instrumento y configura las condiciones de alquiler o venta según tus preferencias.",
    take_to_shop: "Llévalo a una tienda",
    take_to_shop_subtitle: "Llévalo a una tienda colaboradora con SYMPHY para que lo revisen y lo pongan a punto. Nosotros nos encargamos de todo lo demás.",
    share_and_earn: "Comparte y gana",
    share_and_earn_subtitle: "Nosotros nos encargamos de todo el proceso de alquiler y ventas. Tu recibirás tus ganancias mensualmente.",
    security: "Seguridad",
    security_subtitle:
      "We try to ensure that all the instruments are well taken care off, but just in case, we insure all of them internationally. \nWe also make sure that all the users are properly identified.",
  },

  intro_seller: {
    action_title_a: "Ofrece tus servicios",
    action_description_a: "Comparte tus instrumentos musicales y servicios con nuestra creciente base de usuarios, conectando con músicos y entusiastas.",
    action_title_b: "Obtén visibilidad",
    action_description_b:
      "Mejora tu exposición en el mercado a través de nuestras campañas de marketing, permitiéndote conectar con una audiencia más amplia y comprometida.",
    action_title_c: "Gana dinero",
    action_description_c: "Además recibirás una comisión por cada producto que almacenes y revises.",
    action_title_d: "Forma parte de la comunidad",
    action_description_d: "Amplía tus conexiones en la industria dentro de nuestra próspera comunidad, fomentando oportunidades de crecimiento y colaboración.",
    questions_title: "¿Preguntas?",
    questions_description: "No dudes en contactarnos.",
    questions_description_2: "Selecciona la opción de colaboraciones en el asunto.",
  },
  contact: {
    contact_us: "Escríbenos",
    full_name: "Nombre completo",
    subject: "Asunto",
    how_help: "¿Como podemos ayudarte?",
    placeholder_message: "Describe to problema en menos de 250 caracteres",
    placeholder_message_drop:
      "-Número de teléfono:\n\n-Ciudad:\n\nSobre lo que tengo.\n-Instrumento:\n-Tamaño:\nValor/gama\n-Estado:\n-Accesorios(ej:estuche, arco):",
    placeholder_message_request:
      "-Número de teléfono:\n\n-Ciudad:\n\nSobre lo que busco.\n-Instrumento:\n-Tamaño:\nValor/gama\n-Estado:\n-Accesorios(ej:estuche, arco):",
    placeholder_message_partner: "Cuéntanos sobre tu tienda o como te gustaría colaborar con nosotros:\n",
    default_message: "-Número de teléfono:\n\n-Ciudad:\n\nSobre lo que busco.\n-Instrumento:\n-Tamaño:\nValor/gama\n-Estado:\n-Accesorios(ej:estuche, arco):",
    action_request_instrument: "Necesito un instrumento",
    become_partner: "Colaboraciones y socios",
    others: "Otros",
    description: "Para cualquier pregunta, incluyendo propuestas de socios, por favor envía un correo a info@symphy.es o contacta utilizando el formulario.",
  },

  notifications: {
    contract_added: "Contrato añadido",
    contract_updated: "Contrato actualizado",
    payment_requested: "Pago solicitado",
    payment_requested_error: "Error solicitando pago",
    valuation_requested: "Tasación solicitada",
    service_registered: "Servicio registrado",
    service_registered_error: "Error registrando servicio",
    service_updated_error: "Error actualizando servicio",
    listing_created: "Anuncio creado",
    listing_updated: "Anuncio actualizado",
    listing_created_error: "Error creando anuncio",
    max_reserved_items_error: "No se pueden reservar más de 5 productos",
    reservations_no_discount: "El descuento no se puede aplicar si realizas reservas",

    order_cancelled: "Pedido cancelado",
    order_cancelled_error: "Error cancelando pedido",
  },

  required_address: "Introduce dirección exacta",
  required_shop_name: "Introduce nombre de la tienda",
  required_name: "Introduce nombre",
  required_lastname: "Introduce apellidos",
  required_email: "Introduce email",
  required_pass: "Introduce la contraseña",
  required_phone: "Introduce teléfono",
  required_subject: "Escoge asunto",
  required_message: "Introduce un mensaje",
  required_city: "Introduce ciudad",
  required_region: "Introduce región/provincia",
  required_zip_code: "Introduce código postal",
  required_tax_number: "Introduce CIF/NIF",
  required_iban: "Introduce IBAN",

  invalid_email: "Email inválido",
  invalid_iban: "IBAN inválido",

  required_type: "Selecciona un tipo",
  required_description: "Introduce una descripción",
  required_price: "Introduce un precio",

  actions: {
    ok: "Ok",
    back: "Atrás",
    buy: "Comprar",
    reserve: "Reservar",
    subscribe: "Subscribirse",
    skip: "Saltar",
    save: "Grabar",
    save_changes: "Grabar cambios",
    update: "Actualizar",
    update_data: "Actualizar datos",
    update_photo: "Actualizar foto",
    send: "Enviar",
    send_message: "Enviar mensaje",
    message_sent: "Mensaje enviado",
    create_new_product: "Crear nuevo producto",
    create_product: "Crear producto",
    new_product: "Nuevo producto",
    new_product_definition: "Nueva definición de producto",
    view: "Ver",
    view_listing: "Ver anuncio",
    edit: "Editar",
    edit_price: "Editar precio",
    edit_ad: "Editar anuncio",
    completed: "Completado",
    contact_customer: "Contactar cliente",
    share: "Compartir",
    delete: "Borrar",
    delete_children: "Borrar todos los datos relacionados",
    copy_link: "Copiar link",
    copied: "¡Copiado!",
    product_step_1: "Ficha del producto",
    product_step_2: "Anuncio online",
    product_step_3: "Contratos",
    search: "Buscar",
    choose: "Escoger",
    activate_subscription: "Activar subscripciones",
    create_store_listing: "Crear anuncio",
    update_store_listing: "Actualizar anuncio",
    activate_sale: "Activar venta",
    directions: "Navegar",
    close: "Cerrar",
    copy: "Copiar",
    request_validation: "Solicitar tasación",
    validation_requested: "Tasación solicitada",
    remove_all: "Borrar todo",
    upload_files: "Subir archivos",
    drop_or_select: "Arrastrar o seleccionar archivo",
    drop_or_select_desc: "Arrastrar archivos o <0>busca</0> en tu PC.",
    clear: "Limpiar",
    clear_all: "Limpiar todo",
    yes: "Si",
    no: "No",
    continue: "Continuar",
    verify: "Verificar",
    verify_instrument: "Verificar instrumento",
    verification_needed: "Validar instrumento",
    unverify: "Desverificar",
    activate: "Activar",
    deactivate: "Desactivar",
    pending_payment: "Pago pendiente",
    pending_pickup: "Recoger instrumento",
    verify_customer: "Verificar cliente",
    give_instrument: "Dar instrumento",

    accept_all: "Aceptar todas",
    reject_all: "Rechazar todas",
    cancel: "Cancelar",
    accept: "Aceptar",
    reject: "Rechazar",
    add_shop: "Añadir tienda",
    set_visible: "Hacer visible",
    delete_tip: "¿Estás seguro de que quieres borrar?",
    delete_tip_value: "¿Estás seguro de que quieres borrar {{value}} items?",
    email_sent: "Email enviado",
    password_updated: "Contraseña actualizada",
    password_updated_error: "Hubo un error al actualizar la contraseña",
    item_validated: "El artículo ha sido validado",
    updated: "Actualizado",
    error: "Error",
    click_here: "Click aquí",
    create_contract: "Crear contrato",
    update_contract: "Actualizar contrato",

    request_payment: "Solicitar pago",
    request_service: "Solicitar servicio",
    create_service: "Crear servicio",
    update_service: "Actualizar servicio",

    view_all: "Ver todo",
    sure_delete: "¿Estás seguro de que quieres borrar?",
    view_invoice: "Ver factura",
    edit_invoice: "Editar factura",
    view_contract: "Ver contrato",
    duplicate: "Duplicar",
    mark_collected: "Marcar como recogido",
    charge: "Cobrar",
    charge_custom: "Cobrar ajustable",
    manual_confirmation: "Confirmación manual",
    resend_link: "Reenviar link",
    refund: "Reembolsar",
    add_more: "Añadir más",
    add_service: "Añadir servicio",

    request_maintenance: "Solicitar mantenimiento",
    publish: "Publicar",
    loading: "Cargando",
    error_opening_file: "Error abriendo archivo",
    updating: "Actualizando",

    maintenance_requested: "Maintenimiento solicitado",
    in_maintenance: "En mantenimiento",
    view_service: "Ver servicio",
    sign: "Firmar",
    apply: "Aplicar",
    apply_later: "Más tarde",
    help: "Ayuda",
    click_to_sign: "Click para firmar",
    request: "Solicitar",

    complete_order: "Completar pedido",
    cancel_order: "Cancelar pedido",
    to_find: "¡Encontrar!",
  },

  auth: {
    login: "Iniciar sesión",
    register: "Registro",
    already_account: "¿Ya tienes una cuenta?",
    review_account: "Revisa tus datos",
    dont_have_account: "¿No tienes una cuenta?",
    dont_have_account_yet: "¿Aun no tienes cuenta?",
    data_needed: "Necesitamos los siguientes datos para el contrato del instrumento",
    signin: "Iniciar sesión",
    or_use_email: "O usa tu email",
    or_login_with: "O inicia con",
    use_ii: "Usa Internet Identity",
    use_ii_login: "Inicia sesión con Internet Identity",
    use_ii_description: "Inicia sesión",
    use_ii_description2: "Sin contraseñas",
    forgot_password: "¿Olvidaste tu contraseña?",
    reset_password: "Restablecer contraseña",
    repeat_password: "Repite la contraseña",
    repeat_new_password: "Repite la nueva contraseña",
    write_new_password: "Escribe tu nueva contraseña",
    passwords_must_match: "Las contraseñas deben coincidir",
    update_password: "Actualizar contraseña",
    new_password: "Nueva contraseña",
    reset_password_send_code: "Se enviará a tu email un código para restablecer la contraseña",
    send_reset_email: "Enviar email de restablecimiento",
    password_length: "La contraseña debe tener al menos 6 caracteres",

    partners: "Colaboradores",
    partners_register: "Registro colaboradores",
    partner_cif: "CIF/NIF",
    id_card: "DNI o Carnet de Identidad",

    role_seller: "Vendedor",
    role_user: "Usuario",
    role_admin: "Administrador",
    role_admin_seller: "Admin & Vendedor",
    role_user_unverified: "Id sin verificar",
    wrong_password: "Contraseña errónea",
    user_not_found: "Usuario no encontrado",
    email_in_use: "Email ya utilizado",
    login_error: "Error iniciando sesión",
    sent_reset_email: "Email de restablecimiento enviado. Comprueba tu bandeja de entrada. Si no lo recibes, comprueba la carpeta de spam.",
  },
  //User profile
  username: "Nombre de usuario",
  name: "Nombre",
  lastname: "Apellidos",
  email: "Email",
  phone: "Teléfono",
  address: "Dirección",
  address_full: "Dirección completa",
  country: "País",
  about: "Sobre ti",
  floor: "Piso",
  city: "Ciudad",
  region: "Región/Provincia",
  zip_code: "Código postal",
  other_info: "Otra información de dirección",

  security: "Seguridad",
  password: "Contraseña",
  password_change: "Cambiar contraseña",
  password_old: "Contraseña anterior",
  password_new: "Nueva contraseña",
  password_confirm_new: "Confirmar contraseña",
  password_req_min: "al menos 6 caracteres",

  seller_settings: "Ajustes de vendedor",
  allowed_img_format: "Permitido *.png",
  allowed_img_size: " tamaño máximo de {{value}}",
  necessary_if_buying: "Necesario si quieres comprar o depositar instrumentos",

  all: "Todas",
  need_help: "¿Necesitas ayuda?",
  recommended: "Recomendado",
  search_instrument: "Buscar instrumento",
  terms_and_conditions: "Términos y Condiciones",
  privacy_policy: "Política de privacidad",
  i_agree_terms: "Acepto los <0>Términos del Servicio</0>.",
  must_accept_contract_terms: "Debes aceptar los <0>Términos y Condiciones del Contrato</0> para completar el pedido.",
  must_sign_contract: "Debes firmar el <0>Contrato de Ventas y Subscripciones</0> para completar el pedido.",
  must_sign_contract_deposit: "Debes firmar el <0>Contrato de Depósitos</0> para registrar el instrumento.",
  must_accept_terms: "Debes aceptar los Términos y Condiciones.",
  payments_policy: "Política de pagos",
  shipping_policy: "Política de envíos y devoluciones",
  secure_payment: "Pago seguro",
  shipping: "Envío",
  // Marketplace or search
  item: "Artículo",
  where: "Donde",
  where_question: "¿Donde?",
  anywhere: "Cualquier lugar",
  how: "Como",
  any: "Cualquiera",
  distance: "Distancia",
  description: "Descripción",
  instrument_services: "Servicios de instrumentos",
  deposit: "Depósito",
  pick_up: "Recogida",
  which_instrument: "¿Qué instrumento?",
  search_location: "Selecciona ubicación",
  more_options: "Más opciones",
  options: "Opciones",
  instrument: "Instrumento",
  accesory: "Accesorio",
  price: "Precio",
  price_monthly: "Precio cuota",
  sale_price: "Precio venta",
  sizes: "Tamaños",
  select_one: "Selecciona uno",
  select_any: "Selecciona alguno",
  pick_one: "Escoge uno",
  select_options: "Escoge opciones",
  pick_instrument: "Escoge instrumento",
  subscription_min_duration: "Duración mínima subscripción",
  subscription_from_to: "De 3 a 12 meses",
  search_sales: "Buscar en venta",
  search_sales_description: "Se mostrarán artículos en venta",
  select_price_per_month: "Selecciona cuota mensual",
  select_price_sale: "Selecciona rango de precios",

  months_value: "{{value}} meses",
  price_range_value: "de {{min}}€ a {{max}}€",
  price_range_value_month: "de {{min}}€ a {{max}}€ / mes",

  // Create product
  product_spec: "Especificaciones del producto",
  properties: "Propiedades",
  other_properties: "Otras propiedades",
  other_properties_description: "Añade propiedades adicionales para mejorar la visibilidad de tu producto",
  product_type: "Tipo de producto",
  product_types: "Tipos de producto",
  product_name: "Nombre del producto",
  product_name_placeholder: "Violín modelo Stradivari",
  product_ad_name: "Nombre del anuncio",

  product_description: "Descripción del producto",
  product_description_placeholder: "Información relevante del artículo.",
  product_short_description: "Descripción breve del producto",
  ad_text: "Texto del anuncio",

  product_state_title: "Daños actuales",

  product_state_description: "Describe cualquier daño que tenga el producto",
  additional_info_title: "Información adicional sobre el producto",
  additional_info_description: "Cualquier dato relevante que se te ocurra sobre el producto",
  product_owner: "Propietario del producto",
  product_owner_search: "Buscar propietario por email...",
  product_customer_search: "Buscar cliente por email...",
  product_search: "Buscar producto...",
  product_not_found: "Producto no encontrado",
  user_not_found: "Usuario no encontrado",
  product_address_tip: "Donde se puede encontrar el producto, típicamente en la tienda de SYMPHY seleccionada.",
  size: "Tamaño",
  brand: "Marca",
  weight_kg: "Peso aproximado en kg",
  serial_number: "Número de serie",
  year: "Año",
  product_list: "Lista de productos",
  subcontract_list: "Contratos de clientes",
  transaction_list: "Transacciones",
  valuation_price: "Precio tasación",
  undefined: "Sin definir",
  none: "Ninguno",
  what_included: "¿Que incluye el precio?",
  includes: "Incluye:",
  owner_share: "Aportación para el propietario",
  you_receive: "Tu recibes",
  year_simulation: "Simulación anual",
  sale_simulation: "Simulación de venta",
  admin_fees: "Gastos de gestión e IVA",
  fees: "Gastos de gestión",
  discount_on_fees: "Descuento en gastos de gestión",
  congratulations: "¡Enhorabuena!",
  discount_for_referrals: "Descuento por referidos acumulado",
  fees_and_commissions: "Gastos y comisiones de servicio",
  admin_fees_sum: "Gastos de gestión e IVA",
  admin_fees_include: "Los gastos de gestión incluyen:",
  admin_fees_tip: "Esto nos ayuda a mantener la plataforma, cubrir los costes de los partners, logística, seguros, y ofrecerte el mejor servicio posible.",
  admin_fees_tip_vat:
    "Esto nos ayuda a mantener la plataforma, cubrir los costes de los partners, logística, seguros, y ofrecerte el mejor servicio posible. \nIncluye IVA.",
  price_includes_what: "¿Que incluye el precio?",
  start_date: "Fecha de comienzo",
  pay_before: "Pagar antes de",
  late_payments: "Pagos retrasados",
  late: "Retrasado",
  end_date: "Fecha de fin",
  needs_validation: "El producto debe ser validado antes de poder ser mostrado en la tienda ONLINE",
  needs_validation_subtitle: "Encuentra tu punto SYMPHY más cercano",
  needs_valuation_expert: "*La tasación proporcionada debe ser verificada por un experto SYMPHY. <0>Consulta nuestro FAQ</0>",
  needs_validation_explain: "Selecciona ok tras revisar todos los datos",
  product_limit_exceeded: "Has alcanzado el límite de productos",
  product_limit_exceeded_explain: "Para evitar usos fraudulentos tenemos un límite de productos por usuario. Para ampliarlo, por favor contacta con nosotros.",
  info: "Información",
  seller_needs_verified:
    "Estamos revisando su solicitud de ser Partner, contacte con nosotros si no es aceptada en 5 días hábiles. Podrás añadir tiendas una vez sea aceptada.",

  on_going_contract: "Contrato en curso",
  on_going_contract_explain: "El producto no puede ser mostrado en la tienda mientras haya un contrato en curso. Solo puede ser modificado por SYMPHY.",
  estimated_value: "Valor estimado de {{value}}",
  option_to_buy: "Opción a compra",
  option_to_buy_discount: "Opción a compra durante los primeros 6 meses, con devolución del {{value}} de las cuotas pagadas",
  option_to_buy_description: "El usuario podrá comprar el instrumento con un descuento en función de las cuotas pagadas",
  pending_payment_tip: "El usuario debe pagar el producto.",
  discount_value: "Descuento: {{value}}",
  no_discount: "Sin descuento",

  brand_new: "Nuevo",
  like_new: "Como nuevo",
  used: "Usado",
  subscription: "Subscripción",
  reserved_until: "Reservado hasta {{value}}",
  reserve_value: "Reserva: {{value}}",
  reserve_value_tip: "Reserva en la tienda 3 días laborables por {{value}}",
  reserve_tip_long: "Se descontará el importe de la reserva del primer pago. Si no se recoge el artículo en 3 días laborables, se devolverá al completo.",

  after: "Después",
  sale: "Venta",
  donation: "Donación",
  subscription_fee: "Cuota de",
  month: "mes",
  twelve_months_contract: "Contrato de 12 meses",
  also: "Además",
  international_insurance: "Seguro internacional",

  no_search_results: "No se encontraron resultados",
  item_description: "Descripción del producto",
  where_item: "Donde lo encontrarás",
  additional_location: "Recibirás la ubicación exacta y datos de contacto cuando se confirme la reserva",
  checkout_title: "Checkout",
  duration: "duración",
  details_title: "Detalles",
  details_description: "Nombre, tipo de producto, tamaño, imágenes...",
  product_users_title: "Usuario del producto",
  product_users_description: "Propietario del instrumento",
  shop_details_title: "Detalles del anuncio online",
  shop_details_description: "Datos que serán mostrados en el anuncio online público",
  shop_name: "Nombre público en la tienda",
  shop_description: "Descripción de la tienda",
  shop_description_placeholder: `¿Buscas un [nombre del instrumento] en excelentes condiciones? ¡No busques más! Este [marca/modelo] ofrece una calidad de sonido increíble y ha recibido un buen mantenimiento.

Detalles clave:
- Estado: Excelente
- Año: [año]
- Marca: [nombre de la marca]
- Incluye: [accesorios incluidos]

Perfecto tanto para principiantes como para profesionales, este [nombre del instrumento] está listo para llevar la música a tu vida.
`,
  show_in_shop: "Hacer el producto visible en la tienda",
  where_to_find_item: "Donde encontrar el producto",
  where_to_find_item_desc: "Tienda donde has depositado el producto.\n(Se rellena automáticamente si la tienda ya validó el artículo).",
  where_to_deposit_item: "Donde depositar el producto",
  where_to_deposit_desc: "Escoge la tienda donde llevarás el producto.",
  collection_point: "Punto de recogida",

  cart: "Carrito",
  order_verification: "Verificación del pedido",
  payment: "Pago",
  your_data: "Tus datos",
  completed: "Completado",
  deposits: "Depósito",
  deposit_tip: "Se devolverá cuando se devuelva el producto",
  reservation_tip: "Se descontará del primer pago o se devolverá si no se recoge el instrumento en 3 días laborables",

  taxes: "Impuestos e IVA",
  expected_monthly_income: "Ingresos mensuales esperados",
  subs_price_month: "Precio mensual",
  after_user_subscribes: "*Después de que el usuario se suscriba",
  contract_urls: "URLs de contratos",
  fees_and_taxes: "Gastos e impuestos",
  fees_and_taxes_deducted: "*Comisiones de servicio e impuestos serán deducidos",
  contract_options: "Opciones del contrato",
  allow_short_term: "Permitir contratos de corta duración",
  allow_short_term_description: "Contratos de 3 o 6 meses serán posibles. El precio de la cuota será más alto por mes.",
  price_subs_auto: "*Para promover los precios justos, el precio se calcula automáticamente en función de la tasación.",
  pricing: "Precios",
  pricing_description: "Tipos de contrato y configuración",
  free_trial: "Prueba sin compromiso",
  verified_100: "100% Verificado",
  insured: "Asegurado",
  fair: "Justo",
  images: "Imágenes",
  image_urls: "URLs de imágenes separadas por comas",
  search_name_id: "Buscar por usuario, nombres o ID...",
  info_accurate_certify: "Certifico que la información proporcionada es correcta",
  product_updated: "Producto actualizado",
  product_created: "Producto creado",
  in_shop: "Anunciado",
  ad: "Anuncio",
  created_at: "Creado",
  modified: "Modificado",

  in_use: "En uso",
  no_results_for: "No se encontraron resultados para <0>{{query}}</0>.<br/>Intenta revisar si hay errores tipográficos o usa palabras completas.",
  enter_keywords: "Por favor introduce palabras clave",
  not_found: "No encontrado",
  symphy_signature: "Firma SYMPHY",
  user_signature: "Firma USUARIO",

  accessories: {
    title: "Accesorios",
    accessories_description: "Arco, estuche rojo, etc.",
    case: "Funda",
    bow: "Arco",
    strings: "Cuerdas",
    mute: "Sordina",
    rosin: "Resina",
    tuner: "Afinador",
    metronome: "Metrónomo",
  },

  product: {
    title: "Producto",
    product_id: "ID del producto",
    customer: "Cliente",
    status: "Estado",
    state: "Estado",
    status_active: "Activo",
    status_cancelled: "Cancelado",
    status_paused: "En Pausa",
    status_reserved: "Reservado",
    status_reservation_expired: "Reserva caducada",
    status_reservation_expired_desc: "La reserva ha caducado porque el pago no se ha completado. Por favor, realiza el pedido de nuevo.",
    status_draft: "Borrador",
    status_completed: "Completado",
    status_pending_verification: "Verificación pendiente",
    status_verification_completed: "Verificación completada",
    price_from: "{{value}}/mes",
    no_products: "No hay productos",
    no_payments: "Sin pagos",
    buy: "Compra",
    one_payment: "Pago único",
    try_or_change: "Si no te convence, puedes cambiarlo por otro o cancelar y obtener un reembolso completo.",
    verified_description: "Todos los productos en SYMPHY han sido verificados y validados por nuestros expertos.",
    insured_description: "Todos los productos en SYMPHY están asegurados internacionalmente.",
    fair_description: "Todos los productos en SYMPHY tienen un precio justo y transparente.",
    total_to_pay: "Total a pagar: {{value}}",
    contact_details: "Detalles de contacto",
    action: "Acción",
    nothing_to_do: "Nada que hacer",
    verify_instrument_seller_tip: "Confirma que los campos del instrumento son correctos, especialmente el precio",
    give_instrument_tip: "Ahora puedes dar el instrumento al usuario",
    reservations: "Reservas",
    reserved_action_desc:
      "Selecciona los artículos que te quieres quedar.\nLa reserva de los demás productos será cancelada y obtendrás un reembolso de los pagos de reserva pendientes.",
    error_not_found: "Producto no encontrado",
    error_listing_not_found: "Anuncio no encontrado",
    actions_summary: "Resumen de acciones",
    summary: "Resumen",
    instrument_taken_shop: "Instrumento llevado a la tienda",
    instrument_taken_shop_desc:
      "El instrumento ha sido llevado a la tienda para ser revisado y depositado.\n El cliente ha registrado el instrumento previamente en SYMPHY.\nEl instrumento será visible desde tu lista de productos por verificar.",
    verify_desc:
      "Confirma que los campos del instrumento son correctos, especialmente el precio: \n•El usuario debe aportar una factura/comprobante del valor. \n•Si no dispone del mismo, debes estimar el valor. En caso de no ser posible, el instrumento no será validado.\nNo te olvides de registrar, cualquier imperfección, daño y estado de las cuerdas en el apartado 'Otras propiedades'",
    verify_user_desc:
      "Confirma que la información del cliente es correcta, especialmente el DNI.\n(Este paso solo es necesario si se te solicita através de la interfaz)",
    store_instrument: "Almacenar instrumento",
    store_instrument_desc: "Guarda el instrumento en la tienda hasta que otro cliente lo solicite",
    step_nr: "Paso {{value}}",
    instrument_collection: "Recogida del instrumento",
    instrument_acquisition: "Adquisición del instrumento",
    instrument_acquisition_desc:
      "El cliente compra o se suscribe a un producto en la tienda online.\nEl instrumento aparecerá en tu lista de productos almacenados",
    instrument_acquisition_tip: "El instrumento aparecerá en tu lista de productos almacenados",
    pending_pickup_expired: "Ha pasado más de una semana desde el pago",
    contract: "Contrato",
    current_contract: "Contrato actual",
    customer_info: "Cliente",
    configure_contract_error: "Un contrato de propietario tiene que ser creado.",
    request_payment_desc: "Se enviará una petición de pago al cliente.",
    payment_already_requested: "Pago ya solicitado: {{value}}",

    good_state_desc: "El instrumento está en buenas capacidades para su uso.",
    product_link: "Enlace al producto",
    reserved_owner_tip: "El producto está reservado para un posible comprador.",
    reserved_seller_tip:
      "El producto está reservado.\nEl cliente puede probarlo y decidir si se lo queda.\nEn ese caso deberá realizar el pago completo desde su perfil.",
  },

  cookies: {
    title: "Cookies",
    title_policy: "Política de cookies",
    banner_title: "Nuestras cookies",
    banner_text:
      "Al igual que la mayoría de los sitios web que visita, utilizamos cookies en su dispositivo para mejorar su experiencia y comprender cómo podemos mejorar nuestro sitio web. Esto incluye Google Analytics para realizar análisis estadísticos del uso de la página, las interacciones de la página y las rutas a través del sitio web para hacer el sitio web mejor.",
    learn_more: "Leer más",
  },
  about_us: {
    team: "El equipo",
    we_are_javi_pablo: "Somos Javier y Pablo",
    team_subtitle: "Our team will provide you support if you have any problems",
    who: "¿Quienes somos?",
    what: "¿Que es SYMPHY?",
    how: "¿Como funciona?",
    where: "¿Donde estamos?",
    why_us: "Por qué nosotros",
    explain: "Conéctate, Comparte, y Subscríbete con facilidad",
    find_us: "Encuéntramos en",
    they_recommend_symphy: "Ellos recomiendan <0>SYMPHY</0>",
    mission: "Nuestra misión",
    mission_description:
      "Nuestra misión es inspirar a millones de músicos a conectarse y empoderarlos para que liberen a sus verdaderos artistas internos. Creemos que al compartir recursos y apoyarnos unos a otros, podemos construir una comunidad musical más fuerte e inclusiva.",
    any_questions: "Preguntas o solicitudes",
    any_questions_description: "¿Te gustaría recibir más información o solicitar algún instrumento de nuestro catálogo?",
    contact_team: "Contacta al equipo",
    pablo:
      "Experto en software con más de 10 años de experiencia en la industria espacial y el desarrollo de aplicaciones.\nCon estudios musicales de violín.\nCombina experiencia técnica con visión estratégica y planificación para impulsar la innovación y el crecimiento.",
    javi: "Violenchelista en orquestas internacionales, solista y coordinador de múltiples proyectos musicales a nivel Europeo.\nProfesor de Cello con más de 12 años de experiencia.\nTrae pasión por la música y dirije las actividades de márketing y logísticas.",
    why_us_pre: "Descubre los beneficios de usar SYMPHY",
    why_us_sub:
      "Entérate de cómo nuestra plataforma te puede ayudar a conectarte, compartir y suscribirte a instrumentos musicales con una comunidad colaborativa",
    card_title: "Variedad",
    card_description: "Ofrecemos una amplia gama de instrumentos de diferentes marcas y modelos.",
    card_title2: "Comunidad y colaboración",
    card_description2:
      "Te conectamos con una comunidad de músicos que comparten tu pasión y pueden ayudarte a crecer como artista. Creemos en el poder de la colaboración y la creación de comunidades.",
    card_title3: "Seguridad",
    card_description3:
      "Puedes estar seguro de que tus instrumentos están en buenas manos cuando los compartes a través de nuestra plataforma. Queremos que puedas concentrarse en lo que es importante: disfrutar de la música.",

    how_title1: "Háblanos de ti",
    how_description1:
      "Crear un perfil en SYMPHY es fácil. Simplemente regístrate y proporciona información sobre ti, como nivel de experiencia, instrumentos preferidos y ubicación.",
    how_title2: "Encuentra a tu match",
    how_description2:
      "Una vez que hayas creado un perfil, puedes comenzar a buscar posibles coincidencias. Puedes usar nuestras funciones de búsqueda y filtro para encontrar tu combinación perfecta.",
    how_title3: "Recoge tu nuevo instrumento",
    how_description3:
      "Una vez que hayas encontrado lo que buscas y te suscribas a un instrumento, puedes concertar la recogida. Simplemente comunícate con el gestor del instrumento y pruébalo. Nuestra plataforma proporciona una manera segura y fácil de comunicarse con el administrador, y hacer los arreglos necesarios para la recogida y devolución.",
    how_title4: "Disfruta del proceso",
    how_description4:
      "Nososotros nos encargamos de todas las complicaciones y nos aseguramos de que el proceso de suscripción sea simple, seguro y fácil. También brindamos recursos y soporte para ayudarte a aprovechar al máximo la plataforma y asegurarnos de que tengas una experiencia impecable.",
    how_tagline1: "La música no conoce fronteras",
    how_tagline1_description: "Explora nuestra colección de instrumentos",
    how_tagline2: "Abre las puertas a posibilidades infinitas",
    how_tagline2_description: "Únete ahora y amplía tus horizontes musicales",
    how_tagline2_action: "Únete",

    where_description:
      "En Symphy, estamos orgullosos de atender a músicos de todo Madrid.\nSi no te encuentras en una de nuestras ubicaciones actuales y estás interesado en utilizar nuestra plataforma, comunícate con nosotros ya que actualmente nos estamos expandiendo a otras áreas.",
    write_us: "Envíanos un correo a: ",

    still_questions: "¿Todavía tienes preguntas?",
    still_questions_description: "Cúentanos más sobre tu caso e intentaremos ayudadarte.",
    contact_info: "Información de contacto",
    contact_info_description: "Si tienes preguntas, puedes contactarnos a través de:",
    company_details: "Detalles de la empresa:",
    company_name: "Nombre de la empresa:",
    company_id: "NIF:",
  },

  overview: {
    welcome_back: "Bienvenido",
    welcome_back_user: "Bienvenido \n {{name}}",
    message_no_instruments: "Parece que aún \n no tienes ningún instrumento",
    message_no_instruments_description:
      "¿Te gustaría echar un vistazo a nuestro catálogo? \n Tenemos una amplia variedad de instrumentos en todos los rangos de precios. ",
    message_no_iban: "Parece que aún \n no has actualizado tu información de pago",
    message_no_iban_desc: "Para recibir pagos por tus ventas, necesitas añadir tu información bancaria.",
    message_no_details: "Parece que aún \n no has proporcionado tus detalles de usuario",
    message_no_details_desc: "Para registrar tus instrumentos o obtenerlos, necesitas proporcionar tus detalles de usuario.",
    your_instruments: "Tus instrumentos en propiedad",
    instruments_one: "{{count}} instrumento",
    instruments_other: "{{count}} instrumentos",

    here_to_help: "Estamos aquí para ayudarte",
    check_faqs: "Consulta nuestras preguntas frecuentes",
    check_faqs_description: "Hemos preparado una lista de las preguntas y respuestas más comunes. Responden al 90% de las preguntas que recibimos.",
    contact_support: "Contacta con soporte especializado",
    contact_support_description:
      "Si no encuentras lo que buscas, puedes contactar con nuestro equipo de soporte especializado. Estaremos encantados de ayudarte.",
    verified: "Verificado",
    not_verified: "No verificado",
    your_subscriptions: "Tus subscripciones",
    your_subscriptions_description: "Aquí puedes ver las subscripciones que tienes activas",
    value: "Valor: {{value}}",
    in_shop: "En la tienda",
    in_shop_ad: "Anunciado en la tienda",
    not_in_shop: "No está en la tienda",
    not_in_shop_ad: "No anunciado en la tienda",
    ad_not_online: "Anuncio offline",
    ad_online: "Anuncio online",
    rented: "Alquilado",
    contract_details: "Detalles del contrato",
    contract_started: "Contrato iniciado",
    payments_of: "Pagos de {{value}}",
    payments_of_deposit: "Pagos de {{value}} y depósito {{deposit}}",
    subscribed_until: "Subscrito hasta {{value}}",
    contact_shop: "Contacta con la tienda",
    shop_info: "Información de la tienda",
    next_payment: "Próximo pago",
    error_uploading: "Error subiendo archivo",
    update_successful: "Actualización completada",
    owned: "Propiedad",
    subscribed: "Suscrito",

    payments_where: "Donde te realizaremos los pagos por tus ventas o alquileres",
    date: "Fecha",
    payment: "Pago",
    other_config: "Otra configuración",
    max_product_upload: "Máximos productos subidos",
    max_product_upload_default: "Máximo USUARIOS",
    max_product_upload_seller: "Máximo VENDEDORES",
    history: "Historial",
  },

  emails: {
    order_completed: "Pedido Completado",
    order_collected: "Pedido Recogido",
    order_not_found: "Pedido no encontrado",
    order_cancelled: "Pedido Cancelado",
    article_sold: "Artículo Vendido",
    refund_received: "Reembolso recibido",
    partner_registered: "Colaborador registrado",
    instrument_requested: "Instrumento solicitado",
    referral_subject: "¡Invita Amigos y Gana hasta 50€!",

    headline_thanks: "{{name}}, gracias por tu pedido.",
    headline_thanks_deposit: "{{name}}, gracias por registrar tu artículo.",
    headline_cancelled: "{{name}}, tu pedido ha sido cancelado.",
    headline_sold: "{{name}}, tu artículo ha sido vendido.",
    headline_service: "{{name}}, tu servicio ha sido registrado.",
    headline_service_completed: "{{name}}, tu servicio ha sido completado.",
    headline_service_requested: "{{name}}, te han solicitado un servicio.",
    headline_partner_registered: "{{name}}, se ha registrado como Partner.",
    headline_instrument_requested: "Nuevo instrumento solicitado",
    headline_referral: "{{name}}, invita amigos y ahorra.",

    assignment_contract: "Contrato de cesión:",
    product_id: "Id del producto:",
    order_id: "Id del pedido:",
    transaction_id: "Id de la transacción:",
    contract_id: "Id de contrato:",

    order_email_collected: "Nuestro socio de Symphy nos ha confirmado que has recogido el artículo.<br>¡Esperamos que lo disfrutes!",
    order_email_description:
      "Nos complace informarte que tu pedido ha sido procesado con éxito.<br>Ahora puedes pasar a recoger los artículos que has solicitado en las tiendas especificadas.",
    order_email_subs_description: "Subscripción - {{duration}} meses - depósito {{deposit}}",
    order_email_collected_description: "Nuestro socio de Symphy nos ha confirmado que has recogido el artículo.<br>¡Esperamos que lo disfrutes!",
    order_email_cancelled: "El pedido ha sido cancelado y se te ha reembolsado el importe. Puede tardar hasta una semana en procesarse.",

    error_sending_email: "Error enviando email al usuario",

    instrument_registered: "Instrumento Registrado",
    luthier_waiting: "Nuestro luthier te está esperando",
    email_instrument_registered_description:
      "Tu instrumento ha sido registrado con éxito en la plataforma SYMPHY.<br>Debes llevarlo a una tienda colaboradora de SYMPHY para que sea validado y depositado. Una vez completado el proceso, será visible para otros usuarios de SYMPHY.",
    required_docs: "Documentos necesarios:",
    required_docs_list:
      "•Documento de identidad.<br>•Identificación del instrumento(recomendado): factura de compra, tasaciones y/o otra documentación que acredite el valor.",
    instrument_deposited: "Instrumento Depositado",
    instrument_deposited_description:
      "Tu instrumento ha sido validado y depositado con éxito en la tienda SYMPHY.<br>Si has creado un anuncio, ya es visible en la tienda de SYMPHY a través del siguiente link:<br><br><a href='{{link}}'>Tienda SYMPHY</a>",
    instrument_deposited_2:
      "Tu instrumento ha sido validado y depositado con éxito en la tienda SYMPHY.<br>Antes de que sea visible en la tienda online, por favor crea un anuncio aquí:<br><br><a href='{{link}}'>Publicar en la tienda</a>",
    article_sold_description:
      "Tu artículo ha sido vendido y hemos emitido un pago a la cuenta bancaria que nos has proporcionado. Ten en cuenta que el proceso puede tardar de 3 a 5 días laborables.<br>A la cantidad pagada por el comprador se le ha restado la comisión de SYMPHY y los impuestos correspondientes.",

    where_deposit: "A donde llevar el artículo:",
    where_deposit_description: "Puedes encontrar tu tienda SYMPHY más cercana <a href='https://symphy.es/shops'>aquí</a>",
    instrument_deposited_shop: "Donde has depositado el instrumento:",
    instrument_to_deposit_shop: "Donde se entregará el instrumento:",
    instrument_deposited_shop_description: "•Tienda: {{shop}}<br>•Dirección: {{address}}",

    paid_value: "Pagado: {{value}}",
    earned: "Ganado: {{value}}",

    maintenance_created: "Maintenimiento creado",
    maintenance_created_description: "Se ha creado una solicitud de mantenimiento.<br>Te notificaremos una vez esté listo para ser recogido.",
    maintenance_created_description_user: "Se ha creado una solicitud de mantenimiento.<br>Revisa el trabajo a realizar y confirma el servicio y precio.",

    maintenance_completed: "Mantenimiento completado",
    maintenance_completed_description: "Tu instrumento ha sido reparado y está listo para ser recogido en la tienda SYMPHY una vez hayas realizado el pago.",
    partner_registered_description:
      "El descuento será válido 15 días después de que se haya completado el pedido y lo recibirás un tu próximo pago.",
    instrument_requested_description:
      "Un usuario ha solicitado un instrumento:<br>•Nombre: {{name}}<br>•Email: {{email}}<br>•Teléfono: {{phone}}<br>•Ubicación: {{location}} <br>•Instrumento: {{instrument}} <br>•Tamaños: {{sizes}} <br>•Tipo de contrato: {{contractType}} <br>•Rango de precios: {{priceMin}} - {{priceMax}}<br>•Duración: {{duration}} meses",
    services_summary: "Resumen de servicios:",
    service_requested: "Servicio solicitado",

    referral_description:
      "Invita a tus amigos a unirse y gana 5€ por cada amigo que complete un pedido.\nGana hasta un total de 50€ en descuentos en tu próximo pago mensual.",
    referral_subtext: "Oferta por Tiempo Limitado – Un Mes para Referir",
    referral_subdescription:
      "Tienes un mes para referir tantos amigos como quieras.\nTu descuento se aplicará automáticamente en tu próximo pago mensual, 15 días después de que tus amigos completen sus pedidos.",
  },

  checkout: {
    thanks: "Gracias por tu pedido!",
    payment_processed: "Tu pago ha sido procesado correctamente",
    payment_processed_error: "Hubo un error procesando tu pago. Por favor, inténtalo de nuevo.",
    payment_registration_error: "Hubo un error registrando el pago, por favor contacta con nosotros si el problema persiste.",
    payment_error_previous: "Parece que ya tienes alguno de los artículos del carrito. Verifícalo y prueba de nuevo",
    collect_item: "Puedes recoger el instrumento en la tienda",
    show_seller: "Muestra el siguiente código de producto/s en la tienda:",
    continue_shopping: "Continuar comprando",
    order_id: "ID de pedido {{value}}",
    empty_cart: "Carrito vacío!",
    cart_no_items: "Parece que no tienes ningún producto en tu carrito.",
    order_summary: "Resumen del pedido",
    order_paid: "Pedido pagado",

    no_items: "No hay productos",
    vat_included: "IVA incluído",
    order_type: "Tipo de pedido",
    total_price: "Precio total",
    to_pay: "A pagar",
    to_pay_now: "A pagar ahora",
    other_products: "Otros productos",
    total_pay_now: "Total a pagar ahora",
    pay_now: "Pagar ahora",
    first_payment: "Primer pago",
    following_payments: "Pagos siguientes",
    monthly_payment: "Pago mensual",
    credit_card_title: "Tarjeta de Crédito / Débito",
    credit_card_description: "Aceptamos todas las tarjetas de crédito y débito principales como MasterCard y Visa.",
    authorize_recurrent_payments: "Autorizo a SYMPHY a cargar mi método de pago seleccionado según lo definido en el contrato de suscripción y venta.",
    use_new_card: "Usar nueva tarjeta",
    attention: "Atención",
    requires_pickup: "Después del pago, algún producto de tu carrito requiere recogida en tienda.",
    unavailable: "No disponible",
    unavailable_listings: "Algunos de los productos en tu carrito no están disponibles, por favor elimínalos e inténtalo de nuevo:",

    collected: "Cobrado",
    pending: "Pendiente",
    overdue: "Atrasado",
    pending_overdue: "Pendiente y atrasado",
    to_pay_owner: "A pagar",
    to_be_paid: "Por cobrar",
    products_value: "{{value}} productos",
    contracts_value: "{{value}} contratos",
    payments_value: "{{value}} pagos",
    late_payments_value_one: "{{count}} pago de {{amount}}",
    late_payments_value_other: "{{count}} pagos de {{amount}}",

    late_payments_value_deposit_one: "{{count}} pago de {{amount}} y depósito {{deposit}}",
    late_payments_value_deposit_other: "{{count}} pagos de {{amount}} y depósito {{deposit}}",

    save_payment: "Guardar pago",
    pay_new_card: "Pagar con tarjeta nueva",
    pay: "Pagar",
    payed: "Pagado",
    qty: "Cantidad",
    unit_price: "Precio",
    total: "Total",
    link: "Link",
    payment_request: "Solicitud de pago",
    payment_redirect_tip: "Serás redirigido a la página de pago",
    payment_redirect_seller_tip: "El usuario debe realizar el pago para confirmar el contrato",
    payment_result: "Resultado del pago",
    discount_codes: "Códigos de descuento",
    discount_code_error: "El código ({{value}}) no existe - intenta introducirlo de nuevo.",

    are_you_sure: "¿Estás seguro?",
    cancel_order_confirm: "Se cancelará tu reserva y se te realizará un reembolso completo en un plazo de 5 laborables.",
  },

  faq: {
    title: "Preguntas más frecuentes",
    subtitle: "Encuentra respuesta a tus preguntas",
    categories: "Categorías",

    title1: "Sobre SYMPHY",
    title2: "Gestión de alquileres",
    title3: "Para propietarios",
    title4: "Pagos",
    title5: "Seguro",
    title6: "Socios y tiendas",
    item11: "¿Qué es SYMPHY?",
    item11_description:
      "SYMPHY es mucho más que una plataforma; es una revolución en el mundo de la música. Permite a los músicos compartir instrumentos con pasión y descubrir la posibilidad de acceder a instrumentos excepcionales a precios asequibles. SYMPHY busca transformar la forma en que los músicos obtienen sus instrumentos y construir una comunidad basada en la colaboración y el apoyo mutuo.",
    item12: "¿Dónde está disponible?",
    item12_description: "SYMPHY es una comunidad que nació en Madrid y busca expandirse por todo el territorio español.",
    item13: "¿Es gratis usa SYMPHY?",
    item13_description:
      "Formar parte de la comunidad SYMPHY es completamente gratuito. Se cobra una pequeña comisión por cada transacción dentro de la plataforma para seguir creciendo como comunidad.",
    item14: "¿Que instrumentos recogemos?",
    item14_description:
      "Actualmente nos especializamos en instrumentos de cuerda frotada de todos los tamaños y gamas. Si tienes otro tipo de instrumento no dudes en contactarnos.",

    item21: "¿Qué seguro tienen los instrumentos del propietario?",
    item21_description:
      "En SYMPHY, hemos implementado un sistema de seguro para los instrumentos registrados en la plataforma, que se activa al formalizar la entrega del instrumento. Para obtener detalles específicos sobre cómo funciona nuestro seguro y qué cobertura ofrece, te invitamos a consultar la sección de Términos y Condiciones en nuestra plataforma en el siguiente enlace.\nTu tranquilidad es esencial para nosotros, y estamos comprometidos en brindarte una experiencia segura y sin preocupaciones en cada paso de tu experiencia con SYMPHY.",
    item22: "¿Tengo que pagar para listar un instrumento en la plataforma? ",
    item22_description: "Como parte de nuestra promoción de lanzamiento, listar un instrumento en la plataforma SYMPHY es completamente gratuito.",
    item23: "¿Cómo garantiza SYMPHY la calidad de los instrumentos? ",
    item23_description:
      "En SYMPHY, reconocemos la importancia vital de la calidad de los instrumentos musicales. Estamos dedicados a asegurar que todos los instrumentos incluidos en nuestra plataforma cumplan con nuestros estándares de calidad. Realizamos inspecciones y evaluaciones periódicas para garantizar que los instrumentos se mantengan en condiciones óptimas y estén listos para ser utilizados por músicos. Esta vigilancia constante asegura que nuestros usuarios puedan confiar plenamente en la calidad y el rendimiento de los instrumentos que encuentren a través de nuestra plataforma.",
    item24: "¿Cómo funciona el proceso de intercambio de instrumentos en SYMPHY?",
    item24_description:
      "El proceso de intercambio en SYMPHY está diseñado para ser simple y eficiente. Si eres propietario de uno o varios instrumentos que deseas compartir, puedes registrarlos en nuestra plataforma, detallando sus características únicas y después dejarlos en nuestros puntos de recogida asociados donde se encargarán de verificarlo.\nPara aquellos interesados en usar un instrumento, tienen la posibilidad de explorar las numerosas opciones disponibles en nuestro catalogo en línea y luego recoger el instrumento en los lugares asociados, como luthiers de confianza, tiendas especializadas o escuelas de música.\nSYMPHY facilita la comunicación entre ambas partes y garantiza un proceso seguro y protegido, asegurando que tanto los propietarios como los músicos puedan disfrutar de una experiencia confiable y enriquecedora.",
    item25: "¿Documentación a aportar?",
    item25_description:
      "Si alquilas:\n-DNI.\n\n\nSi dejas un instrumento:\n-DNI.\n-Documentación del instrumento como factura, tasación... La revisaremos para verificar el valor del mismo.\n-IBAN: para los pagos.",
    item26: "Estado de los instrumentos",
    item26_description:
      "Los intrumentos deben estar en estado para ser tocados cuando se depositan. Si no lo está, consulta en la tienda sobre el servicio de puesta a punto.\nLos instrumentos deben ser devueltos en el mismo estado en el que se entregaron.",
    item27: "¿Puedo reservar instruentos?",
    item27_description:
      "Sí, puedes reservar instrumentos por 3 días laborables. Si decides quedártelo, el importe de la reserva se descontará del precio final. En caso de no recogerlo, se te devolverá el importe de la reserva.",
    item28: "¿Se me devolverá el depósito?",
    item28_description: "Sí, se te devolverá el depósito una vez devuelvas el instrumento en las mismas condiciones en las que lo recibiste.",

    item31: "¿Un propietario puede proponerme realizar un alquiler de forma ajena a la plataforma?",
    item31_description:
      "En caso de alquilar un instrumento que se encuentra en la plataforma sin hacerlo a través de la misma pones en riesgo la propia plataforma y a los instrumentos del usuario. SYMPHY no se hace responsable de daños, robos y prejuicios derivados de alquileres o ventas realizados fuera de la plataforma.",
    item32: "¿Puedo confiar en que mi instrumento estará en buenas manos al usar SYMPHY?",
    item32_description:
      "Absolutamente. En SYMPHY, nos preocupamos por el cuidado y el respeto de los instrumentos musicales. Los usuarios que alquilan un instrumento se comprometen a mantenerlo en condiciones óptimas durante el período de alquiler. Además, SYMPHY proporciona orientación sobre el manejo adecuado de los instrumentos para garantizar su preservación.",
    item33: "¿Cuanto voy a ganar?",
    item33_description:
      "Las cantidades dependen en función del valor del instrumento. Sabrás el número exacto una vez que se haya verificado el instrumento.\nEl precio de alquiler se calcula en función del valor del instrumento y la duración del alquiler.\nAl total del alquiler hay que descontarle, los gastos de gestión y nuestra comisión. En SYMPHY siempre tratamos de maximizar las ganancias del propietario manteniendo unos precios justos.",
    item34: "¿Cuando voy a cobrar?",
    item34_description:
      "En las ventas, el dinero se transferirá una vez que el comprador haya confirmado la recogida del instrumento.\nEn los alquileres, el dinero se transferirá una vez el contrato haya concluído. El tiempo estimado de la transferencia es de unos tres o cuatro días según la entidad bancaria del propietario.",
    item35: "¿El instrumento tiene que estar en perfecto estado?",
    item35_description:
      "Idelmente si, en caso de no estarlo ofrecemos en nuestras tiendas servicio de puesta a punto a realizar antes de que sea alquilado. El costo se podrá descontar de las primeras cuotas percibidas.",

    item41: "¿Quién decide el valor de los instrumentos?",
    item41_description:
      "Los instrumentos se valorarán en el momento de la verificación y se propondrá al usuario el rango de precio en el que estará su instrumento para el alquiler.",
    item42: "¿Los precios se ven modificados según la duración del alquiler? ",
    item42_description:
      "Sí, los precios se ven modificados según la duración del alquiler. Cuanto más largo sea el alquiler, más económico será el precio mensual.",
    item43: "¿Cuándo cobraré el dinero del alquiler?",
    item43_description:
      "Cada 3 o 6 meses, en función de la duración del alquiler, la plataforma de Symphy enviará automáticamente el dinero al propietario del instrumento, al IBAN que nos proporciones en ajustes. El tiempo estimado de la transferencia es de unos tres o cuatro días según la entidad bancaria del propietario.",
    item44: "¿Como se realizan los pagos?",
    item44_description: "Se realizarán o bien utilizando nuestra TPV online, o por transferencia bancaria a nuestra cuenta:\nES49 0182 5723 1002 0163 6979",

    item51: "¿Necesito seguro como dueño de los instrumentos?",
    item51_description:
      "No, los instrumentos quedan asegurados cuando el usuario lo alquila. Es un seguro obligatorio que ofrece Symphy en asociación con Mercaseguros y del que nos hacemos cargo. Para informarte más sobre el seguro haz click <0>here</0>.",
    item52: "¿Qué clase de seguro ofrece Symphy?",
    item52_description:
      "En SYMPHY, trabajamos duro para que la comunidad sea un lugar seguro para alquilar instrumentos.\nTodos los artículos están cubiertos a terceros por nuestro seguro hasta un valor de 100.000€. \nEl seguro se activa desde el momento de la entrega hasta la devolución. Para más información sobre la cobertura del seguro haz click <0>aquíe</0>.",
    item53: "¿Puedo usar mi propia póliza?",
    item53_description: "En el caso de que tengas un seguro que cubra daños y robos a terceros ponte en contacto con nosotros en info@symphy.es.",
  },

  shops: {
    make_visible: "Hacer tienda visible",
  },

  invoices: {
    preinvoice: "Factura proforma",
    from: "De",
    to: "A",
    billing_address: "Dirección de facturación",
    invoice_date: "Fecha de factura",
    invoice_number: "Número de factura",
    footer_title: "Recuerda",
    footer: "Gracias por elegir SYMPHY. Estamos comprometidos a brindar instrumentos de calidad y un excelente servicio a nuestros clientes.",
    monthly_payment: "Producto {{product}} {{size}}",
    monthly_payment_description: "Pago mensualidad de {{value}}",
    shopping_reference: "Referencia de compra",
    invoice_details: "Detalles de la factura",
  },

  services: {
    service_description: "Trabajos a realizar, por ejemplo: ajuste de alma, cambio de cuerdas, limpieza...",
    price_with_taxes: "Precio (IVA incluído)",
    history: "Historial de servicios",
    status_requested: "Solicitado",
    status_requested_tip: "Después de revisar el instrumento, añade la información del servicio y el precio a la solicitud.",
    status_ongoing: "En curso",
    status_ongoing_tip: "Cambiar a listo cuando el servicio haya sido completado. Después podrás solicitar el pago.",
    status_ongoing_desc:
      "Si el trabajo ha sido completado, click en continuar. Se le enviará un email al usuario para que recoja el instrumento y realice el pago. Puedes obtener su información de contacto poniendo el ratón sobre su email.",

    status_ready: "Listo",
    status_ready_collection: "Listo para recoger",
    status_ready_desc: "El pago ha sido recibido. Dar instrumento al usuario.",
    status_picked_up: "Recogido",

    status_pending_payment_desc: "El usuario debe realizar el pago para porder recoger el instrumento. Enviar solicitud de nuevo?",

    transaction_items: "Artículos de la transacción",
    refunds_value: "Reembolsos: {{value}}",
    deposits_value: "Depósitos: {{value}}",
    discounted_monthly: "Pago mensual con descuento",
    discounts_value: "Descuentos: {{value}}",
    reservation_value: "Reserva: {{value}}",
    price_with_reservation_value: "Pagado: {{value}} - Reserva: {{value2}}",

    service_name: "Nombre del servicio",
    payment_status: "Estado del pago",
    transaction_type: "Tipo de transacción",
    created_date: "Fecha de creación",
    end_date: "Fecha de fin",
    toolbar_filer: "Cliente, id transacción, tienda, producto...",

    create_title: "Servicio de instrumentos",
    create_description: "Cualquier trabajo requerido, desde ajustes de madera, limpieza, afinación...",
    instruments_in_maintenance: "Instrumentos en mantenimiento",
    offered_services: "Servicios ofrecidos",
    maintenance: "Mantenimiento",
    valuation: "Tasación",
    storage: "Almacenamiento",
    sale: "Venta",
    subscription: "Subscripción",
    shop: "Tienda",
    shop_pickup: "Recogida en tienda",
    free: "Gratis",
    visible: "Visible",
    show_services: "Mostrar servicios",
    hide_services: "Ocultar servicios",
    price_min: "Precio mínimo",
    price_max: "Precio máximo",
    type: "Tipo de servicio",
    can_user_verify: "¿Puede el usuario verificar instrumentos?",
    enable_verify: "Activar para permitir al vendedor validar instrumentos",
    are_you_seller: "¿Eres un vendedor?",
    enable_seller: "Activar para ofrecer servicios en SYMPHY",
    enable_verified_seller: "Activar para mostrar las tiendas del vendedor en SYMPHY",
    verified_seller: "Vendedor verificado",
    offer_more: "Contacta con el soporte de SYMPHY para añadir más servicios a tu tienda",
    no_services: "No hay servicios",
    instrument_valuation: "Valoración de instrumentos",
    instrument_maintenance: "Mantenimiento de instrumentos",
    prices_from_to: "Precios desde {{from}} a {{to}}",
    for: "Para",

    service_info: "Información del servicio",
    where_to_do_service: "Donde realizar el servicio",
    where_to_do_valuation: "Donde realizar la tasación",

    service_payment_pending: "Pago del servicio pendiente. Tras el pago se podrá recoger el instrumento.",
    seller_pending_check: "El vendedor debe confirmar el servicio.",
    ongoing_tip: "Se te avisará cuando el servicio sea completado para recoger el instrumento. Ya puedes realizar el pago.",

    verify_user_data: "Verifica datos del usuario",
    verify_user_data_desc: "Confirma que los datos del usuario son los mismos que los del DNI/NIF",
    add_contract_data: "Añade datos del contrato",

    payment_received: "Pago recibido",
    product_verified: "Producto verificado",
    product_verified_successfully: "Producto verificado con éxito.\nPuede ser mostrado en la tienda online.",
    maintenance_service: "Servicio de mantenimiento",
    show_qr_shop: "Mostrar código QR en la tienda",
    show_qr_shop_desc: "Puedes mostrarlo en la tienda para identificar el producto rápidamente.",
    take_shop_qr: "Llévalo a la tienda para que lo verifiquen. Puedes mostrar el código QR en la tienda al vendedor.",
    take_shop_explain:
      "Para publicar tu producto, debes llevarlo a una tienda SYMPHY para que uno de nuestros expertos lo verifique. Una vez verificado, podras publicar tu anuncio para que otros usuarios puedan verlo.\nRecuerda que el instrumento se quedará en la tienda el tiempo que desees, ya que será el punto de recogida.\n ¿Te gustaría elegir una tienda ahora?",
  },
};

export default es;
