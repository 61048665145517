import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// auth
import { useAuthContext } from 'useAuthContext';

import { PATH_DASHBOARD } from 'paths';
import UserService from 'services/UserService';
import MyAvatar from 'src/components/custom-components/MyAvatar';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user, isAuthenticated } = useAuthContext();
  const username = UserService.getUserName(user, isAuthenticated);
  const { t } = useLocales();
  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.settings.root.path}>
      <StyledRoot>
        <MyAvatar />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {t(UserService.getUserRole(user))}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}