// hooks
import UserService from '../../../services/UserService';
import { useAuthContext } from '../../auth/hooks/use-auth-context';
// utils
import createAvatar from '../../utils/createAvatar';
//
import CustomAvatar from '../custom-avatar/CustomAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuthContext();
  const photoUrl = UserService.getUserAvatarUrl(user);
  const displayName = (user?.info[0]?.name ? user?.info[0]?.name : '');
  return (
    <CustomAvatar
      src={photoUrl}
      alt={displayName}
      color={photoUrl ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </CustomAvatar>
  );

}
