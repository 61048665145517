import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
// routes
import { useRouter, useSearchParams } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { AuthConsumer } from '../context/web3auth';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
  const router = useRouter();

  const searchParams = useSearchParams();
  const location = useLocation();
  const state = useLocation();


  const { isAuthenticated, user, loading } = useAuthContext();

  const check = useCallback(() => {
    if (isAuthenticated && user) {
      // console.log("AuthGuard: pathname = " + window.location.pathname + " PATH Welcome = " + PATH_PAGE.postregistration.path);
      // if (window.location.pathname.includes(PATH_PAGE.postregistration.path)) {
      //   router.replace(PATH_PAGE.postregistration.path);
      // } if (window.location.pathname.includes(PATH_PAGE.marketplace.path)) {
      //   router.replace(PATH_PAGE.marketplace.path);
      // } else {
      // TODO go pag to previous page]
      // const searchParamsOriginal = new URLSearchParams(location.search);
      const searchParamsOriginal = searchParams.get("search");
      // console.log("Guest guard " + JSON.stringify(searchParams));
      // console.log("Guest guard extra " + searchParamsOriginal);
      // console.log("Guest guard state " + JSON.stringify(state));
      let returnTo = user?.just_registered ? PATH_PAGE.postregistration.path : searchParams.get("returnTo") || PATH_DASHBOARD.root.path;
      if (user?.just_registered && user?.seller_info[0]) {
        router.navigate(PATH_DASHBOARD.settings.root.path, { page: "seller" }, true);
      } else {
        if (searchParamsOriginal) {
          // const searchParams = new URLSearchParams({ search: searchParamsOriginal }).toString();
          // returnTo += `${searchParamsOriginal}`;
          returnTo += searchParamsOriginal;
          // router.navigate(returnTo, { search: searchParamsOriginal }, true);
        }
        // } 
        // else {
        router.replace(returnTo);
        // }
        // console.log("Guest guard replace " + returnTo);
        // router.replace_with_params(returnTo);

      }
    }
  }, [isAuthenticated, router, searchParams, user]);

  useEffect(() => {
    check();
  }, [check]);

  return <AuthConsumer>{children}</AuthConsumer>;
}

GuestGuard.propTypes = {
  children: PropTypes.node,
};
