export const idlFactory = ({ IDL }) => {
  const DiscountType = IDL.Variant({
    'FeePartner' : IDL.Null,
    'FeeRegistration' : IDL.Null,
    'Deposit' : IDL.Null,
    'Sale' : IDL.Null,
    'FeeAll' : IDL.Null,
    'FeeCompany' : IDL.Null,
    'FeeInsurance' : IDL.Null,
    'Order' : IDL.Null,
    'Other' : IDL.Null,
    'Service' : IDL.Null,
    'Subscription' : IDL.Null,
  });
  const Currency = IDL.Variant({
    'EUR' : IDL.Null,
    'GBP' : IDL.Null,
    'USD' : IDL.Null,
  });
  const Coin = IDL.Record({ 'currency' : Currency, 'amount' : IDL.Nat64 });
  const DiscountCode = IDL.Record({
    'value' : IDL.Text,
    'max_count' : IDL.Opt(IDL.Nat64),
    'count' : IDL.Nat64,
  });
  const Discount = IDL.Record({
    'id' : IDL.Nat64,
    'active' : IDL.Bool,
    'use_once' : IDL.Bool,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'valid_for' : IDL.Opt(IDL.Vec(IDL.Principal)),
    'discount_type' : DiscountType,
    'discount_amount' : IDL.Opt(Coin),
    'used_by' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat64)),
    'discount_max_amount' : IDL.Opt(Coin),
    'discount_percentage' : IDL.Opt(IDL.Float32),
    'max_count_per_user' : IDL.Opt(IDL.Nat64),
    'discount_codes' : IDL.Vec(DiscountCode),
    'is_referral' : IDL.Bool,
    'discount_item_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'date_start' : IDL.Opt(IDL.Nat64),
    'date_end' : IDL.Opt(IDL.Nat64),
  });
  const Args = IDL.Record({ 'discount' : Discount });
  const UseDiscountResponse = IDL.Variant({
    'UsageExceeded' : IDL.Null,
    'NotActive' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : Discount,
    'DuplicatedCode' : IDL.Null,
    'NoPermission' : IDL.Null,
    'NotValidForUser' : IDL.Null,
    'Expired' : IDL.Null,
    'AlreadyUsed' : IDL.Null,
  });
  const Args_1 = IDL.Record({
    'discount_code' : DiscountCode,
    'discount_id' : IDL.Nat64,
  });
  const Response = IDL.Variant({
    'UsageExceeded' : IDL.Null,
    'NotActive' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : DiscountCode,
    'DuplicatedCode' : IDL.Null,
    'NoPermission' : IDL.Null,
    'NotValidForUser' : IDL.Null,
    'Expired' : IDL.Null,
    'AlreadyUsed' : IDL.Null,
  });
  const FeeType = IDL.Variant({
    'Registration' : IDL.Null,
    'Insurance' : IDL.Null,
    'Partners' : IDL.Null,
    'Company' : IDL.Null,
    'Custom' : IDL.Text,
    'CompanySale' : IDL.Null,
  });
  const FeeValue = IDL.Variant({
    'Fixed' : Coin,
    'PercentageAndFixed' : IDL.Tuple(IDL.Opt(IDL.Float32), IDL.Opt(Coin)),
    'Percentage' : IDL.Float32,
  });
  const ServiceTypeName = IDL.Record({ 'name' : IDL.Text });
  const Fee = IDL.Record({
    'id' : IDL.Nat64,
    'fee_values' : IDL.Vec(IDL.Tuple(FeeType, FeeValue)),
    'service_type' : ServiceTypeName,
    'product_price_max' : IDL.Opt(Coin),
  });
  const Args_2 = IDL.Record({ 'fee' : Fee });
  const Response_1 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : Fee,
    'NoPermission' : IDL.Null,
  });
  const ContractConfig = IDL.Variant({
    'Sale' : IDL.Record({
      'active' : IDL.Bool,
      'price_discount' : IDL.Opt(IDL.Float32),
      'price_sale' : Coin,
    }),
    'Donation' : IDL.Record({
      'active' : IDL.Bool,
      'max_duration' : IDL.Opt(IDL.Nat16),
      'available_until' : IDL.Opt(IDL.Nat64),
    }),
    'Consult' : IDL.Record({ 'active' : IDL.Bool }),
    'Subscription' : IDL.Record({
      'active' : IDL.Bool,
      'autorenew' : IDL.Bool,
      'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
      'option_buy_discount' : IDL.Float32,
      'max_duration' : IDL.Opt(IDL.Nat16),
      'deposit' : Coin,
      'option_buy' : IDL.Bool,
      'min_duration' : IDL.Nat16,
      'price_subs' : Coin,
    }),
  });
  const ProductContract = IDL.Record({
    'id' : IDL.Nat64,
    'active' : IDL.Bool,
    'product_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'owner_contracts' : IDL.Vec(ContractConfig),
    'end_date' : IDL.Opt(IDL.Nat64),
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Opt(IDL.Nat64),
    'owner_id' : IDL.Principal,
    'max_end_date' : IDL.Opt(IDL.Nat64),
  });
  const Args_3 = IDL.Record({ 'product_contract' : ProductContract });
  const Response_2 = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : ProductContract,
    'NoPermission' : IDL.Null,
  });
  const ProductRecommendedContract = IDL.Record({
    'id' : IDL.Nat64,
    'price_subs_optimal' : IDL.Opt(Coin),
    'deposit' : IDL.Opt(Coin),
    'price_increase_factor' : IDL.Opt(IDL.Float32),
    'price_subs_max' : IDL.Opt(Coin),
    'price_subs_min' : IDL.Opt(Coin),
    'product_valuation_price' : IDL.Opt(Coin),
    'payments_upfront' : IDL.Opt(IDL.Nat16),
  });
  const Args_4 = IDL.Record({
    'recommended_contract' : ProductRecommendedContract,
  });
  const Response_3 = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : ProductRecommendedContract,
    'NoPermission' : IDL.Null,
  });
  const RequestStatus = IDL.Variant({
    'Cancelled' : IDL.Null,
    'Created' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const ContractType = IDL.Variant({
    'Sale' : IDL.Null,
    'Donation' : IDL.Null,
    'Consult' : IDL.Null,
    'Subscription' : IDL.Null,
  });
  const Location = IDL.Record({
    'lat' : IDL.Float32,
    'lng' : IDL.Float32,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'address' : IDL.Text,
  });
  const ProductRequest = IDL.Record({
    'id' : IDL.Nat64,
    'status' : IDL.Opt(RequestStatus),
    'contract_type' : IDL.Opt(ContractType),
    'created_at' : IDL.Nat64,
    'user_id' : IDL.Opt(IDL.Principal),
    'email' : IDL.Opt(IDL.Text),
    'product_type' : IDL.Text,
    'product_sizes' : IDL.Vec(IDL.Text),
    'comments' : IDL.Opt(IDL.Text),
    'phone' : IDL.Opt(IDL.Text),
    'price_max' : IDL.Opt(Coin),
    'price_min' : IDL.Opt(Coin),
    'min_duration' : IDL.Opt(IDL.Nat16),
    'location' : IDL.Opt(Location),
  });
  const Args_5 = IDL.Record({ 'product_request' : ProductRequest });
  const Response_4 = IDL.Variant({
    'Success' : ProductRequest,
    'ErrorProductType' : IDL.Null,
  });
  const ContractDuration = IDL.Record({ 'duration' : IDL.Nat16 });
  const PaymentInterval = IDL.Variant({
    'Day' : IDL.Null,
    'Week' : IDL.Null,
    'Year' : IDL.Null,
    'Month' : IDL.Null,
  });
  const DiscountUsed = IDL.Record({
    'id' : IDL.Nat64,
    'active' : IDL.Bool,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'discount_type' : DiscountType,
    'discount_amount' : IDL.Opt(Coin),
    'discount_max_amount' : IDL.Opt(Coin),
    'used_code' : IDL.Opt(IDL.Text),
    'discount_percentage' : IDL.Opt(IDL.Float32),
    'max_count_per_user' : IDL.Opt(IDL.Nat64),
    'discount_item_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'date_start' : IDL.Opt(IDL.Nat64),
    'date_end' : IDL.Opt(IDL.Nat64),
  });
  const PriceCalculation = IDL.Record({
    'taxes_interval' : IDL.Opt(Coin),
    'fees_total' : Coin,
    'interval' : IDL.Opt(PaymentInterval),
    'discounts_used' : IDL.Vec(DiscountUsed),
    'price_interval' : IDL.Opt(Coin),
    'fees_interval' : IDL.Opt(Coin),
    'taxes_total' : Coin,
    'contract_duration' : IDL.Opt(IDL.Nat16),
    'final_price' : Coin,
    'other_prices' : IDL.Vec(IDL.Tuple(IDL.Text, Coin)),
  });
  const ContractStatus = IDL.Variant({
    'Paused' : IDL.Null,
    'Reserved' : IDL.Null,
    'PendingPayment' : IDL.Null,
    'Active' : IDL.Null,
    'PendingVerification' : IDL.Null,
    'PendingPickup' : IDL.Null,
    'Cancelled' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const ProductSubContract = IDL.Record({
    'id' : IDL.Nat64,
    'contract_type' : ContractType,
    'duration' : IDL.Opt(ContractDuration),
    'parent_contract_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'end_date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Principal,
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Nat64,
    'price' : IDL.Opt(PriceCalculation),
    'contract_status' : ContractStatus,
  });
  const Args_6 = IDL.Record({ 'product_subcontract' : ProductSubContract });
  const Response_5 = IDL.Variant({
    'Error' : IDL.Text,
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : ProductSubContract,
    'NoPermission' : IDL.Null,
    'ActiveContract' : IDL.Opt(ProductSubContract),
  });
  const ReferralStatus = IDL.Variant({
    'Invalid' : IDL.Null,
    'PendingReward' : IDL.Null,
    'ReferredCancelled' : IDL.Null,
    'ReferredCompleted' : IDL.Null,
    'Completed' : IDL.Null,
    'Pending' : IDL.Null,
  });
  const Referral = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Opt(IDL.Nat64),
    'status' : ReferralStatus,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'referred_id' : IDL.Principal,
    'referrer_id' : IDL.Principal,
    'created_at' : IDL.Nat64,
    'discount_id' : IDL.Opt(IDL.Nat64),
  });
  const Args_7 = IDL.Record({ 'referral' : Referral });
  const Response_6 = IDL.Variant({
    'NotUpdated' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : Referral,
    'ErrorInEvents' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const ListingStatus = IDL.Variant({
    'Reserved' : IDL.Null,
    'Active' : IDL.Null,
    'Draft' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const StoreListing = IDL.Record({
    'id' : IDL.Nat64,
    'status' : ListingStatus,
    'product_id' : IDL.Nat64,
    'creator_id' : IDL.Principal,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'contract_id' : IDL.Opt(IDL.Nat64),
    'created_at' : IDL.Nat64,
    'shop_id' : IDL.Opt(IDL.Nat64),
  });
  const Args_8 = IDL.Record({ 'store_listing' : StoreListing });
  const Response_7 = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : StoreListing,
    'NoPermission' : IDL.Null,
  });
  const OrderItem = IDL.Record({
    'service_type' : ServiceTypeName,
    'product_id' : IDL.Nat64,
    'price' : PriceCalculation,
  });
  const Args_9 = IDL.Record({
    'discount_code' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Opt(IDL.Principal),
    'discount_id' : IDL.Opt(IDL.Nat64),
    'items' : IDL.Vec(OrderItem),
  });
  const OrderItemPrice = IDL.Record({
    'product_id' : IDL.Nat64,
    'price' : PriceCalculation,
  });
  const PriceSummary = IDL.Record({
    'items' : IDL.Vec(OrderItemPrice),
    'price' : PriceCalculation,
  });
  const Response_8 = IDL.Variant({
    'UsageExceeded' : IDL.Null,
    'Error' : IDL.Null,
    'DiscountNotFound' : IDL.Null,
    'NotAuthorized' : IDL.Null,
    'Success' : PriceSummary,
    'DiscountNotApplicable' : IDL.Null,
  });
  const Args_10 = IDL.Record({
    'old_user_id' : IDL.Principal,
    'new_user_id' : IDL.Principal,
  });
  const Response_9 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_11 = IDL.Record({ 'discount_id' : IDL.Nat64 });
  const Response_10 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
  });
  const Args_12 = IDL.Record({ 'discount_code' : IDL.Text });
  const Args_13 = IDL.Record({ 'fee_id' : IDL.Nat64 });
  const Response_11 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
  });
  const Args_14 = IDL.Record({
    'with_subcontracts' : IDL.Bool,
    'product_contract_id' : IDL.Nat64,
  });
  const Response_12 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_15 = IDL.Record({ 'product_id' : IDL.Nat64 });
  const Args_16 = IDL.Record({ 'recommended_contract_id' : IDL.Nat64 });
  const Args_17 = IDL.Record({ 'product_request_id' : IDL.Nat64 });
  const Response_13 = IDL.Variant({ 'Success' : IDL.Null });
  const Args_18 = IDL.Record({
    'activate_listing' : IDL.Bool,
    'product_subcontract_id' : IDL.Nat64,
  });
  const Args_19 = IDL.Record({ 'contract_id' : IDL.Nat64 });
  const Response_14 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_20 = IDL.Record({ 'referral_id' : IDL.Nat64 });
  const Response_15 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_21 = IDL.Record({ 'store_listing_id' : IDL.Nat64 });
  const Args_22 = IDL.Record({ 'product_id' : IDL.Nat64 });
  const Args_23 = IDL.Record({
    'listings' : IDL.Bool,
    'fees' : IDL.Bool,
    'discounts' : IDL.Bool,
    'recommended' : IDL.Bool,
    'contracts' : IDL.Bool,
    'requests' : IDL.Bool,
  });
  const Response_16 = IDL.Variant({ 'Error' : IDL.Null, 'Success' : IDL.Null });
  const Args_24 = IDL.Record({
    'user_name' : IDL.Opt(IDL.Text),
    'user_lastname' : IDL.Opt(IDL.Text),
    'user_email' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Principal,
  });
  const Response_17 = IDL.Variant({
    'Error' : IDL.Null,
    'NoPermissionVerify' : IDL.Null,
    'Success' : IDL.Text,
    'NoPermission' : IDL.Null,
  });
  const Args_25 = IDL.Record({ 'discount_id' : IDL.Nat64 });
  const Response_18 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : Discount,
  });
  const Args_26 = IDL.Record({
    'discount_code' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Opt(IDL.Principal),
    'discount_id' : IDL.Opt(IDL.Nat64),
    'item_id' : IDL.Opt(IDL.Nat64),
  });
  const Response_19 = IDL.Variant({ 'Success' : IDL.Vec(Discount) });
  const Args_27 = IDL.Record({ 'fee_id' : IDL.Nat64 });
  const Response_20 = IDL.Variant({ 'NotFound' : IDL.Null, 'Success' : Fee });
  const Response_21 = IDL.Variant({ 'Success' : IDL.Vec(Fee) });
  const Args_28 = IDL.Record({ 'user_id' : IDL.Principal });
  const UserContactSummary = IDL.Record({
    'name' : IDL.Text,
    'user_id' : IDL.Principal,
    'email' : IDL.Text,
    'lastname' : IDL.Text,
  });
  const ReferralWithUsers = IDL.Record({
    'referral' : Referral,
    'referred' : UserContactSummary,
    'referrer' : UserContactSummary,
  });
  const Response_22 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : IDL.Vec(ReferralWithUsers),
    'Unauthorized' : IDL.Null,
  });
  const Args_29 = IDL.Record({ 'product_contract_id' : IDL.Nat64 });
  const Response_23 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : ProductContract,
  });
  const Response_24 = IDL.Variant({ 'Success' : IDL.Vec(ProductContract) });
  const Response_25 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Vec(ProductContract),
  });
  const ProductContractExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'active' : IDL.Bool,
    'product_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'owner_contracts' : IDL.Vec(ContractConfig),
    'end_date' : IDL.Opt(IDL.Nat64),
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Opt(IDL.Nat64),
    'subcontracts' : IDL.Vec(ProductSubContract),
    'owner_id' : IDL.Principal,
    'max_end_date' : IDL.Opt(IDL.Nat64),
  });
  const Response_26 = IDL.Variant({
    'ConversionError' : IDL.Null,
    'Success' : IDL.Vec(ProductContractExpanded),
  });
  const Args_30 = IDL.Record({ 'product_ids' : IDL.Vec(IDL.Nat64) });
  const Args_31 = IDL.Record({
    'product_id' : IDL.Nat64,
    'user_id' : IDL.Opt(IDL.Principal),
    'valuation_price' : Coin,
  });
  const PriceCalculationSummaryResult = IDL.Record({
    'subscription_prices' : IDL.Vec(PriceCalculation),
    'sale_price' : IDL.Opt(PriceCalculation),
  });
  const Response_27 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : PriceCalculationSummaryResult,
  });
  const Args_32 = IDL.Record({ 'recommended_contract_id' : IDL.Nat64 });
  const Response_28 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : ProductRecommendedContract,
  });
  const Response_29 = IDL.Variant({
    'Success' : IDL.Vec(ProductRecommendedContract),
  });
  const Response_30 = IDL.Variant({ 'Success' : IDL.Vec(ProductRequest) });
  const Args_33 = IDL.Record({ 'product_subcontract_id' : IDL.Nat64 });
  const Response_31 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : ProductSubContract,
  });
  const Args_34 = IDL.Record({
    'remove_cancelled' : IDL.Opt(IDL.Bool),
    'end_date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Opt(IDL.Principal),
    'product_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'start_date' : IDL.Opt(IDL.Nat64),
  });
  const ProductSubContractExpandedSimple = IDL.Record({
    'id' : IDL.Nat64,
    'contract_type' : ContractType,
    'duration' : IDL.Opt(ContractDuration),
    'parent_contract_id' : IDL.Nat64,
    'product_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'end_date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Principal,
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Nat64,
    'price' : IDL.Opt(PriceCalculation),
    'contract_status' : ContractStatus,
  });
  const Response_32 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Vec(ProductSubContractExpandedSimple),
    'NoPermission' : IDL.Null,
  });
  const Args_35 = IDL.Record({ 'contract_id' : IDL.Nat64 });
  const Response_33 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Vec(ProductSubContract),
  });
  const UserInfo = IDL.Record({
    'about' : IDL.Text,
    'username' : IDL.Text,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'avatar_url' : IDL.Text,
    'name' : IDL.Text,
    'id_verified' : IDL.Bool,
    'email' : IDL.Text,
    'location' : IDL.Opt(Location),
    'lastname' : IDL.Text,
    'id_card' : IDL.Opt(IDL.Text),
  });
  const AuthInfo = IDL.Record({
    'verifier' : IDL.Text,
    'verifier_id' : IDL.Text,
  });
  const SellerInfo = IDL.Record({
    'verified' : IDL.Bool,
    'tax_number' : IDL.Text,
    'invitations_sent' : IDL.Vec(IDL.Principal),
  });
  const User = IDL.Record({
    'info' : IDL.Opt(UserInfo),
    'user_id' : IDL.Principal,
    'auth_info' : IDL.Opt(AuthInfo),
    'seller_info' : IDL.Opt(SellerInfo),
  });
  const ProductSubContractExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'contract_type' : ContractType,
    'duration' : IDL.Opt(ContractDuration),
    'parent_contract_id' : IDL.Nat64,
    'product_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'user' : User,
    'end_date' : IDL.Opt(IDL.Nat64),
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Nat64,
    'price' : IDL.Opt(PriceCalculation),
    'contract_status' : ContractStatus,
  });
  const Response_34 = IDL.Variant({
    'Success' : IDL.Vec(ProductSubContractExpanded),
  });
  const Response_35 = IDL.Variant({ 'Success' : IDL.Vec(ProductSubContract) });
  const Args_36 = IDL.Record({
    'product_id' : IDL.Nat64,
    'discount_code' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Opt(IDL.Principal),
    'discount' : IDL.Opt(IDL.Float32),
    'price' : IDL.Opt(Coin),
    'valuation_price' : Coin,
  });
  const Response_36 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : PriceCalculation,
  });
  const Args_37 = IDL.Record({
    'service_type' : ServiceTypeName,
    'discount_code' : IDL.Opt(IDL.Text),
    'final_price' : Coin,
  });
  const UsedFee = IDL.Record({
    'cost' : Coin,
    'total_cost' : IDL.Opt(Coin),
    'fee_type' : FeeType,
  });
  const PriceCalculationResult = IDL.Record({
    'taxes' : Coin,
    'discounts_used' : IDL.Vec(DiscountUsed),
    'fees' : IDL.Vec(UsedFee),
    'initial_price' : Coin,
    'fees_discounted' : IDL.Vec(UsedFee),
    'final_price' : Coin,
  });
  const Response_37 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : PriceCalculationResult,
  });
  const Response_38 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : StoreListing,
  });
  const Args_38 = IDL.Record({ 'store_listing_id' : IDL.Nat64 });
  const ContactInfo = IDL.Record({
    'email' : IDL.Opt(IDL.Text),
    'phone' : IDL.Opt(IDL.Text),
  });
  const ServiceType = IDL.Variant({
    'Valuation' : IDL.Record({
      'product_types' : IDL.Vec(IDL.Text),
      'price_max' : Coin,
      'price_min' : Coin,
    }),
    'Storage' : IDL.Record({
      'quality' : IDL.Nat32,
      'security' : IDL.Text,
      'capacity' : IDL.Nat32,
    }),
    'Sale' : IDL.Null,
    'Shop' : IDL.Null,
    'Maintenance' : IDL.Record({ 'product_types' : IDL.Vec(IDL.Text) }),
    'Subscription' : IDL.Null,
  });
  const Service = IDL.Record({
    'id' : IDL.Nat64,
    'service_type' : ServiceType,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'shop_id' : IDL.Nat64,
    'price' : IDL.Opt(Coin),
  });
  const ShopExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'distance' : IDL.Opt(IDL.Float64),
    'user_id' : IDL.Principal,
    'contact_info' : IDL.Opt(ContactInfo),
    'visible' : IDL.Bool,
    'location' : Location,
    'services' : IDL.Vec(Service),
  });
  const ProductState = IDL.Variant({
    'New' : IDL.Null,
    'LikeNew' : IDL.Null,
    'Used' : IDL.Null,
  });
  const ProductCategory = IDL.Variant({
    'Accesories' : IDL.Null,
    'Instruments' : IDL.Null,
  });
  const Product = IDL.Record({
    'id' : IDL.Nat64,
    'verified' : IDL.Bool,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'created_at' : IDL.Nat64,
    'state' : ProductState,
    'product_type' : IDL.Text,
    'owner_id' : IDL.Principal,
    'category' : ProductCategory,
    'valuation_price' : Coin,
    'location' : IDL.Opt(Location),
    'images' : IDL.Vec(IDL.Text),
  });
  const StoreListingResponse = IDL.Record({
    'id' : IDL.Nat64,
    'status' : ListingStatus,
    'product_id' : IDL.Nat64,
    'creator_id' : IDL.Principal,
    'contract' : IDL.Opt(ProductContract),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'shop' : IDL.Opt(ShopExpanded),
    'description' : IDL.Text,
    'created_at' : IDL.Nat64,
    'shop_id' : IDL.Opt(IDL.Nat64),
    'product' : IDL.Opt(Product),
  });
  const Response_39 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : StoreListingResponse,
  });
  const Response_40 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : StoreListingResponse,
    'NoPermission' : IDL.Null,
  });
  const PriceRange = IDL.Record({
    'max' : IDL.Opt(IDL.Nat64),
    'min' : IDL.Opt(IDL.Nat64),
  });
  const StoreListingOrderBy = IDL.Variant({
    'Name' : IDL.Null,
    'Status' : IDL.Null,
    'CreatedAt' : IDL.Null,
  });
  const ProductOrderBy = IDL.Variant({
    'Id' : IDL.Null,
    'Name' : IDL.Null,
    'ProductType' : IDL.Null,
    'ValuationPrice' : IDL.Null,
    'CreatedAt' : IDL.Null,
  });
  const GenericOrderBy = IDL.Variant({
    'StoreListing' : StoreListingOrderBy,
    'Product' : ProductOrderBy,
  });
  const Args_39 = IDL.Record({
    'status' : IDL.Opt(ListingStatus),
    'page_size' : IDL.Opt(IDL.Nat64),
    'verified' : IDL.Opt(IDL.Bool),
    'creator_id' : IDL.Opt(IDL.Principal),
    'option_to_buy' : IDL.Opt(IDL.Bool),
    'page' : IDL.Opt(IDL.Nat64),
    'sale_prices' : IDL.Opt(PriceRange),
    'product_ids' : IDL.Vec(IDL.Nat64),
    'shop_id' : IDL.Opt(IDL.Nat64),
    'product_size' : IDL.Opt(IDL.Text),
    'product_type' : IDL.Opt(IDL.Text),
    'valuation_prices' : IDL.Opt(PriceRange),
    'monthly_rental_prices' : IDL.Opt(PriceRange),
    'order_by' : IDL.Opt(GenericOrderBy),
    'location_range' : IDL.Opt(IDL.Float64),
    'min_duration' : IDL.Opt(IDL.Nat64),
    'location' : IDL.Opt(Location),
  });
  const Response_41 = IDL.Variant({
    'Success' : IDL.Vec(StoreListingResponse),
    'InternalError' : IDL.Text,
  });
  const Args_40 = IDL.Record({ 'product_ids' : IDL.Vec(IDL.Nat64) });
  const Response_42 = IDL.Variant({ 'Success' : IDL.Vec(StoreListing) });
  const Args_41 = IDL.Record({ 'status' : ListingStatus });
  const Args_42 = IDL.Record({
    'product_id' : IDL.Nat64,
    'discount_code' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Opt(IDL.Principal),
    'contract_duration' : IDL.Nat16,
    'price' : IDL.Opt(Coin),
    'valuation_price' : Coin,
  });
  const Args_43 = IDL.Record({ 'product_ids' : IDL.Vec(IDL.Nat64) });
  const Response_43 = IDL.Variant({ 'Success' : IDL.Vec(IDL.Nat64) });
  const Args_44 = IDL.Record({
    'user_id' : IDL.Opt(IDL.Principal),
    'email' : IDL.Opt(IDL.Text),
  });
  const Args_45 = IDL.Record({ 'user_id' : IDL.Principal });
  const DiscountAndCode = IDL.Record({
    'code' : IDL.Opt(IDL.Text),
    'discount' : IDL.Opt(Discount),
  });
  const Response_44 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : DiscountAndCode,
    'Unauthorized' : IDL.Null,
  });
  const Args_46 = IDL.Record({
    'status' : IDL.Opt(ReferralStatus),
    'user_id' : IDL.Principal,
  });
  const Response_45 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : IDL.Vec(Referral),
    'Unauthorized' : IDL.Null,
  });
  const Args_47 = IDL.Record({
    'transfer_contract' : IDL.Bool,
    'new_user_id' : IDL.Principal,
    'store_listing_id' : IDL.Nat64,
  });
  const Response_46 = IDL.Variant({
    'NotFound' : IDL.Null,
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_48 = IDL.Record({
    'product_subcontract' : ProductSubContract,
    'new_id' : IDL.Opt(IDL.Nat64),
  });
  const Args_49 = IDL.Record({
    'id' : IDL.Nat64,
    'contract_status' : ContractStatus,
  });
  const Response_47 = IDL.Variant({
    'Error' : IDL.Text,
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : ProductSubContract,
    'NoPermission' : IDL.Null,
  });
  const Args_50 = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Opt(IDL.Opt(IDL.Nat64)),
    'status' : IDL.Opt(ReferralStatus),
    'metadata' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))),
    'referred_id' : IDL.Opt(IDL.Principal),
    'referrer_id' : IDL.Opt(IDL.Principal),
    'created_at' : IDL.Opt(IDL.Nat64),
    'discount_id' : IDL.Opt(IDL.Opt(IDL.Nat64)),
  });
  const Args_51 = IDL.Record({ 'discount_code' : IDL.Text });
  return IDL.Service({
    'add_discount' : IDL.Func([Args], [UseDiscountResponse], []),
    'add_discount_code' : IDL.Func([Args_1], [Response], []),
    'add_fee' : IDL.Func([Args_2], [Response_1], []),
    'add_product_contract' : IDL.Func([Args_3], [Response_2], []),
    'add_product_recommended_contract' : IDL.Func([Args_4], [Response_3], []),
    'add_product_request' : IDL.Func([Args_5], [Response_4], []),
    'add_product_subcontract' : IDL.Func([Args_6], [Response_5], []),
    'add_referral' : IDL.Func([Args_7], [Response_6], []),
    'add_store_listing' : IDL.Func([Args_8], [Response_7], []),
    'apply_discount' : IDL.Func([Args_9], [Response_8], ['query']),
    'change_user_id' : IDL.Func([Args_10], [Response_9], []),
    'delete_discount' : IDL.Func([Args_11], [Response_10], []),
    'delete_discount_code' : IDL.Func([Args_12], [Response_10], []),
    'delete_fee' : IDL.Func([Args_13], [Response_11], []),
    'delete_product_contract' : IDL.Func([Args_14], [Response_12], []),
    'delete_product_contract_by_product_id' : IDL.Func(
        [Args_15],
        [Response_12],
        [],
      ),
    'delete_product_recommended_contract' : IDL.Func(
        [Args_16],
        [Response_12],
        [],
      ),
    'delete_product_request' : IDL.Func([Args_17], [Response_13], []),
    'delete_product_subcontract' : IDL.Func([Args_18], [Response_12], []),
    'delete_product_subcontract_by_contract_id' : IDL.Func(
        [Args_19],
        [Response_14],
        [],
      ),
    'delete_referral' : IDL.Func([Args_20], [Response_15], []),
    'delete_store_listing' : IDL.Func([Args_21], [Response_15], []),
    'delete_store_listing_by_product_id' : IDL.Func(
        [Args_22],
        [Response_15],
        [],
      ),
    'generate_marketplace_testdata' : IDL.Func([Args_23], [Response_16], []),
    'generate_user_referral' : IDL.Func([Args_24], [Response_17], []),
    'get_discount' : IDL.Func([Args_25], [Response_18], ['query']),
    'get_discounts' : IDL.Func([Args_26], [Response_19], ['query']),
    'get_fee' : IDL.Func([Args_27], [Response_20], ['query']),
    'get_fees' : IDL.Func([IDL.Record({})], [Response_21], ['query']),
    'get_pending_reward_referrals' : IDL.Func(
        [Args_28],
        [Response_22],
        ['query'],
      ),
    'get_product_contract' : IDL.Func([Args_29], [Response_23], ['query']),
    'get_product_contract_by_product_id' : IDL.Func(
        [Args_22],
        [Response_23],
        ['query'],
      ),
    'get_product_contracts' : IDL.Func(
        [IDL.Record({})],
        [Response_24],
        ['query'],
      ),
    'get_product_contracts_by_product_id' : IDL.Func(
        [Args_22],
        [Response_25],
        ['query'],
      ),
    'get_product_contracts_expanded' : IDL.Func(
        [IDL.Record({})],
        [Response_26],
        ['query'],
      ),
    'get_product_contracts_with_subcontracts_by_product_ids' : IDL.Func(
        [Args_30],
        [Response_26],
        ['query'],
      ),
    'get_product_prices' : IDL.Func([Args_31], [Response_27], ['query']),
    'get_product_recommended_contract' : IDL.Func(
        [Args_32],
        [Response_28],
        ['query'],
      ),
    'get_product_recommended_contracts' : IDL.Func(
        [IDL.Record({})],
        [Response_29],
        ['query'],
      ),
    'get_product_requests' : IDL.Func(
        [IDL.Record({})],
        [Response_30],
        ['query'],
      ),
    'get_product_subcontract' : IDL.Func([Args_33], [Response_31], ['query']),
    'get_product_subcontracts' : IDL.Func([Args_34], [Response_32], ['query']),
    'get_product_subcontracts_by_contract_id' : IDL.Func(
        [Args_35],
        [Response_33],
        ['query'],
      ),
    'get_product_subcontracts_expanded' : IDL.Func(
        [IDL.Record({})],
        [Response_34],
        [],
      ),
    'get_product_subcontracts_pending_verification' : IDL.Func(
        [IDL.Record({})],
        [Response_35],
        ['query'],
      ),
    'get_sale_price' : IDL.Func([Args_36], [Response_36], ['query']),
    'get_service_price' : IDL.Func([Args_37], [Response_37], ['query']),
    'get_store_base_listing_by_product_id' : IDL.Func(
        [Args_22],
        [Response_38],
        ['query'],
      ),
    'get_store_listing' : IDL.Func([Args_38], [Response_39], ['query']),
    'get_store_listing_by_product_id' : IDL.Func(
        [Args_22],
        [Response_40],
        ['query'],
      ),
    'get_store_listings' : IDL.Func([Args_39], [Response_41], ['query']),
    'get_store_listings_by_product_ids' : IDL.Func(
        [Args_40],
        [Response_42],
        ['query'],
      ),
    'get_store_listings_by_status' : IDL.Func(
        [Args_41],
        [Response_41],
        ['query'],
      ),
    'get_subscription_price' : IDL.Func([Args_42], [Response_36], ['query']),
    'get_unavailable_store_listings' : IDL.Func(
        [Args_43],
        [Response_43],
        ['query'],
      ),
    'get_user_product_requests' : IDL.Func([Args_44], [Response_30], ['query']),
    'get_user_referral_code' : IDL.Func([Args_45], [Response_44], ['query']),
    'get_user_referrals' : IDL.Func([Args_46], [Response_45], ['query']),
    'reset_marketplace_testdata' : IDL.Func(
        [IDL.Record({})],
        [Response_13],
        [],
      ),
    'transfer_product' : IDL.Func([Args_47], [Response_46], []),
    'update_discount' : IDL.Func([Args], [UseDiscountResponse], []),
    'update_discount_code' : IDL.Func([Args_1], [Response], []),
    'update_fee' : IDL.Func([Args_2], [Response_1], []),
    'update_product_contract' : IDL.Func([Args_3], [Response_2], []),
    'update_product_recommended_contract' : IDL.Func(
        [Args_4],
        [Response_3],
        [],
      ),
    'update_product_request' : IDL.Func([Args_5], [Response_4], []),
    'update_product_subcontract' : IDL.Func([Args_48], [Response_5], []),
    'update_product_subcontract_status' : IDL.Func(
        [Args_49],
        [Response_47],
        [],
      ),
    'update_referral' : IDL.Func([Args_50], [Response_6], []),
    'update_store_listing' : IDL.Func([Args_8], [Response_7], []),
    'use_discount' : IDL.Func([Args_25], [UseDiscountResponse], []),
    'use_discount_code' : IDL.Func([Args_51], [Response], []),
  });
};
export const init = ({ IDL }) => { return []; };
