/* eslint-disable no-undef */
import { Principal } from "@dfinity/principal";
import Payment from "model/payments/Payment";
import { TRANSACTION_STATUS_MAP } from "model/payments/TransactionItem";
import TransactionWithItems, { SERVICE_TYPE_NAME_MAP } from "model/payments/TransactionWithItems";
import {
  CURRENCIES,
  TRANSACTION_TYPES,
  createRedsysAPI
} from 'redsys-easy';
import { CURRENT_ENDPOINT, IS_PROD, REDSYS_DEV_MERCHANT_URL, REDSYS_KEY, REDSYS_KEY_999, REDSYS_MERCHANT_CODE, REDSYS_MERCHANT_INFO, REDSYS_REDIRECT_URLS, REDSYS_URLS } from "src/config-global";
import { addRefundToMetadata, clonePriceCalculation } from "src/utils/price-utils";
import { getEnumVariableText } from "src/utils/typeConverters";
import BackendService from "./BackendService";
import ProductContractsService from "./ProductContractsService";
import { PaymentIntent } from "model/payments/PaymentIntent";
import { PaymentMethodExpanded } from "model/payments/PaymentMethod";

export const PAYMENT_ADMIN_KEY = 'payments-admin';
export const PAYMENT_USER_KEY = "payments-user";
export const INITIAL_ADMIN_STORAGE_STATE = {
  subcontract: null,
  orderId: "",
  price: null, // Coin
  paymentMethod: null,
  transactionId: 0,
};

/* eslint-disable no-dupe-class-members */

export const PAYMENT_STATUS = {
  pending: { 'Pending': null },
  failed: { 'Failed': null },
  wrongMethod: { 'WrongMethod': null },
  completed: { 'Completed': null },
};

export const PAYMENT_STATUS_ARRAY = [
  { name : 'Pending', enum: PAYMENT_STATUS.pending },
  { name : 'Failed', enum: PAYMENT_STATUS.failed },
  { name : 'WrongMethod', enum: PAYMENT_STATUS.wrongMethod },
  { name : 'Completed', enum: PAYMENT_STATUS.completed },
];

export const PAYMENT_METHOD_STATUS = {
  valid: { 'Valid': null },
  error: { 'Error': null },
  expiredCard: { 'ExpiredCard': null },
};

// TODO populate service params
export const SERVICE_TYPE = {
  storage: { 'Storage': null },
  maintenance: { 'Maintenance': null },
  valuation: { 'Valuation': null },
  subscription: { 'Subscription': null },
  sale: { 'Sale': null },
};

export const PAYMENT_METHOD_TYPE = {
  local: { value: "local", label: "checkout.save_payment" },
  newmethod: { value: "newmethod", label: "checkout.pay_new_card" },
  pay: { value: "pay", label: "checkout.pay" },
  paygold: { value: "paygold", label: "checkout.link" },
};

export const IBAN_REGEX = /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9A-Z]{4})*$/; // Simplified regex for IBAN

/**
 * Provides methods related to the products
 */
export default class PaymentsService {
  static createRedsysAPI = ({ isPaygold = false }) => {
    // console.log('isPaygold', JSON.stringify(isPaygold));
    //     console.log("REDSYS_KEY_999", JSON.stringify(REDSYS_KEY_999));
    // console.log("REDSYS_KEY", JSON.stringify(REDSYS_KEY));

    const PROXIED_REDSYS_URLS = {
      ...REDSYS_URLS,
      restTrataPeticion: "https://65c38744ef9f7b2dfa851eb7--symphyes.netlify.app/.netlify/functions/cors-proxy?url=" + REDSYS_URLS.restTrataPeticion,
    };
    // console.log("createRedsysAPI secret  ", JSON.stringify(isPaygold ? REDSYS_KEY_999 : REDSYS_KEY));
    return createRedsysAPI({
      // urls: {
      //   redirect: SANDBOX_URLS.redirect,
      //   restTrataPeticion: "https://cors-anywhere.herokuapp.com/" + SANDBOX_URLS.restTrataPeticion,
      //   restIniciaPeticion: "https://cors-anywhere.herokuapp.com/" + SANDBOX_URLS.restIniciaPeticion,
      // },
      urls: PROXIED_REDSYS_URLS,
      secretKey: isPaygold ? REDSYS_KEY_999 : REDSYS_KEY,
    });
  };

  // REDSYS
  static getRedsysFormData = ({ redsysAPI, isFirstPayment = true, orderId, amount, merchantCofTxnid, merchantIdentifier, redirect = "checkout" }) => {
    const { createRedirectForm, processRestNotification, restTrataPeticion } = redsysAPI;

    const currency = CURRENCIES["EUR"].num;
    return createRedirectForm({
      ...REDSYS_MERCHANT_INFO,
      ...this.getRedsysCommonParams(orderId, amount, currency, redirect),
      ...this.getRedsysSubscriptionParams(isFirstPayment, merchantCofTxnid, merchantIdentifier),
    });
  };

  static getRedsysCommonParams = (orderId, amount, currency, redirect) => {
    let merchantUrlOk;
    let merchantUrlKo;
    if (redirect === "checkout") {
      merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS}`;
      merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR}`;
    } else if (redirect === "product") {
      merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_PRODUCTS}`;
      merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_PRODUCTS}`;
    } else if (redirect === "product-overview") {
      merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_PRODUCTS}`;
      merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_PRODUCTS}`;
    } else if (redirect === "admin") {
      merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_ADMIN}`;
      merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_ADMIN}`;
    } else {
      merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_OVERVIEW}`;
      merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_OVERVIEW}`;
    }
    let merchantUrl = "";
    // if (IS_PROD) {
    // merchantUrl = `https://${process.env.CANISTER_ID_PAYMENTS}.raw.icp0.io${REDSYS_REDIRECT_URLS.API_PROD}`;
    // } else {
    // OK
    // merchantUrl = `https://73iiu-saaaa-aaaaj-azrva-cai.raw.icp0.io${REDSYS_REDIRECT_URLS.API}`;
    //  NOK
    merchantUrl = REDSYS_DEV_MERCHANT_URL;
    // Error url
    // merchantUrl = `http://127.0.0.1:8000/?canisterId=${process.env.CANISTER_ID_PAYMENTS}`;
    // }
    // Convert EUR -> 978
    return {
      DS_MERCHANT_ORDER: orderId,
      DS_MERCHANT_TRANSACTIONTYPE: TRANSACTION_TYPES.AUTHORIZATION, // '0'
      // amount in smallest currency unit(cents)
      DS_MERCHANT_AMOUNT: amount,
      DS_MERCHANT_CURRENCY: currency,
      DS_MERCHANT_MERCHANTURL: merchantUrl,
      DS_MERCHANT_URLOK: merchantUrlOk,
      DS_MERCHANT_URLKO: merchantUrlKo,
    };
  };

  static getRedsysSubscriptionParams = (isFirstPayment, merchantCofTxnid, merchantIdentifier) => {
    if (isFirstPayment) {
      return {
        // RECURRENT
        DS_MERCHANT_COF_TYPE: "R",
        DS_MERCHANT_COF_INI: "S", // First payment. Next payments store ID DS_MERCHANT_COF_TXNID
        // DS_MERCHANT_EXCEP_SCA: "MIT",
        DS_MERCHANT_IDENTIFIER: "REQUIRED",
      };
    } else {
      return {
        // RECURRENT
        DS_MERCHANT_COF_TYPE: "R",
        DS_MERCHANT_COF_INI: "N", // First payment. Next payments store ID DS_MERCHANT_COF_TXNID
        DS_MERCHANT_COF_TXNID: merchantCofTxnid,
        DS_MERCHANT_EXCEP_SCA: "MIT",
        DS_MERCHANT_DIRECTPAYMENT: "true",
        DS_MERCHANT_IDENTIFIER: merchantIdentifier,
      };
    }
  };

  /**
   * Sometimes the == at the end is cut, added it
   * @param {*} str
   * @returns
   */
  static getFixedMerchandParams = (str) => {
    if (str.slice(-2) !== "==") {
      return str + "==";
    }
    return str;
  };

  // Paygold

  static getRedsysPaygoldRequest = ({ redsysAPI, orderId, amount, emailSubject, userName, userPhone, userEmail, emailText = "", userAddress }) => {
    // let merchantUrlOk;
    // let merchantUrlKo;
    // if (redirect === "checkout") {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR}`;
    // } else if (redirect === "admin") {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_ADMIN}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_ADMIN}`;
    // } else {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_OVERVIEW}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_OVERVIEW}`;
    // }
    const { restTrataPeticion } = redsysAPI;
    const currency = CURRENCIES["EUR"].num;

    let merchantUrl = "";
    if (IS_PROD) {
      merchantUrl = `https://${process.env.CANISTER_ID_PAYMENTS}.raw.icp0.io${REDSYS_REDIRECT_URLS.API_PROD}`;
    } else {
      merchantUrl = REDSYS_DEV_MERCHANT_URL;
      // Override notification info
      // console.log("email " + userEmail + " phone " + userPhone);
      userEmail = "pablosone@hotmail.com";
      userPhone = "+34677647104";
    }
    // userEmail = "pablosone@hotmail.com";
    // userPhone = "+34677647104";
    const params = {
      DS_MERCHANT_MERCHANTCODE: REDSYS_MERCHANT_CODE,
      DS_MERCHANT_TERMINAL: "999",
      DS_MERCHANT_TRANSACTIONTYPE: TRANSACTION_TYPES.PAYLINK,

      DS_MERCHANT_AMOUNT: amount,
      DS_MERCHANT_CURRENCY: currency,
      DS_MERCHANT_ORDER: orderId,
      DS_MERCHANT_CUSTOMER_MAIL: userEmail,
      DS_MERCHANT_CUSTOMER_MOBILE: userPhone,
      DS_MERCHANT_MERCHANTURL: merchantUrl,
      DS_MERCHANT_P2F_XMLDATA: `<nombreComprador>${userName}</nombreComprador><direccionComprador>${userAddress}</direccionComprador><textoLibre1>${emailText}</textoLibre1><subjectMailCliente>${emailSubject}</subjectMailCliente>`,

      // Recursive
      DS_MERCHANT_IDENTIFIER: "REQUIRED",
      //   DS_MERCHANT_CVV2: "123",
      //   DS_MERCHANT_EXPIRYDATE: "3912",
      //   DS_MERCHANT_PAN: "4548810000000003",
    };
    // console.log("getRedsysPaygoldRequest", JSON.stringify(params));
    return restTrataPeticion(params);
    // SUBS
    // {
    //   DS_MERCHANT_MERCHANTCODE: REDSYS_MERCHANT_CODE,
    //   DS_MERCHANT_TERMINAL: "999", //1?
    //   DS_MERCHANT_TRANSACTIONTYPE:  TRANSACTION_TYPES.PAYLINK,

    //   DS_MERCHANT_AMOUNT: amount,
    //   DS_MERCHANT_CURRENCY: currency,
    //   DS_MERCHANT_ORDER: orderId,

    // DS_MERCHANT_IDENTIFIER: "REQUIRED",

    // };
  };

  static getRedsysRefundRequest = ({ redsysAPI, orderId, amount, terminal }) => {
    // let merchantUrlOk;
    // let merchantUrlKo;
    // if (redirect === "checkout") {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR}`;
    // } else if (redirect === "admin") {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_ADMIN}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_ADMIN}`;
    // } else {
    //   merchantUrlOk = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.SUCCESS_OVERVIEW}`;
    //   merchantUrlKo = `${CURRENT_ENDPOINT}${REDSYS_REDIRECT_URLS.ERROR_OVERVIEW}`;
    // }
    const { restTrataPeticion } = redsysAPI;
    const currency = CURRENCIES["EUR"].num;
    const params = {
      DS_MERCHANT_AMOUNT: amount,
      DS_MERCHANT_CURRENCY: currency,
      DS_MERCHANT_MERCHANTCODE: REDSYS_MERCHANT_CODE,
      DS_MERCHANT_ORDER: orderId,
      DS_MERCHANT_TERMINAL: terminal ? terminal : "1",
      DS_MERCHANT_TRANSACTIONTYPE: TRANSACTION_TYPES.AUTO_REFUND,
    };
    // console.log("params", JSON.stringify(params));
    return restTrataPeticion(params);
  };

  ///

  // Create a list of transaction items from a subcontract list
  // Objects { subcContract , productId, item (checkoutItem)}
  static generateTransactionItemsFromSubcontracts = (subContracts) => {
    const transactionItems = subContracts.map((subContractItem) => {
      const serviceType = subContractItem.subContract.isSubscription() ? SERVICE_TYPE.subscription : SERVICE_TYPE.sale;
      const isReserved = subContractItem.item && subContractItem.item.isReserved;
      const status = isReserved ? TRANSACTION_STATUS_MAP.reserved.enum : null;
      const reservePrice = isReserved && subContractItem.item.reservePrice ? subContractItem.item.reservePrice : null;
      const metadata = isReserved ? [["reservation_price", JSON.stringify(reservePrice)]] : null;
      return PaymentsService.createTransactionItem(0, 0, subContractItem.subContract.id, serviceType, subContractItem.productId, status, metadata);
    });

    return transactionItems.filter((item) => item !== null);
  };

  /**
   * Add the first payment
   *
   * @returns
   */
  static addFirstPayment = async (
    symphy_backend,
    userId,
    transactionItems,
    authorizedTransactionId,
    orderId,
    price,
    paymentStatus,
    merchantIdentifier,
    cardExpiryDate,
    cardNumber,
    cardBrand,
    paymentGateway,
    createdAt
  ) => {
    const response = await symphy_backend["payments"].add_first_payment({
      user_id: userId,
      transaction_items: transactionItems,
      // subcontract_id: subcontractId,
      // product_id: productId,
      // service_type: serviceType,
      // Payment method
      payment_gateway: paymentGateway ? [paymentGateway] : [],
      authorized_transaction_id: authorizedTransactionId ? [authorizedTransactionId] : [], // Subscription transaction id (merchant_cof_txnid for redsys), needed for next operations
      // Intent
      order_id: orderId ? [orderId] : [],
      price: price ? [price] : [],
      status: paymentStatus,

      // Card
      merchant_identifier: merchantIdentifier ? [merchantIdentifier] : [], // Card id
      card_expiry_date: cardExpiryDate ? [Number(cardExpiryDate)] : [], // Ds_ExpiryDate format is YYMM
      card_number: cardNumber ? [cardNumber] : [], // Ds_CardNumber
      card_brand: cardBrand ? [cardBrand] : [], // Ds_Card_Brand

      created_at: createdAt ? [BigInt(createdAt)] : [],
    });
    if (!response.Success) {
      console.log("addFirstPayment not successful " + JSON.stringify(response));
      return { error: response };
    }
    return response.Success;
  };

  /**
   * Return a list of all discounts
   *
   * @returns All products
   */
  static addPayment = async (
    symphy_backend,
    userId,
    // subcontractId,
    // productId,
    transactionId,
    orderId,
    price,
    paymentMethodId,
    paymentGateway,
    metadata,
    createdAt,
    status
  ) => {
    // console.log("add payment  order " + JSON.stringify(orderId) + " price " + JSON.stringify(price) + " paymentmethod " + JSON.stringify(paymentMethodId));
    // console.log("add payment " + JSON.stringify(userId) + " " + JSON.stringify(subcontractId) + " " + JSON.stringify(productId) + " " + JSON.stringify(transactionId) + " " + JSON.stringify(orderId) + " " + JSON.stringify(price) + " " + JSON.stringify(paymentMethodId));
    const response = await symphy_backend["payments"].add_payment({
      user_id: userId,
      // subcontract_id: BigInt(subcontractId),
      // product_id: BigInt(productId),
      transaction_id: BigInt(transactionId),
      // Intent
      order_id: orderId ? [orderId] : [],
      price: price ? [clonePriceCalculation(price)] : [],
      payment_method_id: paymentMethodId ? [BigInt(paymentMethodId)] : [],
      metadata: metadata ? metadata : [],
      payment_gateway: paymentGateway ? [paymentGateway] : [],
      created_at: createdAt ? [BigInt(createdAt)] : [],
      status: status ? [status] : [],
    });
    if (!response.Success) {
      console.log("addPayment not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Add a payment conrirmation
   *
   * @returns All products
   */
  static addPaymentConfirmation = async (
    symphy_backend,
    transactionId,
    orderId,
    authorizedTransactionId,
    merchantIdentifier,
    cardExpiryDate,
    cardNumber,
    cardBrand
  ) => {
    const response = await symphy_backend["payments"].add_payment_confirmation({
      transaction_id: transactionId ? [transactionId] : [],
      // Payment method
      authorized_transaction_id: authorizedTransactionId ? [authorizedTransactionId] : [], // Subscription transaction id (merchant_cof_txnid for redsys), needed for next operations
      // Intent
      order_id: orderId ? [orderId] : [],
      status: PAYMENT_STATUS.completed,
      // Card
      merchant_identifier: merchantIdentifier ? [merchantIdentifier] : [], // Card id
      card_expiry_date: cardExpiryDate ? [Number(cardExpiryDate)] : [], // Ds_ExpiryDate format is YYMM
      card_number: cardNumber ? [cardNumber] : [], // Ds_CardNumber
      card_brand: cardBrand ? [cardBrand] : [], // Ds_Card_Brand
    });
    if (!response.Success) {
      console.log("addPaymentConfirmation not successful " + JSON.stringify(response));
      return null;
    }
    console.log("addPaymentConfirmation", JSON.stringify(response.Success));
    return response.Success;
  };

  static getCards = async (symphy_backend, id) => {
    const response = await symphy_backend["payments"].get_cards({ user_id: id ? [Principal.fromText(id.toString())] : [] });
    if (!response.Success) {
      console.log("getCards not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Get payment methods
   *
   * @returns
   */
  static getPaymentMethods = async (symphy_backend, id) => {
    const response = await symphy_backend["payments"].get_payment_methods({ user_id: id ? [Principal.fromText(id.toString())] : [] });
    if (!response.Success) {
      console.log("getPaymentMethods not successful");
      return null;
    }
    return response.Success.map((item) => PaymentMethodExpanded.fromJSON(item));
  };

  /**
   * Get payment methods
   *
   * @returns
   */
  static getPaymentMethodsExpanded = async (symphy_backend, ids) => {
    const response = await symphy_backend["payments"].get_payment_methods_expanded({ user_ids: ids });
    if (!response.Success) {
      console.log("getPaymentMethodsExpanded not successful");
      return null;
    }
    // console.log("getPaymentMethodsExpanded successful " + JSON.stringify(response));
    return response.Success.map((item) => PaymentMethodExpanded.fromJSON(item));
  };

  static fromContractDurationToInterval = (contractDuration) => {
    // console.log("fromContractDurationToInterval", JSON.stringify(contractDuration));
    if (contractDuration) {
      if (contractDuration.duration < 30) {
        return { Day: null };
      } else {
        return { Month: null };
      }
    } else {
      return null;
    }
  };

  static createPaymentMethod = (id, userId, authorizedTransactionId, cardId, status, primary) => {
    const paymentMethod = {
      user_id: userId ? [Principal.fromText(userId.toString())] : [],
      authorized_transaction_id: authorizedTransactionId ? [authorizedTransactionId] : [],
      card_id: cardId ? [cardId] : [],
      status: status,
      primary: primary,
    };
    return paymentMethod;
  };

  static createTransactionItem = (id, transactionId, subcontractId, serviceType, productId, status, metadata) => {
    const item = {
      id: BigInt(id),
      transaction_id: BigInt(transactionId),
      transaction_type: { Shop: { subcontract_id: BigInt(subcontractId) } },
      service_name: { name: getEnumVariableText(serviceType) },
      product_id: productId ? [BigInt(productId)] : [],
      status: status ? status : TRANSACTION_STATUS_MAP.completed.enum,
      metadata: metadata ? metadata : [],
    };
    return item;
  };

  static updateTransactionItemSubcontractId = (transactionItem, subcontractId) => {
    if (transactionItem && transactionItem.transaction_type && transactionItem.transaction_type.Shop) {
      transactionItem.transaction_type.Shop.subcontract_id = subcontractId;
      return transactionItem;
    }
    return null;
  };
  // /**
  //  * Create a payment method
  //  *
  //  * @returns
  //  */
  // static addDiscount = async (symphy_backend, discount) => {
  //   const response = await symphy_backend["payments"].add_discount(
  //     { discount: discount }
  //   );
  //   if (!response.Success) {
  //     console.log("addProductDefinition not successful");
  //     return null;
  //   }
  //   return response.Success;
  // };

  // /**
  //  * Update product definition
  //  *
  //  */
  // static updateDiscount = async (
  //   symphy_backend,
  //   discount
  // ) => {
  //   let response =
  //     await symphy_backend["payments"].update_discount({ discount: discount });
  //   if (!response.Success) {
  //     console.log("updateDiscount not successful");
  //     return null;
  //   }
  //   return response.Success;
  // };

  // /**
  //  * Deletes a product definition by id
  //  *
  //  */
  // static deleteDiscount = async (symphy_backend, id) => {
  //   let response = await symphy_backend["payments"].delete_discount({ discount_id: id });
  //   if (!response.Success) {
  //     console.log("deleteDiscount not successful");
  //     return null;
  //   }
  //   return response.Success;
  // };

  // /**
  //  * Uses a discount
  //  *
  //  */
  // static useDiscount = async (symphy_backend, id) => {
  //   let response = await symphy_backend["payments"].use_discount({ discount_id: id });
  //   if (!response.Success) {
  //     console.log("useDiscount not successful");
  //     return null;
  //   }
  //   return response.Success;
  // };

  // Transactions

  /**
   *  Get Transactions by product ids or subcontract ids
   * @param {*} symphy_backend
   * @param {*} subcontractIds
   * @param {*} productIds
   * @returns
   */
  static getTransactions = async (symphy_backend, filter) => {
    let response = await symphy_backend["payments"].get_transactions({
      transaction_ids: filter.transaction_ids ? [filter.transaction_ids] : [],
      product_ids: filter.product_ids ? [filter.product_ids] : [],
      subcontract_ids: filter.subcontract_ids ? [filter.subcontract_ids] : [],
      with_payment_intents: filter.with_payment_intents ? filter.with_payment_intents : false,
      service_names: filter.service_names ? [filter.service_names] : [],
      user_id: filter.user_id ? [filter.user_id] : [],
      add_subcontracts: filter.add_subcontracts ? filter.add_subcontracts : false,
    });
    if (BackendService.isResponseError(response)) {
      console.log("getTransactions not successful " + JSON.stringify(response));
      return null;
    }
    // Order the payment intents
    const responseSuccess = response.Success.map((transaction) => {
      const sortedPaymentIntents = [...transaction.payment_intents].sort((a, b) => Number(a.date) - Number(b.date));
      return { ...transaction, payment_intents: sortedPaymentIntents };
    });
    return responseSuccess.map((item) => TransactionWithItems.fromJSON(item));
  };

  /**
   *  Get Transactions of a seller
   * @param {*} symphy_backend
   * @param {*} subcontractIds
   * @param {*} productIds
   * @returns
   */
  static getSellerTransactions = async (symphy_backend, filter) => {
    let response = await symphy_backend["payments"].get_seller_transactions({
      transaction_ids: filter.transaction_ids ? [filter.transaction_ids] : [],
      product_ids: filter.product_ids ? [filter.product_ids] : [],
      subcontract_ids: filter.subcontract_ids ? [filter.subcontract_ids] : [],
      user_id: filter.user_id ? [filter.user_id] : [],
      shop_id: filter.shop_id ? [filter.shop_id] : [],
      service_names: filter.service_names ? [filter.service_names] : [],
      with_payment_intents: filter.with_payment_intents ? filter.with_payment_intents : false,
    });
    if (BackendService.isResponseError(response)) {
      console.log("getSellerTransactions not successful " + JSON.stringify(response));
      return null;
    }
    // Order the payment intents
    // const responseSuccess = response.Success.map((transaction) => {
    //   const sortedPaymentIntents = [...transaction.payment_intents].sort((a, b) => Number(a.date) - Number(b.date));
    //   return { ...transaction, payment_intents: sortedPaymentIntents };
    // });
    return response.Success.map((item) => TransactionWithItems.fromJSON(item));
  };

  /**
   *  Get Transactions of current user
   * @returns
   */
  static getMyTransactions = async (symphy_backend, filter) => {
    const response = await symphy_backend["payments"].get_my_transactions({
      transaction_ids: filter.transaction_ids ? [filter.transaction_ids] : [],
      product_ids: filter.product_ids ? [filter.product_ids] : [],
      subcontract_ids: filter.subcontract_ids ? [filter.subcontract_ids] : [],
      with_payment_intents: filter.with_payment_intents ? filter.with_payment_intents : false,
      user_id: filter.user_id ? [filter.user_id] : [],
      service_names: filter.service_names ? [filter.service_names] : [],
      add_subcontracts: filter.add_subcontracts ? filter.add_subcontracts : false,
    });
    if (BackendService.isResponseError(response)) {
      console.log("getMyTransactions not successful " + JSON.stringify(response));
      return null;
    }
    const responseSuccess = response.Success.map((transaction) => {
      const sortedPaymentIntents = [...transaction.payment_intents].sort((a, b) => Number(a.date) - Number(b.date));
      return { ...transaction, payment_intents: sortedPaymentIntents };
    });

    return responseSuccess.map((item) => TransactionWithItems.fromJSON(item));
  };

  /**
   * There is one order id for transaction. The one usefull for emails is typically the first
   * @returns
   */
  static getTransactionOrderIds = async (symphy_backend, transactionId) => {
    let response = await symphy_backend["payments"].get_transaction_order_ids({ transaction_id: transactionId });
    if (BackendService.isResponseError(response)) {
      console.log("getTransactionOrderIds not successful " + JSON.stringify(response));
      return [];
    }
    return response.Success;
  };

  /**
   * There is one order id for transaction. The one usefull for emails is typically the first
   * @returns
   */
  static getSubcontractOrderIds = async (symphy_backend, subcontractId) => {
    let response = await symphy_backend["payments"].get_subcontract_order_ids({ subcontract_id: subcontractId });
    if (BackendService.isResponseError(response)) {
      console.log("getSubcontractOrderIds not successful " + JSON.stringify(response));
      return [];
    }
    return response.Success;
  };

  static addServiceTransaction = async (symphy_backend, product_id, userId, sellerId, shopId, price, serviceName, serviceDescription, status) => {
    const response = await symphy_backend["payments"].add_service_transaction({
      product_id: product_id ? [BigInt(product_id)] : [],
      user_id: userId,
      seller_id: sellerId,
      shop_id: shopId,
      price: price ? [price] : [],
      status: status ? status : TRANSACTION_STATUS_MAP.completed.enum,
      service_name: serviceName ? serviceName : SERVICE_TYPE_NAME_MAP.valuation,
      service_description: serviceDescription ? [serviceDescription] : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("addServiceTransaction not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  static addShopTransaction = async (symphy_backend, info, productId, subcontractId, userId, serviceName, status) => {
    const response = await symphy_backend["payments"].add_shop_transaction({
      info: info ? info :  "Payment",
      user_id: userId,
      service_name: {name: serviceName ? serviceName : SERVICE_TYPE_NAME_MAP.subscription},
      subcontract_id: BigInt(subcontractId),
      product_id: BigInt(productId),
      status: status ? status : TRANSACTION_STATUS_MAP.completed.enum,
    });
    if (BackendService.isResponseError(response)) {
      console.log("addServiceTransaction not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  static addValuationTransaction = async (symphy_backend, args) => {
    return this.addServiceTransaction(
      symphy_backend,
      args.productId,
      args.userId,
      args.sellerId,
      args.shopId,
      args.price,
      SERVICE_TYPE_NAME_MAP.valuation,
      args.serviceDescription,
      args.status
    );
  };

  static addMaintenanceTransaction = async (symphy_backend, args) => {
    return this.addServiceTransaction(
      symphy_backend,
      args.productId,
      args.userId,
      args.sellerId,
      args.shopId,
      args.price,
      SERVICE_TYPE_NAME_MAP.maintenance,
      args.serviceDescription,
      args.status
    );
  };

  static createTransactionItemToUpdate = (itemId, transactionType, serviceName, productId, itemStatus, itemMetadata, isNew = false) => {
    const item = {
      item_id: itemId ? itemId : BigInt(0),
      transaction_type: transactionType ? [transactionType] : [],
      service_name: serviceName ? [serviceName] : [],
      product_id: productId ? [productId] : [],
      item_status: itemStatus ? [itemStatus] : [],
      item_metadata: itemMetadata ? [itemMetadata] : [],
      is_new: isNew,
    };
    return item;
  };

  static updateTransaction = async (symphy_backend, id, info, userId, createdAt, modifiedAt, metadata, items) => {
    let response = await symphy_backend["payments"].update_transaction({
      id: BigInt(id),
      info: info ? [info] : [],
      user_id: userId ? [userId] : [],
      created_at: createdAt ? [BigInt(createdAt)] : [],
      modified_at: modifiedAt ? [BigInt(modifiedAt)] : [],
      metadata: metadata ? [metadata] : [],
      items: items ? items : [],
    });

    if (BackendService.isResponseError(response)) {
      console.log("updateTransaction not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  static updateServiceTransaction = async (symphy_backend, args) => {
    return this.updateTransaction(
      symphy_backend,
      args.id,
      args.serviceDescription,
      args.userId,
      args.createdAt,
      args.modifiedAt,
      args.metadata,
      args.items
      // args.transactionType ? args.transactionType : type,
      // args.serviceName,

      // args.productId,
      // args.itemStatus,
      // args.itemMetadata

      // args.sellerId,
      // args.shopId,
      // args.price,
      // args.status
    );
  };

  static deleteTransaction = async (symphy_backend, id, withChildren) => {
    let response = await symphy_backend["payments"].delete_transaction({
      transaction_id: BigInt(id),
      with_children: withChildren ? withChildren : false,
    });
    if (BackendService.isResponseError(response)) {
      console.log("deleteTransaction not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  static createPaymentIntent = (transactionId, orderId, paymentMethodId, paymentGateway, date, priceCalculation, status, metadata) => {
    const item = {
      transaction_id: BigInt(transactionId),
      order_id: orderId ? [orderId] : [],
      payment_method_id: paymentMethodId ? [paymentMethodId] : [],
      payment_gateway: paymentGateway ? [paymentGateway] : [],
      date: date ? [date] : [],
      price: priceCalculation ? [priceCalculation] : [],
      status: status,
      metadata: metadata ? metadata : [],
    };
    return item;
  };

  static getPaymentIntents = async (symphy_backend, userId, transactionId) => {
    let response = await symphy_backend["payments"].get_payment_intents({
      user_id: userId ? [userId] : [],
      transaction_id: transactionId ? [transactionId] : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("getPaymentIntents not successful " + JSON.stringify(response));
      return null;
    }
    // Sorting based on BigInt values
    return response.Success.sort((a, b) => {
      const dateA = BigInt(a.date);
      const dateB = BigInt(b.date);
      if (dateA > dateB) {
        return -1; // Reverse order
      } else if (dateA < dateB) {
        return 1; // Reverse order
      } else {
        return 0;
      }
    });
  };

  static updatePaymentIntent = async (symphy_backend, id, transactionId, orderId, paymentMethodId, paymentGateway, date, price, status, attempts, metadata) => {
    let response = await symphy_backend["payments"].update_payment_intent({
      id: BigInt(id),
      transaction_id: transactionId ? [BigInt(transactionId)] : [],
      order_id: orderId ? [orderId] : [],
      payment_method_id: paymentMethodId ? [paymentMethodId] : [],
      payment_gateway: paymentGateway ? [paymentGateway] : [],
      date: date ? [date] : [],
      price: price ? [price] : [],
      status: status ? [status] : [],
      attempts: attempts ? [attempts] : [],
      metadata: metadata ? metadata : [],
    });
    // console.log("price", JSON.stringify(price));
    // console.log("response", JSON.stringify(response));
    if (BackendService.isResponseError(response)) {
      console.log("updatePaymentIntent not successful " + JSON.stringify(response));
      return null;
    }
    // Sorting based on BigInt values
    return response.Success;
  };

  /**
   * Confirma a payment manually, the params is the notification from redsys
   *
   * @returns
   */
  static addManualPaymentConfirmation = async (symphy_backend, params) => {
    let response = await symphy_backend["payments"].add_manual_payment_confirmation({
      params: params,
      // payment_date: paymentDate ? [paymentDate] : [],
    });
    console.log("addManualPaymentConfirmation " + JSON.stringify(response));
    if (BackendService.isResponseError(response)) {
      console.log("addManualPaymentConfirmation not successful" + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Deletes a paymentIntent by id
   *
   */
  static deletePaymentIntent = async (symphy_backend, id) => {
    let response = await symphy_backend["payments"].delete_payment_intent({ id: id });
    if (!response.Success) {
      console.log("deletePaymentIntent not successful");
      return null;
    }
    return response.Success;
  };

  /*
   * Add a .transactions field to the items list (e.j. list of subcontracts)
   * Set is user to true if no admin rights
   *
   * @returns All products
   */
  static mapTransactionsToItems = (items, allTransactions) => {
    if (!items || items.length === 0) {
      return items;
    }
    // If no product id then its a product
    const isProducts = items[0].owner_id ? true : false;
    let shopTransactionIds = new Map();
    allTransactions.forEach((transaction) => {
      // If the transaction has a single item
      // Allow mapping of subcontracts and also products
      if (transaction.transaction_item) {
        const itemId = isProducts ? transaction.transaction_item.product_id : transaction.transaction_item.getSubcontractId();
        if (itemId) {
          if (!shopTransactionIds.has(itemId)) {
            shopTransactionIds.set(itemId, []);
          }
          shopTransactionIds.get(itemId).push(transaction);
          // console.log("transaction after push", JSON.stringify(shopTransactionIds.get(itemId)));
        }
      }
      // If the transaction has multiple items
      if (transaction.transaction_items) {
        transaction.transaction_items.forEach((item) => {
          const itemId = isProducts ? item.product_id : item.getSubcontractId();
          if (itemId) {
            if (!shopTransactionIds.has(itemId)) {
              shopTransactionIds.set(itemId, []);
            }
            shopTransactionIds.get(itemId).push(transaction);
          }
        });
      }
    });

    const mapItemWithTransactions = (item) => {
      let sortedTransactions = shopTransactionIds.get(item.id) || [];
      sortedTransactions = TransactionWithItems.sortTransactionsByDate(sortedTransactions);
      sortedTransactions = sortedTransactions.map((transaction) => TransactionWithItems.fromJSON(transaction));
      let result = { ...item, transactions: sortedTransactions }; // Combine the item with the transactions
      if (sortedTransactions.length > 0) {
        result = { ...item, transactions: sortedTransactions, transaction: sortedTransactions[0] }; // Combine the item with the transactions
      } else {
        result = { ...item, transactions: sortedTransactions }; // Combine the item with the transactions
      }
      // Add nextPaymentDate
      // SUbcontracts
      if (!isProducts) {
        const paymentIntents = sortedTransactions.flatMap((transaction) => transaction.payment_intents || []);
        const pendingPayments = item.getPendingPayments(paymentIntents);
        if (pendingPayments > 0) {
          const nextPaymentDate = PaymentIntent.calculateNextPaymentDate(paymentIntents);
          // console.log("addTransactionsToItems nextPaymentDate " + JSON.stringify(nextPaymentDate));
          if (nextPaymentDate) {
            result = { ...result, next_payment_date: nextPaymentDate };
          }
        }
      }
      // console.log("addTransactionsToItems result " + JSON.stringify(result));
      return result; // Combine the item with the transactions
    };

    return items.map(mapItemWithTransactions);
  };

  /**
   * The transactions are retrieved from the backend by id
   * Add a .transaction field to the items list (e.j. list of subcontracts)
   * Set is user to true if no admin rights
   *
   * @returns All products
   */
  static addTransactionsToItems = async (
    symphy_backend,
    items,
    ids,
    isUser = false,
    filter = {
      product_ids: null,
      subcontract_ids: ids,
      with_payment_intents: true,
      add_subcontracts: false,
    }
  ) => {
    if (!items || items.length === 0) {
      return items;
    }
    const allTransactions = isUser ? await this.getMyTransactions(symphy_backend, filter) : await this.getTransactions(symphy_backend, filter);
    return this.mapTransactionsToItems(items, allTransactions);
  };

  static addProviderPayment = async (symphy_backend, subcontractId, userId, price, paymentType, date, status, metadata) => {
    let response = await symphy_backend["payments"].add_provider_payment({
      subcontract_id: BigInt(subcontractId),
      user_id: userId,
      payment_type: paymentType,
      price: price,
      date: date ? [date] : [],
      status: status,
      metadata: metadata ? metadata : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("addProviderPayment not successful " + JSON.stringify(response));
      return null;
    }
    // Sorting based on BigInt values
    return response.Success;
  };

  /**
   * Deletes a Provider Payment by id
   *
   */
  static deleteProviderPayment = async (symphy_backend, id) => {
    let response = await symphy_backend["payments"].delete_provider_payment({ id: id });
    if (!response.Success) {
      console.log("deleteProviderPayment not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Provider payment
   *
   * @returns
   */
  static getProviderPayments = async (symphy_backend, userId, subcontractIds) => {
    let response = await symphy_backend["payments"].get_provider_payments({
      user_id: userId ? [userId] : [],
      subcontract_ids: subcontractIds ? [subcontractIds] : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("getProviderPayments not successful " + JSON.stringify(response));
      return null;
    }
    // Sorting based on BigInt values
    return response.Success.sort((a, b) => {
      const dateA = BigInt(a.date);
      const dateB = BigInt(b.date);
      if (dateA > dateB) {
        return -1; // Reverse order
      } else if (dateA < dateB) {
        return 1; // Reverse order
      } else {
        return 0;
      }
    }).map((item) => Payment.fromJSON(item));
  };

  static updateProviderPayment = async (symphy_backend, id, subcontractId, userId, price, paymentType, paymentGateway, date, status, metadata) => {
    let response = await symphy_backend["payments"].update_provider_payment({
      id: BigInt(id),
      subcontract_id: subcontractId ? [BigInt(subcontractId)] : [],
      user_id: user_id ? [userId] : [],
      payment_type: paymentType ? [paymentType] : [],
      price: price ? [price] : [],
      payment_gateway: paymentGateway ? [paymentGateway] : [],
      date: date ? [date] : [],
      status: status ? [status] : [],
      metadata: metadata ? metadata : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("updateProviderPayments not successful " + JSON.stringify(response));
      return null;
    }
    // Sorting based on BigInt values
    return response.Success;
  };

  static addProviderPaymentsToItems = async (symphy_backend, items, ids) => {
    if (!items || items.length === 0) {
      return items;
    }
    const allPayments = await this.getProviderPayments(symphy_backend, null, ids);
    const subcontractToPayments = new Map();
    allPayments.forEach((payment) => {
      const subcontractId = payment.subcontract_id;
      subcontractToPayments.set(subcontractId, payment);
    });

    const mapItemWithSubcontract = (subcontract) => {
      const paymentBySubcontract = subcontractToPayments.get(subcontract.id);
      let result = { ...subcontract, provider_payment: paymentBySubcontract };
      return result; // Combine the item with the payment
    };
    return items.map(mapItemWithSubcontract);
  };

  static processRefund = async (orderId, terminal, price, enqueueSnackbar, isNested = false) => {
    if (price <= 0) {
      enqueueSnackbar("Can not refund nothing.", { variant: "warning" });
      return null;
    }
    const isPaygold = terminal === "1" || terminal === "001" ? false : true;
    const redsysAPI = this.createRedsysAPI({
      isPaygold: isPaygold,
    });
    try {
      const refund = await this.getRedsysRefundRequest({
        redsysAPI,
        orderId,
        amount: price.amount,
        terminal,
      });
      return refund;
    } catch (error) {
      // console.log("processRefund error ", JSON.stringify(error));
      if (enqueueSnackbar && isNested) enqueueSnackbar("Error processing refund.", { variant: "error" });
      // Try refund with different terminal
      if (isNested) {
        return null;
      } else {
        return await this.processRefund(orderId, isPaygold ? "1" : "999", price, enqueueSnackbar, true);
      }
    }
  };

  static updatePaymentIntentStatus = async (symphy_backend, intent, status) => {
    return await this.updatePaymentIntent(
      symphy_backend,
      intent.id,
      null, // transactionId
      null, // orderId
      null, // paymentMethodId
      null, // paymentGateway
      null, // date
      null, // price
      status, // status
      null, // attempts
      null // metadata
    );
  };

  static updatePaymentIntentWithRefund = async (symphy_backend, intent, price) => {
    const metadata = addRefundToMetadata(intent.metadata, price);
    return await this.updatePaymentIntent(
      symphy_backend,
      intent.id,
      null, // transactionId
      null, // orderId
      null, // paymentMethodId
      null, // paymentGateway
      null, // date
      null, // price
      null, // status
      null, // attempts
      metadata // metadata
    );
  };
}
