import { getMapValueForKey } from "src/utils/typeConverters";
import { ProductSubContract } from "./ProductSubContract";
import ContractConfig from "./ContractConfig";

// ProductContract class
export class ProductContract {
  constructor({
    id,
    product_id,
    owner_id,
    shop_id = [],
    active,
    start_date = [],
    end_date = [],
    max_end_date = [],
    owner_contracts = [],
    subcontracts = [],
    metadata = [],
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.product_id = BigInt(product_id);
    this.owner_id = owner_id;
    this.shop_id = shop_id.length ? [BigInt(shop_id[0])] : [];
    this.active = active;
    this.start_date = start_date.length ? [BigInt(start_date[0])] : [];
    this.end_date = end_date.length ? [BigInt(end_date[0])] : [];
    this.max_end_date = max_end_date.length ? [BigInt(max_end_date[0])] : [];
    this.owner_contracts = owner_contracts.map((config) => ContractConfig.fromJSON(config));
    this.subcontracts = subcontracts.map((subcontract) => ProductSubContract.fromJSON(subcontract));
    this.metadata = metadata;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(contractJSON) {
    if (!contractJSON) return null;
    const {
      id,
      product_id,
      owner_id,
      shop_id = [],
      active,
      start_date = [],
      end_date = [],
      max_end_date = [],
      owner_contracts = [],
      metadata = [],
      subcontracts = [],
      ...otherProps
    } = contractJSON;
    return new ProductContract({
      id,
      product_id,
      owner_id,
      shop_id,
      active,
      start_date,
      end_date,
      max_end_date,
      owner_contracts,
      subcontracts,
      metadata,
      ...otherProps,
    });
  }

  clone() {
    return new ProductContract({
      id: this.id,
      product_id: this.product_id,
      owner_id: this.owner_id,
      shop_id: this.shop_id.length ? [this.shop_id[0]] : [],
      active: this.active,
      start_date: this.start_date.length ? [this.start_date[0]] : [],
      end_date: this.end_date.length ? [this.end_date[0]] : [],
      max_end_date: this.max_end_date.length ? [this.max_end_date[0]] : [],
      owner_contracts: this.owner_contracts.map((config) => config.clone()),
      subcontracts: this.subcontracts.map((subcontract) => subcontract.clone()),
      metadata: this.metadata,
    });
  }

  toBackendObject() {
    return {
      id: this.id,
      product_id: this.product_id,
      owner_id: this.owner_id,
      shop_id: this.shop_id.length ? [this.shop_id[0]] : [],
      active: this.active,
      start_date: this.start_date.length ? [this.start_date[0]] : [],
      end_date: this.end_date.length ? [this.end_date[0]] : [],
      max_end_date: this.max_end_date.length ? [this.max_end_date[0]] : [],
      owner_contracts: this.owner_contracts.map((config) => config.toBackendObject()),
      subcontracts: this.subcontracts.map((subcontract) => subcontract.clone()),
      metadata: this.metadata ,
    };
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    // const entry = this.metadata.find(([entryKey]) => entryKey === key);
    return value && value.length > 0 ? value : null;
  }

  getContractDocs(asArray = false) {
    const contractDocs = this.getMetadataValue("contract_docs");
    return asArray ? (contractDocs && contractDocs.length > 0 ? contractDocs.split(",") : []) : contractDocs;
  }
  set contract_docs(value) {
    this.setMetadataValue("contract_docs", value);
  }

  updateContractDocs(contractUrl) {
    let currentContractUrls = this.getContractDocs(true);
    if (currentContractUrls) {
      currentContractUrls = [...currentContractUrls, contractUrl];
    } else {
      currentContractUrls = [contractUrl];
    }
    this.contract_docs = currentContractUrls.join(",");
  }

  getSubscriptionConfig() {
    return this.owner_contracts.find((config) => config.isSubscription()) || null;
  }

  getSaleConfig() {
    return this.owner_contracts.find((config) => config.isSale()) || null;
  }

  getDepositFromSubscription() {
    const subscriptionConfig = this.getSubscriptionConfig();
    return subscriptionConfig ? subscriptionConfig.getDeposit() : null;
  }

  getLastSubscription(user) {
    let currentSubContract = null;
    if (this.subcontracts && this.subcontracts.length > 0) {
      currentSubContract = ProductSubContract.getLastSubscription(this.subcontracts, user);
    }
    return currentSubContract;
  }
}
