/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components

// Material Kit 2 React examples

// Images
import crescendo from "assets/images/logos/partners/logo-crescendo.jpeg";
import juventudesLogo from "assets/images/logos/partners/logo-jm-espana-color.png";
import madridSoloists from "assets/images/logos/partners/logo-madrid-soloists.png";
import mercaseguros from "assets/images/logos/partners/logo-mercaseguros.png";
import musicalix from "assets/images/logos/partners/logo-musicalix-black.png";
import progreso from "assets/images/logos/partners/logo-progreso.jpg";
import saem from "assets/images/logos/partners/logo-saem.jpg";



import { Box, Typography } from "@mui/material";
import { useLocales } from "../../../../../ui/locales";

function TrustedBy() {
  const { translate } = useLocales();
  return (
    <Box component="section">
      <Divider sx={{ my: 6 }} />
      <Container sx={{ my: 6 }}>
        <Grid container item xs={12} lg={6} justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          <Typography variant="h2">{translate("landing.collaborators_title")}</Typography>
          <Typography variant="body1" color="text" mb={2}>
            {translate("landing.collaborators_subtitle")}
          </Typography>
        </Grid>
        <Grid container spacing={3} justifyContent="center" sx={{ my: 6 }}>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={mercaseguros} alt="Mercaseguros" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={madridSoloists} alt="Madrid Soloists" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={musicalix} alt="Musicalix" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={crescendo} alt="Crescendo" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={saem} alt="Saem" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={progreso} alt="Progreso" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={3} md={2} lg={2}>
            <Box component="img" src={juventudesLogo} alt="Juventudes Musicales" width="100%" opacity={0.6} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default TrustedBy;
