/* eslint-disable no-undef */
import axios from "axios";
import { FIREBASE_FUNCTIONS_URL, INFO_EMAIL, IS_PROD } from "src/config-global";
import { fPrice } from "src/utils/format-number";
import { convertDaysToMonths } from "src/utils/format-time";
import { getMapValueForKey } from "src/utils/typeConverters";
import ProductContractsService from "./ProductContractsService";
import { performPriceMath } from "src/utils/price-utils";
import UserService from "./UserService";

/**
 * Provides methods related to sending emails to users
 */
export default class EmailService {
  static baseUrl = FIREBASE_FUNCTIONS_URL;

  static async makeRequest(endpoint, data) {
    const config = {
      method: "post",
      url: this.baseUrl + endpoint,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*", // Ensure to match your CORS policy
      },
      data: data,
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      // Log error or handle it as needed
      console.error("Request failed:", error);
      throw error;
    }
  }

  static async registerOrderEmail({
    to,
    subject,
    description,
    subtitle,
    subdescription,
    subtitle2,
    subdescription2,
    headline,
    orderTitle,
    orderId,
    items,
    totalPrice,
    totalDiscount,
  }) {
    const data = {
      to,
      subject,
      description,
      subtitle,
      subdescription,
      subtitle2,
      subdescription2,
      headline,
      orderTitle,
      orderId,
      items,
      totalPrice: totalPrice ? totalPrice : null,
      totalDiscount: totalDiscount ? totalDiscount : null,
    };
    return this.makeRequest("registerOrderEmail", data);
  }

  static async sendUsEmail({
    subject,
    description,
    subtitle,
    subdescription,
    subtitle2,
    subdescription2,
    headline,
    orderTitle,
    orderId,
    items,
    totalPrice,
    totalDiscount,
  }) {
    const data = {
      to: IS_PROD ? INFO_EMAIL : "pablosone@hotmail.com",
      subject,
      description,
      subtitle,
      subdescription,
      subtitle2,
      subdescription2,
      headline,
      orderTitle,
      orderId,
      items: items ? items : [],
      totalPrice: totalPrice ? totalPrice : null,
      totalDiscount: totalDiscount ? totalDiscount : null,
    };
    return this.makeRequest("sendOrderEmail", data);
  }

  static async sendReferralCodeEmail({
    user,
    referralCode,
    t
  }) {
    this.sendTextEmail({
        to: user.email,
        subject: t("emails.referral_subject"),
        description: t("emails.referral_description"),
        headline: t("emails.headline_referral", { name: UserService.getUserDisplayName(user, true) }),
        referralCode: referralCode,
        subtitle: t("emails.referral_subtext"),
        subdescription: t("emails.partner_registered_description"),
      });
  }


  static async sendTextEmail({
    to,
    subject,
    description,
    subtitle,
    subdescription,
    headline,
    referralCode,
  }) {
    const data = {
      to: IS_PROD ? to : "pablosone@hotmail.com",
      subject,
      description,
      subtitle,
      subdescription,
      headline,
      referralCode
    };
    console.log("data", JSON.stringify(data));
    return this.makeRequest("sendTextEmail", data);
  }

  static async sendOrderEmail({
    to,
    subject,
    description,
    subtitle,
    subdescription,
    subtitle2,
    subdescription2,
    headline,
    orderTitle,
    orderId,
    items,
    totalPrice,
    totalDiscount,
  }) {
    const data = {
      to: IS_PROD ? to : "pablosone@hotmail.com",
      subject,
      description,
      subtitle,
      subdescription,
      subtitle2,
      subdescription2,
      headline,
      orderTitle,
      orderId,
      items: items ? items : [],
      totalPrice: totalPrice ? totalPrice : null,
      totalDiscount: totalDiscount ? totalDiscount : null,
    };
    return this.makeRequest("sendOrderEmail", data);
  }

  static subcontractToEmailItem(t, subcontract, product) {
    // console.log("subcontract", JSON.stringify(subcontract));
    // console.log("product", JSON.stringify(product));
    const isSubscription = subcontract.isSubscription();
    // TODO add price as an option
    const price = subcontract.price.length > 0 ? subcontract.price[0] : null;
    let deposit = null;
    if (price) {
      deposit = getMapValueForKey(price.other_prices, "deposit");
    }
    const monthsOfContract = subcontract.duration.length > 0 ? convertDaysToMonths(subcontract.duration[0].duration) : 1;
    let description = "";
    if (isSubscription) {
      description = t("emails.order_email_subs_description", {
        duration: monthsOfContract,
        deposit: fPrice(deposit),
      });
    }
    const priceToDisplay = isSubscription ? (price ? fPrice(price.price_interval[0]) : null) : fPrice(price.final_price);
    // const shop = shopMap[item.shop_id[0]];
    const httpsImages = product.images.filter((url) => url.startsWith("https"));
    return {
      name: product.name,
      description: description,
      location: null, // Shop location TODO
      price: priceToDisplay ? (isSubscription ? t("product.price_from", { value: priceToDisplay }) : priceToDisplay) : null,
      price2: null,
      quantity: 1, //
      image_url: httpsImages.length > 0 ? httpsImages[0] : "",
    };
  }

  static subcontractToProviderPayEmailItem(t, subcontract, product, amount, fees, taxes) {
    // AMount is what is paid to the owner
    console.log("fees", JSON.stringify(fees));
    const feesAndTaxes = performPriceMath("+", fees, taxes.amount);
    // What the buyer paid
    const totalPaid = performPriceMath("+", amount, feesAndTaxes.amount);
    const priceToDisplay = t("emails.paid_value", { value: fPrice(totalPaid) });
    const secondPriceToDisplay = t("emails.earned", { value: fPrice(amount) });

    // const shop = shopMap[item.shop_id[0]];
    const httpsImages = product.images.filter((url) => url.startsWith("https"));
    return {
      name: product.name,
      description: null,
      location: null, // Shop location TODO
      price: secondPriceToDisplay,
      price2: priceToDisplay,
      quantity: 1, //
      image_url: httpsImages.length > 0 ? httpsImages[0] : "",
    };
  }

  static productToEmailItem(t, product, withPrice = true) {
    // console.log("subcontract", JSON.stringify(subcontract));
    // console.log("product", JSON.stringify(product));
    const price = product.valuation_price;
    // const shop = shopMap[item.shop_id[0]];
    const httpsImages = product.images.filter((url) => url.startsWith("https"));
    return {
      name: product.name,
      description: product.product_type,
      location: null, // Shop location TODO
      price: withPrice ? t("valuation_price") : null,
      price2: withPrice ? fPrice(price) : null,
      quantity: 1, //
      image_url: httpsImages.length > 0 ? httpsImages[0] : "",
    };
  }

  static transactionToEmailItem(t, product, transaction, withPrice = true) {
    // console.log("subcontract", JSON.stringify(subcontract));
    // console.log("product", JSON.stringify(product));
    const price = transaction.getPrice();
    // const shop = shopMap[item.shop_id[0]];
    const httpsImages = product.images.filter((url) => url.startsWith("https"));
    return {
      name: product.name,
      description: transaction.info,
      location: null, // Shop location TODO
      price: withPrice && price ? fPrice(price) : null,
      // price2: withPrice ? fPrice(price) : null,
      quantity: 1, //
      image_url: httpsImages.length > 0 ? httpsImages[0] : "",
    };
  }
}
