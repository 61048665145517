/* eslint-disable no-dupe-class-members */
import Product, { PRODUCT_CATEGORY, PRODUCT_STATES } from 'model/marketplace/Product';
import { convertToFrontendHashMap, getEnumVariableText, getUniqueFieldValues } from '../ui/utils/typeConverters';
import ProductContractsService from './ProductContractsService';
import BackendService from './BackendService';
import PaymentsService from './PaymentsService';
import EmailService from './EmailService';
import UserService from './UserService';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { BACKEND_URL, HOST, IS_PROD } from 'src/config-global';
import { ProductSubContract } from 'model/contracts/ProductSubContract';


export const TYPE_USER = "USER";
export const TYPE_ADMIN = "ADMIN";
// export const TYPE_ADMIN_EXPANDED = "ADMIN_EXPANDED";
// Products to verify
export const TYPE_SELLER_TO_VERIFY = "SELLER";
// Products in the shop, if active subcontract items should not be displayed
export const TYPE_SELLER_STORED = "SELLER_STORED"; 

// View Type
export const TYPE_OWNER = "owner";
export const TYPE_RENTER = "renter";
export const TYPE_VERIFIER = "verifier";
export const TYPE_VIEWER = "viewer";

export const VEFIFICATION_STATUS_VERIFY = 'verifyinstrument';
export const VEFIFICATION_STATUS_VERIFICATION_NEEDED = "verificationneeded";

/**
 * Provides methods related to the products
 */
export default class ProductsService {
  // /* Make a copy of a product to be able to modify its fields */
  // static createProduct = (
  //   id,
  //   ownerId,
  //   name,
  //   productType,
  //   description,
  //   brand,
  //   year,
  //   weight,
  //   size,
  //   category,
  //   stateDescription,
  //   state,
  //   metadata,
  //   images,
  //   price,
  //   verified,
  //   location
  // ) => {
  //   // console.log("createProduct type " + productType);
  //   const product = {
  //     // eslint-disable-next-line no-undef
  //     id: BigInt(id),
  //     owner_id: ownerId,
  //     name: name,
  //     product_type: productType,
  //     description: description,
  //     brand: brand ? [brand] : [],
  //     year: parseInt(year),
  //     weight: weight ? [parseFloat(weight)] : [],
  //     size: size ? [size] : [],
  //     category: category,
  //     state_description: stateDescription && stateDescription.length > 0 ? [stateDescription] : [],
  //     state: { [state]: null },
  //     metadata: metadata,
  //     images: images,
  //     valuation_price: price,
  //     created_at: 0,
  //     verified: verified,
  //     location: location ? [location] : [],
  //   };
  //   // console.log("createProduct " + JSON.stringify(product));
  //   return product;
  // };

  /* Make a copy of a product to be able to modify its fields */
  static cloneProduct = (originalProduct) => {
    return Object.assign({}, originalProduct);
  };
  /**
   * Add product
   *
   * @returns All products
   */
  static addProduct = async (symphy_backend, product) => {
    // TODO check multiple shops
    // let shop = user.seller_info[0]?.shops[0]?.id;
    let response = await symphy_backend["products"].add_product({ product: product });
    // console.log("addProduct response " + JSON.stringify(response));
    if (!response.Success) {
      console.log("addProduct response was not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Add product
   *
   * @returns All products
   */
  static updateProduct = async (symphy_backend, product) => {
    // TODO check multiple shops
    // let shop = user.seller_info[0]?.shops[0]?.id;
    let response = await symphy_backend["products"].update_product({ product: product });
    if (!response.Success) {
      return null;
    }
    return response.Success;
  };

  /**
   * Update product owner
   *
   * @returns
   */
  static updateProductOwner = async (symphy_backend, productId, ownerId) => {
    // TODO check multiple shops
    let response = await symphy_backend["products"].update_product_owner({ product_id: BigInt(productId), owner_id: ownerId });
    if (BackendService.isResponseError(response)) {
      return false;
    }
    return true;
  };

  /**
   * Return a list of all products
   *
   * @returns All products
   */
  static getProducts = async (symphy_backend, type, ids = []) => {
    // return PRODUCTS;type
    let allProducts;
    if (type === TYPE_USER) {
      allProducts = await symphy_backend["products"].get_my_products({});
    }
    if (type === TYPE_ADMIN) {
      allProducts = await symphy_backend["products"].get_all_products({});
    }
    if (type === TYPE_SELLER_TO_VERIFY) {
      allProducts = await symphy_backend["products"].get_products({
        current_user: [false],
        user_id: [],
        product_ids: [],
        to_verify: [true],
        product_name: [],
      });
    }
    if (type === TYPE_SELLER_STORED) {
      allProducts = await symphy_backend["products"].get_products({
        current_user: [false],
        user_id: [],
        product_ids: ids,
        to_verify: [false],
        product_name: [],
      });
    }
    if (!allProducts.Success) {
      console.log("getProducts response was not successful");
      return [];
    }
    return allProducts.Success;
  };

  /**
   * Add .store_listing as a field to the products
   * @param {*} products
   * @param {*} productIds
   * @param {*} storeListings if present add those directly to the products. No retrieval is done
   * @returns
   */
  static addStoreListingToProducts = async (symphy_backend, products, productIds, storeListings) => {
    if (storeListings) {
      return products.map((product) => {
        const matchingListing = storeListings.find((listing) => listing.product_id === product.id);
        return { ...product, store_listing: matchingListing ? [matchingListing] : [] };
      });
    }
    const retrievedListings = await ProductContractsService.getStoreListingsByProductIds(symphy_backend, productIds);
    if (retrievedListings.length === 0) {
      return products;
    }

    // Create a dictionary to map product_id to an array of listing
    const storeListingDictionary = retrievedListings.reduce((dict, listing) => {
      if (!dict[listing.product_id]) {
        dict[listing.product_id] = [];
      }
      dict[listing.product_id].push(listing);
      return dict;
    }, {});

    // Function to map an item to include its contracts
    const mapProductWithListing = (product) => {
      const itemId = product.id;
      const listing = storeListingDictionary[itemId] ? storeListingDictionary[itemId] : null;
      // product.store_listing = listing;
      return { ...product, store_listing: listing };
    };
    if (Array.isArray(products)) {
      return products.map(mapProductWithListing);
    } else {
      return mapProductWithListing(products);
    }
  };

  /**
   * Deletes a product by id
   *
   */
  static deleteProduct = async (symphy_backend, id) => {
    let response = await symphy_backend["products"].delete_product({ product_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("deleteProduct not successful");
      return;
    }
    return response.Success;
  };

  /**
   * Return a list of all products for an admin
   *
   * @returns All products
   */
  static getAdminProducts = async (symphy_backend) => {
    // return PRODUCTS;
    const allProducts = await symphy_backend["products"].get_all_products({});
    if (!allProducts.Success) {
      console.log("getAdminProducts not successful");
      return [];
    }
    return allProducts.Success.map((item) => Product.fromJSON(item));
  };

  /**
   * Return a list of all products for the list of ids
   *
   * @returns All products
   */
  static getProductsByIds = async (symphy_backend, ids) => {
    const allProducts = await symphy_backend["products"].get_products_by_id({ product_ids: ids });
    if (!allProducts.Success) {
      console.log("getProductsByIds not successful");
      return [];
    }
    return allProducts.Success.map((item) => Product.fromJSON(item));
  };

  /**
   * The products are retrieved from the backend by id
   * Add a .product field to the items list (e.j. list of contracts)
   *
   * @returns All products
   */
  static addProductsToItems = async (symphy_backend, items, ids, providedProducts) => {
    let allProducts;
    if (providedProducts) {
      allProducts = providedProducts.filter((product) => ids.includes(product.id));
      const missingIds = ids.filter((id) => !providedProducts.some((product) => product.id === id));
      if (missingIds.length > 0) {
        const missingProducts = await this.getProductsByIds(symphy_backend, missingIds);
        allProducts = allProducts.concat(missingProducts);
      }
    } else if (ids.length === 1) {
      allProducts = await this.getProduct(symphy_backend, ids[0]);
      allProducts = allProducts ? [allProducts] : [];
    } else {
      allProducts = await this.getProductsByIds(symphy_backend, ids);
    }

    if (allProducts.length === 0) {
      return items;
    }

    const productDictionary = allProducts.reduce((dict, product) => {
      dict[product.id] = product;
      return dict;
    }, {});

    const mapEntityWithProduct = (entity, productId) => {
      if (!productId) return { ...entity };
      const product = productDictionary[productId];
      return { ...entity, product: product };
    };

    // Function to process each item, including its subcontracts if they exist
    const mapItemWithProduct = (item) => {
      // Map the primary item
      const mappedItem = mapEntityWithProduct(item, item.product_id);

      // Check and map subcontracts if they exist
      if (mappedItem.transaction_items && mappedItem.transaction_items.length > 0) {
        mappedItem.transaction_items = mappedItem.transaction_items.map((transactionItem) => mapEntityWithProduct(transactionItem, transactionItem.product_id));
      }
      if (mappedItem.transaction_item) {
        mappedItem.transaction_item = mapEntityWithProduct(mappedItem.transaction_item, mappedItem.transaction_item.product_id);
      }
      return mappedItem;
    };

    if (Array.isArray(items)) {
      return items.map(mapItemWithProduct);
    } else {
      return mapItemWithProduct(items);
    }
  };

  /**
   * Extract the product from the subcontracts and return a list of products with subcontracts
   * from { subcontract { product: { ... }, contractDetail: { ... }, anotherField: { ... } } }
   * to { product: { ..., subcontract : { ...} } }
   * @param {*} subcontracts
   */
  static convertFromSubcontractToProduct = (subcontracts) => {
    if (!subcontracts) {
      return [];
    }
    // Convert the list of subcontracts into a list of products
    const products = subcontracts.map((subcontract) => {
      // const { product, ...subcontractWithoutProduct } = subcontract;
      let productWithSubcontractDetails = {
        ...subcontract.product,
        subcontract: ProductSubContract.fromJSON(subcontract),
      };
      // console.log("convertFromSubcontractToProduct product " + JSON.stringify(productWithSubcontractDetails));
      return Product.fromJSON(productWithSubcontractDetails);
    });
    return products;
  };

  static convertFromListingToProduct = (listings) => {
    if (!listings) {
      return [];
    }
    // Convert the list of listings into a list of products
    const products = listings.map((listing) => {
      let productWithListingDetails = {
        ...listing.product,
        store_listing: listing,
      };
      // console.log("convertFromSubcontractToProduct product " + JSON.stringify(productWithSubcontractDetails));
      return productWithListingDetails;
    });
    return products;
  };

  /**
   * Return a list of products. Requires to be a seller
   *
   * @returns All listings
   */
  static getProductsAsSeller = async (symphy_backend, filter) => {
    const response = await symphy_backend["products"].get_products({
      current_user: filter.current_user ? [filter.current_user] : [],
      product_ids: filter.product_ids ? filter.product_ids : [],
      to_verify: filter.to_verify ? [filter.to_verify] : [],
      user_id: filter.user_id ? [filter.user_id] : [],
      product_name: filter.product_name ? [filter.product_name] : [],
    });
    if (!response.Success) {
      console.log("getProductsAsSeller not successful");
      return [];
    }
    let responseSuccess = response.Success;
    if (responseSuccess.length > 0) {
      let userIds = getUniqueFieldValues(responseSuccess, "user_id");
      responseSuccess = await UserService.addUserSummaryToItems(symphy_backend, responseSuccess, userIds, true);
    }
    return responseSuccess.map((item) => Product.fromJSON(item));
  };

  /**
   * Return a list of all products for sale
   *
   * @returns All products
   */
  static getProduct = async (symphy_backend, id) => {
    const product = await symphy_backend["products"].get_product({ product_id: id });
    if (!product.Success) {
      console.log("getProduct not successful");
      return null;
    }
    return Product.fromJSON(product.Success);
  };

  static getProductTypeLocalized(type, currentLanguage) {
    const hashMap = convertToFrontendHashMap(type);
    const defaultLanguage = "en";
    const languageToUse = currentLanguage ? (currentLanguage.value ? currentLanguage.value : currentLanguage) : defaultLanguage;
    const productType = hashMap[languageToUse];
    return productType;
  }

  static getDefaultProductType(type) {
    const hashMap = convertToFrontendHashMap(type);
    const defaultLanguage = "en";
    const productType = hashMap[defaultLanguage];
    return productType;
  }

  /**
   * Return a list of all products definitions
   *
   * @returns All products
   */
  static getProductDefinitions = async (symphy_backend) => {
    const allProductDefinitions = await symphy_backend["products"].get_product_definitions({});
    if (!allProductDefinitions.Success) {
      console.log("getProductDefinitions not successful");
      return [];
    }
    return allProductDefinitions.Success;
  };

  /**
   * Return a product definition by id
   *
   * @returns All products
   */
  static getProductDefinition = async (symphy_backend, id) => {
    const productDefinition = await symphy_backend["products"].get_product_definition({ product_definition_id: id });
    if (!productDefinition.Success) {
      console.log("getProductDefinitions not successful");
      return null;
    }
    return productDefinition.Success;
  };

  /**
   * Create a product definition
   *
   * @returns
   */
  static addProductDefinition = async (symphy_backend, type, sizes) => {
    const productDefinition = {
      // eslint-disable-next-line no-undef
      id: BigInt(1),
      product_type: type,
      category: this.getInstrumentCategory(),
      sizes: sizes ? sizes : [],
      compatible_products: [],
    };
    const newProductDefinition = await symphy_backend["products"].add_product_definition({ product_definition: productDefinition });
    if (!newProductDefinition.Success) {
      console.log("addProductDefinition not successful");
      return null;
    }
    return newProductDefinition.Success;
  };

  /**
   * Deletes a product definition by id
   *
   */
  static deleteProductDefinition = async (symphy_backend, id) => {
    let response = await symphy_backend["products"].delete_product_definition({ product_definition_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("deleteProductDefinition not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Update product definition
   *
   */
  static updateProductDefinition = async (symphy_backend, productDefinition) => {
    let updatedProductDefinition = await symphy_backend["products"].update_product_definition({ product_definition: productDefinition });
    if (!updatedProductDefinition.Success) {
      console.log("updateProductDefinition not successful");
      return null;
    }
    return updatedProductDefinition.Success;
  };

  /**
   * Get player products
   *
   * @returns
   */
  static getPlayerProducts = async (symphy_backend) => {
    const playerProducts = await symphy_backend["products"].get_my_products({});
    if (!playerProducts.Success) {
      console.log("getPlayerProducts not successful");
      return [];
    }
    return playerProducts.Success;
  };

  /**
   * When a product is verified, add a valuation transaction to the seller and transfer the product to the buyer if necessary
   * After send and email to the owner to confirm the validation
   * If the storelisting is draft, set to active
   * TODO
   * @returns
   */
  static onProductVerified = async ({ symphy_backend, product, sellerId, shop, customerUser, storeListing, sendEmail, t, dispatch, updateStoreListing }) => {
    if (!product.verified) {
      return null;
    }

    // Only one valuation per item. It is checked in the backend
    const valuationTransaction = await PaymentsService.addServiceTransaction(
      symphy_backend,
      product.id,
      customerUser ? customerUser.user_id : sellerId,
      sellerId,
      shop.id,
      null,
      null,
      null
    );
    if (customerUser && storeListing) {
      // UPdate the listing owner and contract
      await ProductContractsService.transferProductToUser(symphy_backend, storeListing.id, customerUser.user_id);
    }
    if (storeListing && this.isStoreListingDraft(storeListing)) {
      let listingToUpdate = Object.assign({}, storeListing);
      listingToUpdate.status = { Active: null };
      if (dispatch) {
        dispatch(updateStoreListing(symphy_backend, listingToUpdate, true));
      } else {
        await ProductContractsService.updateStoreListing(symphy_backend, listingToUpdate);
      }
    }
    if (sendEmail) {
      let baseUrl = BACKEND_URL;
      if (storeListing) {
        baseUrl += PATH_PAGE.product.details(product.id);
      } else {
        baseUrl += PATH_DASHBOARD.product.edit(product.id);
      }

      EmailService.sendOrderEmail({
        // response.email,
        // to: "pablosone@hotmail.com",
        to: customerUser.email,
        subject: t("emails.instrument_deposited"),
        description: t(storeListing ? "emails.instrument_deposited_description" : "emails.instrument_deposited_2", { link: baseUrl }),
        headline: t("emails.headline_thanks_deposit", { name: UserService.getUserDisplayName(customerUser, true) }),
        subtitle: t("emails.instrument_deposited_shop"),
        subdescription: t("emails.instrument_deposited_shop_description", { shop: shop.name, address: shop.location.address }),
        // subtitle2: t("emails.where_deposit"),
        // subdescription2: t("emails.email_instrument_registered_description"),
        orderTitle: t("emails.product_id"),
        orderId: product.id,
        items: [EmailService.productToEmailItem(t, product)],
        totalPrice: null,
      });
    }
    return valuationTransaction;
  };

  /**
   * Get the limit of products an user can upload
   *
   * @returns
   */
  static getUserProductLimit = async (symphy_backend, userId) => {
    // await this.updateDefaultProductLimit(symphy_backend, 10, false);
    // await this.updateDefaultProductLimit(symphy_backend, 20, true);

    const response = await symphy_backend["products"].get_product_limit({ user_id: userId });
    if (BackendService.isResponseError(response)) {
      console.log("getUserProductLimit not successful");
      return [];
    }
    return response.Success;
  };

  static canUserUploadProduct = async (symphy_backend, userId) => {
    const response = await symphy_backend["products"].can_user_upload_product({ user_id: userId });
    if (BackendService.isResponseError(response)) {
      console.log("canUserUploadProduct not successful");
      return false;
    }
    return response.Success;
  };

  /**
   * Update the limit of products an user can upload
   *
   * @returns
   */
  static updateUserProductLimit = async (symphy_backend, userId, newLimit) => {
    const response = await symphy_backend["products"].update_product_limit({ user_id: userId, new_limit: newLimit });
    if (BackendService.isResponseError(response)) {
      console.log("updateUserProductLimit not successful");
      return null;
    }
    return response;
  };

  /**
   * Update the limit of products an user can upload
   *
   * @returns
   */
  static updateDefaultProductLimit = async (symphy_backend, newLimit, forSellers) => {
    const response = await symphy_backend["products"].update_default_product_limit({ for_sellers: forSellers, new_limit: newLimit });
    if (BackendService.isResponseError(response)) {
      console.log("updateDefaultProductLimit not successful");
      return null;
    }
    return response;
  };

  static getProductUploadDefaultLimits = async (symphy_backend) => {
    const response = await symphy_backend["products"].get_product_default_limits({});
    if (BackendService.isResponseError(response)) {
      console.log("getProductUploadDefaultLimits not successful");
      return false;
    }
    return response.Success;
  };

  /**
   * Reset the limit of products an user can upload
   *
   * @returns
   */
  static resetUserProductLimit = async (symphy_backend, userId) => {
    const response = await symphy_backend["products"].reset_product_limit({ user_id: userId });
    if (BackendService.isResponseError(response)) {
      console.log("resetUserProductLimit not successful");
      return null;
    }
    return response.Success;
  };

  static getBackendCanisterIds = async (symphy_backend) => {
    const response = await symphy_backend["products"].get_canister_ids({});
    if (BackendService.isResponseError(response)) {
      console.log("getBackendCanisterIds response was not successful " + JSON.stringify(response));
      return;
    }
    return response.Success;
  };

  /**
   * Return the current user products
   *
   * @return
   */
  static getDummyProducts() {
    // TODOcate
    return this.getDummyInstruments().slice(0, 5).concat(this.getDummyAccesories().slice(0, 5));
  }

  /**
   * Return if the product can be a primary one for equipment
   *
   * @return
   */
  static isProductInstrument(product) {
    return this.getEnumVariableText(this.getInstrumentCategory()) === this.getEnumVariableText(product.category);
  }

  /**
   * Return if the product is an accesory
   *
   * @return
   */
  static isProductAccesory(product) {
    return this.getEnumVariableText(this.getAccesoriesCategory()) === this.getEnumVariableText(product.category);
  }

  /**
   * Use typeConverters.getEnumVariableText instead
   * The category has the format { 'Instruments': null }, return the key
   * @param {*} category
   */
  static getEnumVariableText(category) {
    return getEnumVariableText(category);
  }

  static getInstrumentCategory() {
    // TODO improve this
    return PRODUCT_CATEGORY[0];
  }

  static getAccesoriesCategory() {
    // TODO improve this
    return PRODUCT_CATEGORY[1];
  }

  /**
   * Return product accesories definitions
   *
   * @returns
   */
  // static getAccesoriesDefinitions() {
  //   return ACCESORIES_DEFINITIONS;
  // }

  /**
   * Return product quality
   *
   * @returns
   */

  /**
   * Return product states
   *
   * @returns
   */
  static getProductStates() {
    return PRODUCT_STATES;
  }

  static getRandomImageUrl(item) {
    return "https://source.unsplash.com/random/?" + item;
  }

  // static getRamdomImagesUrlWithCategory(item, isInstrument) {
  //   return [
  //     ...(isInstrument
  //       ? INSTRUMENT_IMAGES.has(item)
  //         ? INSTRUMENT_IMAGES.get(item)
  //         : []
  //       : ACCESORY_IMAGES.has(item)
  //         ? ACCESORY_IMAGES.get(item)
  //         : []),c
  //     ...[this.getRandomImageUrl(item)],
  //     ...[this.getRandomImageUrl(item + "&music")],
  //   ];
  // }

  static isStoreListingActive(storeListing) {
    if (!storeListing) {
      return false;
    }

    const status = getEnumVariableText(storeListing.status);
    if (status === "Active" || status === "Reserved") {
      return true;
    }
    return false;
  }

  static isStoreListingDraft(storeListing) {
    if (!storeListing) {
      return false;
    }

    const status = getEnumVariableText(storeListing.status);
    if (status === "Draft") {
      return true;
    }
    return false;
  }
}