import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// routes
import { PATH_PAGE } from './paths';

// layouts
// guards
// layouts

// import Landing from 'pages/Landing/Landing';
// import ContactUs from 'pages/Landing/ContactUs';
// import AboutUs from 'pages/Landing/AboutUs';

// config


import { authRoutes } from './sections/auth';
import { dashboardRoutes } from './sections/dashboard';
import { mainRoutes } from './sections/main';
import Landing from 'landing_page/pages/Landing/Landing';

// ----------------------------------------------------------------------
export function LandingWrapper() {
  /* Revert the scrollbar */
  document.body.style.overflow = 'unset';
  return (
    <>
      <Outlet />
    </>
  );
}

// This is for components with custom navbar
export default function Router() {
  const routes = [
    // ---------
    // NOTE: if this is edited you probably have to also edit :
    // src/nova_one_assets/src/landing_page/routes.js and src/nova_one_assets/src/landing_page/footer.routes.js
    // ---------
    {
      // LandingPage routes
      path: PATH_PAGE.home,
      element: <LandingWrapper />,
      children: [
        // Redirect to maintenance for now. Uncomment when maintenance is stopped
        // ...(!IS_PROD
        //   ? [
        //     {
        //       element: <Landing />,
        //       index: true,
        //     },
        //   ]
        //   : []),
        {
          element: <Landing />,
          index: true,
        }]
    },
    // ---------
    // NOTE: if this is edited you probably have to also edit src/nova_one_assets/src/ui/layouts/dashboard/config-navigation.js
    // ---------
    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,
    { path: PATH_PAGE.any, element: <Navigate to={PATH_PAGE.page404.name} replace /> },
  ];
  // return useRoutes(routes, { preserveQueryParams: true });
  return useRoutes(routes);
}

