// config
import { Secp256k1KeyIdentity } from '@dfinity/identity-secp256k1';
import { BACKEND_HOST, WEB3_CLIENT_ID, WEB3_CLIENT_ID_PROD } from '../../../config-global';

const CLIENT_ID =
  process.env.DFX_NETWORK === "ic"
    ? WEB3_CLIENT_ID_PROD
    : WEB3_CLIENT_ID;

export function subscribeAuthEvents(web3auth) {
  // subscribe to lifecycle events emitted by web3auth
  // Fix modal not showing by increasing z index
  // web3auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible) => {
  //   if (isVisible) {
  //     // Move to bottom of stack
  //     setTimeout(() => {
  //       const modal = document.getElementById("w3a-modal");
  //       if (modal) {
  //         modal.style.zIndex = "50";
  //       }
  //     }, 10);
  //   }
  // });

  // web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
  //   console.log("connected to wallet", data);
  //   // web3auth.provider will be available here after user is connected
  // });
  // web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
  //   console.log("connecting");
  // });
  // web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
  //   console.log("disconnected");
  // });
  // web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
  //   console.log("error", error);
  // });
  // web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
  //   console.log("error", error);
  // });
};

// export async function initWeb3Auth() {
//   try {
//     // const chainConfig = {
//     //   chainNamespace: CHAIN_NAMESPACES.OTHER, // SOLANA, OTHER
//     //   chainId: "chainId",
//     //   rpcTarget: "rpcUrl",
//     //   displayName: "displayName",
//     //   blockExplorer: "blockExplorerUrl",
//     //   ticker: "ICP",
//     //   tickerName: "Internet Computer",
//     // };
//     const chainConfig = {
//       chainNamespace: CHAIN_NAMESPACES.EIP155,
//       chainId: "0x1",
//       rpcTarget: "https://rpc.ankr.com/eth", // This is the public RPC we have added, please pass on your own endpoint while creating an app
//     };
//     const network = process.env.DFX_NETWORK === "ic" ? "cyan" : "testnet";
//       // const network = 'cyan';
//     // const web3auth = new Web3AuthNoModal({
//     //   // Get your Client ID from Web3Auth Dashboard
//     //   // https://dashboard.web3auth.io/home/web3auth
//     //   clientId: CLIENT_ID,
//     //   chainConfig: chainConfig,
//     //   uiConfig: {
//     //     theme: "light",
//     //     // loginMethodsOrder: ["google", "email_passwordless"],
//     //     defaultLanguage: "en",
//     //   },
//     // });

//     const web3auth = new Web3AuthNoModal({
//       clientId: CLIENT_ID,
//       web3AuthNetwork: network, // mainnet, aqua,  cyan or testnet
//       chainConfig: chainConfig,
//       uiConfig: {
//         theme: "light",
//         // loginMethodsOrder: ["google", "email_passwordless"],
//         defaultLanguage: "en",
//       },
//     });

//     const privateKeyProvider = new CommonPrivateKeyProvider({ config: { chainConfig } });

//     const openloginAdapter = new OpenloginAdapter({
//       privateKeyProvider,
//       adapterSettings: {
//         network: network,
//         // network: "testnet",
//         uxMode: "popup",
//         clientId: CLIENT_ID,
//         loginConfig: {
//           jwt: {
//             name: "Symphy auth",
//             verifier: "symphy-firebase",
//             typeOfLogin: "jwt",
//             clientId: CLIENT_ID,
//           },
//         },
//       },
//     });

//     web3auth.configureAdapter(openloginAdapter);
//     // subscribeAuthEvents(web3auth);

//     const res = await web3auth.init();
//     // setWeb3Auth(web3auth);
//     // if (web3auth.provider) {
//     //   setProvider(web3auth.provider);
//     // }
//     return web3auth;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const fromHexString = (hex) => {
  if (hex.substr(0, 2) === "0x") hex = hex.substr(2);
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return bytes;
};


// Construct an II identity to authenticate the backend based on an user privateKey
export const getIdentityForKey = async (web3auth) => {
  const privateKey = await web3auth.provider.request({
    method: "private_key",
  });
  const identity = Secp256k1KeyIdentity.fromSecretKey(
    fromHexString(privateKey)
  );
  // console.log("identity " + JSON.stringify(identity));
  return identity;
};

// Function to generate a principal based on an email or generate a random one
export const generateIdentity = async (email) => {
  try {
    let identity;
    if (email) {
      const encoder = new TextEncoder();
      const emailArray = encoder.encode(email);
      const hashedArray = await crypto.subtle.digest("SHA-256", emailArray);
      // Ensure the hashed array is exactly 32 bytes
      const finalSeed = new Uint8Array(32);
      finalSeed.set(new Uint8Array(hashedArray));
     identity = await Secp256k1KeyIdentity.generate(finalSeed);
      // principal = identity.getPrincipal();
    } else {
      const randomArray = crypto.getRandomValues(new Uint8Array(32));
      identity = await Secp256k1KeyIdentity.generate(randomArray);
      // identity = keyPair.getPrincipal();
    }
    console.log("Generated Principal:" + identity.getPrincipal().toText() + " email " + email);
    return identity;
  } catch (error) {
    console.error('Error generating principal:', error);
  }
};

export const getIIDerivationOrigin = () => {
  return `https://${process.env.CANISTER_ID_SYMPHY_FRONTEND}.ic0.app`;
};

export const getIIIdentityProvider = () => {
  return process.env.DFX_NETWORK === "ic" ? "https://identity.ic0.app/#authorize" : `http://${process.env.CANISTER_ID_INTERNET_IDENTITY}.${BACKEND_HOST}/`;
};