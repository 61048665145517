import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
// components
import { SplashScreen } from 'src/components/loading-screen';
import { PATH_AUTH } from '../paths';

// ----------------------------------------------------------------------

// AUTH
const SignIn = lazy(() => import('../../../landing_page/pages/Landing/SignIn'));
const SignUp = lazy(() => import('../../../landing_page/pages/Landing/SignUp'));
const ForgotPassword = lazy(() => import('../../../landing_page/pages/Landing/ForgotPassword'));
const ResetPassword = lazy(() => import('../../../landing_page/pages/Landing/ResetPassword'));


// ----------------------------------------------------------------------

const auth = {
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: PATH_AUTH.login.name,
      element: (
        <SignIn />
      ),
    },
    {
      path: PATH_AUTH.register.name,
      element: (
        <SignUp />
      ),
    },
    {
      path: PATH_AUTH.registerParners.name,
      element: (
        <SignUp isPartner />
      ),
    },
    {
      path: PATH_AUTH.forgot.path,
      element: (
        <ForgotPassword />
      ),
    },
    {
      path: PATH_AUTH.reset.path,
      element: (
        <ResetPassword />
      ),
    },
  ],
};


export const authRoutes = [
  {
    path: "/",
    children: [auth],
  },
];
