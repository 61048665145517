// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/bg-violin-shop.avif";
import bgBack from "assets/images/bg-cello.avif";
import { useLocales } from "../../../../../ui/locales";
import { PATH_PAGE } from '../../../../../ui/routes/paths';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from '../../../../../ui/components/animate';
import { useResponsive } from "../../../../../ui/hooks/use-responsive";

function Information() {
  const { translate } = useLocales();
  const isMobile = useResponsive("down", "md");

  return (
    <MKBox
      component="section"
      py={{ xs: 1, sm: 10, md: 6 }}
      my={{ xs: 1, s: 10, md: 6 }}
    >
      <Container component={MotionViewport}>
        <Grid container spacing={3}>
          {!isMobile && (
            <Grid item xs={6} md={5} lg={4} sx={{ mx: "auto" }}>
              <m.div variants={varFade().inLeft}>
                <RotatingCard>
                  <RotatingCardFront
                    image={bgFront}
                    // icon="touch_app"
                    color="dark"
                    description={translate("landing.intro_action_subtitle")}
                    title={<>{translate("landing.intro_action_title")}</>}
                  />
                  <RotatingCardBack
                    image={bgBack}
                    title={translate("landing.intro_action_title_2b")}
                    description={translate("landing.intro_action_subtitle_2")}
                    color="dark"
                    action={{
                      type: "internal",
                      route: PATH_PAGE.contact.path,
                      label: translate("landing.show_me"),
                    }}
                  />
                </RotatingCard>
              </m.div>
            </Grid>
          )}
          <Grid item xs={12} md={7} lg={7} sx={{ ml: "auto" }}>
            <m.div variants={varFade().inRight}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    direction={{ xs: "center", md: "left" }}
                    icon="price_change"
                    title={translate("landing.features_entry2")}
                    description={translate("landing.features_entry2_subtitle")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    direction={{ xs: "center", md: "left" }}
                    icon="library_music"
                    title={translate("landing.features_entry")}
                    description={translate("landing.features_entry_subtitle")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    direction={{ xs: "center", md: "left" }}
                    icon="diversity_3"
                    title={translate("about_us.card_title2")}
                    description={translate("about_us.card_description2")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    direction={{ xs: "center", md: "left" }}
                    icon="health_and_safety"
                    title={translate("landing.features_entry4")}
                    description={translate("about_us.how_description4")}
                  />
                </Grid>
              </Grid>
            </m.div>
          </Grid>
          {isMobile && (
            <Grid item xs={12} md={4} lg={4} sx={{ mx: "auto" }}>
              <m.div variants={varFade().inLeft}>
                <RotatingCard>
                  <RotatingCardFront
                    image={bgFront}
                    icon="touch_app"
                    color="light"
                    description={translate("landing.intro_action_subtitle")}
                    title={<>{translate("landing.intro_action_title")}</>}
                  />
                  <RotatingCardBack
                    image={bgBack}
                    title={translate("landing.intro_action_title_2b")}
                    description={translate("landing.intro_action_subtitle_2")}
                    color="light"
                    action={{
                      type: "internal",
                      route: PATH_PAGE.contact.path,
                      label: translate("landing.show_me"),
                    }}
                  />
                </RotatingCard>
              </m.div>
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
