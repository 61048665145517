// Card class
export class Card {
  constructor({
    id,
    user_id,
    merchant_identifier,
    card_expiry_date = [],
    card_number = [],
    card_brand = [],
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.user_id = user_id;
    this.merchant_identifier = merchant_identifier;
    this.card_expiry_date = card_expiry_date.length ? card_expiry_date : [];
    this.card_number = card_number.length ? card_number : [];
    this.card_brand = card_brand.length ? card_brand : [];

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(cardJSON) {
    if (!cardJSON) return null;
    const {
      id,
      user_id,
      merchant_identifier,
      card_expiry_date = [],
      card_number = [],
      card_brand = [],
      ...otherProps
    } = cardJSON;
    return new Card({
      id: BigInt(id),
      user_id: user_id,
      merchant_identifier,
      card_expiry_date: card_expiry_date,
      card_number,
      card_brand,
      ...otherProps,
    });
  }

  clone() {
    return new Card({
      id: this.id,
      user_id: this.user_id,
      merchant_identifier: this.merchant_identifier,
      card_expiry_date: this.card_expiry_date,
      card_number: this.card_number,
      card_brand: this.card_brand,
    });
  }

  isExpired() {
    if (!this.card_expiry_date.length) return false;
    const currentDate = new Date();
    const currentYYMM = (currentDate.getFullYear() % 100) * 100 + (currentDate.getMonth() + 1);
    return this.card_expiry_date[0] < currentYYMM;
  }

  getCardNumber() {
    return this.card_number.length ? this.card_number[0] : null;
  }
}
