import { PriceCalculation } from "model/payments/PriceCalculation";
import { ContractDuration } from "./ContractDuration";
import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import { addWorkingDays, convertDaysToMonths, fMillis, getElapsedDays, getMonthsDifference } from "src/utils/format-time";
import { CONTRACT_STATUS_MAP, CONTRACT_TYPES_MAP } from "services/ProductContractsService";
import { PAYMENT_STATUS } from "services/PaymentsService";
import { fEmptyPrice, fPrice } from "src/utils/format-number";
import { createPriceCalculation, performPriceCalculationMath, performPriceMath } from "src/utils/price-utils";
import { Coin } from "model/payments/Coin";
import { PaymentIntent } from "model/payments/PaymentIntent";
import TransactionWithItems from "model/payments/TransactionWithItems";

// ProductSubContract class
export class ProductSubContract {
  constructor({
    id,
    parent_contract_id,
    start_date,
    user_id,
    shop_id = [],
    end_date = [],
    price = [],
    duration = [],
    contract_type,
    contract_status,
    transaction,
    transactions = [],
    metadata = {},
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.parent_contract_id = BigInt(parent_contract_id);
    this.start_date = BigInt(start_date);
    this.user_id = user_id;
    this.shop_id = shop_id.length ? [BigInt(shop_id[0])] : [];
    this.end_date = end_date.length ? [BigInt(end_date[0])] : [];
    this.price = price.length ? price.map((p) => PriceCalculation.fromJSON(p)) : [];
    this.duration = duration.length ? duration.map((d) => new ContractDuration(d)) : [];
    this.contract_type = contract_type;
    this.contract_status = contract_status;
    this.transaction = transaction ? TransactionWithItems.fromJSON(transaction) : null;
    this.transactions = transactions.length ? transactions.map((p) => TransactionWithItems.fromJSON(p)) : [];
    this.metadata = metadata;

    if (!this.transaction && this.transactions.length > 0) {
      this.transaction = this.transactions[0];
    } else if (this.transaction && this.transactions.length === 0) {
      this.transactions = [this.transaction];
    }

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(subContractJSON) {
    if (!subContractJSON) return null;
    const {
      id,
      parent_contract_id,
      start_date,
      user_id,
      shop_id = [],
      end_date = [],
      price = [],
      duration = [],
      contract_type,
      contract_status,
      transaction,
      transactions = [],
      metadata = {},
      ...otherProps
    } = subContractJSON;
    return new ProductSubContract({
      id,
      parent_contract_id,
      start_date,
      user_id,
      shop_id,
      end_date,
      price,
      duration,
      contract_type,
      contract_status,
      transaction,
      transactions,
      metadata,
      ...otherProps,
    });
  }

  clone() {
    const clonedMetadata = this.metadata.map(([key, value]) => [key, value]);
    const cloned = new ProductSubContract({
      id: this.id,
      parent_contract_id: this.parent_contract_id,
      start_date: this.start_date,
      user_id: this.user_id,
      shop_id: this.shop_id.length ? [this.shop_id[0]] : [],
      end_date: this.end_date.length ? [this.end_date[0]] : [],
      price: this.price.length ? this.price.map((p) => p.clone()) : [],
      duration: this.duration.length ? this.duration.map((d) => d.clone()) : [],
      contract_type: this.contract_type,
      contract_status: this.contract_status,
      transaction: this.transaction ? this.transaction.clone() : null,
      transactions: this.transactions.length ? this.transactions.map((t) => t.clone()) : [],
      metadata: clonedMetadata,
    });
    // Copy any additional properties that were dynamically added to the original instance
    Object.keys(this).forEach((key) => {
      if (!cloned.hasOwnProperty(key)) {
        cloned[key] = this[key];
      }
    });
    return cloned;
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    // const entry = this.metadata.find(([entryKey]) => entryKey === key);
    return value && value.length > 0 ? value : null;
  }

  // Setter methods for metadata properties
  set contract_docs(value) {
    this.setMetadataValue("contract_docs", value);
  }

  isSubscription() {
    return getEnumVariableText(this.contract_type) === CONTRACT_TYPES_MAP.subscription.name;
  }

  isSale() {
    return getEnumVariableText(this.contract_type) === CONTRACT_TYPES_MAP.sale.name;
  }

  isReserved() {
    return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.reserved.name;
  }

  isCancelled() {
    return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.cancelled.name;
  }

  isPendingPayment() {
    return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.pendingpayment.name;
  }

  isPendingPickup() {
    return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.pendingpickup.name;
  }

  isCompleted() {
    return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.completed.name;
  }
  isOngoing() {
    return !this.isCancelled() && !this.isCompleted() && !this.isPendingPayment() && !this.isPendingPickup() && !this.isReserved();
  }

  //// Utilities
  /**
   * Pending pickup expires after 7 days
   */
  isPendingPickupExpired() {
    // Check if the current contract is a pending pickup
    const elapsedDays = getElapsedDays(this.start_date);
    return this.isPendingPickup() && elapsedDays > 7;
  }

  getContractDocs(asArray = false) {
    const contractDocs = this.getMetadataValue("contract_docs");
    return asArray ? (contractDocs && contractDocs.length > 0 ? contractDocs.split(",") : []) : contractDocs;
  }

  updateContractDocs(contractUrl) {
    let currentContractUrls = this.getContractDocs(true);
    if (currentContractUrls) {
      currentContractUrls = [...currentContractUrls, contractUrl];
    } else {
      currentContractUrls = [contractUrl];
    }
    this.contract_docs = currentContractUrls.join(",");
  }

  isReservationExpired() {
    // Check if the current contract is a reservation
    if (!this.isReserved()) {
      return false;
    }
    // Get the reservation expiration date
    const reservationExpiration = this.getReservedUntil(false);
    // If no expiration date is available, treat it as not expired
    if (!reservationExpiration) {
      return false;
    }
    // Compare the current date with the reservation expiration date
    const currentDate = Date.now(); // Current date in milliseconds
    // Return true if the current date is past the expiration date, else false
    return currentDate > reservationExpiration;
  }

  /**
   * add 3 days to created date
   * @param {*} currentSubcontract
   * @returns
   */
  getReservedUntil(formatted = false, currentLang) {
    const expiration = addWorkingDays(3, Number(this.start_date));
    return formatted ? fMillis(expiration, { day: "2-digit", month: "long" }, false, currentLang) : expiration;
  }
  /**
   * Calculate completed progress of a subcontract based on duration start and end time. If sale, 0 if not completed.
   * @param {*} subcontract
   * @returns
   */
  calculateProgress() {
    if (this.duration.length === 0) {
      return getEnumVariableText(this.contract_status) === CONTRACT_STATUS_MAP.completed.name ? 100 : 0;
    }
    const currentDate = Date.now(); // Current date in milliseconds
    const startDate = Number(this.start_date);
    // Calculate the estimated or actual end date
    const endDate = this.calculateEndDate(false);

    // Calculate the total contract time and how much time has elapsed from the start
    const totalContractTime = endDate - startDate;
    const timeElapsed = currentDate > endDate ? totalContractTime : currentDate - startDate;

    // Calculate progress as a percentage
    const progress = Math.min(100, (timeElapsed / totalContractTime) * 100);

    return progress;
  }

  /**
   * For sale return the start date.
   * For subscription startdate +duration. Result is the same the of the month it started
   * @param {*} subcontract
   * @param {*} toPrint
   * @returns
   */
  calculateEndDate(toPrint = true) {
    const startDate = Number(this.start_date);
    const isSaleContract = this.isSale(this.contract_type);
    if (isSaleContract) return startDate;
    const duration = convertDaysToMonths(this.duration[0]); // Convert to months
    const startDateObj = new Date(startDate);
    const startDayOfMonth = startDateObj.getDate();

    const endDateObj = new Date(startDate);
    endDateObj.setDate(startDayOfMonth); // Set the end day to the same day of the month
    endDateObj.setMonth(endDateObj.getMonth() + duration); // Add the duration in months
    // Return the end date in milliseconds
    if (toPrint) {
      return fMillis(endDateObj.getTime());
    }
    return endDateObj.getTime();
  }

  //// Payments
  getPrice() {
    if (!this.price || this.price.length === 0) {
      return null;
    }
    return this.price[0];
  }

  /**
   * Returns price to show in UI. If subscription monthly if sale final.
   * @param {*} this
   * @returns Coin
   */
  getPriceDisplay(t) {
    if (!this.getPrice()) {
      return "-";
    }
    const price = this.price[0];
    const isSaleContract = this.isSale(this.contract_type);
    return isSaleContract || price.price_interval.length === 0
      ? fPrice(price.final_price)
      : t("product.price_from", { value: fPrice(price.price_interval[0]) });
  }

  // Check if there is a discount to apply
  // Has into account if first payment or after reservation
  // For subscriptions checks the max usage
  // Returns {result, discount }
  discountToApply(receivedIntents = []) {
    const contractPrice = this.getPrice();
    if(!contractPrice) return {result: false};
    const paymentIntents = receivedIntents.length > 0 ? receivedIntents : this.getTransactionIntents();
    const firstIntentPending = PaymentIntent.getFirstIntentPending(paymentIntents);
    const afterReservationPayment = this.isReserved() && !firstIntentPending && paymentIntents.length > 0;
    const discount = this.isSubscription() ? contractPrice.getSubscriptionDiscount(true) : contractPrice.getSaleDiscount(true);
    const isDiscountValid = discount ? discount.isValidForSubcontract(this, paymentIntents) : { result: false, usagesLeft: 0 };
    return { result: isDiscountValid.result && (paymentIntents.length === 0 || firstIntentPending || afterReservationPayment), usagesLeft: isDiscountValid.usagesLeft,  discount: discount };
  }

  /**
   * Interval price for subs. 
   * Its a coin or PriceCalculation , with also. discountPRice. Object object
   * NOTE it doesnt include discounts if the Coin object
   * @param {*} subContract
   * @returns    return {
      ...resultPrice,
      price_obj: this,
      discountedPrice: discountedPrice,
    };
   */
  getBasePaymentPrice(asCoin = true) {
    if (!this.getPrice()) return null;

    const price = this.getPrice().getBasePaymentPrice();
    return asCoin ? Coin.fromJSON(price) : price;
  }

  // Price without fees and taxes
  // Includes discounts
  getRawPaymentPrice() {
    const price = this.getBasePaymentPrice();
    const priceWithDiscounts = price.discountedPrice ? price.discountedPrice : price; 
    const fees = this.getBaseFeePrice();
    const taxes = this.getBaseTaxesPrice();
    const rawPrice = performPriceMath("-", priceWithDiscounts, fees.amount + taxes.amount);
    return {
      ...rawPrice,
      price_obj: price,
    };
  }

  getBaseFeePrice() {
    const price = this.price;
    if (!price || price.length === 0) {
      return null;
    }
    return this.price[0].getBaseFeePrice();
  }

  getBaseTaxesPrice() {
    const price = this.price;
    if (!price || price.length === 0) {
      return null;
    }
    return this.price[0].getBaseTaxesPrice();
  }
  // For subscriptions
  // Null if no deposit
  // Payment intents must be ordered
  // If no deposit on contract take the one from the first  payment intent
  getDeposit(paymentIntents) {
    let deposit;
    if (!this || this.price.length === 0) return null;
    deposit = this.price[0].getDeposit();

    if (!deposit && paymentIntents && paymentIntents.length > 0) {
      const lastIntent = paymentIntents[0];
      if (lastIntent && lastIntent.price.length > 0) {
        deposit = getMapValueForKey(lastIntent.price[0].other_prices, "deposit");
      }
    }
    return deposit;
  }

  deletePaymentIntent(id) {
    if (this.transaction) {
      this.transaction.deletePaymentIntent(id);
    }
    if (this.transactions && this.transactions.length > 0) {
      for (const transaction of this.transactions) {
        transaction.deletePaymentIntent(id);
      }
    }
    return this;
  }

  /** Check is paymentTo owner is due
   * Return {alreadyPaid, hasToPayOwner}
   */
  hasToPayOwner(toPrint = true) {
    const subcontractEndDate = this.calculateEndDate(toPrint);
    const now = new Date();
    const ownerPayment = this.provider_payment;
    const alreadyPaid = ownerPayment && ownerPayment.isCompleted() ? true : false;
    const hasToPayOwner = !alreadyPaid && subcontractEndDate && now >= subcontractEndDate;
    return { alreadyPaid, hasToPayOwner };
  }

  /**
   * Returns price to show in UI. If subscription monthly if sale final.
   * @returns {priceToPay, fees, taxes}
   */
  getAmountToPayOwner() {
    if (!this || !this.price || this.price.length === 0) {
      return fEmptyPrice();
    }
    // TODO Return with taxes
    const price = this.price[0];
    const finalPrice = price.final_price;
    const priceToPay = performPriceMath("-", finalPrice, price.fees_total.amount + price.taxes_total.amount);
    if (priceToPay.amount < 0) return { priceToPay: fEmptyPrice(), fees: fEmptyPrice(), taxes: fEmptyPrice() };
    return { priceToPay, fees: price.fees_total, taxes: price.taxes_total };
  }

  /**
   * Calculate the total amount to pay in a contract
   * @param {*} this
   * @returns Coin
   */
  getTotalToPay() {
    if (!this || !this.price || this.price.length <= 0) {
      return Coin.empty();
    }
    return this.price[0].final_price;
  }

  getPendingToPay() {
    const toPay = this.getToPay({ isCompleted: false, isPending: true, isNotCompleted: false });
    if (toPay) {
      return toPay;
    }
    return Coin.empty();
  }

  /**
   * Calculate the total amount that is left to be paid. Includes discounts
   * @param {*} this
   * @returns Coin
   */
  getRemainingToPay() {
    const toPay = this.getToPay({ isCompleted: false, isPending: false, isNotCompleted: true });
    if (toPay) {
      return toPay;
    }
    return this.getTotalToPay();
  }

  /**
   * Get amount to pay from transaction purchase intent. So total amount
   * @param {*} param0 
   * @returns 
   */
  getToPay({ isCompleted = true, isPending = false, isNotCompleted = false }) {
    const transactions = this.getPurchaseTransaction();
    if (transactions && transactions.length > 0) {
      return transactions.reduce(
        (sum, transaction) => {
          return sum.add(transaction.getTotalToPay({ isCompleted, isPending, isNotCompleted }));
        },
        Coin.empty() // Provide initial value here
      );
    }
    return null;
  }

  /**
   * Check if a contract is fully paid
   * @param {*} subcontract
   * @returns Coin
   */
  isFullyPaid(paymentIntents) {
    const totalPaid = PaymentIntent.getTotalPaid(paymentIntents);
    const totalToPay = this.getTotalToPay();
    const paymentDifference = performPriceMath("-", totalToPay, totalPaid.amount);
    return paymentDifference.amount <= 0;
  }

  /**
   * Check if a contract can be paid based on the status
   * @returns
   */
  isPayable() {
    const transaction = this.getPurchaseTransaction();
    const paymentIntents = transaction ? transaction.payment_intents : [];
    const statusText = getEnumVariableText(this.contract_status);
    const validStatus = statusText === "Active" || statusText === "Cancelled";
    const pendingPayments = this.getPendingPayments(paymentIntents);
    const now = new Date();
    const nextPaymentDate = PaymentIntent.calculateNextPaymentDate(paymentIntents);
    const nextPaymentInFuture = nextPaymentDate && nextPaymentDate > now.getTime();
    return pendingPayments > 0 && validStatus && !this.isCurrentMonthPaid() && !nextPaymentInFuture;
  }

  // Function to check if a subcontract has pending payments
  getPendingPayments(paymentIntents = []) {
    if (!this.isSubscription()) {
      // For sales TODO for other modes
      const latestAndDate = PaymentIntent.getLatestAndDate(paymentIntents);
      if (latestAndDate) {
        const { payment_intent, date } = latestAndDate;
        // console.log("latestAndDate", JSON.stringify(latestAndDate));
        if (payment_intent && payment_intent.isCompleted()) {
          return 0;
        }
      }
      return 1;
    }
    // Check if total amount was paid
    const totalToPay = this.getTotalToPay();
    const totalPaid = PaymentIntent.getTotalPaid(paymentIntents);
    if (totalPaid.amount >= totalToPay.amount) {
      return 0;
    }

    // Calculate the number of pending payments based on the contract duration
    const totalPayments = convertDaysToMonths(this.duration[0]);
    const completedStatus = getEnumVariableText(PAYMENT_STATUS.completed);
    const paymentsMade = paymentIntents.filter((intent) => getEnumVariableText(intent.status) === completedStatus).length;
    const paymentsLeft = totalPayments - paymentsMade;
    return paymentsLeft;
  }

  getPurchaseTransaction() {
    if (this.transactions) {
      return this.transactions.find((t) => t.isShopTransaction());
    } else if (this.transaction) {
      return this.transaction.isShopTransaction() ? this.transaction : null;
    }
  }

  // Function to check if the current month was paid
  isCurrentMonthPaid() {
    const transaction = this.getPurchaseTransaction();
    const paymentIntents = transaction ? transaction.payment_intents : [];
    // Assume sales are paid if there was one payment

    if ((!this.transactions || this.transactions.length === 0) && !this.transaction) {
      return false;
    }
    if (!this.isSubscription()) {
      const pendingPayments = this.getPendingPayments(paymentIntents);
      return pendingPayments === 0;
    }
    // Get the current date
    const currentDate = new Date();

    // Get the latest payment date (if available)
    const latestPaymentDate = PaymentIntent.calculateNextPaymentDate(paymentIntents);

    // If there's no latest payment date, the current month is not paid
    if (!latestPaymentDate) {
      return false;
    }

    // Convert latest payment date to a Date object
    const latestPayment = new Date(latestPaymentDate);

    // Compare the month and year of the current date and latest payment
    return currentDate.getMonth() === latestPayment.getMonth() && currentDate.getFullYear() === latestPayment.getFullYear();
  }

  /**
   * Includes deposit if first payment
   * If first payment pending or after reservation, include the other prices for discounts
   * The next payment price includes discount if necessary
   * @param {*} this
   * @param {*} paymentIntents, dont add the intents if you want to check the price only for this subcontract (first intent contains the whole transaction)
   * @returns
   */
  getNextPaymentPrice(receivedIntents = []) {
    // TODO check if any previous payment has more amount than required
    // If the first payment was done, the price is the base price times due payments
    // If no previous payment include deposit
    // If the amount to pay is less than remaining return the remaining
    // Islast payment? calculate remaining amount
    const paymentIntents = receivedIntents.length > 0 ? receivedIntents : this.getTransactionIntents();

    const duePayments = this.calculateLatePayments(paymentIntents, true);
    // If the first intent, it can contain price from other items in the transaction so do not use it afterwards
    // Use the price from the first intent as it might content discounts
    const firstIntentPending = PaymentIntent.getFirstIntentPending(paymentIntents);
    const firstIntent = PaymentIntent.getFirstIntent(paymentIntents);
    // console.log("duePayments", JSON.stringify(duePayments));
    // After a reservation, we need to include the discoutns
    const afterReservationPayment = this.isReserved() && !firstIntentPending && firstIntent;

    // Get prices from subcontract. Discounts are not applied to the price.
    // Have into account that if the discounts are expired(subscription), they should not be applied
    const basePrice = this.getBasePaymentPrice();

    const basePriceWithDiscounts = basePrice.discountedPrice ? basePrice.discountedPrice : basePrice;
    const basePriceObj = basePrice.price_obj;
    const discountToApply = this.discountToApply(paymentIntents);
    const discount = discountToApply.discount;
    const hasDiscount = discountToApply.result;
    const discountUsagesLeft = discountToApply.usagesLeft;

    const deposit = PaymentIntent.getPendingDeposit(paymentIntents, this);
    const hasDepositDiscount = basePriceObj.hasDepositDiscount();
    const depositOriginal = basePriceObj.getDepositNotDiscounted() ? basePriceObj.getDepositNotDiscounted() : deposit;

    let totalPrice;
    let totalPriceWithoutDiscounts;
    let totalPriceObject;
    let priceCalcNoDiscounts;

    // Record original prices without discounts
    // The interval prices dont show the discount
    if (hasDiscount) {
      if (discountToApply.result) {
        priceCalcNoDiscounts = PriceCalculation.fromJSON(
          createPriceCalculation({
            finalPrice: basePrice,
            priceInterval: basePriceObj.getPriceIntervalNotDiscounted(), // priceInterval,
            feesTotal: basePriceObj.getFeesTotalNotDiscounted(),
            feesInterval: basePriceObj.fees_interval && basePriceObj.fees_interval.length ? basePriceObj.fees_interval[0] : null,
            taxesTotal: basePriceObj.getTaxesTotalNotDiscounted(),
            taxesInterval: basePriceObj.taxes_interval && basePriceObj.taxes_interval.length ? basePriceObj.taxes_interval[0] : null, // taxesInterval,
            totalInterval: basePriceObj.getPriceIntervalNotDiscounted(),
            //  discountsUsed: totalPriceObject.discounts_used && totalPriceObject.discounts_used.length ? totalPriceObject.discounts_used : null,
            otherPrices: deposit ? [["deposit", depositOriginal]] : null,
          })
        );
      }
    }

    // Calculate total price based on due payments and first intent status
    if (duePayments > 1) {
      if (!hasDiscount) {
        // Old code
        totalPrice = firstIntentPending
          ? performPriceMath("+", performPriceMath("*", basePrice, duePayments - 1), firstIntentPending.price[0].final_price.amount)
          : performPriceMath("*", basePrice, duePayments);

        if (!firstIntentPending) {
          totalPriceObject = performPriceCalculationMath("*", basePrice.price_obj, duePayments);
        } else {
          totalPriceObject = performPriceCalculationMath("*", basePrice.price_obj, duePayments - 1);
          const taxesTotal = performPriceMath("+", totalPriceObject.taxes_total, firstIntentPending.price[0].taxes_total.amount);
          const feesTotal = performPriceMath("+", totalPriceObject.fees_total, firstIntentPending.price[0].fees_total.amount);
          totalPriceObject.taxes_total = taxesTotal;
          totalPriceObject.fees_total = feesTotal;
        }
      } else {
        let paymentsWithoutDiscount = hasDiscount ? duePayments - discountUsagesLeft : duePayments;
        if (paymentsWithoutDiscount < 0) paymentsWithoutDiscount = 0;
        const paymentsWithDiscount = hasDiscount ? discountUsagesLeft : 0;
        // Calculate total prices without discount
        if (paymentsWithoutDiscount > 0) {
          totalPrice = performPriceMath("*", basePrice, paymentsWithoutDiscount);
          totalPriceObject = performPriceCalculationMath("*", priceCalcNoDiscounts ? priceCalcNoDiscounts : basePriceObj, paymentsWithoutDiscount);
        }
        // Add the part with discount
        if (paymentsWithDiscount > 0) {
          const totalPriceTemp = performPriceMath("*", basePriceWithDiscounts, paymentsWithDiscount);
          if (totalPrice) totalPrice.add(totalPriceTemp);
          else totalPrice = totalPriceTemp;
          const totalPriceObjectTemp = performPriceCalculationMath("*", basePriceObj, paymentsWithDiscount);
          if (totalPriceObject) totalPriceObject.add(totalPriceObjectTemp);
          else totalPriceObject = totalPriceObjectTemp;
        }
      }
    } else {
      // When there is only one due payment
      totalPrice = firstIntentPending ? firstIntentPending.price[0].final_price : basePriceWithDiscounts;
      // This line looks suspicious
      totalPriceObject = performPriceCalculationMath("*", basePriceObj, duePayments);
    }

    if (deposit && !firstIntentPending) {
      // Add deposit
      totalPrice = performPriceMath("+", totalPrice, deposit.amount);
      // if (totalPriceOriginal.totalPrice !== 0) {
      //   totalPriceOriginal.totalPrice = performPriceMath("+", totalPriceOriginal.totalPrice, depositOriginal.amount);
      // }
    }

    const remainingToPay = this.getRemainingToPay(paymentIntents);
    const leftOverToPay = performPriceMath("-", remainingToPay, totalPrice.amount);
    // adjust amount to pay as it can not be more than total to pay
    if (leftOverToPay < 0) {
      totalPrice = remainingToPay;
    }

    // const contractDuration = subContract.duration.length > 0 ? subContract.duration[0] : null;
    const priceCalculation = createPriceCalculation({
      // contractDuration: contractDuration, //contractDuration,
      // interval: PaymentsService.fromContractDurationToInterval(contractDuration), //interval,
      finalPrice: totalPrice,
      priceInterval: totalPriceObject.price_interval && totalPriceObject.price_interval.length ? totalPriceObject.price_interval[0] : null, // priceInterval,
      feesTotal: totalPriceObject.fees_total, // feesTotal,
      feesInterval: totalPriceObject.fees_interval && totalPriceObject.fees_interval.length ? totalPriceObject.fees_interval[0] : null, // feesInterval,
      taxesTotal: totalPriceObject.taxes_total, //taxesTotal,
      taxesInterval: totalPriceObject.taxes_interval && totalPriceObject.taxes_interval.length ? totalPriceObject.taxes_interval[0] : null, // taxesInterval,
      discountsUsed: totalPriceObject.discounts_used && totalPriceObject.discounts_used.length ? totalPriceObject.discounts_used : null, // TODO discountsUsed
      otherPrices: firstIntentPending
        ? firstIntentPending.price[0].other_prices
        : afterReservationPayment
          ? firstIntent.price[0].other_prices
          : deposit
            ? [["deposit", deposit]]
            : null,
    });
    const result = {
      ...totalPrice,
      // price_obj: performPriceCalculationMath("*", basePrice.price_obj, duePayments),
      price_obj: PriceCalculation.fromJSON(priceCalculation),
      price_obj_no_discounts: priceCalcNoDiscounts ? priceCalcNoDiscounts : null,
    };
    // console.log("getSubContractNextPaymentPrice calculated " + JSON.stringify(result) + " due " + duePayments);
    return result;
    // return performPriceMath("*", basePrice, duePayments);
  }

  getTransactionIntents() {
    const transaction = this.getPurchaseTransaction();
    return transaction ? transaction.payment_intents : [];
  }

  /**
   * Get the latest intent from the purchase transaction
   * @returns
   */
  getLatestIntent() {
    const latestObj = PaymentIntent.getLatestAndDate(this.getTransactionIntents());
    return latestObj ? latestObj.payment_intent : null;
  }

  getNextPaymentDate(interval) {
    return PaymentIntent.calculateNextPaymentDate(this.getTransactionIntents(), interval);
  }

  // Function to calculate late payments, returns pending payments if thats the min
  // The number of late payments is calculated using months.
  // TODO update for days
  calculateLatePayments(receivedPaymentIntents, onlyCompleted = false) {
    const paymentIntents = receivedPaymentIntents ? receivedPaymentIntents : this.getTransactionIntents();
    const pendingPayments = this.getPendingPayments(paymentIntents);
    if (pendingPayments === 0) {
      return 0;
    }
    // Calculate the number of late payments
    const currentDate = new Date();
    // const nextPaymentDate = subcontract.next_payment_date ? new Date(Number(subcontract.next_payment_date[0])) : 0;
    // const latestPaymentDate = new Date(
    //   Number(subcontract.latest_payment_date?.[0]) || 0
    // );
    const nextPaymentDate = PaymentIntent.calculateNextPaymentDate(paymentIntents, null, onlyCompleted);
    let latePayments = 0;

    if (nextPaymentDate > 0 && currentDate.getTime() > nextPaymentDate) {
      const monthsDifferenceFromNext = getMonthsDifference(nextPaymentDate, currentDate) + 1;
      latePayments = monthsDifferenceFromNext;
      // If you do the min, then pending intents are not counted
      latePayments = Math.min(monthsDifferenceFromNext, pendingPayments);
    } else if (!nextPaymentDate && paymentIntents.length === 0 && this.isReserved()) {
      // This can happen if no payment intents because its a reservation
      latePayments = 1;
    }
    return latePayments;
  }

  hasUpcomingPayment() {
    return this.isOngoing() && this.calculateLatePayments(false) > 0 && this.getNextPaymentDate() !== null;
  }

  // Static
  static getLastSubscription(subcontracts, user) {
    let currentSubContract = null;
    if (subcontracts && subcontracts.length > 0) {
      subcontracts.forEach((subContract) => {
        if (
          subContract.isSubscription() &&
          (!currentSubContract || subContract.start_date > currentSubContract.start_date) &&
          (!user || (user && user.user_id && user.user_id.toString() === subContract.user_id.toString()))
        ) {
          currentSubContract = subContract;
        }
      });
    }
    return currentSubContract;
  }
}