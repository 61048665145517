import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import { Discount } from "model/marketplace/Discount";
import UserService from "services/UserService";
import DiscountsFeesService from "../../../services/DiscountsFeesService";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  discounts: [],
  discount: null,
};

const slice = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS DEFS
    getDiscountsSuccess(state, action) {
      state.isLoading = false;
      state.discounts = action.payload.map((discount) => Discount.fromJSON(discount));
      // .slice()
      // .sort((a, b) => convertToFrontendHashMap(a.product_type).en
      //   .localeCompare(convertToFrontendHashMap(b.product_type).en));
    },

    // GET PRODUCT DEF
    getDiscountSuccess(state, action) {
      state.isLoading = false;
      state.discount = Discount.fromJSON(action.payload);
    },

    deleteDiscount(state, action) {
      state.isLoading = false;
      const updateDiscounts = state.discounts.filter(
        (discount) => discount.id !== action.payload
      );

      state.discounts = updateDiscounts;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDiscounts(symphy_backend, discountId, discountCode, userId, itemId, addUsers) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await DiscountsFeesService.getDiscounts(symphy_backend, discountId, discountCode, userId, itemId);
      dispatch(slice.actions.getDiscountsSuccess(response));
      if (addUsers) {
        // Extract all user IDs from the valid_for field of each discount and get unique IDs
        let userIds = [];
        response.forEach((discount) => {
          if (discount.valid_for && discount.valid_for.length > 0 && discount.valid_for[0].length > 0) {
            userIds = userIds.concat(discount.valid_for[0]);
          }
        });
        userIds = [...new Set(userIds)]; // Get unique user IDs

        // Add user summary to items
        response = await UserService.addUserSummaryToItems(symphy_backend, response, userIds, true);
      }
      dispatch(slice.actions.getDiscountsSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDiscount(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await DiscountsFeesService.getDiscount(symphy_backend, BigInt(id));
      dispatch(slice.actions.getDiscountSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDiscount(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await DiscountsFeesService.deleteDiscount(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteDiscount(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

