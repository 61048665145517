import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
// routes
import { useRouter } from 'src/routes/hooks';
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
// hooks
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import UserService from 'services/UserService';
import { varHover } from 'src/components/animate';
import MyAvatar from 'src/components/custom-components/MyAvatar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSnackbar } from 'src/components/snackbar';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user, logout, symphy_backend } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  ///
  const { translate } = useLocales();
  const photoUrl = UserService.getUserAvatarUrl(user);
  const displayName =  UserService.getUserDisplayName(user);
  const email = user && user.info && user.info.length > 0 ? user.info[0].email : "";

  const OPTIONS = [
    {
      label: translate("navigation.home"),
      icon: 'eva:home-fill',
      linkTo: PATH_PAGE.home,
    },
    {
      label: translate("navigation.account_settings"),
      icon: 'eva:settings-2-fill',
      linkTo: PATH_DASHBOARD.settings.root.path,
    },
  ];
  ///

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}>
        <MyAvatar
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}>
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
