export const idlFactory = ({ IDL }) => {
  const PaymentStatus = IDL.Variant({
    'Failed' : IDL.Null,
    'WrongMethod' : IDL.Null,
    'Completed' : IDL.Null,
    'Pending' : IDL.Null,
  });
  const TransactionStatus = IDL.Variant({
    'Reserved' : IDL.Null,
    'Open' : IDL.Null,
    'Ready' : IDL.Null,
    'Rejected' : IDL.Null,
    'Changed' : IDL.Null,
    'Accepted' : IDL.Null,
    'Requested' : IDL.Null,
    'Cancelled' : IDL.Null,
    'ReservationExpired' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const Args_20 = IDL.Record({ 'subcontract_id' : IDL.Nat64 });
  const Currency = IDL.Variant({
    'EUR' : IDL.Null,
    'GBP' : IDL.Null,
    'USD' : IDL.Null,
  });
  const Coin = IDL.Record({ 'currency' : Currency, 'amount' : IDL.Nat64 });
  const PaymentInterval = IDL.Variant({
    'Day' : IDL.Null,
    'Week' : IDL.Null,
    'Year' : IDL.Null,
    'Month' : IDL.Null,
  });
  const DiscountType = IDL.Variant({
    'FeePartner' : IDL.Null,
    'FeeRegistration' : IDL.Null,
    'Deposit' : IDL.Null,
    'Sale' : IDL.Null,
    'FeeAll' : IDL.Null,
    'FeeCompany' : IDL.Null,
    'FeeInsurance' : IDL.Null,
    'Order' : IDL.Null,
    'Other' : IDL.Null,
    'Service' : IDL.Null,
    'Subscription' : IDL.Null,
  });
  const DiscountUsed = IDL.Record({
    'id' : IDL.Nat64,
    'active' : IDL.Bool,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'discount_type' : DiscountType,
    'discount_amount' : IDL.Opt(Coin),
    'discount_max_amount' : IDL.Opt(Coin),
    'used_code' : IDL.Opt(IDL.Text),
    'discount_percentage' : IDL.Opt(IDL.Float32),
    'max_count_per_user' : IDL.Opt(IDL.Nat64),
    'discount_item_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'date_start' : IDL.Opt(IDL.Nat64),
    'date_end' : IDL.Opt(IDL.Nat64),
  });
  const PriceCalculation = IDL.Record({
    'taxes_interval' : IDL.Opt(Coin),
    'fees_total' : Coin,
    'interval' : IDL.Opt(PaymentInterval),
    'discounts_used' : IDL.Vec(DiscountUsed),
    'price_interval' : IDL.Opt(Coin),
    'fees_interval' : IDL.Opt(Coin),
    'taxes_total' : Coin,
    'contract_duration' : IDL.Opt(IDL.Nat16),
    'final_price' : Coin,
    'other_prices' : IDL.Vec(IDL.Tuple(IDL.Text, Coin)),
  });
  const TransactionType = IDL.Variant({
    'Shop' : Args_20,
    'Service' : IDL.Record({
      'shop_id' : IDL.Nat64,
      'seller_id' : IDL.Principal,
      'price' : IDL.Opt(PriceCalculation),
    }),
  });
  const ServiceTypeName = IDL.Record({ 'name' : IDL.Text });
  const TransactionItem = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Nat64,
    'status' : TransactionStatus,
    'transaction_type' : TransactionType,
    'service_name' : ServiceTypeName,
    'product_id' : IDL.Opt(IDL.Nat64),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const Args = IDL.Record({
    'status' : PaymentStatus,
    'card_number' : IDL.Opt(IDL.Text),
    'transaction_items' : IDL.Vec(TransactionItem),
    'card_expiry_date' : IDL.Opt(IDL.Nat16),
    'created_at' : IDL.Opt(IDL.Nat64),
    'merchant_identifier' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Principal,
    'card_brand' : IDL.Opt(IDL.Text),
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
  });
  const PaymentIntent = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Nat64,
    'status' : PaymentStatus,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Nat64,
    'attempts' : IDL.Opt(IDL.Nat8),
    'payment_method_id' : IDL.Opt(IDL.Nat64),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
  });
  const Card = IDL.Record({
    'id' : IDL.Nat64,
    'card_number' : IDL.Opt(IDL.Text),
    'card_expiry_date' : IDL.Opt(IDL.Nat16),
    'merchant_identifier' : IDL.Text,
    'user_id' : IDL.Principal,
    'card_brand' : IDL.Opt(IDL.Text),
  });
  const Transaction = IDL.Record({
    'id' : IDL.Nat64,
    'modified_at' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'info' : IDL.Text,
    'created_at' : IDL.Nat64,
    'user_id' : IDL.Principal,
  });
  const PaymentMethodStatus = IDL.Variant({
    'Error' : IDL.Null,
    'ExpiredCard' : IDL.Null,
    'Valid' : IDL.Null,
  });
  const PaymentMethodType = IDL.Record({ 'id' : IDL.Nat64, 'name' : IDL.Text });
  const PaymentMethod = IDL.Record({
    'id' : IDL.Nat64,
    'status' : PaymentMethodStatus,
    'user_id' : IDL.Principal,
    'primary' : IDL.Bool,
    'method_type' : IDL.Opt(PaymentMethodType),
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
  });
  const PaymentResponse = IDL.Record({
    'payment_intent' : IDL.Opt(PaymentIntent),
    'card' : IDL.Opt(Card),
    'transaction_items' : IDL.Vec(TransactionItem),
    'transaction' : Transaction,
    'payment_method' : IDL.Opt(PaymentMethod),
  });
  const Response = IDL.Variant({
    'PartialSuccess' : PaymentResponse,
    'NotFound' : IDL.Null,
    'Success' : PaymentResponse,
    'NotAdded' : IDL.Null,
    'ErrorInEvents' : IDL.Null,
    'ExistingPayments' : IDL.Null,
  });
  const Args_1 = IDL.Record({ 'params' : IDL.Text });
  const PaymentResponse_1 = IDL.Record({
    'card' : IDL.Opt(Card),
    'payment_method' : IDL.Opt(PaymentMethod),
    'payment_intent_id' : IDL.Opt(IDL.Nat64),
  });
  const Response_1 = IDL.Variant({
    'WrongArguments' : IDL.Null,
    'AlreadyConfirmed' : PaymentResponse_1,
    'NotFound' : IDL.Null,
    'Success' : PaymentResponse_1,
    'NotAdded' : IDL.Null,
    'ErrorInEvents' : IDL.Null,
  });
  const Args_2 = IDL.Record({
    'transaction_id' : IDL.Nat64,
    'status' : IDL.Opt(PaymentStatus),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'created_at' : IDL.Opt(IDL.Nat64),
    'payment_method_id' : IDL.Opt(IDL.Nat64),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
  });
  const Response_2 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : PaymentIntent,
    'NotAdded' : IDL.Null,
    'Unauthorized' : IDL.Null,
  });
  const Args_3 = IDL.Record({
    'transaction_id' : IDL.Opt(IDL.Nat64),
    'status' : PaymentStatus,
    'card_number' : IDL.Opt(IDL.Text),
    'card_expiry_date' : IDL.Opt(IDL.Nat16),
    'merchant_identifier' : IDL.Opt(IDL.Text),
    'card_brand' : IDL.Opt(IDL.Text),
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
    'order_id' : IDL.Opt(IDL.Text),
  });
  const PaymentResponse_2 = IDL.Record({
    'card' : IDL.Opt(Card),
    'payment_method' : IDL.Opt(PaymentMethod),
    'payment_intent_id' : IDL.Opt(IDL.Nat64),
    'order_id' : IDL.Opt(IDL.Text),
  });
  const Response_3 = IDL.Variant({
    'WrongArguments' : IDL.Null,
    'WrongSignature' : IDL.Null,
    'AlreadyConfirmed' : PaymentResponse_2,
    'ErrorNotProcessed' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : PaymentResponse_2,
    'NotAdded' : IDL.Null,
    'ErrorInEvents' : IDL.Null,
    'ErrorProcessed' : IDL.Null,
  });
  const Args_4 = IDL.Record({
    'transaction_id' : IDL.Nat64,
    'status' : PaymentStatus,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Opt(IDL.Nat64),
    'payment_method_id' : IDL.Opt(IDL.Nat64),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
  });
  const Response_4 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : PaymentIntent,
    'NotAdded' : IDL.Null,
  });
  const Args_5 = IDL.Record({
    'status' : PaymentMethodStatus,
    'user_id' : IDL.Principal,
    'primary' : IDL.Bool,
    'method_type' : IDL.Opt(PaymentMethodType),
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
  });
  const Response_5 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : PaymentMethod,
    'NotAdded' : IDL.Null,
  });
  const Args_6 = IDL.Record({
    'ds_signature' : IDL.Text,
    'status' : PaymentStatus,
    'card_number' : IDL.Opt(IDL.Text),
    'transaction_items' : IDL.Vec(TransactionItem),
    'card_expiry_date' : IDL.Opt(IDL.Nat16),
    'created_at' : IDL.Opt(IDL.Nat64),
    'merchant_identifier' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Principal,
    'card_brand' : IDL.Opt(IDL.Text),
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
    'ds_signature_version' : IDL.Text,
    'ds_merchant_parameters' : IDL.Text,
  });
  const Response_6 = IDL.Variant({
    'PartialSuccess' : PaymentResponse,
    'NotFound' : IDL.Null,
    'Success' : PaymentResponse,
    'NotAdded' : IDL.Null,
  });
  const BankTransfer = IDL.Record({
    'sender_account' : IDL.Opt(IDL.Text),
    'confirmation_date' : IDL.Opt(IDL.Nat64),
    'receiver_account' : IDL.Text,
  });
  const PaymentType = IDL.Variant({ 'BankTransfer' : BankTransfer });
  const Args_7 = IDL.Record({
    'status' : PaymentStatus,
    'payment_type' : PaymentType,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Principal,
    'price' : Coin,
    'subcontract_id' : IDL.Nat64,
  });
  const Payment = IDL.Record({
    'id' : IDL.Nat64,
    'status' : PaymentStatus,
    'payment_type' : PaymentType,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Nat64,
    'user_id' : IDL.Principal,
    'price' : Coin,
    'subcontract_id' : IDL.Nat64,
  });
  const Response_7 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : Payment,
    'NotAdded' : IDL.Null,
  });
  const Args_8 = IDL.Record({
    'status' : TransactionStatus,
    'service_name' : ServiceTypeName,
    'product_id' : IDL.Opt(IDL.Nat64),
    'service_description' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Principal,
    'shop_id' : IDL.Nat64,
    'seller_id' : IDL.Principal,
    'price' : IDL.Opt(PriceCalculation),
  });
  const ContractType = IDL.Variant({
    'Sale' : IDL.Null,
    'Donation' : IDL.Null,
    'Consult' : IDL.Null,
    'Subscription' : IDL.Null,
  });
  const ContractDuration = IDL.Record({ 'duration' : IDL.Nat16 });
  const ContractStatus = IDL.Variant({
    'Paused' : IDL.Null,
    'Reserved' : IDL.Null,
    'PendingPayment' : IDL.Null,
    'Active' : IDL.Null,
    'PendingVerification' : IDL.Null,
    'PendingPickup' : IDL.Null,
    'Cancelled' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const ProductSubContractExpandedSimple = IDL.Record({
    'id' : IDL.Nat64,
    'contract_type' : ContractType,
    'duration' : IDL.Opt(ContractDuration),
    'parent_contract_id' : IDL.Nat64,
    'product_id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'end_date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Principal,
    'shop_id' : IDL.Opt(IDL.Nat64),
    'start_date' : IDL.Nat64,
    'price' : IDL.Opt(PriceCalculation),
    'contract_status' : ContractStatus,
  });
  const TransactionItemExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Nat64,
    'status' : TransactionStatus,
    'transaction_type' : TransactionType,
    'service_name' : ServiceTypeName,
    'product_id' : IDL.Opt(IDL.Nat64),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'subcontract' : IDL.Opt(ProductSubContractExpandedSimple),
  });
  const TransactionWithItem = IDL.Record({
    'id' : IDL.Nat64,
    'modified_at' : IDL.Nat64,
    'transaction_item' : TransactionItemExpanded,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'info' : IDL.Text,
    'payment_intents' : IDL.Vec(PaymentIntent),
    'created_at' : IDL.Nat64,
    'user_id' : IDL.Principal,
  });
  const Response_8 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : TransactionWithItem,
    'NoPermission' : IDL.Null,
  });
  const Args_9 = IDL.Record({
    'status' : TransactionStatus,
    'service_name' : ServiceTypeName,
    'product_id' : IDL.Nat64,
    'info' : IDL.Text,
    'user_id' : IDL.Principal,
    'subcontract_id' : IDL.Nat64,
  });
  const Response_9 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : TransactionWithItem,
    'NotAdded' : IDL.Null,
  });
  const Args_10 = IDL.Record({
    'old_user_id' : IDL.Principal,
    'new_user_id' : IDL.Principal,
  });
  const Response_10 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_11 = IDL.Record({ 'id' : IDL.Nat64 });
  const Response_11 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : PaymentIntent,
  });
  const Args_12 = IDL.Record({ 'payment_method_id' : IDL.Nat64 });
  const Response_12 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Response_13 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : Payment,
  });
  const Args_13 = IDL.Record({
    'transaction_id' : IDL.Nat64,
    'with_children' : IDL.Bool,
  });
  const Response_14 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Response_15 = IDL.Variant({ 'Error' : IDL.Null, 'Success' : IDL.Null });
  const Args_14 = IDL.Record({ 'user_id' : IDL.Opt(IDL.Principal) });
  const Response_16 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(Card),
    'Unauthorized' : IDL.Null,
  });
  const Args_15 = IDL.Record({
    'transaction_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'add_subcontracts' : IDL.Bool,
    'with_payment_intents' : IDL.Bool,
    'product_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'service_names' : IDL.Opt(IDL.Vec(ServiceTypeName)),
    'subcontract_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
  });
  const TransactionWithItems = IDL.Record({
    'id' : IDL.Nat64,
    'modified_at' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'info' : IDL.Text,
    'payment_intents' : IDL.Vec(PaymentIntent),
    'transaction_items' : IDL.Vec(TransactionItemExpanded),
    'created_at' : IDL.Nat64,
    'user_id' : IDL.Principal,
  });
  const Response_17 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(TransactionWithItems),
  });
  const Args_16 = IDL.Record({
    'transaction_id' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Opt(IDL.Principal),
  });
  const Response_18 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(PaymentIntent),
    'Unauthorized' : IDL.Null,
  });
  const PaymentMethodExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'status' : PaymentMethodStatus,
    'card' : IDL.Opt(Card),
    'user_id' : IDL.Principal,
    'primary' : IDL.Bool,
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
  });
  const Response_19 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(PaymentMethodExpanded),
    'Unauthorized' : IDL.Null,
  });
  const Args_17 = IDL.Record({ 'user_ids' : IDL.Vec(IDL.Principal) });
  const Args_18 = IDL.Record({
    'user_id' : IDL.Opt(IDL.Principal),
    'subcontract_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
  });
  const Response_20 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(Payment),
    'Unauthorized' : IDL.Null,
  });
  const Args_19 = IDL.Record({
    'transaction_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'with_payment_intents' : IDL.Bool,
    'user_id' : IDL.Opt(IDL.Principal),
    'product_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'shop_id' : IDL.Opt(IDL.Nat64),
    'service_names' : IDL.Opt(IDL.Vec(ServiceTypeName)),
    'subcontract_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
  });
  const Response_21 = IDL.Variant({ 'Success' : IDL.Vec(TransactionWithItem) });
  const Response_22 = IDL.Variant({
    'Error' : IDL.Text,
    'Success' : IDL.Vec(IDL.Text),
    'Unauthorized' : IDL.Null,
  });
  const Args_21 = IDL.Record({ 'transaction_id' : IDL.Nat64 });
  const Args_22 = IDL.Record({
    'transaction_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'add_subcontracts' : IDL.Bool,
    'with_payment_intents' : IDL.Bool,
    'user_id' : IDL.Opt(IDL.Principal),
    'product_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
    'service_names' : IDL.Opt(IDL.Vec(ServiceTypeName)),
    'subcontract_ids' : IDL.Opt(IDL.Vec(IDL.Nat64)),
  });
  const Response_23 = IDL.Variant({
    'Success' : IDL.Vec(TransactionWithItems),
  });
  const Args_23 = IDL.Record({ 'product_id' : IDL.Nat64 });
  const Response_24 = IDL.Variant({ 'Success' : IDL.Opt(Transaction) });
  const Response_25 = IDL.Variant({ 'Success' : IDL.Null });
  const Args_24 = IDL.Record({
    'id' : IDL.Nat64,
    'transaction_id' : IDL.Opt(IDL.Nat64),
    'status' : IDL.Opt(PaymentStatus),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Opt(IDL.Nat64),
    'attempts' : IDL.Opt(IDL.Nat8),
    'payment_method_id' : IDL.Opt(IDL.Nat64),
    'order_id' : IDL.Opt(IDL.Text),
    'payment_gateway' : IDL.Opt(IDL.Text),
    'price' : IDL.Opt(PriceCalculation),
  });
  const Response_26 = IDL.Variant({
    'NotUpdated' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : PaymentIntent,
    'NoPermission' : IDL.Null,
  });
  const Args_25 = IDL.Record({
    'id' : IDL.Nat64,
    'status' : PaymentMethodStatus,
    'primary' : IDL.Bool,
    'authorized_transaction_id' : IDL.Opt(IDL.Text),
    'card_id' : IDL.Opt(IDL.Nat64),
  });
  const Response_27 = IDL.Variant({
    'NotUpdated' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : PaymentMethod,
  });
  const Args_26 = IDL.Record({
    'id' : IDL.Nat64,
    'status' : IDL.Opt(PaymentStatus),
    'payment_type' : IDL.Opt(PaymentType),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'date' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Opt(IDL.Principal),
    'price' : IDL.Opt(Coin),
    'subcontract_id' : IDL.Opt(IDL.Nat64),
  });
  const Response_28 = IDL.Variant({
    'NotUpdated' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : Payment,
    'NoPermission' : IDL.Null,
  });
  const UpdateTransactionItemArgs = IDL.Record({
    'item_status' : IDL.Opt(TransactionStatus),
    'transaction_type' : IDL.Opt(TransactionType),
    'service_name' : IDL.Opt(ServiceTypeName),
    'product_id' : IDL.Opt(IDL.Nat64),
    'is_new' : IDL.Bool,
    'item_metadata' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))),
    'item_id' : IDL.Nat64,
  });
  const Args_27 = IDL.Record({
    'id' : IDL.Nat64,
    'modified_at' : IDL.Opt(IDL.Nat64),
    'metadata' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))),
    'info' : IDL.Opt(IDL.Text),
    'created_at' : IDL.Opt(IDL.Nat64),
    'user_id' : IDL.Opt(IDL.Principal),
    'items' : IDL.Vec(UpdateTransactionItemArgs),
  });
  const Response_29 = IDL.Variant({
    'NotUpdated' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : TransactionWithItems,
    'ErrorInEvents' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  return IDL.Service({
    'add_first_payment' : IDL.Func([Args], [Response], []),
    'add_manual_payment_confirmation' : IDL.Func([Args_1], [Response_1], []),
    'add_payment' : IDL.Func([Args_2], [Response_2], []),
    'add_payment_confirmation' : IDL.Func([Args_3], [Response_3], []),
    'add_payment_intent' : IDL.Func([Args_4], [Response_4], []),
    'add_payment_method' : IDL.Func([Args_5], [Response_5], []),
    'add_payment_notification' : IDL.Func([Args_6], [Response_6], []),
    'add_provider_payment' : IDL.Func([Args_7], [Response_7], []),
    'add_service_transaction' : IDL.Func([Args_8], [Response_8], []),
    'add_shop_transaction' : IDL.Func([Args_9], [Response_9], []),
    'change_user_id' : IDL.Func([Args_10], [Response_10], []),
    'delete_payment_intent' : IDL.Func([Args_11], [Response_11], []),
    'delete_payment_method' : IDL.Func([Args_12], [Response_12], []),
    'delete_provider_payment' : IDL.Func([Args_11], [Response_13], []),
    'delete_transaction' : IDL.Func([Args_13], [Response_14], []),
    'generate_payments_testdata' : IDL.Func(
        [IDL.Record({})],
        [Response_15],
        [],
      ),
    'get_cards' : IDL.Func([Args_14], [Response_16], ['query']),
    'get_my_transactions' : IDL.Func([Args_15], [Response_17], ['query']),
    'get_payment_intents' : IDL.Func([Args_16], [Response_18], ['query']),
    'get_payment_methods' : IDL.Func([Args_14], [Response_19], ['query']),
    'get_payment_methods_expanded' : IDL.Func(
        [Args_17],
        [Response_19],
        ['query'],
      ),
    'get_provider_payments' : IDL.Func([Args_18], [Response_20], ['query']),
    'get_seller_transactions' : IDL.Func([Args_19], [Response_21], ['query']),
    'get_subcontract_order_ids' : IDL.Func([Args_20], [Response_22], ['query']),
    'get_transaction_order_ids' : IDL.Func([Args_21], [Response_22], ['query']),
    'get_transactions' : IDL.Func([Args_22], [Response_23], ['query']),
    'get_valuation_transaction_by_product_id' : IDL.Func(
        [Args_23],
        [Response_24],
        ['query'],
      ),
    'get_valuation_transactions' : IDL.Func(
        [IDL.Record({})],
        [Response_21],
        ['query'],
      ),
    'reset_payments_testdata' : IDL.Func([IDL.Record({})], [Response_25], []),
    'update_payment_intent' : IDL.Func([Args_24], [Response_26], []),
    'update_payment_method' : IDL.Func([Args_25], [Response_27], []),
    'update_provider_payment' : IDL.Func([Args_26], [Response_28], []),
    'update_transaction' : IDL.Func([Args_27], [Response_29], []),
  });
};
export const init = ({ IDL }) => { return []; };
