import { Principal } from "@dfinity/principal";
import { Coin } from "model/payments/Coin";
import { Discount } from "model/marketplace/Discount";
import { Fee } from "model/marketplace/Fee";
import {PriceCalculation} from "model/payments/PriceCalculation";
import { SERVICE_TYPE_NAME_MAP } from "model/payments/TransactionWithItems";
import { getEnumVariableText } from "src/utils/typeConverters";
import { convertToPrice } from "../ui/utils/format-number";
import BackendService from "./BackendService";
import { CONTRACT_TYPES_MAP } from "./ProductContractsService";
import PriceSummary from "model/payments/PriceSummary";

/**
 * Provides methods related to the products
 */
export default class DiscountsFeesService {

  /**
   * Return a list of all discounts
   *
   * @returns All products
   */
  static getDiscounts = async (symphy_backend, discountId, discountCode, userId, itemId) => {
    const response = await symphy_backend["marketplace"].get_discounts({
      discount_id: discountId ? [discountId] : [],
      discount_code: discountCode ? [discountCode] : [],
      user_id: userId ? [userId] : [],
      item_id: itemId ? [itemId] : [],
    });
    if (!response.Success) {
      console.log("getDiscounts not successful");
      return [];
    }
    return response.Success.map((item) => Discount.fromJSON(item));
  };

  /**
   * Return a discount by id
   *
   * @returns Discount by id
   */
  static getDiscount = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_discount({ discount_id: id });
    if (!response.Success) {
      console.log("getDiscount not successful");
      return null;
    }
    return Discount.fromJSON(response.Success);
  };

  /**
   * Create a product definition
   *
   * @returns
   */
  static addDiscount = async (symphy_backend, discount) => {
    const response = await symphy_backend["marketplace"].add_discount({ discount: discount });
    if (BackendService.isResponseError(response)) {
      console.log("addProductDefinition not successful");
      return null;
    }
    return Discount.fromJSON(response.Success);
  };

  /**
   * Update product definition
   *
   */
  static updateDiscount = async (symphy_backend, discount) => {
    let response = await symphy_backend["marketplace"].update_discount({ discount: discount });
    // console.log("response", JSON.stringify(response));
    if (BackendService.isResponseError(response)) {
      console.log("updateDiscount not successful");
      return null;
    }
    return Discount.fromJSON(response.Success);
  };

  /**
   * Deletes a product definition by id
   *
   */
  static deleteDiscount = async (symphy_backend, id) => {
    let response = await symphy_backend["marketplace"].delete_discount({ discount_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("deleteDiscount not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Uses a discount
   *
   */
  static useDiscount = async (symphy_backend, id) => {
    let response = await symphy_backend["marketplace"].use_discount({ discount_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("useDiscount not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of all fees
   *
   * @returns All fees
   */
  static getFees = async (symphy_backend) => {
    const response = await symphy_backend["marketplace"].get_fees({});
    if (!response.Success) {
      console.log("getDiscounts not successful");
      return [];
    }
    return response.Success.map((item) => Fee.fromJSON(item));
  };

  /**
   * Return a fee by id
   *
   * @returns Fee by id
   */
  static getFee = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_fee({ fee_id: id });
    if (!response.Success) {
      console.log("getFee not successful");
      return null;
    }
    return Fee.fromJSON(response.Success);
  };

  static createFee = (id, isSale, productPriceMax, feeCompany, feePartners, feeRegistration) => {
    const fee = {
      // eslint-disable-next-line no-undef
      id: BigInt(id),
      is_sale: isSale,
      product_price_max: convertToPrice(productPriceMax),
      fee_company: parseFloat(feeCompany),
      fee_partners: convertToPrice(feePartners),
      fee_registration: convertToPrice(feeRegistration),
    };
    return fee;
  };

  static cloneFee = (oldFee) => {
    const fee = {
      // eslint-disable-next-line no-undef
      id: oldFee.id,
      is_sale: oldFee.is_sale,
      product_price_max: oldFee.product_price_max,
      fee_company: oldFee.fee_company,
      fee_partners: oldFee.fee_partners,
      fee_registration: oldFee.fee_registration,
    };
    return fee;
  };

  /**
   * Create a fee
   *
   * @returns
   */
  static addFee = async (symphy_backend, fee) => {
    const response = await symphy_backend["marketplace"].add_fee({ fee: fee });
    if (BackendService.isResponseError(response)) {
      console.log("addFee not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Update fee
   *
   */
  static updateFee = async (symphy_backend, fee) => {
    let response = await symphy_backend["marketplace"].update_fee({ fee: fee });
    if (BackendService.isResponseError(response)) {
      console.log("updateFee not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Deletes fee
   *
   */
  static deleteFee = async (symphy_backend, id) => {
    let response = await symphy_backend["marketplace"].delete_fee({ fee_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("deleteFee not successful");
      return null;
    }
    return response.Success;
  };

  /// Discount codes

  static createDiscountCode = (value, count, maxCount) => {
    const fee = {
      value: value,
      count: count,
      max_count: maxCount,
    };
    return fee;
  };

  static addDiscountCode = async (symphy_backend, discountId, discountCode) => {
    try {
      const response = await symphy_backend["marketplace"].add_discount_code({ discount_id: discountId, discount_code: discountCode });
      if (!response.Success) {
        console.log("addDiscountCode not successful");
        return null;
      }
      return response.Success;
    } catch (error) {
      console.error("Error adding discount code:", error);
      throw error;
    }
  };

  static deleteDiscountCode = async (symphy_backend, discountCode) => {
    try {
      const response = await symphy_backend["marketplace"].delete_discount_code({ discount_code: discountCode });
      if (!response.Success) {
        console.log("deleteDiscountCode not successful");
        return null;
      }
      return response.Success;
    } catch (error) {
      console.error("Error deleting discount code:", error);
      throw error;
    }
  };

  static updateDiscountCode = async (symphy_backend, discountId, discountCode) => {
    try {
      const response = await symphy_backend["marketplace"].update_discount_code({ discount_id: discountId, discount_code: discountCode });
      return response.data.discount;
    } catch (error) {
      console.error("Error updating discount code:", error);
      throw error;
    }
  };

  static useDiscountCode = async (symphy_backend, discountCodeValue) => {
    try {
      const response = await symphy_backend["marketplace"].use_discount_code({
        discount_code: discountCodeValue,
      });
      if (!response.Success) {
        console.log("deleteDiscountCode not successful");
        return null;
      }
      return response.data.success;
    } catch (error) {
      console.error("Error using discount code:", error);
      throw error;
    }
  };

  /// Price the subsconfig price has priority over the recommended
  // Contract duration in days
  static getProductPrices = async (symphy_backend, userId, productId, valuationPrice) => {
    try {
      const response = await symphy_backend["marketplace"].get_product_prices({
        user_id: userId ? [userId] : [],
        product_id: productId,
        valuation_price: valuationPrice,
      });
      let priceSummary = response.Success;
      if (priceSummary.sale_price.length > 0) {
        priceSummary.sale_price = [PriceCalculation.fromJSON(priceSummary.sale_price[0])];
      }
      if (priceSummary.subscription_prices.length > 0) {
        priceSummary.subscription_prices = priceSummary.subscription_prices.map((item) => PriceCalculation.fromJSON(item));
      }
      return priceSummary;
    } catch (error) {
      console.error("Error getting subscription price:", error);
      throw error;
    }
  };
  
  /** Gets the subcontract price with discount. It uses nextPaymentPrice 
   * Retrurns a map of product_id to price
  */
  static getSubcontractsPriceWithDiscount = async (symphy_backend, subcontract, forcedPrice) => {
    const orderItems = DiscountsFeesService.convertSubcontractsToOrderItems([subcontract], true, forcedPrice);
      const backendPriceWithDiscount = await this.getPriceWithDiscount(symphy_backend, subcontract.user_id, null, null, orderItems);
      if (backendPriceWithDiscount?.Success) {
        const priceMapping = {};
        const priceSummary = PriceSummary.fromJSON(backendPriceWithDiscount.Success);
        priceSummary.items.forEach((item) => {
          const productId = item.product_id;
          if (productId) {
            priceMapping[productId] = item.price;
          }
        });
        return priceMapping;
      }
    return null;
  }


  static getPriceWithDiscount = async (symphy_backend, userId, discountCode, discountId, items = []) => {
    try {
      const response = await symphy_backend["marketplace"].apply_discount({
        user_id: userId ? [userId] : [],
        discount_code: discountCode ? [discountCode] : [],
        discount_id: discountId ? [BigInt(discountId)] : [],
        items: items ? items : [],
      });
      // console.log("getPriceWithDiscount", JSON.stringify(response));
      if (!BackendService.isResponseSuccess(response)) {
        console.log("getPriceWithDiscount not successful " + JSON.stringify(response));
        return response;
      }
      return response;
    } catch (error) {
      console.error("Error getting getPriceWithDiscount price:", error);
      return null;
    }
  };

  static convertCheckoutItemsToOrderItems = (items) => {
    if (!items) {
      return [];
    }
    return items.map((item) => {
      const isSubscription = getEnumVariableText(item.contractType) === getEnumVariableText(CONTRACT_TYPES_MAP.subscription.enum);
      const priceCalculation = PriceCalculation.fromJSON(isSubscription ? item.subscriptionPrice : item.salePrice);
      return this.createOrderItem(isSubscription, priceCalculation, item.product.id);
      // return {
      //   service_type: isSubscription ? SERVICE_TYPE_NAME_MAP.subscription : SERVICE_TYPE_NAME_MAP.sale,
      //   product_id: BigInt(item.product.id),
      //   valuation_price: Coin.fromJSON(item.product.valuation_price),
      //   price: priceCalculation,
      // };
    });
  };

  static convertSubcontractsToOrderItems = (items, useNextPaymentPrice = false, forcedPrice) => {
    if (!items) {
      return [];
    }
    return items.map((item) => {
      const isSubscription = item.isSubscription();
      const priceCalculation = forcedPrice ? forcedPrice : !useNextPaymentPrice ? item.getPrice().getWithoutDiscount() : item.getNextPaymentPrice().price_obj;
      return this.createOrderItem(isSubscription, priceCalculation, item.product_id);
      // return {
      //   service_type: isSubscription ? SERVICE_TYPE_NAME_MAP.subscription : SERVICE_TYPE_NAME_MAP.sale,
      //   product_id: BigInt(item.product.id),
      //   valuation_price: Coin.fromJSON(item.product.valuation_price),
      //   price: priceCalculation,
      // };
    });
  };

  static createOrderItem = (isSubscription, priceCalculation, productId) => {
    return {
      service_type: isSubscription ? SERVICE_TYPE_NAME_MAP.subscription : SERVICE_TYPE_NAME_MAP.sale,
      product_id: BigInt(productId),
      valuation_price: Coin.empty(),
      price: priceCalculation,
    };
  };

  static getSubscriptionProductPrice = async (symphy_backend, userId, productId, valuationPrice, contractDuration, priceOverride, discountCode) => {
    // try {
    const response = await symphy_backend["marketplace"].get_subscription_price({
      user_id: userId ? [userId] : [],
      product_id: productId,
      valuation_price: valuationPrice,
      contract_duration: contractDuration,
      price: priceOverride ? [priceOverride] : [],
      discount_code: discountCode ? [discountCode] : [],
    });
    return response.Success;
    // } catch (error) {
    //   console.error("Error getting subscription price:", error);
    //   throw error;
    // }
  };

  static getSaleProductPrice = async (symphy_backend, userId, productId, valuationPrice, priceOverride, discount, discountCode) => {
    try {
      const response = await symphy_backend["marketplace"].get_sale_price({
        user_id: userId ? [userId] : [],
        product_id: productId,
        valuation_price: valuationPrice,
        price: priceOverride ? [priceOverride] : [],
        discount: discount ? [discount] : [],
        discount_code: discountCode ? [discountCode] : [],
      });
      return response.Success;
    } catch (error) {
      console.error("Error getting subscription price:", error);
      throw error;
    }
  };

  static getServicePrice = async (symphy_backend, valuationPrice, discountCode) => {
    try {
      const response = await symphy_backend["marketplace"].get_service_price({
        service_type: SERVICE_TYPE_NAME_MAP.maintenance,
        final_price: valuationPrice,
        discount_code: discountCode ? [discountCode] : [],
      });
      return response.Success;
    } catch (error) {
      console.error("Error getting subscription price:", error);
      throw error;
    }
  };

  static extractSubscriptionPrice = (priceCalculationSummary, contractDurationDays) => {
    if (!priceCalculationSummary || !priceCalculationSummary.subscription_prices) {
      return null;
    }
    // console.log("extractSubscriptionPrice days : ", JSON.stringify(contractDurationDays));

    // console.log("extractSubscriptionPrice priceCalculationSummary : ", JSON.stringify(priceCalculationSummary));

    // Sort the subscription_prices array based on contract_duration_days in ascending order
    const sortedPrices = priceCalculationSummary.subscription_prices.sort((a, b) => a.contract_duration[0] - b.contract_duration[0]);
    for (const priceCalculation of sortedPrices) {
      // console.log(
      //   "priceCalculation " +
      //     JSON.stringify(priceCalculation.contract_duration) +
      //     " interval " +
      //     JSON.stringify(priceCalculation.price_interval[0]) +
      //     " final " +
      //     JSON.stringify(priceCalculation.final_price)
      // );
      // Find the smallest contract duration that is larger than the requested one
      if (priceCalculation.contract_duration[0] >= contractDurationDays) {
        // console.log('Found price calculation for contract duration:', JSON.stringify(priceCalculation));
        // console.log('extractSubscriptionPrice found: ', JSON.stringify(priceCalculation));
        return priceCalculation;
      }
    }
    // console.log('extractSubscriptionPrice: null ');
    // Return null or handle the case when no matching contract duration is found
    return null;
  };
}