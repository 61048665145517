import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import DiscountsFeesService from "../../../services/DiscountsFeesService";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  fees: [],
  fee: null,
};

const slice = createSlice({
  name: "fee",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS DEFS
    getFeesSuccess(state, action) {
      state.isLoading = false;
      state.fees = action.payload
        .slice()
        .sort((a, b) => Number(a.product_price_max.amount) - Number(b.product_price_max.amount));
    },

    // GET PRODUCT DEF
    getFeeSuccess(state, action) {
      state.isLoading = false;
      state.fee = action.payload;
    },

    deleteFee(state, action) {
      state.isLoading = false;
      const updateFees = state.fees.filter(
        (product) => product.id !== action.payload
      );

      state.fees = updateFees;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFees(symphy_backend) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await DiscountsFeesService.getFees(symphy_backend);
      dispatch(slice.actions.getFeesSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFee(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await DiscountsFeesService.getFee(symphy_backend, BigInt(id));
      dispatch(slice.actions.getFeeSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFee(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await DiscountsFeesService.deleteFee(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteFee(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

