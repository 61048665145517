export const idlFactory = ({ IDL }) => {
  const ProductState = IDL.Variant({
    'New' : IDL.Null,
    'LikeNew' : IDL.Null,
    'Used' : IDL.Null,
  });
  const ProductCategory = IDL.Variant({
    'Accesories' : IDL.Null,
    'Instruments' : IDL.Null,
  });
  const Currency = IDL.Variant({
    'EUR' : IDL.Null,
    'GBP' : IDL.Null,
    'USD' : IDL.Null,
  });
  const Coin = IDL.Record({ 'currency' : Currency, 'amount' : IDL.Nat64 });
  const Location = IDL.Record({
    'lat' : IDL.Float32,
    'lng' : IDL.Float32,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'address' : IDL.Text,
  });
  const Product = IDL.Record({
    'id' : IDL.Nat64,
    'verified' : IDL.Bool,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'created_at' : IDL.Nat64,
    'state' : ProductState,
    'product_type' : IDL.Text,
    'owner_id' : IDL.Principal,
    'category' : ProductCategory,
    'valuation_price' : Coin,
    'location' : IDL.Opt(Location),
    'images' : IDL.Vec(IDL.Text),
  });
  const Args = IDL.Record({ 'product' : Product });
  const Response = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : Product,
    'ErrorDefinition' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const ProductDefinition = IDL.Record({
    'id' : IDL.Nat64,
    'sizes' : IDL.Vec(IDL.Text),
    'product_type' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'compatible_products' : IDL.Vec(IDL.Nat64),
    'category' : ProductCategory,
  });
  const Args_1 = IDL.Record({ 'product_definition' : ProductDefinition });
  const Response_1 = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : ProductDefinition,
    'NoPermission' : IDL.Null,
  });
  const Args_2 = IDL.Record({ 'user_id' : IDL.Principal });
  const Response_2 = IDL.Variant({ 'Error' : IDL.Null, 'Success' : IDL.Bool });
  const Args_3 = IDL.Record({
    'old_user_id' : IDL.Principal,
    'new_user_id' : IDL.Principal,
  });
  const Response_3 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_4 = IDL.Record({ 'product_id' : IDL.Nat64 });
  const Response_4 = IDL.Variant({
    'NoPermissionVerify' : IDL.Null,
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_5 = IDL.Record({ 'product_definition_id' : IDL.Nat64 });
  const Response_5 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_6 = IDL.Record({ 'defs' : IDL.Bool, 'products' : IDL.Bool });
  const Response_6 = IDL.Variant({ 'Error' : IDL.Null, 'Success' : IDL.Null });
  const Response_7 = IDL.Variant({ 'Success' : IDL.Vec(Product) });
  const ListingStatus = IDL.Variant({
    'Reserved' : IDL.Null,
    'Active' : IDL.Null,
    'Draft' : IDL.Null,
    'Completed' : IDL.Null,
  });
  const StoreListing = IDL.Record({
    'id' : IDL.Nat64,
    'status' : ListingStatus,
    'product_id' : IDL.Nat64,
    'creator_id' : IDL.Principal,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'contract_id' : IDL.Opt(IDL.Nat64),
    'created_at' : IDL.Nat64,
    'shop_id' : IDL.Opt(IDL.Nat64),
  });
  const ProductExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'verified' : IDL.Bool,
    'store_listing' : IDL.Opt(StoreListing),
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'created_at' : IDL.Nat64,
    'state' : ProductState,
    'product_type' : IDL.Text,
    'owner_id' : IDL.Principal,
    'category' : ProductCategory,
    'valuation_price' : Coin,
    'location' : IDL.Opt(Location),
    'images' : IDL.Vec(IDL.Text),
  });
  const Response_8 = IDL.Variant({
    'Error' : IDL.Null,
    'Success' : IDL.Vec(ProductExpanded),
  });
  const Canisters = IDL.Record({
    'payments_canister_id' : IDL.Opt(IDL.Principal),
    'users_canister_id' : IDL.Opt(IDL.Principal),
    'marketplace_canister_id' : IDL.Opt(IDL.Principal),
  });
  const Response_9 = IDL.Variant({ 'Success' : Canisters });
  const Args_7 = IDL.Record({ 'product_id' : IDL.Nat64 });
  const Response_10 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : Product,
  });
  const Response_11 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Tuple(IDL.Nat32, IDL.Nat32),
    'NoPermission' : IDL.Null,
  });
  const Args_8 = IDL.Record({ 'product_definition_id' : IDL.Nat64 });
  const Response_12 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : ProductDefinition,
  });
  const Response_13 = IDL.Variant({ 'Success' : IDL.Vec(ProductDefinition) });
  const Args_9 = IDL.Record({ 'user_id' : IDL.Principal });
  const Response_14 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Opt(IDL.Nat32),
    'NoPermission' : IDL.Null,
  });
  const Args_10 = IDL.Record({
    'user_id' : IDL.Opt(IDL.Principal),
    'product_ids' : IDL.Vec(IDL.Nat64),
    'to_verify' : IDL.Opt(IDL.Bool),
    'product_name' : IDL.Opt(IDL.Text),
    'current_user' : IDL.Opt(IDL.Bool),
  });
  const Response_15 = IDL.Variant({ 'Success' : IDL.Vec(Product) });
  const Args_11 = IDL.Record({ 'product_ids' : IDL.Vec(IDL.Nat64) });
  const Response_16 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Response_17 = IDL.Variant({ 'Success' : IDL.Null });
  const Response_18 = IDL.Variant({ 'Success' : IDL.Null });
  const Args_12 = IDL.Record({
    'for_sellers' : IDL.Bool,
    'new_limit' : IDL.Nat32,
  });
  const Args_13 = IDL.Record({
    'user_id' : IDL.Principal,
    'new_limit' : IDL.Nat32,
  });
  const Args_14 = IDL.Record({
    'product_id' : IDL.Nat64,
    'owner_id' : IDL.Principal,
  });
  return IDL.Service({
    'add_product' : IDL.Func([Args], [Response], []),
    'add_product_definition' : IDL.Func([Args_1], [Response_1], []),
    'can_user_upload_product' : IDL.Func([Args_2], [Response_2], ['query']),
    'change_user_id' : IDL.Func([Args_3], [Response_3], []),
    'delete_product' : IDL.Func([Args_4], [Response_4], []),
    'delete_product_definition' : IDL.Func([Args_5], [Response_5], []),
    'generate_products_testdata' : IDL.Func([Args_6], [Response_6], []),
    'get_all_products' : IDL.Func([IDL.Record({})], [Response_7], ['query']),
    'get_all_products_expanded' : IDL.Func([IDL.Record({})], [Response_8], []),
    'get_canister_ids' : IDL.Func([IDL.Record({})], [Response_9], ['query']),
    'get_my_products' : IDL.Func([IDL.Record({})], [Response_7], ['query']),
    'get_my_products_expanded' : IDL.Func([IDL.Record({})], [Response_8], []),
    'get_product' : IDL.Func([Args_7], [Response_10], ['query']),
    'get_product_default_limits' : IDL.Func(
        [IDL.Record({})],
        [Response_11],
        ['query'],
      ),
    'get_product_definition' : IDL.Func([Args_8], [Response_12], ['query']),
    'get_product_definitions' : IDL.Func(
        [IDL.Record({})],
        [Response_13],
        ['query'],
      ),
    'get_product_limit' : IDL.Func([Args_9], [Response_14], ['query']),
    'get_products' : IDL.Func([Args_10], [Response_15], ['query']),
    'get_products_by_id' : IDL.Func([Args_11], [Response_15], ['query']),
    'reset_product_limit' : IDL.Func([Args_9], [Response_16], []),
    'reset_products_testdata' : IDL.Func([IDL.Record({})], [Response_17], []),
    'update_canister_ids' : IDL.Func([Canisters], [Response_18], []),
    'update_default_product_limit' : IDL.Func([Args_12], [Response_16], []),
    'update_product' : IDL.Func([Args], [Response], []),
    'update_product_definition' : IDL.Func([Args_1], [Response_1], []),
    'update_product_limit' : IDL.Func([Args_13], [Response_16], []),
    'update_product_owner' : IDL.Func([Args_14], [Response_16], []),
  });
};
export const init = ({ IDL }) => { return []; };
