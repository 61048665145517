export class ContractDuration {
  constructor({ duration }) {
    this.duration = Number(duration); // Ensure duration is stored as a number
  }

  static fromJSON(durationJSON) {
    if (!durationJSON) return null;
    const { duration } = durationJSON;
    return new ContractDuration({ duration });
  }

  clone() {
    return new ContractDuration({ duration: this.duration });
  }

  getPaymentInterval() {
    return ContractDuration.getPaymentIntervalFromDuration(this.duration);
  }

  calculateNumberOfPayments() {
    return ContractDuration.calculateNumberOfPayments(this.duration);
  }

  paymentIntervalInMs() {
    return ContractDuration.getPaymentIntervalInMs(this.getPaymentInterval());
  }

  static getPaymentIntervalFromDuration(duration) {
    if (duration < 30) {
      return "Day";
    } else if (duration < 365) {
      return "Month";
    } else {
      return "Year";
    }
  }

  static calculateNumberOfPayments(duration) {
    const interval = ContractDuration.getPaymentIntervalFromDuration(duration);
    switch (interval) {
      case "Day":
        return 1;
      case "Week":
        return Math.floor(duration / 7);
      case "Month":
        return Math.ceil(duration / 30.44);
      case "Year":
        return Math.floor(duration / 365);
      default:
        return 0;
    }
  }

  static getPaymentIntervalInMs(interval) {
    const DAY_IN_MS = 24 * 60 * 60 * 1000;
    const WEEK_IN_MS = 7 * DAY_IN_MS;
    const MONTH_IN_MS = 30.44 * DAY_IN_MS;
    const YEAR_IN_MS = 365 * DAY_IN_MS;

    switch (interval) {
      case "Day":
        return DAY_IN_MS;
      case "Week":
        return WEEK_IN_MS;
      case "Month":
        return MONTH_IN_MS;
      case "Year":
        return YEAR_IN_MS;
      default:
        return 0;
    }
  }
}
