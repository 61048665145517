import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import TransactionItem from "./TransactionItem";
import { PAYMENT_STATUS } from "services/PaymentsService";
import { PaymentIntent } from "./PaymentIntent";
import { performPriceMath } from "src/utils/price-utils";
import { Coin } from "./Coin";
import { PriceCalculation } from "./PriceCalculation";
// import { VIEW_OWNER } from "src/sections/product/view/product-overview-view";
export const PRODUCT_STATES = [{ LikeNew: null }, { Used: null }, { New: null }];
export const PRODUCT_CATEGORY = [{ Instruments: null }, { Accesories: null }];

// ServiceTypeName
export const SERVICE_NAMES = ["Valuation", "Sale", "Subscription", "Storage", "Maintenance"];
export const SERVICE_TYPE_VALUATION = "Valuation";
export const SERVICE_TYPE_SALE = "Sale";
export const SERVICE_TYPE_SUBSCRIPTION = "Subscription";
export const SERVICE_TYPE_STORAGE = "Storage";
export const SERVICE_TYPE_MAINTENANCE = "Maintenance";

export const SERVICE_TYPE_NAME_MAP = {
  storage: { name: SERVICE_TYPE_STORAGE },
  maintenance: { name: SERVICE_TYPE_MAINTENANCE },
  valuation: { name: SERVICE_TYPE_VALUATION },
  subscription: { name: SERVICE_TYPE_SUBSCRIPTION },
  sale: { name: SERVICE_TYPE_SALE },
};

/**
 * Represents TransactionWithItem and TransactionWithItems
 * Payment intents are sortedby date
 */
export default class TransactionWithItems {
  constructor(id, info, user_id, created_at, modified_at, transaction_item = null, transaction_items = [], payment_intents = [], metadata = [], ...otherProps) {
    this.id = id;
    this.info = info;
    this.user_id = user_id;
    this.created_at = created_at;
    this.modified_at = modified_at;
    // this.transaction_item = transaction_item; // Assuming transaction_item is already an instance of TransactionItem or similar class
    // this.transaction_items = transaction_items; // Assuming transaction_items is an array of TransactionItem instances
    this.transaction_items = [...transaction_items].sort((a, b) => Number(a.created_at) - Number(b.created_at));
    this.transaction_item = transaction_item || (transaction_items.length > 0 ? transaction_items[0] : null); // Default to first item if transaction_item is not provided
    this.payment_intents = [...payment_intents].sort((a, b) => Number(a.date) - Number(b.date)).map((intent) => PaymentIntent.fromJSON(intent));
    this.metadata = metadata;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(transactionWithItemJSON) {
    if (!transactionWithItemJSON) return null;
    // Extract known properties
    const { id, info, user_id, created_at, modified_at, transaction_item, transaction_items, payment_intents, metadata, ...otherProps } =
      transactionWithItemJSON;
    // Create the TransactionItem from its JSON representation, if necessary
    const transactionItemInstance = transaction_item ? TransactionItem.fromJSON(transaction_item) : null;
    const transactionItemsInstance = transaction_items ? transaction_items.map((item) => TransactionItem.fromJSON(item)) : [];

    // Return a new instance of TransactionWithItem including any additional properties
    return new TransactionWithItems(
      id,
      info,
      user_id,
      created_at,
      modified_at,
      transactionItemInstance,
      transactionItemsInstance,
      payment_intents,
      metadata,
      otherProps
    );
  }

  clone() {
    // Create a shallow copy of this instance, including any additional properties
    const clonedObj = new TransactionWithItems(
      this.id,
      this.info,
      this.user_id,
      this.created_at,
      this.modified_at,
      this.transaction_item ? this.transaction_item.clone() : null,
      this.transaction_items ? [...this.transaction_items] : [],
      [...this.payment_intents],
      [...this.metadata]
    );

    // Copy any additional properties that were dynamically added
    Object.keys(this).forEach((key) => {
      if (!clonedObj.hasOwnProperty(key)) {
        clonedObj[key] = this[key];
      }
    });

    return clonedObj;
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  getServiceName(toPrint = true) {
    if (this.transaction_item && this.transaction_item.service_name && this.transaction_item.service_name.name) {
      return toPrint ? this.transaction_item.service_name.name : this.transaction_item.service_name;
    } else {
      return null;
    }
  }

  getTransactionTypeVal() {
    if (this.transaction_item && this.transaction_item.transaction_type) {
      return getEnumVariableText(this.transaction_item.transaction_type);
    } else {
      return null;
    }
  }

  getTransactionType() {
    if (this.transaction_item && this.transaction_item.transaction_type) {
      return this.transaction_item.transaction_type;
    } else {
      return null;
    }
  }

  getShopId() {
    return this.transaction_item && this.transaction_item.getShopId() ? this.transaction_item.getShopId() : null;
  }
  getSellerId() {
    return this.transaction_item && this.transaction_item.getSellerId() ? this.transaction_item.getSellerId() : null;
  }

  getStatus() {
    return this.transaction_item && this.transaction_item.getStatus() ? this.transaction_item.getStatus() : null;
  }

  getStatusText() {
    return this.transaction_item && this.transaction_item.getStatusText() ? this.transaction_item.getStatusText() : null;
  }

  getPrice(finalPrice = true) {
    return this.transaction_item && this.transaction_item.getPrice(finalPrice) ? this.transaction_item.getPrice(finalPrice) : null;
  }

  // Setter methods
  set shop(value) {
    if (this.transaction_item) {
      this.transaction_item.shop(value);
    }
  }

  getShop() {
    return this.transaction_item ? this.transaction_item.shop : null;
  }

  isValuation() {
    return this.transaction_item ? this.transaction_item.isValuationService() : false;
  }

  isMaintenance() {
    return this.transaction_item ? this.transaction_item.isMaintenanceService() : false;
  }

  isShopTransaction() {
    return this.transaction_item ? this.transaction_item.isShopTransaction() : false;
  }

  isCompleted() {
    if (this.transaction_items && this.transaction_items.length > 0) {
      // Check if all items are completed
      return this.transaction_items.every((item) => item.isCompleted());
    }
    return this.transaction_item ? this.transaction_item.isCompleted() : false;
  }

  isRequested() {
    return this.transaction_item ? this.transaction_item.isRequested() : false;
  }

  isOnGoing() {
    return this.transaction_item ? this.transaction_item.isOnGoing() : false;
  }

  getServiceNameTranslation(viewType = "owner") {
    if (!this.transaction_item) {
      return this.info;
    }
    switch (this.transaction_item.service_name.name) {
      case "Valuation":
        return this.getValuationActionTexts(viewType).title;
      case "Payment":
        return "services.payment_received";
      case "Maintenance":
        return "services.maintenance_service";
      default:
        return this.info;
    }
  }

  getActionText(viewType = "owner") {
    if (!this.transaction_item) {
      return this.info;
    }
    switch (this.transaction_item.service_name.name) {
      case "Valuation":
        return this.getValuationActionTexts(viewType).actionText;
      case "Payment":
        return "services.payment_received";
      case "Maintenance":
        return "services.maintenance_service";
      default:
        return this.info;
    }
  }

  static sortTransactionsByDate(transactions) {
    if (!transactions || transactions.length === 0) return [];
    return [...transactions].sort((a, b) => Number(b.created_at) - Number(a.created_at));
  }

  getFirstPaymentIntent() {
    // TODO order
    if (this.payment_intents && this.payment_intents.length > 0) {
      const firstPayment = PaymentIntent.getFirstIntent(this.payment_intents);
      return firstPayment;
    }
    return null;
  }

  getLatestPaymentIntent(fullObj = false) {
    // TODO move to PaymentIntent class
    if (this.payment_intents && this.payment_intents.length > 0) {
      const latestPayment = PaymentIntent.getLatestAndDate(this.payment_intents);
      return fullObj ? latestPayment : latestPayment && latestPayment.payment_intent ? latestPayment.payment_intent : null;
    }
    return null;
  }

  isPendingPayment() {
    // TODO move to PaymentIntent class
    const latestIntent = this.getLatestPaymentIntent();
    return latestIntent ? getEnumVariableText(latestIntent.status) === getEnumVariableText(PAYMENT_STATUS.pending) : false;
  }

  isCompletedPayment() {
    // TODO move to PaymentIntent class
    const latestIntent = this.getLatestPaymentIntent();
    return latestIntent ? getEnumVariableText(latestIntent.status) === getEnumVariableText(PAYMENT_STATUS.completed) : false;
  }

  isStatusCancelled() {
    if (this.transaction_items.length > 0) {
      // Check if all items are cancelled
      // const cancelledItems = this.transaction_items.filter((item) => item.isCancelled());
      // const oneCompleted = this.transaction_items.some((item) => item.isCompleted());
      return this.transaction_items.every((item) => item.isCancelled());
    } else if (this.transaction_item) {
      // If there is only a single transaction_item, check if it's cancelled
      return this.transaction_item.isCancelled();
    }
    return false; // Default case if no items are present
  }
  isStatusReserved() {
    if (this.transaction_items.length > 0) {
      // Check if all items are cancelled
      return this.transaction_items.every((item) => item.isReserved());
    } else if (this.transaction_item) {
      // If there is only a single transaction_item, check if it's cancelled
      return this.transaction_item.isReserved();
    }
    return false; // Default case if no items are present
  }

  getReservedSubcontracts() {
    const reservedSubcontracts = [];
    if (this.transaction_items.length > 0) {
      this.transaction_items.forEach((item) => {
        if (item.isSubcontractReserved()) {
          reservedSubcontracts.push(item.getSubcontract());
        }
      });
    }
    return reservedSubcontracts;
  }

  getReservationData() {
    let isReservation = false;
    let reservationUntil = null;
    let reservationAmount = 0;
    let reservedItems = [];
    if (this.transaction_items.length > 0) {
      this.transaction_items.forEach((item) => {
        if (item.isSubcontractReserved()) {
          isReservation = true;
          if (!reservationUntil) {
            reservationUntil = item.getReservedUntil();
          }
          const itemReservationAmount = Number(item.getReservationPrice().amount);
          reservationAmount += itemReservationAmount;
          const subcontract = item.getSubcontract();
          reservedItems.push({
            transaction_item_id: item.id,
            product_id: item.product_id,
            reservationAmount: itemReservationAmount,
            subcontract: subcontract,
          });
        }
      });
    }
    return {
      reservationAmount: reservationAmount,
      isReservation: isReservation,
      reservationUntil: reservationAmount,
      reservedItems: reservedItems,
    };
  }

  getTotalReservationPrice() {
    let totalReservedPrice = Coin.empty();
    this.transaction_items.forEach((item) => {
      // Check if the item has a reserved price
      const reservationPrice = item.getReservationPrice();
      if (reservationPrice) {
        totalReservedPrice = totalReservedPrice.add(reservationPrice); // Add the reservation price to the total
      }
    });
    return totalReservedPrice;
  }

  /** From payment intents */
  getTotalToPay({ isCompleted = true, isPending = false, isNotCompleted = false }) {
    if (this.payment_intents && this.payment_intents.length > 0) {
      return this.payment_intents.reduce(
        (sum, paymentIntent) => {
          return sum.add(paymentIntent.getToPay({ isCompleted, isPending, isNotCompleted }));
        },
        Coin.empty() // Provide initial value here
      );
    }
    return Coin.empty();
  }

  deletePaymentIntent(id) {
    if (this.payment_intents && this.payment_intents.length > 0) {
      this.payment_intents = this.payment_intents.filter((paymentIntent) => paymentIntent.id !== id);
    }
    return this;
  }

  getPaymentIntent(id) {
    if (this.payment_intents && this.payment_intents.length > 0) {
      const intentIndex = this.payment_intents.findIndex((paymentIntent) => paymentIntent.id === id);
      if (intentIndex !== -1) {
        return this.payment_intents[intentIndex];
      }
    }
    return null;
  }

  /**
   *
   * @param {*} paymentIntent either the intent or its id
   * @param {*} status
   * @returns
   */
  updatePaymentIntent(paymentIntent, status, metadata) {
    const intentId = paymentIntent && paymentIntent.id ? paymentIntent.id : paymentIntent;
    if (this.payment_intents && this.payment_intents.length > 0) {
      const intentIndex = this.payment_intents.findIndex((existingIntent) => existingIntent.id === intentId);
      if (intentIndex !== -1) {
        // Replace the existing payment intent
        // updatedPaymentIntents = [...transaction.payment_intents];
        const intent = this.payment_intents[intentIndex].clone();
        if (paymentIntent.transaction_id) {
          intent.transaction_id = BigInt(paymentIntent.transaction_id);
          intent.order_id = paymentIntent.order_id;
          intent.payment_method_id = paymentIntent.payment_method_id;
          intent.date = BigInt(paymentIntent.date);
          intent.price = paymentIntent.price.length ? [PriceCalculation.fromJSON(paymentIntent.price[0])] : [];
          if (status) {
            intent.status = status;
          } else {
            intent.status = paymentIntent.status;
          }
          intent.attempts = paymentIntent.attempts;
          intent.payment_gateway = paymentIntent.payment_gateway;
          if (metadata) {
            intent.metadata = metadata;
          } else {
            intent.metadata = paymentIntent.metadata;
          }
        } else {
          if (status) {
            intent.status = paymentIntent.status;
          }
          if (metadata) {
            intent.metadata = metadata;
          }
        }
        this.payment_intents[intentIndex] = intent;
      } else {
        // Add the new payment intent
        this.payment_intents.push(PaymentIntent.fromJSON(paymentIntent));
      }

      // Update the transaction with the new payment intents
      // const updatedTransaction = TransactionWithItems.fromJSON({ ...transaction, payment_intents: updatedPaymentIntents });
    }
    return this;
  }

  setCancelled() {
    if (this.transaction_item) {
      this.transaction_item.setCancelled();
    }
    if (this.transaction_items.length > 0) {
      this.transaction_items.forEach((item) => {
        item.setCancelled();
      });
    }
  }

  /**
   * Add a product object to the transaction item that matches the product_id. If its in .transaction_item, also add it
   * @param {*} product
   * @returns
   */
  setProductForItem(product) {
    // Check and set product for the singular transaction_item
    if (
      this.transaction_item &&
      this.transaction_item.product_id &&
      this.transaction_item.product_id.length &&
      this.transaction_item.product_id[0] === product.id
    ) {
      this.transaction_item.setProduct(product); // Set product for the singular transaction item
    }

    // Check and set product for the array of transaction_items
    if (this.transaction_items && this.transaction_items.length > 0) {
      this.transaction_items.forEach((transactionItem) => {
        if (transactionItem.product_id && transactionItem.product_id.length && transactionItem.product_id[0] === product.id) {
          transactionItem.setProduct(product); // Set product for each matching transaction item
        }
      });
    }
  }

  /**
   * Get product ids from all transactions and their transaction items
   * @param {*} transactions
   * @returns
   */
  static getItemsProductIds = (transactions) => {
    const productIds = new Set();
    // Loop through each productContract to access both owner_id and subcontracts
    transactions.forEach((transaction) => {
      // Iterate over transaction items if they exist
      if (transaction.transaction_items && transaction.transaction_items.length > 0) {
        transaction.transaction_items.forEach((transaction_item) => {
          if (transaction_item.product_id) {
            productIds.add(transaction_item.product_id);
          }
        });
      }
      if (transaction.transaction_item) {
        if (transaction.transaction_item.product_id) {
          productIds.add(transaction.transaction_item.product_id);
        }
      }
    });

    // Convert the Set back to an array
    const uniqueProductIds = Array.from(productIds);
    return uniqueProductIds;
  };
}
