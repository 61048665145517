import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export const DATE_TIME_FORMAT = 'dd MMM yyyy p';

/**
 * Convert a backend ms timestamp to a human readable date
 * @param {*} date 
 * @param {*} newFormat 
 * @returns 
 */
export function fMillis(date, newFormat, withYear=true, language="es") {
  if (!date) {
    return "";
  }
  // const fm = newFormat || "dd MMM yyyy";
  // eslint-disable-next-line no-undef
  const timeInMilliseconds = BigInt(date).toString();
  // return date ? format(new Date(Number(timeInMilliseconds)), fm) : "";

  // Convert the input date (which is presumably in milliseconds) to a BigInt and then to a Number
  const dateObject = new Date(Number(timeInMilliseconds));

  // Use the provided format or fallback to a default format
  const options =
    newFormat ?  newFormat : withYear
      ? { day: "2-digit", month: "short", year: "numeric" }
      : { day: "2-digit", month: "short"};

  // Use the provided language or fallback to the user's locale if not provided
  const locale = language || navigator.language || navigator.userLanguage;

  // Format the date using the specified or default locale
  return new Intl.DateTimeFormat(locale, options).format(dateObject);
}

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

/**
 * Convert the initial date string to the desired format (dd/mm/yyyy)
 * @param {string} initialDateString The initial date string to be converted
 * @returns {string} The date string in the format dd/mm/yyyy
 */
export function convertDatePickerStringToDateFormat(initialDateString) {
  // Split the initial date string by spaces
  const dateParts = String(initialDateString).split(' ');

  // Extract the day, month, and year parts from the date string
  const dayMonthYear = dateParts.slice(2, 5);

  // Reorder the date parts and join them with '/'
  const formattedDate = dayMonthYear.reverse().join('/');

  return formattedDate;
}

/**
 * Convert a date in the format dd/mm/yyyy to milliseconds
 * @param {*} date 
 * @returns 
 */
export function datePickerToMillis(date) {
  return new Date(date).getTime();
}

/**
 * Convert a date in the format dd/mm/yyyy to milliseconds
 * @param {*} date 
 * @returns 
 */
export function dateToMillis(date) {
  if (!date) {
    return Date.now();
  }
  // console.log(date);
  const [day, month, year] = String(date).split('/').map(Number);
  const formattedDate = new Date(year, month - 1, day); // Note: month is 0-based in JavaScript Date object
  
  return formattedDate.getTime();
}


export function convertMonthsToDays(months) {
  const averageDaysInMonth = 365.25 / 12; // Average days per month, accounting for leap years
  const days = Math.round(months * averageDaysInMonth); // Round to the nearest whole number
  return days;
}

export function convertDaysToMonths(days) {
  const averageDaysInMonth = 365.25 / 12; // Average days per month, accounting for leap years

  // Function to extract the days count from various input formats
  const extractDaysValue = (input) => {
    if (Array.isArray(input) && input.length > 0) {
      input = input[0];
    }
    if (typeof input === "object" && input !== null && input.duration !== undefined) {
      return input.duration;
    }
    return input;
  };

  const daysValue = extractDaysValue(days); // Extract the actual number of days from input
  const months = daysValue / averageDaysInMonth; // Convert days to months using the average days per month
  return Math.ceil(months); // Use Math.ceil to round up to the nearest whole number of months
}

export function getFutureDate(initialDate, monthsToAdd) {
  // Calculate the end date
  const endDate = new Date(initialDate.getFullYear(), initialDate.getMonth() + monthsToAdd, initialDate.getDate());
  // Set the end date day to match the initial date
  endDate.setDate(initialDate.getDate());
  return endDate;
}

// Function to get the difference in months between two dates
export const getMonthsDifference = (date1, date2) => {
  const monthsDifference = (date2.getFullYear() - date1.getFullYear()) * 12;
  return monthsDifference + date2.getMonth() - date1.getMonth();
};

/**
 * Calculates the number of days elapsed since a given date.
 * @param {Date | string} startDate - The start date from which to calculate the elapsed days in ms
 * @returns {number} The number of days elapsed since the start date.
 */
export function getElapsedDays(startDate) {
    const nowMillis = new Date().getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    return Math.floor((nowMillis - Number(startDate)) / millisecondsPerDay);
}


/**
 * Calculates the number of minutes elapsed since a given date.
 * @param {Date | string} startDate - The start date from which to calculate the elapsed days in ms
 * @returns {number} The number of days elapsed since the start date.
 */
export function getElapsedMinutes(startDate) {
  const nowMillis = new Date().getTime();
  const msPerMin =  60 * 1000; //  seconds * milliseconds
  return Math.floor((nowMillis - Number(startDate)) / msPerMin);
}


export function addWorkingDays(daysToAdd, startDateInMs = Date.now()) {
  const resultDate = new Date(startDateInMs);
  let remainingDays = daysToAdd;

  while (remainingDays > 0) {
    resultDate.setDate(resultDate.getDate() + 1); // Move to the next day

    // Check if the new day is a working day (Monday to Friday)
    const dayOfWeek = resultDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 = Sunday, 6 = Saturday
      remainingDays--; // Only count working days
    }
  }
  return resultDate;
}


