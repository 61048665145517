// PaymentMethodType class
export class PaymentMethodType {
  constructor({ name, id, ...otherProps }) {
    this.name = name;
    this.id = BigInt(id);

    Object.assign(this, otherProps);
  }

  static fromJSON(paymentMethodTypeJSON) {
    if (!paymentMethodTypeJSON) return null;
    const { name, id, ...otherProps } = paymentMethodTypeJSON;
    return new PaymentMethodType({ name, id, ...otherProps });
  }

  clone() {
    return new PaymentMethodType({ name: this.name, id: this.id });
  }

  isCard() {
    return this.name === 'card';
  }

  isBankTransfer() {
    return this.name === 'bank_transfer';
  }

  static card(id) {
    return new PaymentMethodType({ name: 'card', id: BigInt(id) });
  }

  static bankTransfer(id) {
    return new PaymentMethodType({ name: 'bank_transfer', id: BigInt(id) });
  }
}
