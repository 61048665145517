/* eslint-disable no-undef */
import numeral from 'numeral';
import ProductsService from '../../services/ProductsService';
import { getEnumVariableText } from './typeConverters';
import { Coin } from 'model/payments/Coin';

// ----------------------------------------------------------------------

/**
 * Convert timestamp from backend to frontend (loose 6 digits precission)
 * Backend is in MS
 * @param {*} number 
 * @returns 
 */
export function fDate(number) {
  return Math.floor(Number(number) / 1000000)
}

/**
 * Convert timestamp from backend to frontend date
 * @param {*} number 
 * @returns 
 */
export function msToDate(number) {
  return new Date(Number(number));
}

export function fNumber(number) {
  return numeral(number).format();
}

export function getDefaultCurrencySymbol() {
  return getCurrencySymbol(getEnumVariableText(getEmptyCoin().currency));
}

// -----EXTRAs
export function getCurrencySymbol(currency) {
  return Coin.getCurrencySymbol(currency);
}
function getDecimalFactor(currency) {
  return Coin.getDecimalFactor(currency);
}

export function fEmptyPrice(removeCurrency) {
  return Coin.fEmptyPrice(removeCurrency);
}

export function getEmptyCoin() {
  return Coin.empty();
}

export function fPriceString(priceString, currency, removeCurrency) {
  return fPrice(createCoinFromAmount(String(priceString), currency, removeCurrency));
}


/* Format a Backend price to a string with proper number of decimals and currency 
*/
export function fPrice(price, removeCurrency, removeDecimals) {
  return Coin.fPrice(price, removeCurrency, removeDecimals);
}

export function fPriceToNumber(price, removeCurrency, removeDecimals) {
  return Coin.fPriceToNumber(price, removeCurrency, removeDecimals);
}

/**
 *  The price string should be an integer without decimals coming from a Coin object amount
 * @param {*} inputPrice 
 * @param {*} currency 
 * @returns 
 */
export function createCoinFromAmount(inputPrice, currency) {
  return Coin.createCoinFromAmount(inputPrice, currency);
}

// Convert a price string to a currency. The decimals will be taken into account
// The decimals can be both . or ,
// Use it to convert and input string price t oa price object
export function convertToPrice(inputPrice, currency) {
  return Coin.fromStringPrice(inputPrice, currency);
}
export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00') + " €";
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number, oneDecimal) {
  if (oneDecimal) {
      const format = number ? numeral(number).format("0.0a") : "";
      return result(format, ".0");
  }
  const format = number ? numeral(number).format('0.00a') : '';
  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fDistance(number) {
  const format = number ? numeral(number).format('0.0') : '';

  return result(format, '.0') + " km";
}

export function fDecimals(number) {
  const format = number ? numeral(number).format('0.000') : '';

  return result(format, '.000');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function convertToDigitsDigits(number, digits=5) {
  const str = String(number);
  return str.padStart(digits, "0");
}

/**
 * Convert a string to a number with a fixed number of decimal places the output format will be 1.00
 * @param {*} priceString 
 * @returns 
 */
export function ensureTwoOrOneDecimalDigits(price, oneDecimal) {
  // Replace "," with "." to standardize the decimal separator
  let priceString = String(price).replace(",", ".");
  
  // Check if there is a decimal point
  if (!priceString.includes(".")) {
    // If no decimal point, add ".00"
    priceString += oneDecimal ? "":".00";
  } else {
    // Split into integer and decimal parts
    const parts = priceString.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];
    
    // Ensure the decimal part has exactly two digits
    if (oneDecimal) {
      if (decimalPart.length > 1) {
        // If more than one digit, truncate
        decimalPart = decimalPart.substring(0, 1);
      }
    } else if (decimalPart.length === 1) {
      // If one digit, append "0"
      decimalPart += "0";
    } else if (decimalPart.length > 2) {
      // If more than two digits, truncate
      decimalPart = decimalPart.substring(0, 2);
    }
    
    // Reassemble the price string
    priceString = `${integerPart}.${decimalPart}`;
  }
  
  return priceString;
}