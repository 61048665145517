import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
// routes
import { PATH_DASHBOARD, paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
import { CheckoutContext } from './checkout-context';
import ProductContractsService from 'services/ProductContractsService';
import { convertDaysToMonths } from 'src/utils/format-time';
import { performPriceMath } from 'src/utils/price-utils';
import Product from 'model/marketplace/Product';
import PriceSummary from 'model/payments/PriceSummary';
import { PriceCalculation } from 'model/payments/PriceCalculation';
import { ProductSubContract } from 'model/contracts/ProductSubContract';
import { Discount } from 'model/marketplace/Discount';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'checkout';
export const PRODUCT_CHECKOUT_STEPS = ["cart", "your_data", "payment"];
export const DEFAULT_NEXT_PAYMENTS_DISCOUNTED = { amount: 0, periods: 0, full: false };

const initialState = {
  activeStep: 0,
  items: [], // Store listings with .contract, .salePrice, .subscriptionPrice, salePrice, subscriptionDuration, allPrices, contractId, subsConfig, saleConfig
  subTotal: 0, // Price (monthly if subscription) without deposits. Includes taxes and fees
  subTotalSales: 0,
  total: 0, // total to pay now, includes deposits
  discount: 0, // PriceSummary object with discounts applied
  shipping: 0,
  // added
  fees: 0,
  deposits: 0,
  futureDeposits: 0, // Deposits to be paid after reservation is selected
  reservations: 0,
  nextPayments: 0, // Of all subscriptions. Would be the monthly price
  nextPaymentsDiscounted: DEFAULT_NEXT_PAYMENTS_DISCOUNTED, // Of all subscriptions. Would be the monthly price {amount, periods, full} full is if the discount is for the whole duration
  taxes: 0,
  feesSales: 0,
  taxesSales: 0,
  orderId: null,
  status: "PENDING_PAYMENT",
  //
  billing: null,
  totalItems: 0,
};

export function getCheckoutItemData(item) {
  const product = Product.fromJSON(item.product);
  const isSubscription = ProductContractsService.isSubscriptionContract(item.contractType);
  const price = PriceCalculation.fromJSON(isSubscription ? item.subscriptionPrice : item.salePrice);
  const deposit = price.getDeposit() ? price.getDeposit() : "";
  const monthsOfContract = isSubscription ? convertDaysToMonths(price.contract_duration[0]) : 0;
  const basePriceToPay = isSubscription ? price.price_interval[0] : price.final_price;
  const nextPayments = isSubscription ? performPriceMath("+", price.price_interval[0], deposit.amount) : price.final_price;
  const instrumentCondition = product.getStateDescription();
  const images = item.product.images && item.product.images.length > 0 ? item.product.images.join(",") : "";
  const productAccessories = product.getAccessories(true, true);
  return {
    product: product,
    isSubscription,
    price,
    basePriceToPay,
    nextPayments, // includes deposit
    deposit,
    duration: monthsOfContract,
    instrumentCondition,
    images,
    instrumentAccessories: productAccessories,
  };
}

/**
 * From a PriceSummary object, return a map with product_id as key and PriceCalculation as value for its items
 * @param {*} discount 
 * @returns 
 */
export function getCheckoutDiscountItemMap(discount) {
  console.log("getCheckoutDiscountItemMap", JSON.stringify(discount));
  const discountItemsMap = new Map();
  if (!discount || !discount.items) {
    return discountItemsMap;
  }
  discount.items.forEach((item) => {
    discountItemsMap.set(Number(item.product_id), PriceCalculation.fromJSON(item.price));
  });

  return discountItemsMap;
}


export function CheckoutProvider({ children }) {
  const router = useRouter();

  const [values, setValues] = useLocalStorage(STORAGE_KEY, initialState);

  const setValue = useCallback(
    (name, value) => {
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setValues]
  );

  const onGetCart = useCallback(() => {
    const totalItems = values.items.reduce((total, item) => total + item.quantity, 0);
    // const subTotal = values.items.reduce((total, item) => total + item.quantity * item.price, 0);
    // console.log("onGetCart", JSON.stringify(onGetCart));
    let subTotal = 0; // For subscriptions what would be initial payment
    let subTotalSales = 0; // Payments from sales
    let deposits = 0; // Only added from subscriptions
    let futureDeposits = 0; // Deposits to be paid after reservation is selected
    let reservations = 0;
    let nextPayments = 0;
    let nextPaymentsDiscounted = DEFAULT_NEXT_PAYMENTS_DISCOUNTED;

    let fees = 0;
    let taxes = 0;
    let feesSales = 0;
    let taxesSales = 0;
    const priceSummary = values.discount && values.discount.price ? PriceSummary.fromJSON(values.discount) : null;
    // If there is a discount we have to calculate total. Which is the total from the discount + deposits - reservations
    let total = priceSummary  && priceSummary.price && priceSummary.price.final_price.amount > 0 ? Number(priceSummary.price.final_price.amount) : 0;
    // const hasDepositDiscount = priceSummary && priceSummary.price.hasDepositDiscount() ? priceSummary.price.hasDepositDiscount() : null;
    // setValues(initialState);
    // return;
    values.items.forEach((item) => {
      // const item = ProductSubContract.fromJSON(itemPre);
      const quantity = Number(item.quantity);
      let itemFees, itemTaxes, itemPrice;
      const priceSummaryItem = priceSummary ? priceSummary.getItemByProductId(item.product.id) : null;
      const priceSummaryItemPrice = priceSummaryItem ? priceSummaryItem.price : null;
      const priceSummaryItemSubsDiscount = priceSummaryItemPrice ? priceSummaryItemPrice.getSubscriptionDiscount() : null;
      if (ProductContractsService.isSubscriptionContract(item.contractType)) {
        // if (item.isSubscription()) {
        if (!item.subscriptionPrice) return;
        const subscriptionPrice = PriceCalculation.fromJSON(item.subscriptionPrice);
        itemFees = Number(subscriptionPrice.fees_interval[0].amount) * quantity;
        itemTaxes = Number(subscriptionPrice.taxes_interval[0].amount) * quantity;
        const itemBasePrice = Number(subscriptionPrice.price_interval[0].amount) * quantity;
        itemPrice = itemBasePrice - (itemFees + itemTaxes);
        nextPayments += Number(itemBasePrice);

        // Calculate the periods with discount, note only one item supported\
        if (priceSummary && priceSummaryItemSubsDiscount && priceSummaryItemPrice.price_interval.length > 0) {
          const maxCount = priceSummaryItemSubsDiscount.getSubscriptionMaxCount();
          if (maxCount > 1) {
            // Set the discounted price from the PriceSummary Item
            const isDiscountWholeDuration = priceSummaryItemPrice.isSubscriptionDiscountWholeDuration();
            nextPaymentsDiscounted = {
              amount: priceSummaryItemPrice.price_interval[0].amount,
              periods: item.isReserved && item.reservePrice ? maxCount : maxCount - 1,
              full: isDiscountWholeDuration,
            };
          }
        }

        // Data from discount
        const hasDepositDiscount = priceSummaryItem && priceSummaryItem.price && priceSummaryItem.price.hasDepositDiscount();
        const deposit = hasDepositDiscount ? priceSummaryItem.price.getDeposit() : subscriptionPrice.getDeposit();
        // Total has to be updated when there is a  discount 
        if (item.isReserved && item.reservePrice) {
          reservations += Number(item.reservePrice.amount) * quantity;
          if (hasDepositDiscount) {
            const depositOriginal = Number(subscriptionPrice.getDeposit().amount) * quantity;
            const discountedDeposit = Number(deposit.amount) * quantity;
            total += discountedDeposit; 
            deposits += depositOriginal; // Add the original to display it
            // deposit = subscriptionPrice.getDeposit();
          } else {
            // Dont show the deposit now
            futureDeposits += Number(deposit.amount) * quantity;
          }
          if (priceSummaryItemPrice) {
            total += (-Number(priceSummaryItemPrice.getBasePrice().amount) + Number(item.reservePrice.amount)) * quantity;
          }
        } else {
          fees += itemFees;
          taxes += itemTaxes;
          subTotal += itemPrice;

          if (deposit) {
            deposits += Number(deposit.amount) * quantity;
            if (priceSummaryItemPrice) total += Number(deposit.amount) * quantity;
          }
        }
        // if (priceSummary) {
        //   console.log(
        //     "rersev ",
        //     JSON.stringify(Number(priceSummaryItemPrice ? priceSummaryItemPrice.getBasePrice().amount : subscriptionPrice.getBasePrice().amount) * quantity)
        //   );
        //   total += -(Number(priceSummaryItemPrice ? priceSummaryItemPrice.getBasePrice().amount : subscriptionPrice.getBasePrice().amount) * quantity);
        // }
      } else {
        if (item.isReserved && item.reservePrice) {
          reservations += Number(item.reservePrice.amount) * quantity;
          if (priceSummaryItemPrice) {
            total += (-Number(priceSummaryItemPrice.getBasePrice().amount) + Number(item.reservePrice.amount)) * quantity;
          }
          // if (priceSummary) {
          //   console.log('sale ', JSON.stringify((Number(priceSummaryItemPrice ? priceSummaryItemPrice.getBasePrice().amount : item.salePrice.final_price.amount) * quantity)));
          //   // total += -(Number(priceSummaryItemPrice ? priceSummaryItemPrice.getBasePrice().amount : item.salePrice.final_price.amount) * quantity);
          // }
          // subTotalSales += Number(item.reservePrice.amount);
        } else {
          itemFees = Number(item.salePrice.fees_total.amount) * quantity;
          itemTaxes = Number(item.salePrice.taxes_total.amount) * quantity;
          itemPrice = Number(item.salePrice.final_price.amount) * quantity - (itemFees + itemTaxes);

          feesSales += itemFees;
          taxesSales += itemTaxes;
          subTotalSales += itemPrice;
        }
      }
    });

    // total = subTotal + subTotalSales + fees + deposits + taxes + feesSales + taxesSales - (values.discount || 0);

    // const discountSummary = !values.discount ? null : values.discount instanceof PriceSummary ? values.discount : PriceSummary.fromJSON(values.discount);

    if (values.discount && values.discount.price) {
      // const priceSummary = PriceSummary.fromJSON(values.discount);
      // total = Number(values.discount.price.final_price.amount) + deposits;
      // total += deposits;
    } else {
      total = subTotal + subTotalSales + fees + deposits + taxes + feesSales + taxesSales + reservations;
    }

    setValue("subTotal", subTotal);
    setValue("subTotalSales", subTotalSales);
    setValue("totalItems", totalItems);
    setValue("billing", values.activeStep === 1 ? null : values.billing);
    setValue("discount", values.discount ? values.discount : null);
    setValue("shipping", values.items.length ? values.shipping : 0);
    setValue("total", total);
    setValue("fees", fees);
    setValue("deposits", deposits);
    setValue("futureDeposits", futureDeposits);
    setValue("reservations", reservations);
    setValue("nextPayments", nextPayments);
    setValue("nextPaymentsDiscounted", nextPaymentsDiscounted);
    setValue("taxes", taxes);
    setValue("feesSales", feesSales);
    setValue("taxesSales", taxesSales);
  }, [
    values.items,
    values.activeStep,
    values.billing,
    values.discount,
    values.shipping,
    values.subTotal,
    //
    values.deposits,
    values.futureDeposits,
    values.reservations,
    values.fees,
    values.taxes,
    values.feesSales,
    values.taxesSales,
    values.subTotalSales,
    values.totalItems,
    values.total,
    values.nextPayments,
    values.nextPaymentsDiscounted?.amount,
    values.nextPaymentsDiscounted?.periods,
    values.nextPaymentsDiscounted?.full,
  ]);

  useEffect(() => {
    onGetCart();
  }, [onGetCart]);

  const completed = values.activeStep >= PRODUCT_CHECKOUT_STEPS.length;

  // Use in productsdetailsummary
  // onAddCart({
  //   ...{ quantity: 1 },
  //   ...productListing,
  //   product: product,
  //   contractType: accordionExpanded === PANEL_SUBSCRIPTION ? CONTRACT_TYPES_MAP.subscription.enum : CONTRACT_TYPES_MAP.sale.enum,
  //   // subscriptionPrice: DiscountsFeesService.extractSubscriptionPrice(backendProductPrices, convertMonthsToDays(values.contractDuration)),
  //   subscriptionPrice: subscriptionPrice, // Selected subscription price
  //   subscriptionDuration: data.contractDuration, // In months
  //   salePrice: backendProductPrices.sale_price[0], // Sale price
  //   allPrices: backendProductPrices, // All contract config prices
  //   contractId: productContract.id,
  //   subsConfig: subsConfig, // All sub prices
  //   saleConfig: saleConfig, // ALl sale price config
  //   // subTotal: data.price * data.quantity,
  // });
  const onAddToCart = useCallback(
    (newItem) => {
      let itemExisted = false;
      const updatedItems = values.items.map((item) => {
        if (String(item.id) === String(newItem.id) || String(item.product.id) === String(newItem.product.id)) {
          // No quantity for items supported
          itemExisted = true;
          return newItem;
          // return {
          //   ...newItem,
          //   // colors: uniq([...item.colors, ...newItem.colors]),
          //   // quantity: item.quantity + 1,
          // };
        }
        return item;
      });

      if (!itemExisted) {
        updatedItems.push(newItem);
      }
      setValue("items", updatedItems);
      setValue("discount", null);
    },
    [setValue, values.items]
  );

  const onDeleteCart = useCallback(
    (itemId) => {
      const updatedItems = values.items.filter((item) => item.id !== itemId);
      setValue("discount", null);
      setValue("items", updatedItems);
    },
    [setValue, values.items]
  );

  const onBackStep = useCallback(() => {
    setValue("activeStep", values.activeStep - 1);
  }, [setValue, values.activeStep]);

  const onNextStep = useCallback(() => {
    // console.log("onNextStep");
    if (!completed) setValue("activeStep", values.activeStep + 1);
  }, [setValue, values.activeStep, completed]);

  const onGotoStep = useCallback(
    (step) => {
      setValue("activeStep", step);
    },
    [setValue]
  );

  const onIncreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = values.items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });

      setValue("items", updatedItems);
    },
    [setValue, values.items]
  );

  const onDecreaseQuantity = useCallback(
    (itemId) => {
      const updatedItems = values.items.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });

      setValue("items", updatedItems);
    },
    [setValue, values.items]
  );

  const onCreateBilling = useCallback(
    (address) => {
      setValue("billing", address);

      onNextStep();
    },
    [onNextStep, setValue]
  );

  const onApplyDiscount = useCallback(
    (discount) => {
      setValue("discount", discount);
    },
    [setValue]
  );

  const onApplyShipping = useCallback(
    (shipping) => {
      setValue("shipping", shipping);
    },
    [setValue]
  );

  const onSetPaymentStatus = useCallback(
    (value) => {
      setValue("status", value.status);
      setValue("orderId", value.orderid);
    },
    [setValue]
  );

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      setValues(initialState);
      router.replace(PATH_DASHBOARD.general.root.path);
    }
  }, [completed, router, setValues]);

  const memoizedValue = useMemo(
    () => ({
      ...values,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onCreateBilling,
      onApplyDiscount,
      onApplyShipping,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
      //
      onSetPaymentStatus,
    }),
    [
      completed,
      onAddToCart,
      onApplyDiscount,
      onApplyShipping,
      onBackStep,
      onCreateBilling,
      onDecreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onIncreaseQuantity,
      onNextStep,
      onReset,
      onSetPaymentStatus,
      values,
    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}

CheckoutProvider.propTypes = {
  children: PropTypes.node,
};
