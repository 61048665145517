/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function BaseLayoutWrapper({ breadcrumb, title, children, backgroundImage, transparentNavbar , marginTop = {xs: 0, md: 10} }) {
  return (
    <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
      <MKBox position={transparentNavbar ? "" : "fixed"} top="0.75rem" width="100%" style={{ zIndex: "1" }}>
        <DefaultNavbar
          transparent={transparentNavbar}
          routes={routes}
          action={{
            type: "internal",
            route: "/login",
            label: "Login",
            color: "info",
          }}
          relative
        />
      </MKBox>

      <Container sx={{ mt: marginTop }}>
        <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          {breadcrumb && (
            <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
              <Breadcrumbs routes={breadcrumb} />
            </MKBox>
          )}
          {title && title.length > 0 && (
            <MKTypography variant="h3" mb={1}>
              {title}
            </MKTypography>
          )}
          {children}
        </Grid>
      </Container>
      <MKBox mt="auto">
        <CenteredFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

// // Typechecking props for the BaseLayout
// BaseLayoutWrapper.propTypes = {
//   // breadcrumb: PropTypes.arrayOf(PropTypes.object).isRequired,
//   // title: PropTypes.string.isRequired,
//   // children: PropTypes.node.isRequired,
// };

export default BaseLayoutWrapper;
