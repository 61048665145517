import { setPriority } from "os";
import { convertToFrontendHashMap, getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";

// Helper function to convert product type HashMap to localized string
const convertProductTypeToLocalizedString = (productType) => {
  // Assuming `productType` is an object like { en: "Type", es: "Tipo" }
  const lang = "en"; // or dynamically determine the language
  return productType[lang] || productType[Object.keys(productType)[0]]; // Fallback to the first available language
};

// Tranlation keys
export const PRODUCT_CATEGORIES = {
  Instruments: "Instruments",
  Accessories: "Accessories",
};

const DEFAULT_LANG = "en";

export default class ProductDefinition {
  constructor(id, product_type, category, sizes, compatible_products, currentLang = DEFAULT_LANG, ...otherProps) {
    this.current_lang = currentLang;
    this.id = id;
    this.setProductType(product_type);
    // this.product_type = product_type;
    // this.product_type_map = convertToFrontendHashMap(product_type);
    // this.product_type_localized = this.getProductTypeLocalized(currentLang);
    this.category = category;
    this.sizes = sizes;
    this.compatible_products = compatible_products;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(productDefinitionJSON, currentLang = DEFAULT_LANG) {
    if (!productDefinitionJSON) return null;
    // Extract known properties and include the rest in otherProps
    const { id, product_type, category, sizes, compatible_products, ...otherProps } = productDefinitionJSON;
    // Return a new instance of ProductDefinition including any additional properties
    return new ProductDefinition(id, product_type, category, sizes, compatible_products, currentLang, otherProps);
  }

  clone() {
    // Creating a new instance of ProductDefinition with the same properties
    const clonedProductDefinition = new ProductDefinition(
      this.id,
      this.product_type, // Deep clone the product_type object
      this.category,
      [...this.sizes], // Deep clone the sizes array
      [...this.compatible_products] // Deep clone the compatible_products array
    );
    // Copy any additional properties that were dynamically added to the original instance
    Object.keys(this).forEach((key) => {
      if (!clonedProductDefinition.hasOwnProperty(key)) {
        clonedProductDefinition[key] = this[key];
      }
    });
    return clonedProductDefinition;
  }

  // Setter methods for properties
  setProductType(value) {
    this.product_type = value;
    this.product_type_map = convertToFrontendHashMap(value);
    this.product_type_localized = this.getProductTypeLocalized(this.current_lang);
    if (!this.product_type_localized) {
      this.product_type_localized = this.getDefaultProductType();
    }
  }

  // // Getter methods for properties

  // get productType() {
  //   return convertProductTypeToLocalizedString(this.product_type);
  // }

  // Method to add a compatible product
  getDefaultProductType() {
    return this.product_type_map[DEFAULT_LANG];
  }
  getProductTypeLocalized(language) {
    if (this.product_type_localized) return this.product_type_localized;
    const languageToUse = language ? (language.value ? language.value : language) : this.current_lang;
    const productType = this.product_type_map[languageToUse];
    return productType;
  }

  getProductTypesString() {
    if (!this.product_type) {
      return "";
    }
    let inputString = "";

    for (const [key, value] of this.product_type) {
      // const value = hashMap[key];
      inputString += `${key}=${value}; `;
    }

    return inputString.trim();
  }

  static createProductTypeFromString(inputString) {
    const keyValuePairs = inputString.split(";"); // Split input string by a delimiter, e.g., ';'
    const hashMap = [];
    keyValuePairs.forEach((pair) => {
      if (pair && pair.length > 0) {
        const [key, value] = pair.split("="); // Split each key-value pair by a separator, e.g., '='
        // hashMap[key.trim()] = value.trim(); // Trim whitespace and assign key-value pair to the object
        hashMap.push([key.trim(), value.trim()]);
      }
    });

    return hashMap;
  }

  // Method to remove a compatible product
  removeCompatibleProduct(productId) {
    this.compatible_products = this.compatible_products.filter((id) => id !== productId);
  }

  getCategory(toPrint = true) {
    return toPrint ? getEnumVariableText(this.category) : this.category;
  }

  static getProductTypeLocalized(type, currentLanguage) {
    const hashMap = convertToFrontendHashMap(type);
    const languageToUse = currentLanguage ? (currentLanguage.value ? currentLanguage.value : currentLanguage) : DEFAULT_LANG;
    const productType = hashMap[languageToUse];
    return productType;
  }

  static getProductTypeToDefMap(definitions) {
    const productTypeMap = new Map();
    definitions.forEach((definition) => {
      const localizedProductType = definition.getProductTypeLocalized(definition.current_lang);
      productTypeMap.set(localizedProductType, definition);
    });
    return productTypeMap;
  }

  static getDefByType(definitionsMap, type) {
    return definitionsMap.get(type) || null;
  }
}
