import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import { convertToFrontendHashMap } from "../../utils/typeConverters";
import ProductsService from "../../../services/ProductsService";
import ProductDefinition from "model/marketplace/ProductDefinition";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  productDefinitions: [],
  productDefinition: null,
  lang: "en",
};

const slice = createSlice({
  name: "productDefinition",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS DEFS
    getProductDefinitionsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length === 0) {
        state.productDefinitions = [];
        return;
      }

      // console.log("currentLang " + JSON.stringify(currentLang) + " state " + JSON.stringify(state.currentLang));
      state.productDefinitions = action.payload
        .sort((a, b) => a.product_type_localized.localeCompare(b.product_type_localized));
    },

    // GET PRODUCT DEF
    getProductDefinitionSuccess(state, action) {
      state.isLoading = false;
      state.productDefinition = ProductDefinition.fromJSON(action.payload, state.currentLang);
    },

    deleteProductDefinition(state, action) {
      state.isLoading = false;
      const updateProductDefinitions = state.productDefinitions.filter(
        (product) => product.id !== action.payload
      );

      state.productDefinitions = updateProductDefinitions;
    },

    setCurrentLang(state, action) {
      state.lang = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProductDefinitions(symphy_backend, lang) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if (lang) dispatch(slice.actions.setCurrentLang(lang.value));
    // try {
      const currentLang = lang ? lang.value : null;
      let response = await ProductsService.getProductDefinitions(symphy_backend);
      if (response && currentLang) {
        response = response
          .map((definition) => ProductDefinition.fromJSON(definition, currentLang));
      }
      dispatch(slice.actions.getProductDefinitionsSuccess(response));
    // } catch (error) {
      // console.log(error);
      // dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------

export function getProductDefinition(symphy_backend, id, currentLang) {
  return async () => {
    dispatch(slice.actions.startLoading());
    if(currentLang)
      dispatch(slice.actions.setCurrentLang(currentLang));
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductsService.getProductDefinition(symphy_backend, BigInt(id));
      dispatch(slice.actions.getProductDefinitionSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProductDefinition(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await ProductsService.deleteProductDefinition(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteProductDefinition(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

