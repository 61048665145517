import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import ProductsService, { TYPE_ADMIN, TYPE_SELLER_STORED, TYPE_SELLER_TO_VERIFY, TYPE_USER } from "services/ProductsService";
import UserService from "services/UserService";
import ProductContractsService, { CONTRACT_STATUS_MAP } from "../../../services/ProductContractsService";
import Product from "model/marketplace/Product";
import { getEnumVariableText, getUniqueFieldValues, mergeArrays, removeArrayDuplicates } from "src/utils/typeConverters";
import { getProductSubContractsResponse } from "./productContracts";
import PaymentsService from "services/PaymentsService";
import UserSummary from "model/users/UserSummary";
import TransactionWithItems from "model/payments/TransactionWithItems";
import { ProductSubContract } from "model/contracts/ProductSubContract";
// ----------------------------------------------------------------------

export const DEFAULT_PRICE_RANGE = [0, 200];
export const PRICE_RANGE_TEXT = "priceRange";
export const PRODUCT_DEFINITION_TEXT = "productDefinition";
export const DEFAULT_PRODUCT_SIZE = "any";
export const PRODUCT_SIZE_TEXT = "sizes";

export const KEY_LOCATION = "location";
export const KEY_CONTRACT_DURATION = "contractDuration";

// TODO move to product contracts
export const DURATIONS = [
  { name: "all", value: 0 },
  { name: "3", value: 3 },
  { name: "6", value: 6 },
  { name: "12", value: 12 },
];
export const DEFAULT_CONTRACT_DURATION = DURATIONS.at(0);


const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  recommendedContracts: [],
  recommendedContract: null,
  filters: {
    location: null,
    productDefinition: null,
    priceRange: DEFAULT_PRICE_RANGE,
    sizes: [DEFAULT_PRODUCT_SIZE],
    contractDuration: DEFAULT_CONTRACT_DURATION,
  },
  create: {
    activeStep: 0,
  },
  // shops: [],
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // RESET STATE
    resetState(state) {
      return initialState;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // --------------- PRODUCTS ---------------

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.products = action.payload.map((item) => Product.fromJSON(item));
      } else {
        state.products = [];
      }
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = Product.fromJSON(action.payload);
    },

    deleteProduct(state, action) {
      state.isLoading = false;
      const updatedProducts = state.products.filter((product) => product.id !== action.payload);

      state.products = updatedProducts;
    },

    updateProduct(state, action) {
      state.isLoading = false;
      const index = state.products.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.products[index] = action.payload;
      }
    },

    addProduct(state, action) {
      state.isLoading = false;
      const length = state.products.push(Product.fromJSON(action.payload));
    },

    filterProducts(state, action) {
      state.filters.location = action.payload.location;
      state.filters.productDefinition = action.payload.productDefinition;
      state.filters.sizes = action.payload.sizes;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.contractDuration = action.payload.contractDuration;
    },

    // --------------- END PRODUCTS ---------------

    // --------------- PRODUCTS RECOMMENDED CONTRACTS---------------

    // GET RECOMMENDED PRODUCT CONTRACTS
    getRecommendedContractsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length === 0) {
        state.recommendedContracts = [];
        return;
      }
      state.recommendedContracts = action.payload;
    },

    // GET PRODUCT
    getRecommendedContractSuccess(state, action) {
      state.isLoading = false;
      state.recommendedContract = action.payload;
    },

    deleteRecommendedContract(state, action) {
      state.isLoading = false;
      const updatedRecommendedContracts = state.recommendedContracts.filter((recommendedContract) => recommendedContract.id !== action.payload);

      state.recommendedContracts = updatedRecommendedContracts;
    },

    updateRecommendedContract(state, action) {
      state.isLoading = false;
      const index = state.recommendedContracts.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.recommendedContracts[index] = action.payload;
      }
    },

    addRecommendedContract(state, action) {
      state.isLoading = false;
      const length = state.recommendedContracts.push(action.payload);
    },

    // getShopsSuccess(state, action) {
    //   // state.isLoading = false;
    //   state.shops = action.payload;
    // },

    // --------------- END PRODUCT RECOMMENDED CONTRACTS ---------------

    resetStep(state) {
      state.create.activeStep = 0;
    },

    backStep(state) {
      state.create.activeStep -= 1;
    },

    nextStep(state) {
      state.create.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.create.activeStep = step;
    },
  },
});

// Reducer
export default slice.reducer;
export const { resetState: resetProductsState } = slice.actions;

// Actions
export const {
  resetStep,
  gotoStep,
  backStep,
  nextStep,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(symphy_backend, type, withContracts = false, user, productIds, storeListings, listener, fromSubcontracts=false, addMaintenanceTransactions=false, shopId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    let response = null;
    let userIds;
    if (type === TYPE_SELLER_STORED) {
      // Get the listings
      response = await ProductContractsService.getStoreListings(symphy_backend, null, shopId);
      if (response.length > 0) {
        productIds = getUniqueFieldValues(response, "product_id");

        // TODO support to multiple shops
        response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);

        // Change to item with .store_listing
        response = ProductsService.convertFromListingToProduct(response);

      }
    } else {
      response = await ProductsService.getProducts(symphy_backend, type, productIds);
      productIds = getUniqueFieldValues(response, "id");
    }
      userIds = getUniqueFieldValues(response, "owner_id");
      if (withContracts && response.length > 0 && productIds.length > 0) {
        response = await ProductContractsService.addContractsToItems(symphy_backend, response, productIds);
        // Extract userIds from all subcontracts
        const subcontractUserIds = response
          .flatMap((product) => product.contracts ?? []) // Flatten contracts if they exist
          .flatMap((contract) => contract.subcontracts ?? []) // Flatten subcontracts if they exist
          .map((subcontract) => subcontract.user_id) // Extract user_id
          .filter(Boolean); // Filter out any undefined values

        if (subcontractUserIds.length > 0) {
          userIds = removeArrayDuplicates([...userIds, ...subcontractUserIds]);
        }

        if (type === TYPE_SELLER_STORED) {
          // For seller stored, remove items that have active subcontract
          response = response.filter((product) => {
            const currentSubscriptionSubcontract = product.contracts
              ? ProductContractsService.getCurrentContractSubscription(product.contracts)
              : product.subcontract;
            const hasSubContract = !!currentSubscriptionSubcontract;
            return !currentSubscriptionSubcontract || getEnumVariableText(currentSubscriptionSubcontract.contract_status) !== CONTRACT_STATUS_MAP.active.name;
          });
        }
      }

      // Retrieve products linked to a subcontarct: i.e. rented
      if (fromSubcontracts) {
        const subcontractsResponse = await getProductSubContractsResponse(symphy_backend, null, null, user.user_id, true, response, false, true, false);
        // Filter out productSubContracts where product is not null
        // TODO product should not be null
        // TODO a user can have multiple subcontracts per product, remove the non active ones
        if (subcontractsResponse) {
          // const validProductSubContracts = subcontractsResponse.filter((subContract) => subContract.product !== null && subContract.isSubscription());
          const validProductSubContracts = subcontractsResponse.filter((subContract) => subContract.product !== null);
          const contractUserIds = getUniqueFieldValues(validProductSubContracts, "user_id");
          if (contractUserIds.length > 0) {
            userIds = removeArrayDuplicates([...userIds, ...contractUserIds]);
          }
          let productsFromSubcontracts = ProductsService.convertFromSubcontractToProduct(validProductSubContracts);
          if (productsFromSubcontracts.length > 0) {
            const productIdsFromSubcontracts = getUniqueFieldValues(subcontractsResponse, "product_id");
            productIds = removeArrayDuplicates([...productIds, ...productIdsFromSubcontracts]);
          }
          // Remove duplicates
          response = mergeArrays(response, productsFromSubcontracts);
        }
      }

      if (type !== TYPE_SELLER_STORED && storeListings && response.length > 0) {
        const retrieveListings = !Array.isArray(storeListings);
        response = await ProductsService.addStoreListingToProducts(symphy_backend, response, productIds, !retrieveListings ? true : false);
      }
      if (addMaintenanceTransactions && response.length > 0) {
        // TODO user id
        // Add maintenance transactions
        const filter = {
          transaction_ids: null,
          product_ids: productIds,
          subcontract_ids: null,
          user_id: null,
          shop_id: null,
          service_names: [{ name: "Maintenance" }],
          // service_names: [],
          with_payment_intents: true,
        };
        const transactions =
          type === TYPE_USER
            ? await PaymentsService.getMyTransactions(symphy_backend, filter)
            : type === TYPE_SELLER_STORED || type === TYPE_SELLER_TO_VERIFY
            ? await PaymentsService.getSellerTransactions(symphy_backend, filter)
            : await PaymentsService.getTransactions(symphy_backend, filter);

        if (transactions && transactions.length > 0) {
          response = PaymentsService.mapTransactionsToItems(response, transactions);
        }
      }

      if (userIds && response.length > 0) {
        if (type === TYPE_ADMIN || type === TYPE_SELLER_TO_VERIFY || type === TYPE_SELLER_STORED) {
          // Add 'user' property to each item
          response = await UserService.addUserSummaryToItems(symphy_backend, response, userIds, true);
        } else if (user) {
          const currentUserSummary = UserSummary.fromUser(user);
          // Add 'user' property to each item
          response = response.map((item) => {
            return { ...item, user: currentUserSummary };
          });
        }
      }
      if (listener) {
        listener();
      }
      dispatch(slice.actions.getProductsSuccess(response));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}


export function getPlayerProducts(symphy_backend, withContracts = false, withListings = false, withShops = false, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      let response = await ProductsService.getPlayerProducts(symphy_backend);
      if (response.length > 0 && (withContracts || withListings)) {
        let productIds = response.map(product => product.id);
        if (withContracts) {
          response = await ProductContractsService.addContractsToItems(symphy_backend, response, productIds);
          if (withShops) {
            // Add shops from the contracts and subcontracts
            let shopIds = ProductContractsService.getProductContractsShopIds(response);
            // let searchFilter = UserService.createShopSearchFilter({ shopIds: shopIds });
            response = await UserService.addShopsToItems(symphy_backend, response, shopIds, { toSubcontracts: false, isTransaction: false });
            // let searchFilter = UserService.createShopSearchFilter({ shopIds: shopIds });
            // dispatch(slice.actions.getShopsSuccess(shopResponse));
          }
          // console.log("getPlayerProducts " + JSON.stringify(response));
        }
        if (withListings) {
          response = await ProductContractsService.addStoreListingsToItems(symphy_backend, response, productIds);
          // console.log("getPlayerProducts " + JSON.stringify(response));
        }
      }
      if (listener) {
        listener();
      }
      dispatch(slice.actions.getProductsSuccess(response));
    // } catch (error) {
    //   console.log("getPlayerProducts error " + JSON.stringify(error));
    //   dispatch(slice.actions.hasError(error));
    //   if (listener) {
    //     listener({error: error});
    //   }
    // }
  };
}

// ----------------------------------------------------------------------

export function getProduct(symphy_backend, id, addItems={transactions: {all: false}, contracts: false, listing: false, user: false, fromSubcontracts: false, nestedProducts: false, nestedSubcontracts: false}, currentUser, isAdminUser, type ) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    // eslint-disable-next-line no-undef
      const typeVerify = type === TYPE_ADMIN || type === TYPE_SELLER_TO_VERIFY || type === TYPE_SELLER_STORED;
      let product = await ProductsService.getProduct(symphy_backend, BigInt(id));
      if (product) {
        let userIds = [product.owner_id];
        const isOwnUser = currentUser && product.owner_id.toString() === currentUser.user_id.toString();
        if (addItems) {
          if (addItems.transactions) {
            product = await addTransactionToProduct(symphy_backend, product, addItems.transactions.all, currentUser, isAdminUser, addItems.nestedProducts, addItems.nestedSubcontracts);
          }
          if (addItems.contracts) {
            product = await ProductContractsService.addContractsToItems(symphy_backend, [product], [BigInt(id)]);
            if (typeVerify) {
              const subcontractUserIds = product
                .flatMap((product) => product.contracts ?? []) // Flatten contracts if they exist
                .flatMap((contract) => contract.subcontracts ?? []) // Flatten subcontracts if they exist
                .map((subcontract) => subcontract.user_id) // Extract user_id
                .filter(Boolean); // Filter out any undefined values
              if (subcontractUserIds.length > 0) {
                userIds = removeArrayDuplicates([...userIds, ...subcontractUserIds]);
              }
            }

            if (type === TYPE_SELLER_STORED) {
              // For seller stored, remove items that have active subcontract
              product = product.filter((product) => {
                const currentSubscriptionSubcontract = product.contracts
                  ? ProductContractsService.getCurrentContractSubscription(product.contracts)
                  : product.subcontract;
                const hasSubContract = !!currentSubscriptionSubcontract;
                return (
                  !currentSubscriptionSubcontract || getEnumVariableText(currentSubscriptionSubcontract.contract_status) !== CONTRACT_STATUS_MAP.active.name
                );
              });
            }
          }

          // Retrieve products linked to a subcontarct: i.e. rented
          // This actually changes the product by the one from the subcontract, so fix it
          if (addItems.fromSubcontracts && currentUser) {
            const productToUse = product.length > 0 ? product[0] : product;
            const subcontractsResponse = await getProductSubContractsResponse(
              symphy_backend,
              null,
              null,
              currentUser.user_id,
              true,
              [productToUse],
              false,
              // () => {},
              true,
              false,
              [BigInt(productToUse.id)]
            );
            // Filter out productSubContracts where product is not null
            if (subcontractsResponse) {
              const validProductSubContracts = subcontractsResponse
                .map((subcontract) => ProductSubContract.fromJSON(subcontract))
                .filter(
                (subContract) => {
                  return String(subContract.product_id) === String(productToUse.id) && subContract.product !== null;
                                  // return String(subContract.product_id) === String(productToUse.id) && subContract.product !== null && subContract.isSubscription();
                  }
              );

              let productsFromSubcontracts = ProductsService.convertFromSubcontractToProduct(validProductSubContracts);

              if (productsFromSubcontracts.length > 0) {
                const responseToUse = productToUse;
                const { subcontract, ...restProductFields } = productsFromSubcontracts[0];
                product = { ...responseToUse, ...restProductFields, subcontract: subcontract };
              }
            }
          }
          if (addItems.listing) {
            product = await ProductsService.addStoreListingToProducts(symphy_backend, product, [BigInt(id)]);
          }
          if (addItems.user) {
            if (isOwnUser) {
              const currentUserSummary = UserSummary.fromUser(currentUser);
              // Add 'user' property to each item
              product = product.map((item) => {
                return { ...item, user: currentUserSummary };
              });
            } else if ((type === TYPE_ADMIN && (!currentUser || !isOwnUser)) || typeVerify) {
              // Add 'user' property to each item
              console.log("product", JSON.stringify(product));
              product = await UserService.addUserSummaryToItems(symphy_backend, Array.isArray(product) ? product : [product], userIds, true);
            } 
          }
        }
      }
      dispatch(slice.actions.getProductSuccess(product && product.length > 0 ? product[0] : product));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export async function addTransactionToProduct(symphy_backend, product, allTransactions, currentUser, isAdminUser, nestedProducts = false, nestedSubcontracts=false) {
  let newProduct = product;
  // eslint-disable-next-line no-undef
  if (product) {
    const filter = {
      transaction_ids: null,
      product_ids: [BigInt(product.id)],
      subcontract_ids: null,
      // user_id: allTransactions ? null : product.owner_id,
      shop_id: null,
      service_names: allTransactions ? null : [{ name: "Valuation" }],
      // service_names: [],
      with_payment_intents: true,
      add_subcontracts: nestedSubcontracts,
    };
    // const isOwnUser = currentUser && product.owner_id.toString() === currentUser.user_id.toString();
    let transactions = isAdminUser
      ? await PaymentsService.getTransactions(symphy_backend, filter)
      : await PaymentsService.getMyTransactions(symphy_backend, filter);
    
    if (transactions && transactions.length > 0) {
      if (nestedProducts) {
        const uniqueProductIds = TransactionWithItems.getItemsProductIds(transactions);
        // if (uniqueProductIds.length > 1 || uniqueProductIds[0] !== BigInt(product.id)) {
          transactions = await ProductsService.addProductsToItems(symphy_backend, transactions, uniqueProductIds);
        // }
        // transactions = transactions.map((transaction) => { if(transaction.return { ...transaction, product: product }; });

      }
      const responseWithTransaction = PaymentsService.mapTransactionsToItems([product], transactions);

      if (responseWithTransaction && responseWithTransaction.length > 0) {
        newProduct = responseWithTransaction[0];
      }
    }
  }
  return newProduct;
}


export function refreshProduct(item, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.getProductSuccess(item));
    if (listener) {
      listener();
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProduct(symphy_backend, product, deleteChildren=false, isAdmin=false, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      // eslint-disable-next-line no-undef
    // await ProductsService.deleteProduct(symphy_backend, BigInt(product.id));
    if (deleteChildren) {
        if (product.store_listing && product.store_listing.length) {
          await ProductContractsService.deleteStoreListing(symphy_backend, BigInt(product.store_listing[0].id));
        }
        // Delete transactions with intents
        if (isAdmin) {
          const filter = {
            transaction_ids: null,
            product_ids: [BigInt(product.id)],
            subcontract_ids: null,
            user_id: null,
            shop_id: null,
            service_names: [],
            // service_names: [],
            with_payment_intents: false,
          };
          const transactions = await PaymentsService.getTransactions(symphy_backend, filter);
          if (transactions && transactions.length > 0) {
            for (let transaction of transactions) {
              // console.log("delete transaction", JSON.stringify(transaction.id));
              await PaymentsService.deleteTransaction(symphy_backend, BigInt(transaction.id), true);
            }
          }
        }
        if (product.contracts && product.contracts.length > 0) {
          for (let contract of product.contracts) {
            // Delete contract with subs if admin
            // console.log("delete contract", JSON.stringify(contract.id));
            await ProductContractsService.deleteProductContract(symphy_backend, BigInt(contract.id), isAdmin);
          }
        }
    }
    await ProductsService.deleteProduct(symphy_backend, BigInt(product.id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteProduct(BigInt(product.id)));
      if (listener) {
        listener();
      }
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}


export function updateProduct(symphy_backend, product, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    // console.log("Updating product a" + product.id);
    // eslint-disable-next-line no-undef
    const response = await ProductsService.updateProduct(symphy_backend, product);

    // eslint-disable-next-line no-undef
    dispatch(slice.actions.updateProduct(response));
    if (isCurrent) {
      dispatch(slice.actions.getProductSuccess(response));
    }
    if (listener) {
      listener(response);
    }
    // } catch (error) {
    //   console.log("Error updating " + error);
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function addProduct(symphy_backend, product, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      // eslint-disable-next-line no-undef
      const response = await ProductsService.addProduct(symphy_backend, product);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.addProduct(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductSuccess(response));
      }
      if (listener) {
        listener(response);
      }
    // } catch (error) {
    //   console.log("Error adding " + JSON.stringify(error) + " " + JSON.stringify(product));
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}


// --------------- PRODUCTS RECOMMENDED CONTRACTS---------------

export function getRecommendedContracts(symphy_backend) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductContractsService.getRecommendedContracts(symphy_backend);
      dispatch(slice.actions.getRecommendedContractsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getRecommendedContract(symphy_backend, id) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // eslint-disable-next-line no-undef
//       const response = await ProductsService.getRecommendedContract(symphy_backend, BigInt(id));
//       dispatch(slice.actions.getRecommendedContractSuccess(response));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function deleteRecommendedContract(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await ProductContractsService.delete_product_recommended_contracts(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteRecommendedContract(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateRecommendedContract(symphy_backend, product, isCurrent, listener) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // eslint-disable-next-line no-undef
//       const response = await ProductContractsService.updateProduct(symphy_backend, product);
//       // eslint-disable-next-line no-undef
//       dispatch(slice.actions.updateRecommendedContract(response));
//       if (isCurrent) {
//         dispatch(slice.actions.getRecommendedContractSuccess(response));
//       }
//       if (listener) {
//         listener();
//       }
//     } catch (error) {
//       console.log("Error updating " + error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function addRecommendedContract(symphy_backend, recommendedContract, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.addRecommendedContract(symphy_backend, recommendedContract);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.addRecommendedContract(response));
      if (isCurrent) {
        dispatch(slice.actions.getRecommendedContractSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error adding " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}