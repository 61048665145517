import { getEnumVariableText } from "src/utils/typeConverters";

export default class BackendService {
  static generateTestData = async ({ symphy_backend, all, users, products, marketplace, payments }) => {
    if (all) {
      await this.generateUserTestData(symphy_backend);
      // Wait 5 seconds for the change to propagate
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await this.generateProductsTestData({ symphy_backend: symphy_backend });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await this.generateMarketplaceTestData({ symphy_backend: symphy_backend });
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await this.generatePaymentsTestData(symphy_backend);
    } else {
      if (users) {
        await this.generateUserTestData(symphy_backend);
      }
      if (products) {
        await this.generateProductsTestData({ symphy_backend: symphy_backend });
      }
      if (marketplace) {
        await this.generateMarketplaceTestData({ symphy_backend: symphy_backend });
      }
      if (payments) {
        await this.generatePaymentsTestData(symphy_backend);
      }
    }
  };

  static resetTestData = async ({ symphy_backend, all, users, products, marketplace, payments }) => {
    if (all) {
      await this.resetUsersTestData(symphy_backend);
      await this.resetProductsTestData(symphy_backend);
      await this.resetMarketplaceTestData(symphy_backend);
      await this.resetPaymentsTestData(symphy_backend);
    } else {
      if (users) {
        await this.resetUsersTestData(symphy_backend);
      }
      if (products) {
        await this.resetProductsTestData(symphy_backend);
      }
      if (marketplace) {
        await this.resetMarketplaceTestData(symphy_backend);
      }
      if (payments) {
        await this.resetPaymentsTestData(symphy_backend);
      }
    }
  };

  static setUpAdmin = async (symphy_backend) => {
    await symphy_backend["users"].setup_current_user_admin({});
  };

  static generateUserTestData = async (symphy_backend) => {
    await symphy_backend["users"].generate_users_testdata({});
  };
  static generateProductsTestData = async ({ symphy_backend, defs = true, products = true }) => {
    const result = await symphy_backend["products"].generate_products_testdata({
      defs: defs,
      products: products,
    });
  };
  static generateMarketplaceTestData = async ({ symphy_backend, production = false }) => {
    await symphy_backend["marketplace"].generate_marketplace_testdata({
      recommended: true,
      requests: !production,
      contracts: !production,
      listings: !production,
      discounts: !production,
      fees: true,
    });
  };
  static generatePaymentsTestData = async (symphy_backend) => {
    await symphy_backend["payments"].generate_payments_testdata({});
  };

  static resetPaymentsTestData = async (symphy_backend) => {
    await symphy_backend["payments"].reset_payments_testdata({});
  };

  static resetMarketplaceTestData = async (symphy_backend) => {
    await symphy_backend["marketplace"].reset_marketplace_testdata({});
  };

  static resetProductsTestData = async (symphy_backend) => {
    await symphy_backend["products"].reset_products_testdata({});
  };

  static resetUsersTestData = async (symphy_backend) => {
    await symphy_backend["users"].reset_users_testdata({});
  };

  static isResponseSuccess = (response) => {
    return response.Success || getEnumVariableText(response) === "Success";
  };

  static isResponseError = (response) => {
    return !this.isResponseSuccess(response);
  };

  static mergeUsers = async (symphy_backend, oldUserId, newUserId) => {
    const responseUsers = await symphy_backend.users.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId });
    console.log("responseUsers", JSON.stringify(responseUsers));
    if (this.isResponseError(responseUsers)) return false;
    const responseProducts = await symphy_backend.products.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId });
    console.log("responseProducts ", JSON.stringify(responseProducts));
    if (this.isResponseError(responseProducts)) return false;
    const responseMarketplace = await symphy_backend.marketplace.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId });
    console.log("responseMarketplace ", JSON.stringify(responseMarketplace));
    if (this.isResponseError(responseMarketplace)) return false;
    const responsePayments = await symphy_backend.payments.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId });
    console.log("responsePayments ", JSON.stringify(responsePayments));
    if (this.isResponseError(responsePayments)) return false;
    return true;
    // if (this.isResponseError(await symphy_backend.users.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId }))) return false;
    // if (this.isResponseError(await symphy_backend.products.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId }))) return false;
    // if (this.isResponseError(await symphy_backend.marketplace.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId }))) return false;
    // if (this.isResponseError(await symphy_backend.payments.change_user_id({ old_user_id: oldUserId, new_user_id: newUserId }))) return false;
    // return true;
  };
}