import React, { createContext, useContext } from "react";
import { IS_DEV, IS_PROD } from "src/config-global";

const FacebookPixelContext = createContext();

export const useFacebookPixel = () => useContext(FacebookPixelContext);

// Standard Facebook Pixel Event Names
// PageView - Triggered when a user views a page on your website.
// ViewContent - Fired when a user views a particular page or product. Commonly used for product pages or content pages.
// Search - Triggered when a user makes a search on your site.
// AddToCart - Fired when a user adds a product to their shopping cart.
// AddToWishlist - Triggered when a user adds a product to their wishlist.
// InitiateCheckout - Fired when a user initiates the checkout process.
// AddPaymentInfo - Triggered when a user adds payment information, such as a credit card.
// Purchase - Fired when a purchase is completed.
// Lead - Triggered when a user expresses interest in your product or service, such as submitting a contact form or signing up for a trial.
// CompleteRegistration - Fired when a user completes a registration form on your site.
// Contact - Triggered when a user contacts your business through phone, SMS, chat, etc.
// CustomizeProduct - Fired when a user customizes a product (for example, choosing colors or sizes).
// Donate - Used for donations on non-profit websites.
// FindLocation - Triggered when a user searches for a physical location of your business.
// Schedule - Fired when a user books an appointment.
// StartTrial - Triggered when a user starts a trial of your service or product.
// SubmitApplication - Used for applications, such as job or credit card applications.
// Subscribe - Triggered when a user subscribes to a paid service.
const FacebookPixelProvider = ({ children }) => {
  // const pixelId = "1048965933634952";
  const isPixelInitialized = React.useRef(false);

  const initPixel = () => {
    // if (IS_DEV) return;
    if (!isPixelInitialized.current && !window.fbq) {
      // (function (f, b, e, v, n, t, s) {
      //   if (f.fbq) return;
      //   n = f.fbq = function () {
      //     n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      //   };
      //   if (!f._fbq) f._fbq = n;
      //   n.push = n;
      //   n.loaded = !0;
      //   n.version = "2.0";
      //   n.queue = [];
      //   t = b.createElement(e);
      //   t.async = !0;
      //   t.src = v;
      //   s = b.getElementsByTagName(e)[0];
      //   s.parentNode.insertBefore(t, s);
      // })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      // fbq("init", pixelId);
      // fbq("track", "PageView");

      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
      window.fbq("init", "1278652523495108");
      // window.fbq("track", "PageView");
      isPixelInitialized.current = true;
      // window.fbq("track", "Purchase", { currency: "USD", value: 10.0 });
    }
  };

  const trackEvent = (eventName, eventParams) => {
    if (!isPixelInitialized.current) {
      initPixel();
    }
    if (window.fbq) {
      fbq("track", eventName, eventParams);
      console.log("event done", JSON.stringify(eventName));
      console.log("event done eventParams ", JSON.stringify(eventParams));
    }
  };

  const trackCTA = (buttonName, additionalParams = {}) => {
    const eventParams = {
      button_name: buttonName,
      event_source_url: window.location.href,
      event_time: Math.floor(Date.now() / 1000),
      action_source: "website",
      ...additionalParams,
    };
    trackEvent("Lead", eventParams);
  };

  const trackPageOpen = (pageName, additionalParams = {}) => {
    const eventParams = {
      pageName,
      event_source_url: window.location.href,
      event_time: Math.floor(Date.now() / 1000),
      ...additionalParams,
    };
    trackEvent("PageView", eventParams);
  };

  const trackPurchase = ({
    currency = 'EUR',
    value,
    eventSourceUrl,
    orderId,
    eventId,
    clientUserAgent,
    dateOfBirth,
    email,
    firstName,
    lastName,
    phone,
    zipCode,
    city,
    user
  }) => {
      const userData = user || {};
      const userEmail = userData.email || email;
      const userFirstName = userData.name || firstName;
      const userLastName = userData.lastName || lastName;
      const userPhone = userData.phone || phone;
      const userZipCode = userData && userData.getZip() ? userData.getZip() :  zipCode;
      const userCity = userData && userData.getCity() ? userData.getCity() : city;
      const userDOB = userData.dateOfBirth || dateOfBirth;

      const eventData = {
        content_type: "product",
        currency,
        value,
        orderId,
        eventID: eventId,
        event_source_url: eventSourceUrl ? eventSourceUrl : window.location.href,
        event_time: Math.floor(Date.now() / 1000),
        action_source: "website",
      };

      // Conditionally add hashed values to the event data
      const userAgent = clientUserAgent ? clientUserAgent : navigator.userAgent;
      if (userAgent) eventData.client_user_agent = userAgent;
      // if (userEmail) eventData.email = (userEmail);
      // if (userFirstName) eventData.fn = (userFirstName);
      // if (userLastName) eventData.ln = (userLastName);
      // if (userPhone) eventData.ph = (userPhone);
      // if (dateOfBirth) eventData.db = (dateOfBirth);
      // if (userCity) eventData.ct = (userCity);
      // if (userZipCode) eventData.zp = (userZipCode);

      if (userEmail) eventData.email = userEmail;
      if (userFirstName) eventData.fn = userFirstName;
      if (userLastName) eventData.ln = userLastName;
      if (userPhone) eventData.ph = userPhone;
      if (userDOB) eventData.db = userDOB;
      if (userCity) eventData.ct = userCity;
      if (userZipCode) eventData.zp = userZipCode;

      trackEvent("Purchase", eventData);
      // window.fbq("track", "CheckoutCompleted", eventData);
  };
  

  return <FacebookPixelContext.Provider value={{ trackEvent, trackPurchase, trackPageOpen, trackCTA }}>{children}</FacebookPixelContext.Provider>;
};

export default FacebookPixelProvider;
