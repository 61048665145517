import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import {Coin} from "./Coin";
import {Discount} from "../marketplace/Discount";
import { DiscountUsed } from "../marketplace/DiscountUsed";
import { convertDaysToMonths } from "src/utils/format-time";
import { performPriceMath } from "src/utils/price-utils";

export class PriceCalculation {
  constructor({
    contract_duration = [],
    interval = [],
    final_price,
    price_interval = [],
    fees_total,
    fees_interval = [],
    taxes_total,
    taxes_interval = [],
    discounts_used = [],
    other_prices = [],
    ...otherProps
  }) {
    this.contract_duration = contract_duration;
    this.interval = interval && interval.length ? interval : [];
    this.final_price = new Coin(final_price);
    this.price_interval = price_interval.length ? [new Coin(price_interval[0])] : [];
    this.fees_total = new Coin(fees_total);
    this.fees_interval = fees_interval.length ? [new Coin(fees_interval[0])] : [];
    this.taxes_total = new Coin(taxes_total);
    this.taxes_interval = taxes_interval.length ? [new Coin(taxes_interval[0])] : [];
    this.discounts_used = discounts_used && discounts_used.length > 0 ? discounts_used.map((discount) => DiscountUsed.fromJSON(discount)) : [];
    this.other_prices = other_prices && other_prices.length > 0 ? other_prices.map((entry) => [...entry]) : []; //Copy the backend hashmap so that it can be edited
    // console.log("other_prices " + JSON.stringify(other_prices));
    // console.log("other " + JSON.stringify(other_prices[1][1]) + " " + JSON.stringify(true));

    if (other_prices && other_prices.length > 0) {
      for (const [index, [key, value]] of Object.entries(other_prices)) {
        this.other_prices[index][1] = new Coin(value);
      }
    }
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(priceCalculationJSON) {
    if (!priceCalculationJSON) return null;
    const {
      contract_duration = [],
      interval = [],
      final_price,
      price_interval = [],
      fees_total,
      fees_interval = [],
      taxes_total,
      taxes_interval = [],
      discounts_used = [],
      other_prices = [],
      ...otherProps
    } = priceCalculationJSON;
    return new PriceCalculation({
      contract_duration,
      interval,
      final_price,
      price_interval,
      fees_total,
      fees_interval,
      taxes_total,
      taxes_interval,
      discounts_used,
      other_prices,
      ...otherProps,
    });
  }

  getOtherProps() {
    const {
      contract_duration,
      interval,
      final_price,
      price_interval,
      fees_total,
      fees_interval,
      taxes_total,
      taxes_interval,
      discounts_used,
      other_prices,
      ...otherProps
    } = this;
    return otherProps;
  }

  clone() {
    return new PriceCalculation({
      contract_duration: this.contract_duration,
      interval: this.interval,
      final_price: this.final_price.clone(),
      price_interval: this.price_interval.length ? this.price_interval : [],
      fees_total: this.fees_total.clone(),
      fees_interval: this.fees_interval.length ? this.fees_interval : [],
      taxes_total: this.taxes_total.clone(),
      taxes_interval: this.taxes_interval.length ? this.taxes_interval : [],
      discounts_used: this.discounts_used.map((discount) => discount.clone()),
      other_prices: Object.fromEntries(Object.entries(this.other_prices).map(([key, value]) => [key, value])),
      ...this.getOtherProps(),
    });
  }

  getWithoutDiscount() {
    if (this.discounts_used.length === 0) {
      return this.clone();
    }
    const deposit = this.getDeposit();
    const depositOriginal = this.getDepositNotDiscounted() ? this.getDepositNotDiscounted() : deposit;
    const isSubscription = this.price_interval.length > 0;
    return new PriceCalculation({
      contract_duration: this.contract_duration,
      interval: this.interval,
      final_price: this.getFinalPriceNotDiscounted(),
      price_interval: this.getPriceIntervalNotDiscounted() ? [this.getPriceIntervalNotDiscounted()] : [],
      fees_total: this.getFeesTotalNotDiscounted(),
      fees_interval: this.fees_interval.length ? this.fees_interval : [],
      taxes_total: this.getTaxesTotalNotDiscounted(),
      taxes_interval: this.taxes_interval.length ? this.taxes_interval : [],
      discounts_used: this.discounts_used.map((discount) => discount.clone()),
      other_prices: deposit ? [["deposit", depositOriginal]] : null,
      ...this.getOtherProps(),
    });
  }

  // Method to add or update a other_prices value
  setOtherPricesValue(key, value) {
    const index = this.other_prices.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.other_prices[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.other_prices.push([key, value]);
    }
  }

  compare(other, onlyFields = true) {
    // if (!(other instanceof PriceCalculation)) {
    //   throw new Error("The object to compare must be an instance of PriceCalculation.");
    // }

    // console.log("compare final " + JSON.stringify(this.final_price) + " other " + JSON.stringify(other.final_price));
    // console.log("compare price_interval " + JSON.stringify(this.price_interval) + " other " + JSON.stringify(other.price_interval));
    // console.log("compare fees_total " + JSON.stringify(this.fees_total) + " other " + JSON.stringify(other.fees_total));
    // console.log("compare taxes_total " + JSON.stringify(this.taxes_total) + " other " + JSON.stringify(other.taxes_total));

    const compareCoins = (coin1, coin2) => (coin1 && coin2 ? coin1.amount === coin2.amount : coin1 === coin2);
    const compareCoinArrays = (arr1, arr2) => arr1.length === arr2.length && arr1.every((coin, index) => compareCoins(coin, arr2[index]));

    const fieldsMatch =
      compareCoins(this.final_price, other.final_price) &&
      compareCoinArrays(this.price_interval, other.price_interval) &&
      compareCoins(this.fees_total, other.fees_total) &&
      // compareCoinArrays(this.fees_interval, other.fees_interval) &&
      compareCoins(this.taxes_total, other.taxes_total);
    // compareCoinArrays(this.taxes_interval, other.taxes_interval);
    if (onlyFields) {
      return fieldsMatch;
    }

    const otherPricesMatch =
      this.other_prices.length === other.other_prices.length &&
      this.other_prices.every(([key, value], index) => key === other.other_prices[index][0] && compareCoins(value, other.other_prices[index][1]));

    const discountsMatch =
      this.discounts_used.length === other.discounts_used.length &&
      this.discounts_used.every((discount, index) => discount.equals(other.discounts_used[index]));

    return fieldsMatch && otherPricesMatch && discountsMatch;
  }

  setAllOtherPrices(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setOtherPricesValue(key, value.toString());
    });
  }

  getOtherPricesValue(key) {
    const value = getMapValueForKey(this.other_prices, key);
    // const entry = this.other_prices.find(([entryKey]) => entryKey === key);
    return value ? value : null;
  }

  getInterval(toPrint = false) {
    return toPrint ? getEnumVariableText(this.interval) : this.interval;
  }

  setDeposit(deposit) {
    this.setOtherPricesValue("deposit", deposit instanceof Coin ? deposit : Coin.fromJSON(deposit));
  }

  getDeposit() {
    return this.getOtherPricesValue("deposit");
  }
  /**
   * In total contract duration
   * @returns
   */
  getTotalDiscount() {
    return this.getOtherPricesValue("total_discount");
  }

  /**
   * If there is a discount applied, return the different between original price and discounted
   */
  getFinalAmountDiscounted() {
    const originalFinalPrice = this.getFinalPriceNotDiscounted();
    if (originalFinalPrice) {
      return originalFinalPrice.subtract(this.final_price);
    }
    return Coin.empty();
  }

  getFinalPriceNotDiscounted() {
    return this.getOtherPricesValue("final_price_not_discounted");
  }

  getFeesTotalNotDiscounted() {
    return this.getOtherPricesValue("fees_total_not_discounted");
  }

  getTaxesTotalNotDiscounted() {
    return this.getOtherPricesValue("taxes_total_not_discounted");
  }

  getPriceIntervalNotDiscounted() {
    return this.getOtherPricesValue("price_interval_not_discounted");
  }

  getDepositNotDiscounted() {
    return this.getOtherPricesValue("deposit_not_discounted");
  }

  hasDepositDiscount() {
    // Check if the priceCalculation object has a 'discounts_used' array
    if (!Array.isArray(this.discounts_used)) {
      return false;
    }
    // Loop through the discounts_used array to find a deposit discount
    for (const discount of this.discounts_used) {
      if (discount.isDeposit()) {
        return true; // Deposit discount found
      }
    }
    // No deposit discount found
    return false;
  }

  // Check if the subscription discount is applied for the whole duration
  isSubscriptionDiscountWholeDuration() {
    const subscriptionDiscount = this.getSubscriptionDiscount();
    if (!subscriptionDiscount || this.contract_duration.length === 0) {
      return false;
    }
    const durationInMonths = convertDaysToMonths(this.contract_duration[0]);
    return subscriptionDiscount.getSubscriptionMaxCount() >= durationInMonths;
  }

  getSaleDiscount(addOrderDiscount = false) {
    // Check if the discounts_used array exists and is an array
    if (!Array.isArray(this.discounts_used)) {
      return null;
    }
    // Loop through discounts_used and return the first Subscription discount found
    for (const discount of this.discounts_used) {
      if ((addOrderDiscount && discount.isOrderDiscount()) || discount.isSaleDiscount()) {
        return discount; // Return the discount if it's a Subscription type
      }
    }
    // If no Subscription discount is found, return null
    return null;
  }

  getSubscriptionDiscount(addOrderDiscount = false) {
    // Check if the discounts_used array exists and is an array
    if (!Array.isArray(this.discounts_used)) {
      return null;
    }
    // Loop through discounts_used and return the first Subscription discount found
    for (const discount of this.discounts_used) {
      if ((addOrderDiscount && discount.isOrderDiscount()) || discount.isSubscription()) {
        return discount; // Return the discount if it's a Subscription type
      }
    }

    // If no Subscription discount is found, return null
    return null;
  }

  getDiscount() {
    // Check if the discounts_used array exists and is an array
    if (!Array.isArray(this.discounts_used)) {
      return null;
    }
    for (const discount of this.discounts_used) {
      if (discount.discount_amount.length > 0 || discount.discount_percentage.length > 0) {
        return discount;
      }
    }
    // If no Subscription discount is found, return null
    return null;
  }

  // Check if the discounts used contain this one
  hasUsedDiscount(id, discountCode) {
    // Check if the discounts_used array exists and is an array
    if (!Array.isArray(this.discounts_used)) {
      return null;
    }
    for (const discount of this.discounts_used) {
      if (discount.matches(id, discountCode)) {
        return discount;
      }
    }
    // If no Subscription discount is found, return null
    return null;
  }

  getPriceDiscounted() {
    // Check if the discounts_used array exists and is an array
    const discount = this.getDiscount();
    if (discount) {
      discount.calculateAmount(this.final_price);
    }
    return null;
  }

  // calculateDiscountedPrice(price, discount) {
  //   return price - discount.calculateAmount(price);
  // }

  // Method to get base price for duration
  getBasePrice() {
    return this.contract_duration ? (this.price_interval.length ? this.price_interval[0] : this.final_price) : this.final_price;
  }

  // Add value to a specific field
  addToField(value, field = "final_price") {
    if (this[field] instanceof Coin) {
      this[field] = this[field].add(value);
    } else if (Array.isArray(this[field]) && this[field].length > 0 && this[field][0] instanceof Coin) {
      this[field][0] = this[field][0].add(value);
    } else {
      throw new Error(`Field ${field} is not a valid Coin or does not exist.`);
    }
  }

  // Subtract value from a specific field
  subtractFromField(value, field = "final_price") {
    if (this[field] instanceof Coin) {
      this[field] = this[field].subtract(value);
    } else if (Array.isArray(this[field]) && this[field].length > 0 && this[field][0] instanceof Coin) {
      this[field][0] = this[field][0].subtract(value);
    } else {
      throw new Error(`Field ${field} is not a valid Coin or does not exist.`);
    }
  }

  // Method to add another PriceCalculation
  add(other) {
    let combinedOtherPrices = [...this.other_prices];
    other.other_prices.forEach(([key, value]) => {
      const combinedValue = combinedOtherPrices.find(([combinedKey]) => combinedKey === key);
      if (combinedValue) {
        combinedValue[1] = combinedValue[1].add(value);
      } else {
        combinedOtherPrices.push([key, value]);
      }
    });

    return new PriceCalculation({
      contract_duration: this.contract_duration || other.contract_duration,
      interval: this.interval || other.interval,
      final_price: this.final_price.add(other.final_price),
      price_interval: this.price_interval.length ? [this.price_interval[0].add(other.price_interval[0])] : other.price_interval,
      fees_total: this.fees_total.add(other.fees_total),
      fees_interval: this.fees_interval.length ? [this.fees_interval[0].add(other.fees_interval[0])] : other.fees_interval,
      taxes_total: this.taxes_total.add(other.taxes_total),
      taxes_interval: this.taxes_interval.length ? [this.taxes_interval[0].add(other.taxes_interval[0])] : other.taxes_interval,
      discounts_used: [...this.discounts_used, ...other.discounts_used],
      other_prices: combinedOtherPrices,
    });
  }

  /**
   * Extract price , deposit its separated, if subs price interval if sale final
   * Its a PriceCalculation object
   * If there is a discount , get the original price not the discounted one, return in discountedPrice the discounted one
   * @param {*} subContract
   * @returns    return {
      ...resultPrice: {amount, currency} without discount
      price_obj: this: Full Price calculation object
      discountedPrice: discountedPrice,
    };
   */
  getBasePaymentPrice = () => {
    const isSubscription = this.price_interval.length > 0;

    const discount = isSubscription ? this.getSubscriptionDiscount(true) : this.getSaleDiscount(true);
    const discountedPrice = discount ? (isSubscription ? this.price_interval[0] : this.final_price) : null;
    let resultPrice;
    if (discount) {
      if (isSubscription) {
        resultPrice = this.getPriceIntervalNotDiscounted() ? this.getPriceIntervalNotDiscounted() : this.price_interval[0];
      } else {
        resultPrice = this.getFinalPriceNotDiscounted() ? this.getFinalPriceNotDiscounted() : this.final_price;
      }
    } else {
      resultPrice = isSubscription ? this.price_interval[0] : this.final_price;
    }
    // const resultPrice= discountedPrice ? discountedPrice : originalPrice;
    return {
      ...resultPrice,
      price_obj: this,
      discountedPrice: discountedPrice,
    };
  };

  getBaseFeePrice() {
    const resultPrice = this.fees_interval.length > 0 ? this.fees_interval[0] : this.fees_total;
    return {
      ...resultPrice,
      price_obj: this,
    };
  }

  getBaseTaxesPrice() {
    const resultPrice = this.taxes_interval.length > 0 ? this.taxes_interval[0] : this.taxes_total;
    return {
      ...resultPrice,
      price_obj: this,
    };
  }

  getRawPaymentPrice() {
    const price = this.getBasePaymentPrice();
    const priceWithDiscounts = price.discountedPrice ? price.discountedPrice : price;
    const fees = this.getBaseFeePrice();
    const taxes = this.getBaseTaxesPrice();
    const rawPrice = performPriceMath("-", priceWithDiscounts, fees.amount + taxes.amount);
    return {
      ...rawPrice,
      price_obj: price,
    };
  }

  // getBaseFeeInterval = () => {
  //   const discountedPrice = this.getSubscriptionDiscount() ? this.price_interval[0] : null;
  //   let resultPrice =
  //     this.price_interval.length > 0
  //       ? this.getSubscriptionDiscount() && this.getPriceIntervalNotDiscounted()
  //         ? this.getPriceIntervalNotDiscounted()
  //         : this.price_interval[0]
  //       : this.final_price;
  //   return this.getSubscriptionDiscount() &&
  // };
}
