/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import ProductContractsService from "../../../services/ProductContractsService";
import ProductsService from "../../../services/ProductsService";
import { getUniqueFieldValues } from "src/utils/typeConverters";
import Product from "model/marketplace/Product";
import Shop from "model/users/Shop";
import { fPrice } from "src/utils/format-number";
import { IS_DEV } from "src/config-global";
// ----------------------------------------------------------------------

export const DEFAULT_PRICE_RANGE = [0, 350];
export const DEFAULT_PRICE_SALE_RANGE = [0, 100000];
export const PRICE_RANGE_SALE_TEXT = "priceRangeSale";
export const PRICE_RANGE_TEXT = "priceRange";
export const PRODUCT_DEFINITION_TEXT = "productDefinition";
export const DEFAULT_PRODUCT_SIZE = "any";
export const PRODUCT_SIZE_TEXT = "sizes";

export const KEY_LOCATION = "location";
export const KEY_CONTRACT_DURATION = "contractDuration";

// TODO move to product contracts
export const DURATIONS = [
  { name: "none", value: -1 },
  { name: "all", value: 0 },
  { name: "3", value: 3 },
  { name: "6", value: 6 },
  { name: "12", value: 12 },
];
export const DEFAULT_CONTRACT_DURATION = DURATIONS.at(1);


const initialState = {
  isLoading: false,
  error: null,
  storeListings: [],
  storeListing: null,
  filters: {
    location: null,
    productDefinition: null,
    priceRange: DEFAULT_PRICE_RANGE,
    priceRangeSale: DEFAULT_PRICE_SALE_RANGE,
    searchForSale: false,
    sizes: [DEFAULT_PRODUCT_SIZE],
    contractDuration: DEFAULT_CONTRACT_DURATION,
  },
};

const slice = createSlice({
  name: "store_listing",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // RESET STATE
    resetState(state) {
      return initialState;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getStoreListingsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length === 0) {
        state.storeListings = [];
        return;
      }
      state.storeListings = action.payload;
    },

    // GET PRODUCT
    getStoreListingSuccess(state, action) {
      state.isLoading = false;
      state.storeListing = action.payload;
    },

    filterProducts(state, action) {
      state.filters.location = action.payload.location;
      state.filters.productDefinition = action.payload.productDefinition;
      state.filters.sizes = action.payload.sizes;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.priceRangeSale = action.payload.priceRangeSale;
      state.filters.searchForSale = action.payload.searchForSale;
      state.filters.contractDuration = action.payload.contractDuration;
    },

    deleteListing(state, action) {
      state.isLoading = false;
      const updatedListings = state.storeListings.filter((listings) => listings.id !== action.payload);

      state.storeListings = updatedListings;
    },

    updateListing(state, action) {
      state.isLoading = false;
      const index = state.storeListings.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.storeListings[index] = action.payload;
      }
    },

    addListing(state, action) {
      state.isLoading = false;
      const length = state.storeListings.push(action.payload);
    },
  },
});

// Reducer
export default slice.reducer;
export const { resetState: resetStoreListingState } = slice.actions;

// Actions
export const {
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getStoreListings(symphy_backend, shopId, withProducts, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await ProductContractsService.getStoreListings(symphy_backend, null, shopId);
      if (withProducts && response.length > 0) {
        // let productIds = getUniqueFieldValues(response, "product_id");
        // TODO support to multiple shops
        // response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);
        response = response
          .filter((item) => item.product && item.product.length > 0) // Ensure the `product` field exists and is not empty
          .map((item) => ({
            ...item,
            product: Product.fromJSON(item.product[0]), // Map the product to Product.fromJSON
          }));
      }
      if (listener) {
        listener();
      }
      dispatch(slice.actions.getStoreListingsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActiveStoreListings(symphy_backend, withProducts = false, listener, shops) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    let response = await ProductContractsService.getActiveStoreListings(symphy_backend);
    response = response.filter((listing) => ProductContractsService.isListingInShop(listing));
    if (response.length > 0 && withProducts) {
      response = response
        .filter((item) => item.product && item.product.length > 0) // Ensure the `product` field exists and is not empty
        // .filter((item) => item.product[0].verified === true) // TODO this should be done in the backend
        .map((item) => ({
          ...item,
          product: Product.fromJSON(item.product[0]), // Map the product to Product.fromJSON
        }));
    }

    // For each listing, add shop from the provided shops map if it's missing
    // TODO remove this once the backend is updated to return the shop with the listing
    if (shops) {
      response = response.map((listing) => {
      // If shop is not present and shop_id exists, find the corresponding shop from the `shops` map
        if ((!listing.shop || listing.shop.length === 0) && listing.shop_id && listing.shop_id.length > 0) {
          const shopId = listing.shop_id[0]; // Get the shop ID
          const correspondingShop = shops[shopId]; // Access the shop directly from the shops map

          // Add the shop to the listing if found
          if (correspondingShop) {
            listing.shop = [correspondingShop];
          }
        } else if (listing.shop && listing.shop.length > 0) {
          listing.shop = [Shop.fromJSON(listing.shop[0])];
        }
      return listing; // Return the updated listing
    });
    }
    // if (response.length > 0 && withProducts) {
    //   let productIds = response.map((listing) => listing.product_id);
    //   response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);

    //   // Filter out items where product.verified is false
    //   response = response.filter((item) => item.product.verified !== false);
    // }
    if (listener) {
      listener(response);
    }


    // if (IS_DEV) {
    //   printCatalogCsv(response);
    // }
    dispatch(slice.actions.getStoreListingsSuccess(response));
    // } catch (error) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function printCatalogCsv(response) {
  const headers = ["id", "title", "description", "availability", "condition", "price", "link", "brand", "image_link", "additional_image_link", "product_type"];

  // Start with the header row
  let csvContent = headers.join(",") + "\n";

  // Loop through each item and build each row of data
  response.forEach((element) => {
    const product = element.product;
    const subsConfig = ProductContractsService.getProductContractSubsConfig(element.contract);
    if (subsConfig) {
      const id = product.id;
      const title = element.name.replace(/"/g, '""'); // Escape quotes
      let description = element.description
        .replace(/<[^>]*>/g, "") // Remove HTML tags
        .replace(/([.])(?=[^\s])/g, "$1 "); // Add space after period if not present
      description = description.replace(/"/g, '""'); // Escape quotes after HTML removal
      if (description === "Description") {
        description = ""; // Remove default description
      }
      const availability = "in stock"; // Facebook expects lowercase English
      const condition = product.getStateText() === "New" ?  "new" :"used"; // Translate to Facebook's expected format
      const price = `${fPrice(subsConfig.price_subs, true)} EUR`;
      const link = `https://symphy.es/product/${id}`;
      const brand = product.getBrand() && product.getBrand() !== "Desconocido" ? product.getBrand() : "";
      const imagesArray = product.images || [];
      const imageLink = imagesArray[0] || ""; // Main image
      const additionalImageLinks = imagesArray.slice(1).join(","); // Other images

      const productType = product.product_type;
      if (imagesArray.length > 0) {
        // Add row data for each product, using double quotes to handle commas and special characters
        csvContent += `${id},"${title}","${description}",${availability},${condition},${price},${link},"${brand}","${imageLink}","${additionalImageLinks}","${productType}"\n`;
      }
    }
  });

  // Add UTF-8 BOM to ensure proper encoding of special characters
  const utf8Bom = "\uFEFF";
  csvContent = utf8Bom + csvContent;

  // Create a Blob with CSV content, including UTF-8 BOM for proper encoding
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  // Generate a download link for the Blob
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "facebook_catalog.csv");
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getStoreListing(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getStoreListing(
        symphy_backend,
        // eslint-disable-next-line no-undef
        BigInt(id)
      );
      dispatch(slice.actions.getStoreListingSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStoreListingByProductId(symphy_backend, id, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getStoreListingByProductId(
        symphy_backend,
        // eslint-disable-next-line no-undef
        BigInt(id)
      );
      if (listener) {
        listener(response);
      }
      dispatch(slice.actions.getStoreListingSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStoreListingByProductIdExpanded(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      let response = await ProductContractsService.getStoreListingByProductId(
        symphy_backend,
        // eslint-disable-next-line no-undef
        BigInt(id)
      );
      // console.log("getStoreListingByProductIdExpanded " + JSON.stringify(response));
      if (response) {
        let productIds = [response.product_id];
        response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);

        dispatch(slice.actions.getStoreListingSuccess(response));
      } else {
        dispatch(slice.actions.hasError("not_found"));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deleteListing(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await ProductContractsService.deleteStoreListing(
        symphy_backend,
        BigInt(id)
      );
      dispatch(slice.actions.deleteListing(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoreListing(symphy_backend, listing, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.updateStoreListing(symphy_backend, listing);
      if (listing.product) {
        response.product = listing.product;
      }
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.updateListing(response));
      if (isCurrent) {
        dispatch(slice.actions.getStoreListingSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error updating " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStoreListing(symphy_backend, listing, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.addStoreListing(symphy_backend, listing);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.addListing(response));
      if (isCurrent) {
        dispatch(slice.actions.getStoreListingSuccess(response));
      }
      if (listener) {
        listener(response);
      }
    // } catch (error) {
    //   console.log("Error adding listing " + error);
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// ----------------------------------------------------------------------
