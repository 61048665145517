// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `User`,
  list: `list`,
  shop: `Shop`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `Create`,
  general: `general`,
  profile: `profile`,
  account: `account`,
  invoice: `invoice`,
  calendar: `calendar`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  default: `default`,
  landing: {
    main_title: "Connecting musicians with",
    main_title_2: "musical instruments?",
    main_subheader: "We are preparing something incredible, sign up to find your ideal instrument, without compromises.",
    main_subheader_b: "Musical instrument rental at never-before-seen prices.",
    main_subheader_c: "Access instruments of all ranges at never-before-seen prices.",
    main_action: "To Rent",
    main_colaborative: "Earn money!",
    main_tell_more: "Tell me more",
    calculate_how_much: "Calculate how much",

    subheader: "The Collaborative Economy ",
    subheader_2: "reaches the world of music",
    subheader_tag: "Are you a professional musician or a music student?\nCheck this out.",

    intro_action_title: "Do you need an instrument?",
    intro_action_subtitle: "We have it!",
    intro_action_title_2: "Checkout the catalogue",
    intro_action_title_2b: "Instruments at home?",
    intro_action_subtitle_2: "Profit from them",
    show_me: "Show me",
    review: "Review",

    features_entry: "Save money",
    features_entry_subtitle: "Access the catalogue of instruments from other musicians and find the perfect match for your skills.",
    features_entry2: "Earn money",
    features_entry2_subtitle: "Share your own instruments with others and earn money while doing so.",
    features_entry4: "Secure and transparent",

    testimonials_title: "A Symphony of",
    testimonials_title2: "Success Stories",
    testimonials_pretitle: "See how sharing and subscribing to musical instruments has transformed the lives of our users",
    testimonials_subtitle: "Testimonies from our users",

    testimonial:
      "Renting a violin from Symphy was the best thing I could’ve done. My daughter wanted to try it out, but we weren’t sure if she’d actually like it. The whole process was easy, and the violin was in great shape. Highly recommend it!",
    testimonial_name: "Sara Mártinez",
    testimonial_date: "3 days ago",
    testimonial_item_reviewed_name: "Symphy violin rental service",

    testimonial_2:
      "I’ve always wanted to learn the cello, but buying one is crazy expensive. With Symphy, I can try it out without spending a fortune. Plus, they’re super helpful.",
    testimonial_name_2: "José Márquez",
    testimonial_date_2: "1 week ago",
    testimonial_item_reviewed_name_2: "Symphy cello rental service",

    testimonial_3:
      "I was nervous about renting, because in other stores the instruments are usually all beat up... But the violin from Symphy was spotless and sounded fantastic. Real professionals.",
    testimonial_name_3: "David García",
    testimonial_date_3: "2 weeks ago",
    testimonial_item_reviewed_name_3: "Quality of Symphy rental violins",

    testimonial_4:
      "The flexibility they offer is the best. My son has had to change viola sizes twice because he keeps growing, and with Symphy I don’t have to worry about buying a new one every year.",
    testimonial_name_4: "Jacobo Álvarez",
    testimonial_date_4: "1 month ago",
    testimonial_item_reviewed_name_4: "Flexibility in Symphy viola rental",

    testimonial_5:
      "I wasn’t sure if my son would stick with the cello after a few months. In the end, he wanted to switch to the viola, and with Symphy, we just made the switch, easy and hassle-free.",
    testimonial_name_5: "María López",
    testimonial_date_5: "2 weeks ago",
    testimonial_item_reviewed_name_5: "Instrument switching service at Symphy",

    testimonial_6: "Symphy is amazing! I had an old violin that I wasn’t using, and now it’s earning me money every month. It’s great!",
    testimonial_name_6: "Javier Pérez",
    testimonial_date_6: "3 months ago",
    testimonial_item_reviewed_name_6: "Symphy own violin rental service",

    testimonial_7: "My cello was just sitting there... I'm so glad I found Symphy! They handle everything, and I get extra cash. Super easy.",
    testimonial_name_7: "Carmen Rivera",
    testimonial_date_7: "1 month ago",
    testimonial_item_reviewed_name_7: "Symphy own cello rental service",

    testimonial_8:
      "Symphy has a great system. My cello finally came out of the closet and is being played, and I also earn extra money. Nothing to complain about!",
    testimonial_name_8: "Javier Ramírez",
    testimonial_date_8: "4 months ago",
    testimonial_item_reviewed_name_8: "Symphy cello rental system",

    testimonial_9: "My violin wasn’t getting any use, but now it’s helping someone learn music, and I’m earning. Love that Symphy made it possible!",
    testimonial_name_9: "Isabel Pérez",
    testimonial_date_9: "5 months ago",
    testimonial_item_reviewed_name_9: "Symphy violin lending service",

    testimony_v_name_1: "Rosalía",
    testimony_v_description_1: "Madre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_2: "Javier",
    testimony_v_description_2: "Padre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_3: "María",
    testimony_v_description_3: "Madre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_4: "Federico",
    testimony_v_description_4: "Padre de Paula, estudiante de Cello de 15 años.",
    testimony_v_name_5: "Marta",
    testimony_v_description_5: "Madre de Paula, estudiante de Cello de 15 años.",

    instruments_title: "Explore Our Collection of Instruments",
    instruments_pretitle: "Access a Wide Range of Instruments for All Levels",
    instruments_subtitle: "Choose your perfect instrument",

    collaborators_title: "They trust us",
    collaborators_subtitle: "Many music schools, luthiers, musicians and organizations",

    instruments_cat_title: "We have the instrument you are looking for",
    instruments_cat_pretitle: "Simplified Instrument Categories",
    instruments_cat_subtitle: "Options for everyone, from beginners to stars.\nWe specialize in string instruments:\nViolins, violas and cellos, among others.",
    instruments_drop_title: "Publish your instrument.",
    instruments_drop_title2: "Earn money.",
    instruments_drop_action_title: "Find your SYMPHY partner shop",
    instruments_drop_action_desc: "Take it to your closest SYMPHY partner shop to register it for free.",
    instruments_drop_action: "Find shop",
    instruments_drop_action2: "Learn more",

    query: "Query",

    all: "All",
    features: "Features",
    soon: "Soon",
    find_your_instrument: "Find your instrument",
    we_have_the_instrument: "We have exactly what you are looking for!",
    violins: "Violins Rental Shop",
    violas: "Violas Rental Shop",
    violoncellos: "Cellos Rental Shop",
    violins_beginner: "Beginner Violins Rental Shop",
    violins_professional: "Professional Violins Rental Shop",
    violas_beginner: "Beginner Violas Rental Shop",
    violas_professional: "Professional Violas Rental Shop",
    cellos_beginner: "Beginner Cellos Rental Shop",
    cellos_professional: "Professional Cellos Rental Shop",
    general_keywords:
      "Symphy, musical instrument rental, violin rental, viola rental, cello rental, double bass rental, string instruments, musical instrument rental, musical instrument rental for children, musical instrument rental Madrid, musical instruments for beginners, musical instruments for professionals, violin rental, viola rental, cello rental, instrument rental, musical instruments Madrid, violins for rent, violas for beginners, instruments for musicians, cheap instrument rental, classical music, strings, symphonic instruments, children's instruments, violins for children, violas for children, cellos for children, violonchelos for children",
    general_desc:
      "Find the rental of the instrument you are looking for from 14.99€ per month. Violins, violas, cellos and much more. For all levels, initiation, conservatory and professional",
    violins_desc: "Rent violins from 14.99€ per month with purchase option. For all the levels: children & students, conservatory and professional",
    violas_desc: "Rent violas from 14.99€ per month with purchase option. For all the levels: children & students, conservatory and professional",
    cellos_desc: "Rent cellos from 14.99€ per month with purchase option. For all the levels: children & students, conservatory and professional",

    violins_desc_beginner: "Violines for beginners from 14.99€ per month with purchase option. All sizes: 1/4, 1/2, 3/4, 4/4. Includes bow and case.",
    violins_desc_professional:
      "Violins for professionals from 34.99€ per month with purchase option. Insured, superior qualities and verified by our luthiers.",
    violas_desc_beginner: "Violas for beginners from 14.99€ per month with purchase option. All sizes: 1/4, 1/2, 3/4, 4/4. Includes bow and case.",
    violas_desc_professional: "Violas for professionals from 34.99€ per month with purchase option. Insured, superior qualities and verified by our luthiers.",
    cellos_desc_beginner: "Cellos for beginners from 14.99€ per month with purchase option. All sizes: 1/4, 1/2, 3/4, 4/4. Includes bow and case.",
    cellos_desc_professional: "Cellos for professionals from 34.99€ per month with purchase option. Insured, superior qualities and verified by our luthiers.",
  },
  navigation: {
    general: "General",
    billing: "Billing",
    home: "Home",
    account_settings: "Account settings",
    settings: "Settings",
    seller_area: "Tu tienda",
    my_shop: "My shop",
    my_products: "My Products",
    products: "Products",
    product_definitions: "Product definitions",
    product_details: "Product details",
    product_overview: "Procuct overview",
    product_edit: "Edit product",
    store_listings: "Store listings",
    product_requests: "Product requests",
    player_area: "Musician's corner",
    dashboard: "Dashboard",
    add_product: "Add product",
    artists: "Artists",
    products_to_verify: "Procucts to verify",
    to_verify: "To verify",
    products_stored: "Stored products",
    discounts: "Discounts",
    discount: "Discount",
    discount_subs: "Subscription fee discount",
    fees: "Fees",

    management: "Management",
    users: "User management",
    marketplace: "Musical Instruments Marketplace",
    to_marketplace: "To the catalogue!",
    list: "List",
    shops: "Shops",
    invoices: "Invoices",
    apps: "Apps",
    chat: "Chat",
    contracts: "Contracts",
    recommended_contracts: "Recommended contracts",
    customer_contracts: "Owner contracts",
    customer_subcontracts: "User contracts",
    platform_config: "Platform configuration",

    contact: "Contact",
    about: "About",
    about_us: "About us",
    logout: "Logout",
    faqs: "FAQs",
    instruments: "Instruments",
    partners: "For partners",
    shops_finder: "Partner Shops",
    help_support: "Help & support",
    legal: "Legal",
    transactions: "Transactions",

    to_settings: "To settings",
    overview: "Overview",
    services: "Services",
    details: "Details",
  },
  intro: {
    action_title_a: "How would you like to",
    action_title_b: "continue at SYMPHY?",
    action_subtitle: "Drop an instrument to earn cash or get an instrument",
    action_drop: "Drop it",
    action_get: "Get it",

    action_drop_title_a: "Yor instruments",
    action_drop_title_b: "always with SYMPHY",
    action_drop_subtitle: "Here is how it works",

    action_drop_button: "Register my instrument",

    action_get_title_a: "Time to tell us",
    action_get_title_b: "your needs",
    action_get_title_full: "Time to tell us <0>your needs</0>",
    action_get_subtitle: "Select your ideal options",

    min_contract_duration: "Minimum contract duration:",

    no_data: "We didn't find anything.",
    notify_me: "Would you like to be notified when we have it?",
    notify_me_action: "Notify me!",
    no_data_ready: "All ready",
    notify_me_ready: "We will let you know as soon as we have the ideal instrument for you",
    required_instrument: "Instrument is required",
    no_data_adjust: "Try to adjust the search",
    give_email: "Give us your email",

    upload_your_instrument: "Upload your instrument",
    upload_your_instrument_subtitle: "Tell us everything about your instrument and set the rental or sale conditions according to your preferences.",
    take_to_shop: "Take it to your local shop",
    take_to_shop_subtitle: "Drop your instrument at your SYMPHY partner local shop so that its reviewed. We will take care of the rest.",
    share_and_earn: "Share and earn money",
    share_and_earn_subtitle: "We will take care of the rental process and you will receive your earnings monthly.",
    security: "Security",
    security_subtitle:
      "We try to ensure that all the instruments are well taken care off, but just in case, we insure all of them internationally. \nWe also make sure that all the users are properly identified.",
  },

  intro_seller: {
    action_title_a: "Offer your services",
    action_description_a: "Share your musical instruments and services with our growing user base, connecting with musicians and enthusiasts.",
    action_title_b: "Get visibility",
    action_description_b: "Enhance your market exposure through our marketing campaigns, enabling you to connect with a larger and engaged audience.",
    action_title_c: "Earn money",
    action_description_c: "You will review and store products from users. You will be paid for each item you process.",
    action_title_d: "Be part of the community",
    action_description_d: "Broaden your industry connections within our thriving community, fostering opportunities for growth and collaboration.",
    questions_title: "Questions?",
    questions_description: "Don't hesitate to contact us.",
    questions_description_2: "Select the option of collaborations in the subject.",
  },

  contact: {
    contact_us: "Contact us",
    full_name: "Full name",
    subject: "subject",
    how_help: "How can we help you?",
    placeholder_message: "Describe your problem in at least 250 characters",
    placeholder_message_drop: "-Phone nr:\n\n-City:\n\nAbout what I have.\n-Instrument:\n-Size\n-Value/Quality\n-State:\n-Accesories(ex:case, bow):",
    placeholder_message_request:
      "-Phone nr:\n\n-City:\n\nAbout what I am looking for.\n-Instrument:\n-Size\n-Value/Quality\n-State:\n-Accesories(ex:case, bow):",

    placeholder_message_partner: "Tell us about you shop or how you would like to partner with us:\n",
    default_message: "-Phone nr:\n\n-City:\n\nAbout what I am searching or I have.\n-Instrument:\n-Size\n-Value/Quality\n-State:\n-Accesories(ex:case, bow):",
    action_request_instrument: "I need an instrument",
    become_partner: "Become a partner",
    others: "Others",
    description: "For further questions, including partnership opportunities, please send an email to email info@symphy.es or contact using the form.",
  },

  notifications: {
    contract_added: "Contract added",
    contract_updated: "Contract updated",
    payment_requested: "Payment requested",
    payment_requested_error: "Error requesting payment",
    service_registered: "Service registered",
    service_registered_error: "Error registering service",
    service_updated_error: "Error updating service",

    listing_created: "Listing created",
    listing_updated: "Listing updated",
    listing_created_error: "Error creating listing",
    max_reserved_items_error: "You can't reserve more than 5 products",
    reservations_no_discount: "Discount can not be applied for reservations",

    order_cancelled: "Order cancelled successfully",
    order_cancelled_error: "Error cancelling order",
  },

  required_address: "Exact address is required",
  required_name: "Name is required",
  required_shop_name: "Shop name is required",
  required_latname: "Lastname is required",
  required_email: "Email is required",
  required_pass: "Password is required",
  required_phone: "Phone number is required",
  required_subject: "Subject is required",
  required_message: "Message is required",
  required_city: "City is required",
  required_region: "Region is required",
  required_zip_code: "Zip code is required",
  invalid_email: "Invalid email",
  required_tax_number: "VAT number is required",
  required_iban: "IBAN is required",
  invalid_iban: "Invalid IBAN",
  required_type: "Product type is required",
  required_description: "Description is required",
  required_price: "Price is required",

  actions: {
    ok: "Ok",
    back: "Back",
    skip: "Skip",
    buy: "Buy",
    reserve: "Reserve",
    subscribe: "Subscribe",
    save: "Save",
    save_changes: "Save changes",
    update: "Update",
    update_data: "Update data",
    update_photo: "Update photo",
    upload_photo: "Upload photo",
    send: "Send",
    send_message: "Send message",
    message_sent: "Message sent",
    create_new_product: "Create new product",
    create_product: "Create product",
    new_product: "New Product",
    new_product_definition: "New Product Definition",
    view: "View",
    view_listing: "View ad",
    edit: "Edit",
    edit_price: "Edit price",
    edit_ad: "Edit ad",
    completed: "Completed",
    contact_customer: "Contact customer",
    share: "Share",
    delete: "Delete",
    delete_children: "Delete all related data",
    copy_link: "Copy link",
    copied: "Copied!",
    product_step_1: "Product data",
    product_step_2: "Online(ad) presence",
    product_step_3: "Contracts",
    search: "Search",
    choose: "Choose",
    activate_subscription: "Activate subscription",
    create_store_listing: "Create store listing",
    update_store_listing: "Update store listing",
    activate_sale: "Activate sale",
    directions: "Directions",
    close: "Close",
    copy: "Copy",
    request_validation: "Request validation",
    validation_requested: "Validation requested",
    remove_all: "Remove all",
    upload_files: "Upload files",
    drop_or_select: "Drop or select file",
    drop_or_select_desc: "Drop files here or click <0>browse</0> thorough your machine.",
    clear: "Clear",
    clear_all: "Clear all",
    yes: "Yes",
    no: "No",
    continue: "Continue",
    verify: "Verify",
    verify_instrument: "Verify instrument",
    verification_needed: "Instrument validation needed",
    unverify: "Unverify",
    activate: "Activate",
    deactivate: "Deactivate",
    pending_payment: "Pending payment",
    pending_pickup: "Pickup instrument",
    verify_customer: "Verify customer",
    give_instrument: "Give instrument",

    accept_all: "Accept all",
    reject_all: "Reject all",
    cancel: "Cancel",
    accept: "Accept",
    reject: "Reject",
    add_shop: "Add shop",
    set_visible: "Set visible",
    delete_tip: "Are you sure you want to delete this?",
    delete_tip_value: "Are you sure you want to delete {{value}} items?",
    email_sent: "Email sent",
    password_updated: "Password updated",
    password_updated_error: "There was an error updating the password",
    item_validated: "The item has been validated",
    updated: "Updated",
    error: "Error",
    click_here: "Click here",
    create_contract: "Create contract",
    update_contract: "Update contract",
    request_payment: "Request payment",
    request_service: "Request service",
    create_service: "Create service",
    update_service: "Update service",
    view_all: "View all",
    sure_delete: "Are you sure you want to delete?",

    view_invoice: "View invoice",
    edit_invoice: "Edit invoice",
    view_contract: "View contract",
    duplicate: "Duplicate",
    mark_collected: "Mark as collected",
    charge: "Charge",
    charge_custom: "Charge custom",
    manual_confirmation: "Manual confirmation",
    resend_link: "Resend link",
    refund: "Refund",
    add_more: "Add more",
    add_service: "Add service",
    request_maintenance: "Request service",
    publish: "Publish",
    loading: "Loading",
    updating: "Updating",
    error_opening_file: "Error opening file",

    maintenance_requested: "Maintenance requested",
    in_maintenance: "In maintenance",
    view_service: "View service",
    sign: "Sign",
    apply: "Apply",
    apply_later: "Apply later",
    help: "Help",
    click_to_sign: "Click to sign",
    request: "Request",

    complete_order: "Complete order",
    cancel_order: "Cancel order",
    to_find: "To find!",
  },

  auth: {
    login: "Login",
    register: "Register",
    already_account: "Already have an account?",
    review_account: "Review your data",
    dont_have_account: "Don't have an account?",
    dont_have_account_yet: "Don't have an account yet?",
    data_needed: "We need the following data for the instrument contract",
    signin: "Sign in",
    or_use_email: "Or use your email",
    or_login_with: "Or Login with",
    use_ii: "Use Internet Identity",
    use_ii_login: "Login with Internet Identity",
    use_ii_description: "Login",
    use_ii_description2: "Without passwords",
    forgot_password: "Forgot password?",
    reset_password: "Reset password",
    repeat_password: "Repeat password",
    repeat_new_password: "Repeat new password",
    write_new_password: "Write your new password",
    passwords_must_match: "Passwords must match",
    update_password: "Update password",
    new_password: "New password",
    reset_password_send_code: "A reset code will be send to your email",
    send_reset_email: "Send reset email",
    password_length: "Password must be at least 6 characters",

    partners: "Partners",
    partners_register: "Partners registration",
    partner_cif: "CIF/NIF",
    id_card: "DNI or ID card",

    role_seller: "Seller",
    role_user: "User",
    role_admin: "Admin",
    role_admin_seller: "Admin & Seller",
    role_user_unverified: "Unverified ID",
    wrong_password: "Wrong password",
    user_not_found: "User not found",
    email_in_use: "Email already in use",
    login_error: "Login error",
    sent_reset_email: "Reset email sent. Check you inbox. If you don't receive it, check the spam folder.",
  },

  //User profile
  username: "Username",
  name: "Name",
  lastname: "Lastname",
  email: "Email",
  phone: "Phone",
  address: "Address",
  address_full: "Full address",
  country: "Country",
  about: "About",
  floor: "Floor",
  city: "City",
  region: "Region",
  zip_code: "Zip code",
  other_info: "Other address info",

  security: "Security",
  password: "Password",
  password_change: "Change password",
  password_old: "Old password",
  password_new: "New password",
  password_confirm_new: "Confirm new password",
  password_req_min: "at least 6 characters",

  seller_settings: "Seller settings",
  allowed_img_format: "Allowed *.png",
  allowed_img_size: " max size of {{value}}",

  //
  all: "All",
  need_help: "Need help?",
  recommended: "Recommended",
  search_instrument: "Search instrument",
  terms_and_conditions: "Terms & Conditions",
  privacy_policy: "Privacy policy",
  i_agree_terms: "I agree to <0>tTerms of Service</0>.",
  must_accept_contract_terms: "You must accept the <0>Contract Terms and Conditions </0> to complete the order.",
  must_sign_contract: "You must sign the <0>Contract of Sales & Subscriptions</0> to complete the order.",
  must_sign_contract_deposit: "You must sign the <0>Deposit Contract</0> to register the instrument.",
  must_accept_terms: "You must accept the the Terms and Conditions.",
  payments_policy: "Payments policy",
  shipping_policy: "Shipping and returns policies",
  secure_payment: "Secure payment",
  shipping: "Shipping",

  // Marketplace or search
  item: "Item",
  where: "Where",
  where_question: "Where?",
  anywhere: "Anywhere",
  how: "How",
  any: "Any",
  distance: "Distance",
  description: "Description",
  instrument_services: "Instrument services",
  which_instrument: "Which instrument",
  deposit: "Deposit",
  pick_up: "Pick up",
  search_location: "Search location",
  more_options: "More options",
  options: "Options",
  instrument: "Instrument",
  accesory: "Accesory",
  price: "Price",
  price_monthly: "Precio per month",
  sale_price: "Sale price",
  sizes: "Sizes",
  select_one: "Select one",
  select_any: "Select any",
  pick_one: "Pick one",
  select_options: "Choose options",
  pick_instrument: "Choose an instrument",
  subscription_min_duration: "Subscription min duration",
  subscription_from_to: "From 3 to 12 months",
  select_price_per_month: "Select price per month",
  select_price_sale: "Select price range",
  search_sales: "Search sales",
  search_sales_description: "Items for sale will be shown",

  months_value: "{{value}} months",
  price_range_value: "from {{min}}€ to {{max}}€",
  price_range_value_month: "from {{min}}€ to {{max}}€ per month",

  // Create product
  product_spec: "Product specification",
  properties: "Properties",
  other_properties: "Other details",
  other_properties_description: "You can add more properties in the next step",
  product_type: "Product type",
  product_types: "Product types",
  product_name: "Product name",
  product_name_placeholder: "Violin Stradivari",
  product_ad_name: "Ad name",

  product_description: "Product description",
  product_description_placeholder: "Relevant information about the product.",
  product_short_description: "Product short description",
  ad_text: "Ad text",
  product_state_title: "Current damages",
  product_state_description: "Describe any damages that the product has",
  additional_info_title: "Additional information about the product",
  additional_info_description: "Any other relevant information about the product",
  product_owner: "Product owner",
  product_owner_search: "Search owner by email...",
  product_customer_search: "Search customer by email...",
  product_search: "Search product...",
  product_not_found: "Product not found",
  user_not_found: "User not found",
  product_address_tip: "Where the product can be found, typically SYMPHY's selected shop.",
  size: "Size",
  brand: "Brand",
  weight_kg: "Estimated weight in kg",
  serial_number: "Serial number",
  year: "Year",
  product_list: "Product list",
  subcontract_list: "Customer contracts",
  transaction_list: "Transactions",

  valuation_price: "Valuation price",
  undefined: "Undefined",
  none: "None",
  what_included: "What is it included?",
  includes: "Includes:",
  owner_share: "Owner's share",
  you_receive: "You receive",
  year_simulation: "Yearly simulation",
  sale_simulation: "Sale simulation",
  fees_and_commissions: "Service fees and commissions",
  admin_fees: "Administration fees and VAT",
  fees: "Administration fees",
  congratulations: "Congratulations!",
  discount_on_fees: "Discount on fees",
  discount_for_referrals: "Discount for referrals acummulated",

  admin_fees_sum: "Administration fees and VAT",
  admin_fees_include: "The administration fees include:",
  admin_fees_tip: "This helps us to keep the platform running, cover the partner, logistic and insurance costs, and offer you the best possible service.",
  admin_fees_tip_vat:
    "This helps us to keep the platform running, cover the partner, logistic and insurance costs, and offer you the best possible service. \nThis includes VAT.",
  price_includes_what: "What does the price include?",
  start_date: "Start date",
  pay_before: "Pay before",
  late_payments: "Late payments",
  late: "Late",
  end_date: "End date",
  needs_validation: "The product needs to be validated by a verified seller before being shown in the ONLINE marketplace.",
  needs_validation_subtitle: "Find your closest SYMPHY partner",
  needs_validation_explain: "Check ok after reviewing all the data",
  product_limit_exceeded: "You have reached the product limit",
  product_limit_exceeded_explain: "To avoid fraudulent uses we have a limit of products per user. To extend it, please contact us.",
  info: "Info",
  seller_needs_verified:
    "We are reviewing your request to be a Partner, contact us if it is not accepted within 5 business days. You will be able to create shops once accepted.",

  needs_valuation_expert: "*The valuation provided has to be verified by a SYMPHY expert. <0>Check our FAQ</0>",
  on_going_contract: "On going contract",
  on_going_contract_explain: "The product can not be displayed in the shop while there is an ongoing contract. It can only be edited by SYMPHY.",

  estimated_value: "Estimated value of {{value}}",
  option_to_buy: "Option to buy",
  option_to_buy_discount: "Option to buy during the first 6 months, with {{value}}% return on previous payments",
  option_to_buy_description: "The user will be able to buy the instrument with a discount based on the payments made",
  pending_payment_tip: "The user must pay the product.",
  discount_value: "Discount: {{value}}",
  no_discount: "No discount",
  // Product details
  brand_new: "Brand new",
  like_new: "Like new",
  used: "Used",
  subscription: "Subscription",
  reserved_until: "Reserved until {{value}}",
  reserve_value: "Reserve: {{value}}",
  reserve_value_tip: "Reserve in the shops for 3 working days for {{value}}",
  reserve_tip_long:
    "The reserve amount will be deducted from the first payment. If the item is not collected within 3 working days, it will be returned in full.",

  after: "After",
  sale: "Sale",
  donation: "Donation",
  subscription_fee: "Subscription fee",
  month: "month",
  twelve_months_contract: "12 months contract",
  also: "Also",
  international_insurance: "International insurance",
  no_search_results: "No results found",
  item_description: "Item description",
  where_item: "Where you'll find it",
  additional_location: "You will receive the exact location and contact details once the product is reserved",
  checkout_title: "Checkout",
  duration: "duration",
  details_title: "Details",
  details_description: "Name, product type, size, images...",
  product_users_title: "Product user",
  product_users_description: "Owner of the instrument",
  shop_details_title: "Online shop listing details",
  shop_details_description: "Data that will be shown in the public online listing",
  shop_name: "Public name to display in the shop",
  shop_description: "Description to show in the marketplace",
  shop_description_placeholder: `Looking for a [instrument name] in great condition? Look no further! This [brand/model] offers amazing sound quality and has been well-maintained.

Key details:
- Condition: Excellent
- Year: [Year]
- Brand: [Brand Name]
- Includes: [Accessories included]

Perfect for beginners or professionals alike, this [instrument name] is ready to bring music into your life.
`,
  show_in_shop: "Display the product in the shop",
  where_to_find_item: "Where to find the product",
  where_to_find_item_desc: "Shop where you have deposited the product.\n(It will be filled automatically if the shop has already validated the item).",
  where_to_deposit_item: "Where to deposit the product",
  where_to_deposit_desc: "Choose the shop where you will take the product.",
  collection_point: "Collection point",

  cart: "Cart",
  order_verification: "Order verification",
  payment: "Payment",
  completed: "Completed",
  your_data: "Your data",
  deposits: "Deposits",
  deposit_tip: "It will be returned once the product is returned in good condition",
  reservation_tip: "It will be deducted from the first payment or returned if the instrument is not collected within 3 working days",

  taxes: "VAT and Taxes",
  expected_monthly_income: "Expected monthly income",
  subs_price_month: "Monthly price",
  after_user_subscribes: "*After the user subscribes",
  contract_urls: "Contract urls",
  fees_and_taxes: "Fees and taxes",
  fees_and_taxes_deducted: "*Fees and taxes will be deducted",
  contract_options: "Contract options",
  allow_short_term: "Allow short term subscriptions",
  allow_short_term_description: "Contracts of 3 or 6 months will be possible. The subscription price will be higher per month.",
  price_subs_auto: "*To promote fair pricings, the price is automatically computed based on the valuation.",
  pricing: "Pricing",
  pricing_description: "Contract types and configuration",
  free_trial: "Prueba sin compromiso",
  verified_100: "100% Verified",
  insured: "Insured",
  fair: "Fair",
  images: "Images",
  image_urls: "Image urls separated by commas",
  search_name_id: "Search by user, names, or ID...",
  info_accurate_certify: "I certify that the information provided is accurate",
  product_updated: "Product updated",
  product_created: "Product created",
  in_shop: "In shop",
  ad: "Ad",
  created_at: "Created at",
  modified: "Modified",

  in_use: "In use",
  no_results_for: "No results found for  <0>{{query}}</0>.<br/>Try checking for typos or using complete words.",
  enter_keywords: "Please enter keywords",
  not_found: "Not found",
  symphy_signature: "SYMPHY's signature",
  user_signature: "USER's signature",

  accessories: {
    title: "Accessories",
    accessories_description: "Bow, red case, etc.",
    case: "Case",
    bow: "Bow",
    strings: "Strings",
    mute: "mute",
    rosin: "rosin",
    tuner: "tuner",
    metronome: "metronome",
  },

  product: {
    title: "Product",
    product_id: "Product id",
    customer: "Customer",
    status: "Status",
    state: "State",
    status_active: "Active",
    status_cancelled: "Cancelled",
    status_paused: "Paused",
    status_reserved: "Reserved",
    status_reservation_expired: "Reservation expired",
    status_reservation_expired_desc: "Reservation expired because the payment has not been completed. Please do the order again.",
    status_draft: "Draft",
    status_completed: "Completed",
    status_pending_verification: "Pending verification",
    status_verification_completed: "Verification completed",
    price_from: "{{value}}/month",
    no_products: "No products",
    no_payments: "No payments",
    buy: "Buy",
    one_payment: "One time payment",
    try_or_change: "If you are not satisfied, you can change it for another or cancel and get a full refund.",
    verified_description: "All products in SYMPHY have been verified and validated by our experts.",
    insured_description: "All products in SYMPHY are insured internationally.",
    fair_description: "All products in SYMPHY have a fair and transparent price.",
    total_to_pay: "Total to be paid: {{value}}",
    contact_details: "Contact details",
    action: "Action",
    nothing_to_do: "Nothing to do",
    verify_instrument_seller_tip: "Confirm that the instrument fields are correct, specially the valuation price.",
    give_instrument_tip: "You can now give the instrument to the user",
    reservations: "Reservations",
    reserved_action_desc:
      "Select the items you want to keep.\nReservations of the other products will be cancelled and you will receive a refund of the pending reservation payments.",
    error_not_found: "Product not found",
    error_listing_not_found: "Ad not found",
    actions_summary: "Actions summary",
    summary: "Summary",
    instrument_taken_shop: "Instrument taken to shop",
    instrument_taken_shop_desc:
      "The instrument has been taken to the shop to be reviewed and deposited.\nThe customer has previously registered the instrument in the platform.\nThe instrument will be visible in your products to verify list.",
    verify_desc:
      "Confirm that the instrument fields are correct, especially the price: •The user must provide an invoice/proof of value. \n•If you do not have it, you must estimate the value. If this is not possible, the instrument will not be validated.\nDo not forget to record any imperfections, damage and condition of the strings in the 'Other properties' section.",
    verify_user_desc: "Confirm that the user's information is correct, especially the ID.",
    store_instrument: "Store instrument",
    store_instrument_desc: "Store the instrument at the shop until another customer requests it",
    step_nr: "Step {{value}}",
    instrument_collection: "Instrument collection",
    instrument_acquisition: "Instrument acquisition",
    instrument_acquisition_desc: "The customer buys or subscribes to a product in the online shop.",
    instrument_acquisition_tip: "The instrument will appear in your stored products list",
    pending_pickup_expired: "It has been more than a week since the payment",
    contract: "Contract",
    current_contract: "Current contract",
    customer_info: "Customer",
    configure_contract_error: "An owner contract has to be created.",
    request_payment_desc: "A payment request will be sent to the customer",
    payment_already_requested: "Payment already requested: {{value}}",
    good_state_desc: "The instrument is in good condition for its use.",
    product_link: "Product link",

    reserved_owner_tip: "The product is reserved for a potential buyer.",
    reserved_seller_tip:
      "The product is reserved.\nThe customer can try it and decide if they want to keep it.\nIn that case, they must make the full payment from their profile.",
  },

  cookies: {
    title: "Cookies",
    title_policy: "Cookies policy",
    banner_title: "Our cookies",
    banner_text:
      "Like most websites you visit we use cookies on your device to improve your experience and understand how we can make our website better. This includes Google Analytics to carry out statistical analysis of page use, page interactions and paths through the Website to make the website better.",
    learn_more: "Learn more",
  },
  about_us: {
    team: "Meet the team",
    we_are_javi_pablo: "we are Javier and Pablo",
    team_subtitle: "Our team will provide you support if you have any problems",
    who: "Who we are?",
    what: "What is SYMPHY?",
    how: "How does it work?",
    where: "Where are we?",
    why_us: "Why us?",
    explain: "Connect, Share, and Subscribe with ease",
    find_us: "Find us on",
    they_recommend_symphy: "They recommend <0>SYMPHY</0>",
    mission: "Our mission",
    mission_description:
      "Our mission is to inspire millions of musicians to connect and empower them to release their true inner artists. We believe that by sharing resources and supporting one another, we can build a stronger, more inclusive music community.",
    any_questions: "Questions or requests",
    any_questions_description: "Would you like to get more information or request an instrument from our catalogue?",
    contact_team: "Contact team",
    pablo:
      "Software expert with over 9 years of experience in the Space industry and app development.\nWith violin musical education.\nHe combines technical expertise with strategic vision and planning to drive innovation and growth",
    javi: "International orchestra cello player, soloist artist and coordinator of several musical projects at European level.\nWith more than 12 years of experience as a cello teacher.\nBrings passion for music and leads the marketing and logistics coordination.",
    why_us_pre: "Empower your musical journey with SYMPHY",
    why_us_sub: "Find out how our platform can help you connect, share and subscribe to musical instruments with a collaborative community",
    card_title: "Variety",
    card_description: "We offer a wide range of instruments from different brands and models.",
    card_title2: "Community & Collaboration",
    card_description2:
      "We connect you to a community of musicians who share your passion and can help you grow as an artist. We believe in the power of collaboration and community building.",
    card_title3: "Security",
    card_description3:
      "You can be confident that your instruments are in safe hands when shared through our platform, so you can focus on what's important - enjoying music.",

    how_title1: "Tell us about yourself",
    how_description1:
      "Creating a profile on SYMPHY is easy. Simply sign up and provide us with information about yourself, such as your experience level, preferred instruments, and location.",
    how_title2: "Find your perfect match",
    how_description2:
      "Once you've created a profile, you can start browsing through potential matches. You can use our search and filter functions to find the perfect match for you.",
    how_title3: "Choose your instrument",
    how_description3:
      "Once you've found a match and subscribed to an instrument, you can arrange to pick it up. Simply contact the instrument's handler and test the instrument. Our platform provides a secure and easy way for you to communicate with the handler, and make the necessary arrangements for pickup and return.",
    how_title4: "Enjoy the process",
    how_description4:
      "We handle all the complications and make sure the subscription process is simple, safe and easy. We also provide resources and support to help you get the most out of the platform, and make sure you have an enjoyable experience.",

    how_tagline1: "Music knows no boundaries",
    how_tagline1_description: "Explore our collection of instruments",
    how_tagline2: "Unlock the doors to endless possibilities",
    how_tagline2_description: "Join now and expand your musical horizons",
    how_tagline2_action: "Join now",
    write_us: "Send us and email to: ",
    where_description:
      "At Symphy, we're proud to serve musicians all over Madrid.\nIf you're not in one of our current locations and are interested in using our marketplace, please contact us as we are currently expanding to other areas.",
    still_questions: "Still have questions?",
    still_questions_description: "Please describe your case to receive the most accurate advice.",
    contact_info: "Contact information",
    contact_info_description: "If you have any questions, please contact us at:",
    company_details: "Company details",
    company_name: "Company name:",
    company_id: "NIF:",
  },

  overview: {
    welcome_back: "Welcome back",
    welcome_back_user: "Welcome back {{name}}",
    message_no_instruments: "It looks like \n you don't have any instruments yet",
    message_no_instruments_description: "Would you like to take a look at our catalogue? \n We have a wide range of instruments available.",
    message_no_iban: "It looks like \n you have not updated your payment details",
    message_no_iban_desc: "In order to receive payments for your sales, you need to add your bank account information.",
    message_no_details: "It looks like \n you have not provided your user details",
    message_no_details_desc: "In order to register your instruments or obtain them, you need to provide your user details.",

    your_instruments: "Your owned instruments",
    instruments_zero: "{{count}} instruments",
    instruments_other: "{{count}} instruments",

    here_to_help: "We are here to help",
    check_faqs: "Check our Frequently Asked Questions",
    check_faqs_description: "We have prepared a list of the most common questions and answers. They answer to 90% of the questions we receive.",
    contact_support: "Contact with our specialized support team",
    contact_support_description: "If you can't find what you're looking for, you can contact our specialized support team. We will be happy to help you.",
    verified: "Verified",
    not_verified: "Not verified",
    your_subscriptions: "Your subscriptions",
    your_subscriptions_description: "Here you can see your active subscriptions",
    value: "Value: {{value}}",
    in_shop: "In the shop",
    in_shop_ad: "Ad in the shop",
    not_in_shop: "Not in the shop",
    not_in_shop_ad: "Ad not in the shop",
    ad_not_online: "Ad not online",
    ad_online: "Ad online",
    rented: "Rented",
    contract_details: "Contract details",
    contract_started: "Contract started",
    payments_of: "Payments of {{value}}",
    payments_of_deposit: "Payments of {{value}} and deposit {{deposit}}",
    subscribed_until: "Subscribed until {{value}}",
    contact_shop: "Contact shop",
    shop_info: "Shop info",
    next_payment: "Next payment",
    error_uploading: "Error uploading file",
    update_successful: "Update successful",
    owned: "Owned",
    subscribed: "Subscribed",
    payments_where: "Where we will make the payments for your sales or rentals",
    date: "Date",
    payment: "Payment",
    other_config: "Other config",
    max_product_upload: "Max product uploads",
    max_product_upload_default: "Max DEFAULT product uploads",
    max_product_upload_seller: "Max SELLER product uploads",
    history: "History",
  },
  emails: {
    order_completed: "Order Completed",
    order_collected: "Order Collected",
    order_not_found: "Order not found",
    order_cancelled: "Order Cancelled",
    article_sold: "Article Sold:",
    refund_received: "Refund received",
    partner_registered: "Partner registered",
    instrument_requested: "Instrument requested",
    referral_subject: "Get €50 by Referring Friends!",

    headline_thanks: "{{name}}, thanks for your order.",
    headline_thanks_deposit: "{{name}}, thanks for registering your item.",
    headline_cancelled: "{{name}}, your order has been cancelled.",
    headline_sold: "{{name}}, your article has been sold.",
    headline_service: "{{name}}, your service has been registered.",
    headline_service_completed: "{{name}}, your service has been completed.",
    headline_service_requested: "{{name}}, a service has been requested.",
    headline_partner_registered: "{{name}}, has registered as a partner.",
    headline_instrument_requested: "An instrument has been requested.",
    headline_referral: "{{name}}, refer friends and get discounts.",

    assignment_contract: "Assignment contract:",
    product_id: "Product id:",
    order_id: "Order id:",
    transaction_id: "Transaction id:",
    contract_id: "Contract id:",

    order_email_collected: "Our SYMPHY partner has confirmed us that you have collected the item.<br>We hope that you enjoy it!",
    order_email_description:
      "We are pleased to inform you that your order has been successfully processed.<br>You can now pick up the items you have requested at the specified stores.",
    order_email_subs_description: "Subscription - {{duration}} months - deposit {{deposit}}",
    order_email_collected_description: "Our Symphy partner has confirmed us that you have collected the item.<br>We hope that you enjoy it!",
    order_email_cancelled: "The order has been cancelled and a refund has been issued to you. It might take up to a business week to be fully processed.",

    error_sending_email: "Error sending email to user",

    instrument_registered: "Instrument Registered",
    luthier_waiting: "Our luthier is waiting for you",
    email_instrument_registered_description:
      "Your instrument has been successfully registered in the SYMPHY platform.<br>You should take it to a SYMPHY partner shop to be validated and deposited. Once the process is completed, it will be visible to other SYMPHY.",
    required_docs: "Required documents:",
    required_docs_list: "•ID card.<br>•Instrument identification (recommended): purchase invoice, appraisals and/or other documentation that proves the value.",
    instrument_deposited: "Instrument Deposited",
    instrument_deposited_description:
      "Your instrument has been successfully validated and deposited in the SYMPHY shop.<br>If you have already created an ad, it is now visible in the SYMPHY shop through the following link:<br><br><a href='{{link}}'>SYMPHY shop</a>",
    instrument_deposited_2:
      "Your instrument has been successfully validated and deposited in the SYMPHY shop.<br>Before it is displayed in the online shop, please create an add here:<br><br><a href='{{link}}'>Publish to shop</a>",
    article_sold_description:
      "Your article has been sold and we have issued a payment to the bank account you have provided to us. Note that the process can take 3 to 5 business days.<br>SYMPHY's commission and taxes have been deducted to the amount paid by the buyer.",
    where_deposit: "Where to take the item:",
    where_deposit_description: "You can find the closest SYMPHY shop <a href='https://symphy.es/shops'>here</a>",
    instrument_deposited_shop: "Where you have deposited the instrument:",
    instrument_to_deposit_shop: "Where to deposit the instrument:",
    instrument_deposited_shop_description: "•Shop: {{shop}}<br>•Address: {{address}}",

    paid_value: "Paid: {{value}}",
    earned: "Earned: {{value}}",

    maintenance_created: "Maintenance created",
    maintenance_created_description: "A maintenance request has been created.<br>We will notify you once it is ready for collection.",
    maintenance_created_description_user: "A maintenance request has been created.<br>Check the work to be done and confirm the service and price.",
    maintenance_completed: "Maintenance completed",
    maintenance_completed_description: "Your instrument service has been completed and it's ready for collection at the SYMPHY shop once the payment is done. ",
    partner_registered_description: "The discount will be valid 15 days after the order is completed and you will receive it in your next payment.",
    instrument_requested_description:
      "A user has requested an instrument:<br>•Name: {{name}}<br>•Email: {{email}}<br>•Phone: {{phone}}<br>•Location: {{location}}<br>•Instrument: {{instrument}}<br>•Sizes: {{sizes}}<br>•Contract Type: {{contractType}}<br>•Price Range: {{priceMin}} - {{priceMax}}<br>•Duration: {{duration}} months",

    services_summary: "Services summary:",
    service_requested: "Service requested",

    referral_description:
      "Invite friends to join us and earn €5 for each friend who completes an order.\nEarn up to a total of €50 in discounts on your next monthly payment.",
    referral_subtext: "Limited Time Offer – One Month to Refer",
    referral_subdescription:
      "You have one month to refer as many friends as you like.\nYour discount will be applied automatically to your next monthly payment, 15 days after each order is completed by your friends.",
  },

  checkout: {
    thanks: "Thanks for your order!",
    payment_processed: "Your payment has been processed successfully",
    payment_processed_error: "There was an error processing your payment, please try again later",
    payment_error_previous: "It looks like you already own some of the items in the cart. Check it and try again",
    payment_registration_error: "There was an error handling the payment, please contact us if the issue persists.",
    collect_item: "You can collect the item at the shop",
    show_seller: "Show the following product code/s in the shop:",
    continue_shopping: "Continue shopping",
    order_id: "Order ID: {{value}}",
    empty_cart: "Cart is Empty!",
    cart_no_items: "Look like you have no items in your shopping cart.",
    order_summary: "Order summary",
    order_paid: "Order paid",

    no_items: "No items",
    vat_included: "VAT included if applicable",
    order_type: "Order type",
    total_price: "Total price",
    other_products: "Other products",
    to_pay: "To pay",
    to_pay_now: "To pay now",
    pay_now: "Pay now",
    total_pay_now: "Total to pay now",
    first_payment: "First monthly payment",
    following_payments: "Following payments",
    monthly_payment: "Monthly payment",
    credit_card_title: "Credit / Debit Card",
    credit_card_description: "We accept all major credit and debit cards such as MasterCard and Visa.",
    authorize_recurrent_payments: "I authorize SYMPHY to charge my selected payment method as defined in the sales and subscription contract.",
    use_new_card: "Use a new card",
    attention: "Attention",
    requires_pickup: "After payment, some products in your cart require pickup in store.",
    unavailable: "Unavailable",
    unavailable_listings: "Some of the items in your cart are not available, please remove then and try again:",
    collected: "Collected",
    pending: "Pending",
    overdue: "Overdue",
    pending_overdue: "Pending & Overdue",
    to_pay_owner: "To pay",
    to_be_paid: "To be paid",
    contracts_value: "{{value}} contracts",
    products_value: "{{value}} products",
    payments_value: "{{value}} pagos",
    late_payments_value_one: "{{count}} payment of {{amount}}",
    late_payments_value_other: "{{count}} payments of {{amount}}",

    late_payments_value_deposit_one: "{{value}} payment of {{amount}} & deposit {{deposit}}",
    late_payments_value_deposit_other: "{{value}} payments of {{amount}} & deposit {{deposit}}",

    save_payment: "Save payment",
    pay_new_card: "Pay with new card",
    pay: "Pay",
    payed: "Payed",
    qty: "Qty",
    unit_price: "Unit",
    total: "Total",
    link: "Link",
    payment_request: "Payment request",
    payment_redirect_tip: "You will be redirected to the payment page",
    payment_redirect_seller_tip: "The user must make the payment to confirm the contract",
    payment_result: "Payment result",
    discount_codes: "Discount codes",
    discount_code_error: "Code ({{value}}) does not exist - try entering your code again.",

    are_you_sure: "Are you sure?",
    cancel_order_confirm: "Your reservation will be cancelled and you will receive a full refund within 5 business days.",
  },
  faq: {
    title: "Frequently asked questions",
    subtitle: "Find the answer to your questions",
    categories: "Categories",

    title1: "About SYMPHY",
    title2: "Subscriptions management",
    title3: "For owners",
    title4: "Payments",
    title5: "Insurance",
    title6: "Partners and shops",
    item11: "What is SYMPHY?",
    item11_description:
      "SYMPHY is much more than a platform; it is a revolution in the world of music. It allows musicians to share instruments with passion and discover the possibility of accessing exceptional instruments at affordable prices. SYMPHY seeks to transform the way musicians obtain their instruments and build a community based on collaboration and mutual support.",
    item12: "Where is SYMPHY active?",
    item12_description: "Symphy is a community that was born in Madrid and aims to expand throughout the entire Spanish territory.",
    item13: "Is it free to use SYMPHY?",
    item13_description:
      "Being part of the Symphy community is completely free. A small commission is charged for each transaction within the platform to continue growing as a community.",
    item14: "What instruments do we collect?",
    item14_description:
      "We currently specialize in stringed instruments of all sizes and ranges. If you have another type of instrument, do not hesitate to contact us.",

    item21: "What insurance do instruments have?",
    item21_description:
      "At SYMPHY, we have implemented an insurance system for the instruments registered on the platform, which is activated when the instrument is delivered. For specific details on how our insurance works and what coverage it offers, we invite you to consult the Terms and Conditions section on our platform at the following link.\nYour peace of mind is essential to us, and we are committed to providing you with a safe and worry-free experience at every step of your SYMPHY experience.",
    item22: "Do I have to pay to list an instrument on the platform? ",
    item22_description: "As part of our launch promotion, listing an instrument on the SYMPHY platform is completely free.",
    item23: "How does Symphy ensure the quality of instruments? ",
    item23_description:
      "At SYMPHY we recognize the vital importance of the quality of musical instruments. We are dedicated to ensuring that all instruments included on our platform meet our quality standards. We conduct regular inspections and evaluations to ensure that instruments are kept in optimal condition and are ready to be used by musicians. This constant vigilance ensures that our users can fully trust the quality and performance of the instruments they find through our platform.",
    item24: "How does the instrument exchange process work at Symphy?",
    item24_description:
      "The exchange process at SYMPHY is designed to be simple and efficient. If you own one or more instruments that you want to share, you can register them on our platform, detailing their unique characteristics and then leave them at our associated collection points where they will be in charge of verifying it. For those interested in using an instrument, they have the possibility to explore the numerous options available in our online catalog and then pick up the instrument at associated places, such as trusted luthiers, specialized stores or music schools. SYMPHY facilitates communication between both parties and guarantees a safe and protected process, ensuring that both owners and musicians can enjoy a reliable and enriching experience.",
    item25: "Documents to provide?",
    item25_description:
      "If you rent:\n-ID card.\n\n\nIf you leave an instrument:\n-ID card.\n-Documentation of the instrument such as invoice, appraisal... We will review it to verify its value.\n-IBAN: for the payments.",
    item26: "Instrument state",
    item26_description:
      "Instruments must be in a state to be played when deposited. If it is not, check with the store about the tuning service.\nInstruments must be returned in the same condition as they were delivered.",
    item27: "Can I reserve instruments?",
    item27_description:
      "Yes, you can reserve instruments for 3 business days. If you decide to keep it, the reservation amount will be deducted from the final price. If you do not collect it, the reservation amount will be refunded.",
    item28_description: "Yes, the deposit will be returned to you once you return the instrument in the same condition as you received it.",

    item31: "Can an owner propose to me to rent an instrument outside the platform?",
    item31_description:
      "In case of renting an instrument that is on the platform without doing it through it you put the platform itself and the user's instruments at risk. SYMPHY is not responsible for damages, thefts and damages derived from rentals or sales made outside the platform.",
    item32: "Can I trust that my instrument will be in good hands when using Symphy?",
    item32_description:
      "Absolutely. At SYMPHY, we care about the care and respect of musical instruments. Users who rent an instrument are committed to keeping it in optimal condition during the rental period. In addition, SYMPHY provides guidance on the proper handling of instruments to ensure their preservation.",
    item33: "How much will I earn?",
    item33_description:
      "The amounts depend on the value of the instrument. You will know the exact number once the instrument has been verified.\nThe rental price is calculated based on the value of the instrument and the duration of the rental.\nTo the total of the rent, the management expenses and our commission must be deducted. At SYMPHY we always try to maximize the owner's profits while maintaining fair prices.",
    item34: "When will I get paid?",
    item34_description:
      "In sales, the money will be transferred once the buyer has confirmed the collection of the instrument.\nIn rentals, the money will be transferred once the contract has ended. The estimated time of the transfer is about three or four days depending on the owner's bank.",

    item35: "Does the instrument have to be in perfect condition?",
    item35_description:
      "Ideally yes, if it is not, we offer in our stores a tuning service to be carried out before it is rented. The cost can be deducted from the first payments received.",

    item41: "Who decides the value of the instruments?",
    item41_description:
      "The instruments will be valued at the time of verification and the user will be proposed in what price range their instrument will be for rental.",
    item42: "Are prices modified according to the duration of the rental?",
    item42_description: "Yes, the prices are modified according to the duration of the rental. The longer the rental, the cheaper the monthly price.",
    item43: "When will I receive the money from the rental?",
    item43_description:
      "Every 3 or 6 months, depending on the duration of the rental, the Symphy platform will automatically send the money to the owner of the instrument, to the IBAN that you provide us in settings. The estimated time of the transfer is about three or four days depending on the owner's bank.",
    item44: "How are the payments done?",
    item44_description: "They will be made either using our online POS, or by bank transfer to our account:\nES49 0182 5723 1002 0163 6979",
    item51: "Do I need insurance as an instrument owner?",
    item51_description:
      "No, the instruments are insured when the user rents it. It is a mandatory insurance that Symphy offers in partnership with Mercaseguros and that we take care of. To find out more about the insurance click <0>here</0>.",
    item52: "What kind of insurance does Symphy offer?",
    item52_description:
      "At SYMPHY, we work hard to make the community a safe place to rent instruments. All items are covered to third parties by our insurance up to a value of € 100,000. The insurance is activated from the moment of delivery until the return. For more information about the insurance coverage click <0>here</0>.",
    item53: "Can I use my own insurance?",
    item53_description: "If you have an insurance that covers damage and theft to third parties, contact us at info@symphy.es.",

    tooltip_upload: "A",
  },
  shops: {
    make_visible: "Make shop visible",
  },
  invoices: {
    preinvoice: "Proforma invoice",
    from: "From",
    to: "To",
    billing_address: "Billing address",
    invoice_date: "Invoice date",
    invoice_number: "Invoice number",
    footer_title: "To Remember",
    footer: "Thanks for choosing SYMPHY. We are committed to providing quality instruments and excellent service to our customers.",
    monthly_payment: "Product {{product}} {{size}}",
    monthly_payment_description: "Pago mensualidad de {{value}}",
    shopping_reference: "Shopping reference",
    invoice_details: "Invoice details",
  },

  services: {
    service_description: "Work to be done, for example: sound post adjustment, string change, cleaning...",
    price_with_taxes: "Price (incl. taxes)",
    history: "Service history",
    status_requested: "Requested",
    status_requested_tip: "After reviewing the instrument, add the service information and price to the request.",
    status_ongoing: "On Going",
    status_ongoing_tip: "Change to ready when the service has been completed. Then you can request the payment.",
    status_ongoing_desc:
      "If the work has been completed, click on continue. An email will be sent to the user to collect the instrument and to do the payment. You can get their contact information by hovering over their email.",

    status_ready: "Ready",
    status_ready_collection: "Ready for collection",
    status_ready_desc: "The payment has been received. Give the instrument to the user.",
    status_picked_up: "Picked up",

    status_pending_payment_desc: "The user must make the payment to collect the instrument. Send payment request again?",

    transaction_items: "Transaction items",

    refunds_value: "Refunds: {{value}}",
    deposits_value: "Deposits: {{value}}",
    discounted_monthly: "Discounted monthly payment",
    discounts_value: "Discounts: {{value}}",
    reservation_value: "Reservation: {{value}}",
    price_with_reservation_value: "Paid: {{value}} - Reservation: {{value2}}",
    service_name: "Service name",
    payment_status: "Payment status",
    transaction_type: "Transaction type",
    created_date: "Created date",
    end_date: "End date",
    toolbar_filer: "Customer, transaction id  or shop, product...",

    create_title: "Service an instrument",
    create_description: "Anything required, from wood work, cleaning, tuning...",
    instruments_in_maintenance: "Instruments in maintenance",
    offered_services: "Offered services",

    maintenance: "Maintenance",
    valuation: "Valuation",
    storage: "Storage",
    sale: "Sale",
    subscription: "Subscription",
    shop: "Shop",
    shop_pickup: "Shop pickup",
    free: "Free",
    visible: "Visible",
    show_services: "Show services",
    hide_services: "Hide services",
    max_price: "Max price",
    min_price: "Min price",

    type: "Service type",
    can_user_verify: "Can the user verify instruments?",
    enable_verify: "Enabling this will allow the seller to validate instruments",
    are_you_seller: "Are you a seller?",
    enable_seller: "Enabling this will allow you to offer services in SYMPHY",
    enable_verified_seller: "Enable to show the seller's shops in SYMPHY",
    verified_seller: "Verified seller",
    offer_more: "Contact SYMPHY's support to add more services to your shop",
    no_services: "No services",
    instrument_valuation: "Instrument valuation",
    instrument_maintenance: "Instrument maintenance",
    prices_from_to: "Prices from {{from}} to {{to}}",
    for: "For",

    service_info: "Service information",
    where_to_do_service: "Where to do the service",
    where_to_do_valuation: "Where to do the valuation",

    service_payment_pending: "Service payment pending. After payment, the instrument can be collected.",
    seller_pending_check: "The seller must confirm the service.",
    ongoing_tip: "You will be notified when the service is completed to collect the instrument. You can now make the payment.",

    verify_user_data: "Verify user data",
    verify_user_data_desc: "Confirm that the user's information is correct, especially the ID.",
    add_contract_data: "Add contract data",

    payment_received: "Payment received",
    product_verified: "Product verified",
    product_verified_successfully: "Product verified successfully.\nIt can be shown in the online shop.",
    maintenance_service: "Maintenance service",
    show_qr_shop: "Show QR code in the shop",
    show_qr_shop_desc: "You can show it at the shop to identify the product quickly.",
    take_shop_qr: "Take it to the shop to be verified. You can show the QR code in the shop to the seller.",
    take_shop_explain:
      "To publish your product, you must take it to a SYMPHY store for verification by one of our experts. Once verified, you will be able to post your listing so that other users can see it.\nRemember, the instrument will stay in the store for as long as you like, as it will serve as the pickup point.\nWould you like to choose a store now?",
  },
};

export default en;
