import { forwardRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKAvatar
import MKAvatarRoot from "components/MKAvatar/MKAvatarRoot";

const MKAvatar = forwardRef(({ bgColor, size, shadow, src, ...rest }, ref) => {
  // State to track whether the image has failed to load
  const [imageFailed, setImageFailed] = useState(false);

  // Handler when image fails to load
  const handleImageError = () => {
    setImageFailed(true);
  };

  return (
    <MKAvatarRoot
      ref={ref}
      ownerState={{ shadow, bgColor, size, src, imageFailed }}
      src={src}
      onError={handleImageError} // Add onError event handler
      {...rest}
    />
  );
});
// Setting default values for the props of MKAvatar
MKAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
};

// Typechecking props for the MKAvatar
MKAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default MKAvatar;
