import { getMapValueForKey } from "src/utils/typeConverters";
import Utils from "../Utils";
import Service from "../marketplace/Service";
import ContactInfo from "./ContactInfo";
import { Location } from "model/contracts/Location";

export default class Shop {
  constructor(id, visible, name, description, location, services = [], user_id, contact_info = [], distance = [], metadata = {}, ...otherProps) {
    this.id = id;
    this.visible = visible;
    this.name = name;
    this.description = description;
    this.location = Location.fromJSON(location);
    this.services = services;
    this.user_id = user_id;
    this.contact_info = contact_info; // Array with 0 or 1 item
    this.distance = distance;
    this.metadata = metadata;

    Object.assign(this, Utils.flattenAndMergeProps(otherProps));
  }

  static fromJSON(shopJSON) {
    const { id, visible, name, description, location, services, user_id, contact_info, distance, metadata, ...otherProps } = shopJSON;
    return new Shop(
      id,
      visible,
      name,
      description,
      location,
      services ? services.map((service) => Service.fromJSON(service)) : [],
      user_id,
      contact_info ? [ContactInfo.fromJSON(contact_info)] : [],
      distance ? [distance] : [],
      metadata,
      otherProps
    );
  }

  clone() {
    return new Shop(
      this.id,
      this.visible,
      this.name,
      this.description,
      this.location,
      this.services.map((service) => service.clone()),
      this.user_id,
      this.contact_info,
      this.distance,
      this.metadata,
      Utils.cloneOtherProps(this, ["id", "visible", "name", "description", "location", "services", "user_id", "contact_info", "distance", "metadata"])
    );
  }

  toBackendObject() {
    return {
      id: this.id,
      visible: this.visible,
      user_id: this.user_id,
      name: this.name,
      description: this.description,
      location: this.location,
      services: this.servicesToBackendObject(),
      contact_info: this.contact_info,
      metadata: this.metadata,
    };
  }

  // Convert services to backend objects
  servicesToBackendObject() {
    return this.services.map((service) => service.toBackendObject());
  }

  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  getLocationMetadataValue(key) {
    const value = getMapValueForKey(this.location.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  /**
   * Maps shops to their services and generates a list of all services from all shops.
   *
   * @param {Array} shops - List of shops.
   * @returns {Object} An object containing two properties:
   *  - shopToServicesMap: A map of shop IDs to their services.
   *  - allServices: A list of all services from all shops.
   */
  static mapShopsToServices(shops) {
    const shopToServicesMap = new Map();
    const allServices = [];

    shops.forEach((shop) => {
      shopToServicesMap.set(shop.id, shop.services);
      allServices.push(...shop.services);
    });

    return { shopToServicesMap, allServices };
  }

  // ContactInfo methods
  getPhone(printable = false) {
    return this.contact_info.length > 0 ? this.contact_info[0].getPhone() : printable ? "" : null;
  }

  hasPhone() {
    return this.contact_info.length > 0 && this.contact_info[0].hasPhone();
  }

  getEmail(printable = false) {
    return this.contact_info.length > 0 ? this.contact_info[0].getEmail() : printable ? "" : null;
  }

  hasEmail() {
    return this.contact_info.length > 0 && this.contact_info[0].hasEmail();
  }

  hasStorageService() {
    return this.services.some((service) => service.service_type.isStorage());
  }

  hasValuationService() {
    return this.services.some((service) => service.service_type.isValuation());
  }

  hasMaintenanceService() {
    return this.services.some((service) => service.service_type.isMaintenance());
  }

  // Method to get the maintenance service
  getMaintenanceService() {
    return this.services.find((service) => service.service_type.isMaintenance());
  }

  // Method to get the storage service
  getStorageService() {
    return this.services.find((service) => service.service_type.isStorage());
  }

  // Method to get the valuation service
  getValuationService() {
    return this.services.find((service) => service.service_type.isValuation());
  }

  getAddress(truncate) {
    return this.location.getAddress(truncate);
  }

  getCity(truncate) {
    return this.location.getCity(truncate);
  }

  static shopsHaveMaintenanceService(shops) {
    return shops.some((shop) => shop.hasMaintenanceService());
  }
}
