import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
//
import { navHorizontalConfig } from '../config';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, config, sx, ...other }) {
  const currentRoles = config.currentRole.length > 0 ? config.currentRole : [];
  return (
    <Stack
      direction="row"
      sx={{
        mx: 'auto',
        ...hideScroll.y,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => {
        // ADDED
        const groupRoles = group.roles;
        if (typeof groupRoles === "undefined" ||
          groupRoles.some((r) => currentRoles.includes(r))) {
          return (
            <Group
              key={group.subheader || index}
              items={group.items}
              config={navHorizontalConfig(config)}
            />
          );
        }
      })}
    </Stack>
  );
}

NavSectionHorizontal.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
};

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

function Group({ items, config }) {
  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
};
