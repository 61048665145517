import { getMapValueForKey } from "src/utils/typeConverters";
import { truncateText } from "src/utils/uiUtils";

export class Location {
  constructor({ address = "", lng = 0.0, lat = 0.0, metadata = {}, ...otherProps } = {}) {
    this.address = address;
    this.lng = parseFloat(lng);
    this.lat = parseFloat(lat);
    this.metadata = metadata;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(locationJSON) {
    if (!locationJSON) return null;
    const { address, lng, lat, metadata, ...otherProps } = locationJSON;
    return new Location({
      address: address || "",
      lng: parseFloat(lng) || 0.0,
      lat: parseFloat(lat) || 0.0,
      metadata: metadata || {},
      ...otherProps,
    });
  }

  clone() {
    return new Location({
      address: this.address,
      lng: this.lng,
      lat: this.lat,
      metadata: this.metadata,
    });
  }

  /**
   * Convert to JSON representation
   */
  toJSON() {
    return {
      address: this.address,
      lng: this.lng,
      lat: this.lat,
      metadata: this.metadata,
    };
  }

  /**
   * Update location's coordinates
   * @param {number} lat - latitude
   * @param {number} lng - longitude
   */
  updateCoordinates(lat, lng) {
    this.lat = parseFloat(lat);
    this.lng = parseFloat(lng);
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    // const entry = this.metadata.find(([entryKey]) => entryKey === key);
    return value && value.length > 0 ? value : null;
  }

  getAddress(truncate) {
    return truncate ? truncateText(this.address, 30) : this.address;
  }

  getCity(truncate) {
    const text = this.getMetadataValue("city") ? this.getMetadataValue("city") : this.address;
    return truncate ? truncateText(text, 30) : text;
  }

  getZip() {
    return this.getMetadataValue("zip_code");
  }
}
