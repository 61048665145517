import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { TYPE_ADMIN, TYPE_SELLER_STORED, TYPE_SELLER_TO_VERIFY, TYPE_USER } from 'services/ProductsService';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { PATH_DASHBOARD, ROOTS_MANAGEMENT_INVOICES, ROOTS_MANAGEMENT_USERS, ROOTS_PRODUCT } from '../paths';
import { ProductDetailsPage } from './main';

// ----------------------------------------------------------------------

// TEST DATA GENERATOR
const TestDataGen = lazy(() => import('../../pages/TestDataGen'));
// SELLER
const SellerApp = lazy(() => import('../../pages/dashboard/seller/seller-app'));
// const  ServiceDetail = lazy(() => import('../../pages/ServiceDetail')));
const MaintenanceServicePage = lazy(() => import('../../pages/dashboard/seller/maintenance-service-page'));
// PRODUCT
const ProductListPage = lazy(() => import('../../pages/list/ProductListPage'));
const ProductEditPage = lazy(() => import("../../pages/dashboard/product/edit"));
const ProductCreatePage = lazy(() => import("../../pages/dashboard/product/new"));
const ProductDefinitionListPage = lazy(() => import('../../pages/list/ProductDefinitionListPage'));
const ProductRequestListPage = lazy(() => import('../../pages/list/ProductRequestListPage'));
const ProductOverviewPage = lazy(() => import("../../pages/product/overview"));

// USER
const UserAccountPage = lazy(() => import('../../pages/dashboard/user/account'));
const UserListPage = lazy(() => import('../../pages/dashboard/user/UserListPage'));
// CONTRACTS 
const ContractListPage = lazy(() => import('../../pages/list/ContractListPage'));
const SubcontractListPage = lazy(() => import('../../pages/dashboard/subcontract/list'));
const ProductRecommendedContractListPage = lazy(() => import('../../pages/list/ProductRecommendedContractListPage'));
// DISCOUNTS AND FEES
const DiscountListPage = lazy(() => import('../../pages/list/DiscountListPage'));
const FeeListPage = lazy(() => import('../../pages/list/FeeListPage'));
// OVERVIEW
const UserApp = lazy(() => import('../../pages/overview/user-app'));
// TRANSACTIONS
const TransactionsListPage = lazy(() => import("../../pages/dashboard/transactions/list"));

// INVOICE
// const InvoiceListPage = lazy(() => import('../../pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import("../../pages/dashboard/invoice/details"));
const InvoiceCreatePage = lazy(() => import("../../pages/dashboard/invoice/new"));
const InvoiceEditPage = lazy(() => import("../../pages/dashboard/invoice/edit"));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          {/* <Suspense fallback={<LoadingScreen />}> */}
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },\
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      // { element: <UserApp />, index: true },
      // TEST DATA GENERATOR
      { path: PATH_DASHBOARD.general.root.name, element: <UserApp /> },
      { path: PATH_DASHBOARD.general.root_ok.name, element: <UserApp /> },
      { path: PATH_DASHBOARD.general.root_nok.name, element: <UserApp /> },
      { path: PATH_DASHBOARD.test_data.name, element: <TestDataGen /> },
      // OVERVIEW
      {
        path: PATH_DASHBOARD.general.seller.root.name,
        children: [
          { element: <MaintenanceServicePage />, index: true },
          // { path: PATH_DASHBOARD.general.seller.seller.name, element: <SellerApp /> },
          { path: PATH_DASHBOARD.general.seller.seller.name, element: <MaintenanceServicePage /> },
          { path: PATH_DASHBOARD.general.seller.verify.name, element: <ProductListPage userType={TYPE_SELLER_TO_VERIFY} /> },
        ],
      },
      // { path: PATH_DASHBOARD.general.player.name, element: <PlayerArea /> },
      // USER ACCOUNT
      { path: PATH_DASHBOARD.settings.root.name, element: <UserAccountPage /> },
      // PRODUCT
      {
        path: PATH_DASHBOARD.product.root.name,
        children: [
          { element: <ProductListPage userType={TYPE_USER} />, index: true },
          { path: PATH_DASHBOARD.product.list.name, element: <ProductListPage userType={TYPE_USER} /> },
          { path: PATH_DASHBOARD.product.list_ok.name, element: <ProductListPage userType={TYPE_USER} /> },
          { path: PATH_DASHBOARD.product.list_nok.name, element: <ProductListPage userType={TYPE_USER} /> },
          // { path: PATH_DASHBOARD.product.new.name, element: <ShopProductCreateEditPage /> },
          { path: PATH_DASHBOARD.product.new.name, element: <ProductCreatePage /> },
          // { path: PATH_DASHBOARD.product.listings.name, element: <ProductListPage userType={TYPE_USER} listType={LIST_TYPE_LISTING} /> },
          // { path: ROOTS_PRODUCT + "/:id/edit", element: <ShopProductCreateEditPage /> },
          { path: ROOTS_PRODUCT + "/:id/edit", element: <ProductEditPage /> },
          { path: ROOTS_PRODUCT + "/:id", element: <ProductDetailsPage /> },

          { path: ROOTS_PRODUCT + "/:id/overview", element: <ProductOverviewPage /> },

          { path: PATH_DASHBOARD.product.seller.verify.name, element: <ProductListPage userType={TYPE_SELLER_TO_VERIFY} /> },
          { path: PATH_DASHBOARD.product.seller.stored_products.name, element: <ProductListPage userType={TYPE_SELLER_STORED} /> },
        ],
      },
      // MANAGEMENT
      {
        // Dashboard routes
        path: PATH_DASHBOARD.management.root.name,
        children: [
          { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
          // USERS
          {
            path: PATH_DASHBOARD.management.users.root.name,
            children: [
              { element: <UserListPage />, index: true },
              { path: PATH_DASHBOARD.management.users.root.name, element: <UserListPage /> },
              { path: ROOTS_MANAGEMENT_USERS + "/:id/edit", element: <UserAccountPage /> },
            ],
          },
          // MARKETPLACE
          {
            path: PATH_DASHBOARD.management.marketplace.root.name,
            children: [
              { element: <ProductListPage userType={TYPE_ADMIN} />, index: true },
              { path: PATH_DASHBOARD.management.marketplace.product.name, element: <ProductListPage userType={TYPE_ADMIN} /> },
              // { path: PATH_DASHBOARD.management.marketplace.listings.name, element: <ProductListPage userType={TYPE_ADMIN} listType={LIST_TYPE_LISTING} /> },
              { path: PATH_DASHBOARD.management.marketplace.product_requests.name, element: <ProductRequestListPage /> },
            ],
          },
          // CONTRACTS
          {
            path: PATH_DASHBOARD.management.contracts.root.name,
            children: [
              { element: <ContractListPage userType={TYPE_ADMIN} />, index: true },
              // Contracts
              { path: PATH_DASHBOARD.management.contracts.contracts.name, element: <ContractListPage userType={TYPE_ADMIN} /> },
              { path: PATH_DASHBOARD.management.contracts.subcontracts.name, element: <SubcontractListPage /> },
              { path: PATH_DASHBOARD.management.contracts.subcontracts_ok.name, element: <SubcontractListPage /> },
              { path: PATH_DASHBOARD.management.contracts.subcontracts_nok.name, element: <SubcontractListPage /> },
              { path: PATH_DASHBOARD.management.contracts.transactions.name, element: <TransactionsListPage /> },
            ],
          },
          // INVOICES
          {
            path: PATH_DASHBOARD.management.invoices.root.name,
            children: [
              // { element: <InvoiceListPage />, index: true },
              { element: <SubcontractListPage />, index: true },
              // Contracts
              { path: PATH_DASHBOARD.management.invoices.invoices.name, element: <SubcontractListPage /> },
              { path: ROOTS_MANAGEMENT_INVOICES + "/:id", element: <InvoiceDetailsPage /> },
              { path: PATH_DASHBOARD.management.invoices.detail.name, element: <InvoiceDetailsPage /> },
              { path: ROOTS_MANAGEMENT_INVOICES + "/:id/edit", element: <InvoiceEditPage /> },
              { path: PATH_DASHBOARD.management.invoices.new.name, element: <InvoiceCreatePage /> },
            ],
          },
          // PLATFORM CONFIG
          {
            path: PATH_DASHBOARD.management.config.root.name,
            children: [
              { element: <ProductDefinitionListPage />, index: true },
              { path: PATH_DASHBOARD.management.config.product_definitions.name, element: <ProductDefinitionListPage /> },
              { path: PATH_DASHBOARD.management.config.recommended_contracts.name, element: <ProductRecommendedContractListPage /> },
              { path: PATH_DASHBOARD.management.config.discounts.name, element: <DiscountListPage /> },
              { path: PATH_DASHBOARD.management.config.fees.name, element: <FeeListPage /> },
            ],
          },
        ],
      },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
    ],
  },
];
