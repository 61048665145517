import { useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href) => navigate(href),
      navigate: (href, state, replace = false) => navigate(href, { state: state, replace: replace }),
      replace: (href) => navigate(href, { replace: true }),
      replace_with_params: (href, params) => {
        console.log("useRouter: replace_with_params href = " + href + " params = " + JSON.stringify(params));
        navigate(
          href,
          {
            replace: true,
            search: `?${createSearchParams({ query: params })}`,
            state: { query: params },
          },
          // { state: { query: params } }
        );
      },
    }),
    [navigate]
  );

  return router;
}
