//
import merge from 'lodash/merge';
//
import { badge } from './components/badge';
import { button } from './components/button';
import MuiDatePicker, { datePicker } from './components/date-picker';
import { list } from './components/list';
import LoadingButton, { loadingButton } from './components/loading-button';
import { table } from './components/table';

// import Input from './Input';


// Theme landing
import themeLanding from "assets/theme";
import { accordion } from './components/accordion';
import { alert } from "./components/alert";
import { appBar } from './components/appbar';
import { autocomplete } from "./components/autocomplete";
import { backdrop } from "./components/backdrop";
import { breadcrumbs } from "./components/breadcrumbs";
import { buttonGroup } from "./components/button-group";
import { card } from './components/card';
import { checkbox } from "./components/checkbox";
import { chip } from './components/chip';
import { cssBaseline } from "./components/css-baseline";
import { dataGrid } from "./components/data-grid";
import { fab } from './components/fab';
import { menu } from './components/menu';
import { pagination } from "./components/pagination";
import { paper } from './components/paper';
import { popover } from "./components/popover";
import { progress } from "./components/progress";
import { radio } from './components/radio';
import { select } from "./components/select";
import { skeleton } from "./components/skeleton";
import { stepper } from "./components/stepper";
import { svgIcon } from "./components/svg-icon";
import { switches } from "./components/switch";
import { tabs } from './components/tabs';
import { textField } from "./components/textfield";
import { timeline } from "./components/timeline";
import { toggleButton } from "./components/toggle-button";
import { tooltip } from "./components/tooltip";
import { treeView } from "./components/tree-view";
import { typography } from "./components/typography";
import { defaultProps } from './default-props';
import { rating } from './components/rating';
import { dialog } from './components/dialog';
import { avatar } from './components/avatar';
import { slider } from './components/slider';
import { drawer } from './components/drawer';

// ----------------------------------------------------------------------

export function componentsOverrides(theme) {
  // const dashboardComponents = Object.assign(
  //   Fab(theme),
  //   Tabs(theme),
  //   Chip(theme),
  //   Card(theme),
  //   Menu(theme),
  //   Link(theme),
  //   Input(theme),
  //   Radio(theme),
  //   Badge(theme),
  //   Lists(theme),
  //   Table(theme),
  //   Paper(theme),
  //   Alert(theme),
  //   Switch(theme),
  //   Select(theme),
  //   Button(theme),
  //   Rating(theme),
  //   Dialog(theme),
  //   Avatar(theme),
  //   Slider(theme),
  //   Drawer(theme),
  //   Stepper(theme),
  //   Tooltip(theme),
  //   Popover(theme),
  //   SvgIcon(theme),
  //   Checkbox(theme),
  //   DataGrid(theme),
  //   Skeleton(theme),
  //   Timeline(theme),
  //   TreeView(theme),
  //   Backdrop(theme),
  //   Progress(theme),
  //   Accordion(theme),
  //   DatePicker(theme),
  //   Typography(theme),
  //   Pagination(theme),
  //   ButtonGroup(theme),
  //   Breadcrumbs(theme),
  //   Autocomplete(theme),
  //   ControlLabel(theme),
  //   ToggleButton(theme),
  //   LoadingButton(theme)
  // );

  const dashboardComponents = merge(
    defaultProps(theme),
    //
    fab(theme),
    tabs(theme),
    chip(theme),
    card(theme),
    menu(theme),
    // Link(theme),
    list(theme),

    // Stack(theme),
    badge(theme),
    // Lists(theme),
    table(theme),
    paper(theme),
    alert(theme),
    radio(theme),
    select(theme),
    button(theme),
    rating(theme),
    dialog(theme),
    appBar(theme),
    avatar(theme),
    slider(theme),
    drawer(theme),
    stepper(theme),
    tooltip(theme),
    popover(theme),
    svgIcon(theme),
    switches(theme),
    checkbox(theme),
    dataGrid(theme),
    skeleton(theme),
    timeline(theme),
    treeView(theme),
    backdrop(theme),
    progress(theme),
    textField(theme),
    accordion(theme),
    typography(theme),
    pagination(theme),
    datePicker(theme),
    buttonGroup(theme),
    breadcrumbs(theme),
    cssBaseline(theme),
    autocomplete(theme),
    toggleButton(theme),
    // MuiDatePicker(theme),
    loadingButton(theme)
  );

  // Added code to merge themes
  const mergedComponents = themeLanding.components;
  for (const [key, value] of Object.entries(dashboardComponents)) {
    mergedComponents[key] = value;
  }
  return mergedComponents;
}
