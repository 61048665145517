// ----------------------------------------------------------------------



export function delay(ms) {
  return new Promise(
    resolve => setTimeout(resolve, ms ? ms : 1500)
  );
}

export function addAlphaToHexColor(color, opacity) {
  //if it has an alpha, remove it
  if (color.length > 7)
    color = color.substring(0, color.length - 2);

  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  let opacityHex = _opacity.toString(16).toUpperCase()

  // opacities near 0 need a trailing 0
  if (opacityHex.length === 1)
    opacityHex = "0" + opacityHex;

  return color + opacityHex;
}

export function hideScrollBars(isMobile) {
  // Avoid bug with scrollbar appearing 
  if (!isMobile) {
    document.body.style.overflow = 'hidden';
  }
}

export function truncateText(str, length) {
  const ending = "...";
  if (length == null) {
    length = 100;
  }
  if (str && str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export function validateEmail(email) {
  // Use a regular expression or any other method to validate the email address
  // Here's a simple example using a regex pattern
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export function formatIBAN(iban) {
  if (!iban) return "";
  // Remove any non-alphanumeric characters that aren't part of a standard IBAN
  const formatted = iban.replace(/[^A-Z0-9]+/gi, "").toUpperCase();
  // Add spaces every four characters
  return formatted.replace(/(.{4})/g, "$1 ").trim();
}

export function removeSpaces(text) {
  return text.replace(/[\s\-]/g, "");
}