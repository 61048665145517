/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail, signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword
} from 'firebase/auth';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
// config
import { AuthClient } from '@dfinity/auth-client';
import { idlFactory as idlFactoryMarketplace, marketplace } from "../../../../../../declarations/marketplace";
import { idlFactory as idlFactoryPayments, payments } from "../../../../../../declarations/payments";
import { idlFactory as idlFactoryProducts, products } from "../../../../../../declarations/products";
import { idlFactory as idlFactoryUsers, users } from "../../../../../../declarations/users";

import UserService from '../../../../services/UserService';
import { BACKEND_HOST, FIREBASE_API, FIREBASE_FUNCTIONS_URL, G_ANALYTICS, IS_PROD } from '../../../config-global';

import { getIIDerivationOrigin, getIIIdentityProvider } from "./utils";

// Analytics
import { Actor, HttpAgent } from '@dfinity/agent';
import { Secp256k1KeyIdentity } from "@dfinity/identity-secp256k1";
import axios from "axios";
import ReactGA from "react-ga4";
import { AuthContext } from './auth-context';


// ----------------------------------------------------------------------
const SESSION_TIMEOUT_NANOS = BigInt(30 * 24 * 60 * 60 * 1000 * 1000 * 1000); // 30 days

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------
// Generate a map for backendCanister with canisterId and name as keys
// Separate the users canister to make login faster
const usersCanister = {
  idlFactory: idlFactoryUsers,
  canisterId: process.env.CANISTER_ID_USERS,
  backend: users,
};
const backendCanisterMap = {
  users: usersCanister,
  products: {
    idlFactory: idlFactoryProducts,
    canisterId: process.env.CANISTER_ID_PRODUCTS,
    backend: products,
  },
  marketplace: {
    idlFactory: idlFactoryMarketplace,
    canisterId: process.env.CANISTER_ID_MARKETPLACE,
    backend: marketplace,
  },
  payments: {
    idlFactory: idlFactoryPayments,
    canisterId: process.env.CANISTER_ID_PAYMENTS,
    backend: payments,
  },
};

// Auth method used for internet idendity
const AUTH_METHOD_II = 'II';
// Auth method used for firebase
const AUTH_METHOD_FIREBASE = 'firebase';

const initialState = {
  user: null,
  symphy_backend: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    const {user, symphy_backend } = action.payload;
    return {
      user: user,
      symphy_backend: symphy_backend,
      loading: false,
    };
  }
  if (action.type === 'LOGIN') {
    const { user, symphy_backend } = action.payload;
    return {
      ...state,
      user: user,
      symphy_backend: symphy_backend,
      loading: false,
    };
  }
  if (action.type === 'REGISTER') {
    const { user, symphy_backend } = action.payload;
    return {
      ...state,
      user: user,
      symphy_backend: symphy_backend,
      loading: false,
    };
  }
  if (action.type === 'LOGOUT') {
    const { symphy_backend } = action.payload;
    return {
      ...state,
      user: null,
      symphy_backend: symphy_backend,
      loading: false,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);
// AUTH.setPersistence( browserLocalPersistence);
const GOOGLE_PROVIDER = new GoogleAuthProvider();

if (IS_PROD) ReactGA.initialize(G_ANALYTICS);

// THree auth providers are used: Firebase -> Web3Auth -> II
// If the user logs in direclty with II the other two methods are not required
// If the user logs in with Firebase, a session is created in Web3Auth to obtain its privateKey and then a II
// Identity is created based on that private key

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authClient, setAuthClient] = useState(null);

  /**
   * Initializes the connection to the different backend canisters. If identity null only anonymous queries are allowed
   *
   * @param {*} authClient The Aunthenticated client
   */
  const initBackendCanisters = useCallback(async (identity, allCanisters, usersBackend) => {
    // At this point we're authenticated, and we can get the identity from the auth client:
    // Using the identity obtained from the auth client, we can create an agent to interact with the IC.
    const symphy_backend = usersBackend && usersBackend.backend ? { ...usersBackend.backend } : {};
    const agent = usersBackend && usersBackend.agent ? usersBackend.agent : null;
    // const isMainnet = process.env.DFX_NETWORK === "ic";
    // const host = isMainnet ? "https://icp-api.io" : "http://" + BACKEND_HOST;
    // const agent = new HttpAgent({ identity: identity, host, retryTimes: 5 });
    //     if (!isMainnet) {
    //       agent.fetchRootKey();
    //     }
    // Iterate backendCanisterMap and create an actor using the corresponding actor creator function
    for (const [canisterName, { canisterId, idlFactory, backend }] of Object.entries(backendCanisterMap)) {
      // Users only if allcnaisters true
      if (canisterName === "users" && !allCanisters) {
        continue;
      }
      if (identity) {
        // If creating the acto like this, the identity is trimmed
        // const actor = createActor(canisterId, { agentOptions });
        // Authenticated backend
        const actor = Actor.createActor(idlFactory, {
          agent,
          canisterId: canisterId,
        });
        // Add actor to symphy_backend map
        symphy_backend[canisterName] = actor;
      } else {
        // Anonimous queries
        symphy_backend[canisterName] = backend;
      }
    }
    console.log("finish initBackendCanisters ");

    // console.log("finish initBackendCanisters " + JSON.stringify(symphy_backend));
    // Store the identity in local storage
    return symphy_backend;
  }, []);
  /**
   * Init backend with anonym user
   */
  const resetBackendCanisters = useCallback(async () => {
    return await initBackendCanisters(null, true, null);
  }, [initBackendCanisters]);

  /**
   * Initializes only the users canister
   *
   * @param {*} authClient The Aunthenticated client
   */
  const initUsersCanister = async (identity) => {
    // At this point we're authenticated, and we can get the identity from the auth client:
    // Using the identity obtained from the auth client, we can create an agent to interact with the IC.
    // console.log("initUsersCanister");
    const symphy_backend = {};
    const isMainnet = process.env.DFX_NETWORK === "ic";
    const host = isMainnet ? "https://icp-api.io" : "http://" + BACKEND_HOST;
    const agent = new HttpAgent({ identity: identity, host, retryTimes: 5 });
    if (!isMainnet) {
      agent.fetchRootKey();
    }

    if (identity) {
        // Authenticated backend
        const actor = Actor.createActor(usersCanister.idlFactory, {
          agent,
          canisterId: usersCanister.canisterId,
        });
        // Add actor to symphy_backend map
        symphy_backend.users = actor;
      } else {
        // Anonymous queries
        symphy_backend.users = backend;
      }
    // Store the identity in local storage
    return {backend: symphy_backend, agent: agent};
  };

  /**
   * Refreshes the user from the backend
   */
  const refreshUser = useCallback(async (symphy_backend) => {
    return await UserService.getBackendUser(symphy_backend);
  }, []);

  // Gets and updates local user then dispatch state
  const getUserAndFinishInit = useCallback(
    async (users_backend, identity) => {
      let user = UserService.getLocalUser();
      // If authenticated and using local user, and remote service available
      if (!user || user.isLocal) {
        // Refresh user
        user = await refreshUser(users_backend.backend);
      }
      dispatchInitial(user, users_backend.backend);
      const symphy_backend = await initBackendCanisters(identity, false, users_backend);
      dispatchLogin(user, symphy_backend);
    },
    [refreshUser]
  );

  // Gets dispatch init state without user
  const finishInitWithError = useCallback((symphy_backend) => {
    dispatchInitial(null, symphy_backend);
  }, []);

  // LOGOUT
  const logoutFirebase = useCallback(async () => {
    localStorage.removeItem("userSession");
    signOut(AUTH);
  }, []);

  const logout = useCallback(
    async (clean = true) => {
      if (authClient) {
        await authClient.logout();
      }
      if (clean) {
        logoutFirebase();
        UserService.deleteLocalUser();
      }
      // Refresh backend connection to use queries that dont required auth
      const backend_no_auth = await resetBackendCanisters();
      dispatch({
        type: "LOGOUT",
        payload: {
          symphy_backend: backend_no_auth,
        },
      });
    },
    [authClient, logoutFirebase, resetBackendCanisters]
  );

  // When storing the state, some properties seems to be lost
  const initII = useCallback(async (firebaseUser) => {
    try {
      // Init Firebase
      let firebaseIdentity;
      const sessionData = localStorage.getItem("userSession");
      const session = sessionData ? JSON.parse(sessionData) : null;
      if (firebaseUser && session && session.uid === firebaseUser.uid) {
        // Validate that the stored session corresponds to the current user
        firebaseIdentity = Secp256k1KeyIdentity.fromParsedJson(session.identity);
      } else {
        // Clear the session if it does not match the current user or does not exist
        localStorage.removeItem("userSession");
        console.log("Session mismatch or no session; need new identity generation");
        // Optionally fetch or generate a new principal if necessary
      }

      // Authclient only required if login in with II
      let localAuthClient = authClient;
      let identity = firebaseIdentity ? firebaseIdentity : localAuthClient ? await localAuthClient.getIdentity() : null;
      if (!localAuthClient && !firebaseIdentity) {
        // Initialize AuthClient
        localAuthClient = await AuthClient.create({
          idleOptions: {
            disableIdle: true,
            disableDefaultIdleCallback: true,
          },
          // storage: idbAuthClientStore,
        });
        // const isAuthenticated = await client.isAuthenticated();
        // setIsAuthenticated(isAuthenticated);
        console.log("Auth client created");
        setAuthClient(localAuthClient);
        identity = await localAuthClient.getIdentity();
        // console.log("Identity retrieved " + JSON.stringify(identity));
      }
      // Attempt to retrieve the identity
      // const symphy_backend = await initBackendCanisters(identity);
      const users_backend = await initUsersCanister(identity);
      if (firebaseIdentity || (await localAuthClient.isAuthenticated())) {
        // TODO maybe if local user already stop loading
        // If we are authenticated (i.e., the symphy_backend object is set), we retrieve the user
        console.log("Symphy users backend ok");
        await getUserAndFinishInit(users_backend, identity);
      } else {
        console.log("Not authenticated");
        await logout(false);
        // await getUserAndFinishInit(symphy_backend);
        // await logout(symphy_backend);
        // await initializeFirebase(symphy_backend);
      }
    } catch (err) {
      console.error(err);
      // finishInitWithError();
      await logout(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribeFirebaseAuth = onAuthStateChanged(AUTH, async (firebaseUser) => {
      if (firebaseUser) {
        // console.log("Firebase User is signed in", firebaseUser);
        // setUser(firebaseUser);
      } else {
        console.log("Firebase No user signed in");
        // setUser(null);
      }
      await initII(firebaseUser);
    });

    return () => unsubscribeFirebaseAuth(); // Make sure we unbind the listener on unmount
  }, []);

  // useEffect(() => {
  //   return () =>  initII();
  // }, [initII]);

  const dispatchInitial = (user, symphy_backend) => {
    dispatch({
      type: "INITIAL",
      payload: {
        user,
        symphy_backend,
      },
    });
  };

  const dispatchLogin = (user, symphy_backend) => {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          symphy_backend,
        },
      });
  };

  // LOGIN
  const authenticateIIBackend = useCallback(async (identity, userInfo, authInfo, sellerInfo) => {
    // console.log(
    //   "authenticateIIBackend info" + JSON.stringify(userInfo) + " authInfo " + JSON.stringify(authInfo) + " sellerInfo " + JSON.stringify(sellerInfo)
    // );
    // const symphy_backend = await initBackendCanisters(identity);
    const users_backend = await initUsersCanister(identity);
    // Retrieve the user right after the login
    const user = await UserService.getBackendUser(users_backend.backend, userInfo, authInfo, sellerInfo);
    dispatchInitial(user, users_backend.backend);
    // Init the other canisters
    const symphy_backend = await initBackendCanisters(identity, false, users_backend);
    dispatchLogin(user, symphy_backend);
  }, []);

  const login = useCallback(async (email, password) => {
    return await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithII = useCallback(
    async (userInfo, sellerInfo, listener) => {
      await authClient.login({
        maxTimeToLive: SESSION_TIMEOUT_NANOS,
        onSuccess: async () => {
          console.log("loginWithII success");
          await authenticateIIBackend(
            await authClient.getIdentity(), // Identity
            userInfo ? userInfo : null, // web3User
            null, //authInfo
            sellerInfo ? sellerInfo : null // sellerInfo
          );
          if (listener) {
            listener(true);
          }
        },
        onError: (error) => {
          console.error("Login Failed: ", error);
          if (listener) {
            listener(false);
          }
        },
        identityProvider: getIIIdentityProvider(),
        // This enables mapping to other domains
        ...(process.env.DFX_NETWORK === "ic" && {
          derivationOrigin: getIIDerivationOrigin(),
        }),
      });
    },
    [authClient, authenticateIIBackend]
  );

  const loginWithGoogle = useCallback(async () => {
    return await signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);

  const verifyUser = async (user) => {
    if (user) {
      // User is signed in, obtain the JWT
      const token = await user.getIdToken();
      // Set up Axios to call your Firebase function
      const baseUrl = FIREBASE_FUNCTIONS_URL;
      const config = {
        method: "get",
        url: baseUrl + "verifyUserAuth",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);
      return response.data;
    } else {
      // User is signed out
      console.log("No user is signed in.");
    }
  };

  const firebaseLoginResult = useCallback(async (loginResult, sellerInfo, userInfo) => {
    // console.log("Login result " + JSON.stringify(loginResult));
    // The signed-in user info.
    const idToken = await loginResult.user.getIdToken(true);
    // console.log("Login result idToken" + JSON.stringify(idToken));

    // console.log("Login result " + JSON.stringify(loginResult.user));
    const uid = loginResult.user.uid;
    // console.log("Login result uid" + JSON.stringify(uid));

    const verifyResult = await verifyUser(loginResult.user);
    let identity;
    if (verifyResult && verifyResult.identity) {
      // identity = Principal.fromText(verifyResult.principal);
      // console.log("Received identity" + JSON.stringify(verifyResult.identity));
      identity = Secp256k1KeyIdentity.fromParsedJson(verifyResult.identity);
      // console.log("Identity " + JSON.stringify(identity.getPrincipal()));
      if (!identity) {
        console.error("No identity");
        return;
      }
      // Store the UID and principal in localStorage as a single JSON object
      const userSession = {
        uid: loginResult.user.uid,
        identity: verifyResult.identity,
      };
      localStorage.setItem("userSession", JSON.stringify(userSession));

      await authenticateIIBackend(
        identity,
        UserService.convertFirebaseUserToInfo(loginResult.user, userInfo),
        UserService.getFirebaseAuthInfo(loginResult.user),
        sellerInfo
      );
    }
    // const user = await web3auth.getUserInfo();
    // console.log("User info", user);
    // const identity = await generateIdentity(uid);
    // const localAuthClient = await AuthClient.create({ identity: identity });

    // return;

    // TODO first auth user canister to check the jwt then the rest
    // AuthClient only required fo II
    // FIrebase verify jwt and return an identity
    // const localAuthClient = await AuthClient.create({
    //   identity: identity,
    //   idleOptions: {
    //     disableIdle: true,
    //     disableDefaultIdleCallback: true,
    //   },
    //   // storage: idbAuthClientStore,
    // });
    // const isAuthenticated = await client.isAuthenticated();
    // setIsAuthenticated(isAuthenticated);
    // setAuthClient(localAuthClient);

    // } catch (error) {
    //   // console.log("Error" + error);
    // }
  }, []);

  // REGISTER
  const setUser = useCallback(async (symphy_backend, user) => {
    // const setUser = useCallback(async (symphy_backend, user) => {
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        symphy_backend,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, sellerInfo, userInfo) => {
    const res = await createUserWithEmailAndPassword(AUTH, email, password)
    //   .then(async (res) => {
    //   // TODO if error delete user
    // });
    return await firebaseLoginResult(res, sellerInfo, userInfo);
  }, []);

  const setNewPassword = useCallback(async (newPassword) => {
    return await updatePassword(AUTH.currentUser, newPassword);
  }, []);

  const resetPassword = useCallback(async (email) => {
    // const actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be in the authorized domains list in the Firebase Console.
    //   url: 'https://www.symphy.co/finishSignUp?cartId=1234',
    //   // This must be true.
    //   handleCodeInApp: true,
    //   iOS: {
    //     bundleId: 'com.example.ios'
    //   },
    //   // android: {
    //   //   packageName: 'com.example.android',
    //   //   installApp: true,
    //   //   minimumVersion: '12'
    //   // },
    //   dynamicLinkDomain: 'example.page.link'
    // };
    return await sendPasswordResetEmail(AUTH, email);
  }, []);

  const resetPasswordWithCode = useCallback(async (code, newPassword) => {
    return await confirmPasswordReset(AUTH, code, newPassword);
  }, []);

  // ----------------------------------------------------------------------

  // const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';
  const checkAuthenticated = state.user?.user_id ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      isAuthenticated: status === "authenticated",
      user: state.user,
      symphy_backend: state.symphy_backend,
      loading: status === "loading",
      method: AUTH_METHOD_FIREBASE,
      //
      login,
      loginWithII,
      loginWithGoogle,
      firebaseLoginResult,
      register,
      logout,
      refreshUser,
      resetPassword,
      resetPasswordWithCode,
      setUser,
      setNewPassword,
    }),
    [status, state.user, state.symphy_backend, login, loginWithII, logout, refreshUser, resetPassword, resetPasswordWithCode, setUser, setNewPassword]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
