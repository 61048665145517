import { Location } from "model/contracts/Location";
import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";

export default class UserSummary {
  constructor(
    user_id,
    username,
    name,
    lastname,
    about,
    email,
    avatar_url,
    location = null,
    is_seller = false,
    is_verified_seller = false,
    id_card = null,
    id_verified = false,
    metadata = {},
    permissions = {},
    ...otherProps
  ) {
    this.user_id = user_id;
    this.username = username;
    this.name = name;
    this.lastname = lastname;
    this.about = about;
    this.email = email;
    this.avatar_url = avatar_url;
    this.location = location && location.length > 0 ? [Location.fromJSON(location[0])] : [];
    this.is_seller = is_seller;
    this.is_verified_seller = is_verified_seller;
    this.id_card = id_card;
    this.id_verified = id_verified;
    this.metadata = metadata;
    this.permissions = permissions;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(thisJSON) {
    if (!thisJSON) return null;
    const {
      user_id,
      username,
      name,
      lastname,
      about,
      email,
      avatar_url,
      location,
      is_seller,
      is_verified_seller,
      id_card,
      id_verified,
      metadata,
      permissions,
      ...otherProps
    } = thisJSON;
    return new UserSummary(
      user_id,
      username,
      name,
      lastname,
      about,
      email,
      avatar_url,
      location,
      is_seller,
      is_verified_seller,
      id_card,
      id_verified,
      metadata,
      permissions,
      otherProps
    );
  }

  static fromUser(user, permissions) {
    const userInfo = user.info[0];
    const sellerInfo = user.seller_info[0];
    return new UserSummary(
      user.user_id,
      userInfo.username,
      userInfo.name,
      userInfo.lastname,
      userInfo.about,
      userInfo.email,
      userInfo.avatar_url,
      userInfo.location,
      sellerInfo ? true : false,
      sellerInfo ? sellerInfo.verified : false,
      userInfo.id_card,
      userInfo.id_verified,
      userInfo.metadata,
      permissions ? permissions : []
    );
  }

  static fromSummaryAndInfo(user, userInfo, sellerInfo) {
    return new UserSummary(
      user.user_id,
      userInfo.username,
      userInfo.name,
      userInfo.lastname,
      userInfo.about,
      userInfo.email,
      userInfo.avatar_url,
      userInfo.location,
      sellerInfo ? true : false,
      sellerInfo ? sellerInfo.verified : false,
      userInfo.id_card,
      userInfo.id_verified,
      userInfo.metadata,
      user.permissions ? user.permissions : []
    );
  }

  clone() {
    return new UserSummary(
      this.user_id,
      this.username,
      this.name,
      this.lastname,
      this.about,
      this.email,
      this.avatar_url,
      this.location,
      this.is_seller,
      this.is_verified_seller,
      this.id_card,
      this.id_verified,
      { ...this.metadata },
      { ...this.permissions }
    );
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    this.metadata[key] = value;
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  // Method to add or update a permission
  setPermission(key, value) {
    this.permissions[key] = value;
  }

  getPermission(key) {
    return this.permissions[key] || null;
  }

  setAllPermissions(permissionsObject) {
    Object.entries(permissionsObject).forEach(([key, value]) => {
      if (value) this.setPermission(key, value);
    });
  }

  // Method to retrieve user information as a formatted string
  getFullName() {
    if (this.name && this.name.length > 0) return `${this.name} ${this.lastname}`;
    return this.email ? this.email : this.username;
  }

  getPhone() {
    return this.getMetadataValue("phone");
  }

  getIBAN() {
    return this.getMetadataValue("iban");
  }

  getIdCard(hidden = true) {
    return hidden ? String(this.id_card).replace(/.{4}$/, "****") : String(this.id_card);
  }

  getProfileInfo() {
    return {
      user_id: this.user_id,
      username: this.username,
      full_name: this.getFullName(),
      about: this.about,
      email: this.email,
      avatar_url: this.avatar_url,
      location: this.location,
      is_seller: this.is_seller,
      is_verified_seller: this.is_verified_seller,
      id_card: this.id_card,
      id_verified: this.id_verified,
      metadata: this.metadata,
      permissions: this.permissions,
    };
  }

  hasAdminPermission() {
    if (this.permissions) {
      let isAdmin = false;
      // The roles come in an array
      this.permissions.forEach((role) => {
        const roleKey = role[0];
        const roleValue = getEnumVariableText(role[1]);
        // console.log(
        //   "Is admin role full key  " + roleKey + " value " + roleValue
        // );
        if (roleKey === "Global" && roleValue === "WRITE") {
          isAdmin = true;
          return isAdmin;
        }
      });
      return isAdmin;
    }
    return false;
  }

  getAccountType() {
    const isUserAdmin = this.hasAdminPermission();
    if (this.is_verified_seller && isUserAdmin) {
      return { key: "all", value: "admin & seller" };
    } else if (isUserAdmin) {
      return { key: "admin", value: "admin" };
    } else if (this.is_verified_seller) {
      // console.log("backendRolesContainAdmin " + JSON.stringify(this.permissions));
      return { key: "seller", value: "seller" };
    } else if (this.is_seller) {
      // console.log("backendRolesContainAdmin " + JSON.stringify(this.permissions));
      return { key: "unseller", value: "seller unverified" };
    } else {
      return { key: "musician", value: "musician" };
    }
  }

  isAccountType(filterRole = "all") {
    const accountType = this.getAccountType().key;
    if (filterRole === accountType) return true;
    return (
      (filterRole === "all" && (accountType === "all" || accountType === "admin" || accountType === "seller")) ||
      (filterRole === "admin" && accountType === "all") ||
      (filterRole === "seller" && (accountType === "all" || accountType === "seller"))(filterRole === "unseller" && accountType === "unseller")
    );
  }

  isMatchingFilter(filterName) {
    const lowerCaseName = filterName.toLowerCase();
    const userNameMatch =
      (this.username && this.username.toLowerCase().includes(lowerCaseName)) ||
      (this.name && this.name.toLowerCase().includes(lowerCaseName)) ||
      (this.lastname && this.lastname.toLowerCase().includes(lowerCaseName)) ||
      (this.email && this.email.toLowerCase().includes(lowerCaseName)) ||
      (this.id_card && this.id_card.length > 0 && this.id_card[0].toLowerCase().includes(lowerCaseName));

    return userNameMatch;
  }

  getLocation() {
    return this.location.length > 0 ? this.location[0] : null;
  }

  getZip() {
    return this.getLocation() ? this.getLocation().getZip(): null;
  }

  getCity() {
    return this.getLocation() ? this.getLocation().getCity(false): null;
  }
}
