import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';
// @mui
//
import { alpha, styled, useTheme } from "@mui/material/styles";
import { bgGradient } from '../../utils/cssStyles';

// ----------------------------------------------------------------------

BlurredBackground.propTypes = {
  sx: PropTypes.object,
  show: PropTypes.bool,
  children: PropTypes.node,
  bg: PropTypes.string,
};

const FixedRoot = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    position: "fixed",
  },
}));

const backgrounds = [
  "/assets/background/overlay_blur_trib.avif",
  "/assets/background/overlay_blur_blue.avif",
  "/assets/background/overlay_blur_purple.avif",
  "/assets/background/overlay_blur_pink.avif",
  "/assets/background/overlay_blur_multi.avif",
];

export default function BlurredBackground({
  show = false,
  bg,
  bgIndex,
  behind = true,
  children,
  sx,
  ...other
}) {
  const theme = useTheme();
  return (
    <AnimatePresence>
      {show && (
        <m.div
        // {...varZoom({ durationIn: 1.35, durationOut: 1.2 }).inUp}
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        // transition={{ duration: 1.2 }}
        >
          <FixedRoot
            sx={{
              ...bgGradient({
                color: alpha(
                  theme.palette.background.default,
                  theme.palette.mode === "light" ? 0.5 : 0.94
                ),
                imgUrl: bg ? bg : backgrounds.at(bgIndex),
              }),
              zIndex: behind ? "-1 !important" : "auto",
            }}
          ></FixedRoot>
        </m.div>
      )}
    </AnimatePresence>
  );
}
