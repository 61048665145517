import PropTypes from 'prop-types';
import { Box, LinearProgress } from "@mui/material";
// hooks
// config
// auth
//

// ----------------------------------------------------------------------


export default function LoadingScreen({ sx, ...other }) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      {...other}>
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
    </Box>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};
