// prop-types is a library for typechecking of props.

// @mui material components
import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function RotatingCardFront({ color, image, icon, title, description }) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignContent="center"
      borderRadius="lg"
      coloredShadow={color}
      width="100%"
      position="relative"
      zIndex={2}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.35),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.35)
          )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
        backgroundPosition: "center center",
      }}>
      <MKBox py={12} px={3} textAlign="center" lineHeight={1}>
        {icon && (
          <MKTypography variant="h2" color="white" my={2}>
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        )}
        <MKTypography variant="h3" color="white" gutterBottom sx={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.65)" }}>
          {title}
        </MKTypography>
        <MKTypography variant="body1" color="white" opacity={1} sx={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.65)" }}>
          {description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// // Setting default props for the RotatingCardFront
// RotatingCardFront.defaultProps = {
//   color: "info",
//   icon: "",
// };

// // Typechecking props for the RotatingCardFront
// RotatingCardFront.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "dark",
//     "light",
//   ]),
//   image: PropTypes.string.isRequired,
//   icon: PropTypes.node,
//   title: PropTypes.node.isRequired,
//   description: PropTypes.node.isRequired,
// };

export default RotatingCardFront;
