
// routes
import { PATH_PAGE } from 'paths';

const routes_prod = [
  {
    name: "navigation.contact",
    icon: "",
    route: PATH_PAGE.contact.name,
  },
  // {
  //   name: "navigation.faqs",
  //   icon: "",
  //   route: PATH_PAGE.faqs.name,
  // },
  {
    name: "navigation.about_us",
    icon: "",
    route: PATH_PAGE.about.name,
  },
];

export default routes_prod;
