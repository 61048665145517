// ----------------------------------------------------------------------

function path(root, sublink) {
  if (root === "/") {
    return `${root}${sublink}`;
  }
  return `${root}/${sublink}`;
}

function pathObject(root, sublink) {
  return { name: sublink, path: path(root, sublink) }
}

// SUBLINK NAMES
const DASHBOARD = 'dashboard';
const MANAGEMENT = 'management';
const PRODUCTS = 'products';
const PRODUCT_REQUESTS = 'product-requests';
const TRANSACTIONS = "transactions";

const STORE_LISTINGS = "listings";
const USERS = 'users';
const CONTRACTS = 'contracts';
const SUBCONTRACTS = 'subcontracts';
const RECOMMENDED_CONTRACTS = 'recommended-contracts';
const PRODUCT_DEFINITIONS = 'product-definitions';
const TESTDATA_GENERATOR = "test";
const MARKETPLACE = 'marketplace';
const INVOICES = "invoices";
const WELCOME = 'welcome';
const MAINTENANCE = "maintenance";
const SELLER = 'seller';
const PLAYER = 'player';
const PRODUCT = 'product';
const ACCOUNT = 'account';
const CONFIG = 'config';
const PRODUCTS_VERIFY = "product-verify";
const PRODUCTS_STORED = "product-stored";
const DISCOUNTS = "discounts";
const FEES = "fees";
const CHECKOUT = 'checkout';
const LINK = "link";

// ROOT PATHS
const HOME = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_MANAGEMENT = path(ROOTS_DASHBOARD, MANAGEMENT);
export const ROOTS_PRODUCT = path(ROOTS_DASHBOARD, PRODUCT);
export const ROOTS_MANAGEMENT_USERS = path(ROOTS_MANAGEMENT, USERS);
export const ROOTS_MANAGEMENT_MARKETPLACE = path(ROOTS_MANAGEMENT, MARKETPLACE);
export const ROOTS_MANAGEMENT_CONTRACTS = path(ROOTS_MANAGEMENT, CONTRACTS);
export const ROOTS_MANAGEMENT_INVOICES = path(ROOTS_MANAGEMENT, INVOICES);
export const ROOTS_MANAGEMENT_CONFIG = path(ROOTS_MANAGEMENT, CONFIG);
export const ROOTS_SELLER = path(ROOTS_DASHBOARD, SELLER);

// ACTIONS
const NEW = 'new';
const VIEW = "view";
const EDIT = 'edit';
const LIST = 'list';
const LISTINGS = "listing";

// ----------------------------------------------------------------------


export const PATH_AUTH = {
  root: pathObject(HOME, "auth"),
  login: pathObject(HOME, "login"),
  register: pathObject(HOME, "register"),
  registerParners: pathObject(HOME, "register-partners"),
  logout: pathObject(HOME, "logout"),
  forgot: pathObject(ROOTS_AUTH, "forgot"),
  reset: pathObject(ROOTS_AUTH, "reset"),
};

export const PATH_PAGE = {
  home: HOME,
  account: pathObject(HOME, "account"),
  comingSoon: pathObject(HOME, "coming-soon"),
  maintenance: pathObject(HOME, "maintenance"),
  about: pathObject(HOME, "about"),
  contact: pathObject(HOME, "contact"),
  faqs: pathObject(HOME, "faqs"),
  page403: pathObject(HOME, "403"),
  page404: pathObject(HOME, "404"),
  page500: pathObject(HOME, "500"),
  marketplace: {
    root: pathObject("", MARKETPLACE),
    // product: (id) => { return "/" + PRODUCT + `/${id}` },
    // product_name: PRODUCT,
  },
  product: {
    root: pathObject("", PRODUCT),
    checkout: pathObject("/", PRODUCT + "/" + CHECKOUT),
    checkout_ok: pathObject("/", PRODUCT + "/" + CHECKOUT + "/success"),
    checkout_nok: pathObject("/", PRODUCT + "/" + CHECKOUT + "/error"),
    details: (id) => {
      return "/" + PRODUCT + `/${id}`;
    },
    product_name: PRODUCT,
  },
  any: "*",
  postregistration: pathObject(HOME, "welcome"),
  privacy: pathObject(HOME, "privacy"),
  cookies: pathObject(HOME, "cookies"),
  payments_policy: pathObject(HOME, "payments-policy"),
  shipping_policy: pathObject(HOME, "shipping-policy"),
  // security_policy: pathObject(HOME, "security-policy"),

  sellers: pathObject(HOME, "sellers"),
  seller_finder: pathObject(HOME, "shops"),
  link: pathObject(HOME, LINK),

  quiero_un_instrumento: pathObject(HOME, "quiero-un-instrumento"),
  alquilar_un_instrumento: pathObject(HOME, "alquilar-un-instrumento"),
};

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: { name: DASHBOARD, path: ROOTS_DASHBOARD },
  general: {
    root: pathObject(ROOTS_DASHBOARD, WELCOME),
    root_ok: pathObject(ROOTS_DASHBOARD, WELCOME + "/success"),
    root_nok: pathObject(ROOTS_DASHBOARD, WELCOME + "/error"),
    seller: {
      root: pathObject(ROOTS_DASHBOARD, SELLER),
      seller: pathObject(ROOTS_SELLER, WELCOME),
      maintenance: pathObject(ROOTS_SELLER, MAINTENANCE),
      verify: pathObject(ROOTS_SELLER, PRODUCTS_VERIFY),
    },
    player: pathObject(ROOTS_DASHBOARD, PLAYER),
    marketplace: pathObject(ROOTS_DASHBOARD, MARKETPLACE),
  },
  product: {
    root: pathObject(ROOTS_DASHBOARD, PRODUCT),
    list: pathObject(ROOTS_PRODUCT, LIST),
    list_ok: pathObject(ROOTS_PRODUCT, LIST + "/success"),
    list_nok: pathObject(ROOTS_PRODUCT, LIST + "/error"),
    listings: pathObject(ROOTS_PRODUCT, LISTINGS),
    seller: {
      verify: pathObject(ROOTS_PRODUCT, PRODUCTS_VERIFY),
      stored_products: pathObject(ROOTS_PRODUCT, PRODUCTS_STORED),
    },
    new: pathObject(ROOTS_PRODUCT, NEW),
    overview: (id) => path(ROOTS_PRODUCT, `${id}/overview`),
    view: (id) => path(ROOTS_PRODUCT, `${id}`),
    edit: (id) => path(ROOTS_PRODUCT, `${id}/edit`),
  },
  management: {
    root: pathObject(ROOTS_DASHBOARD, MANAGEMENT),
    users: {
      root: pathObject(ROOTS_MANAGEMENT, USERS),
      new: pathObject(ROOTS_MANAGEMENT_USERS, NEW),
      // list: pathObject(ROOTS_MANAGEMENT_USERS, LIST),
      edit: (id) => path(ROOTS_MANAGEMENT_USERS, `${id}/edit`),
    },
    marketplace: {
      root: pathObject(ROOTS_MANAGEMENT, MARKETPLACE),
      product: pathObject(ROOTS_MANAGEMENT_MARKETPLACE, PRODUCTS),
      listings: pathObject(ROOTS_MANAGEMENT_MARKETPLACE, STORE_LISTINGS),
      definition: {
        edit: (id) => path(ROOTS_MANAGEMENT_MARKETPLACE, `${id}/${EDIT}`),
      },
      product_requests: pathObject(ROOTS_MANAGEMENT_MARKETPLACE, PRODUCT_REQUESTS),
    },
    contracts: {
      root: pathObject(ROOTS_MANAGEMENT, CONTRACTS),
      contracts: pathObject(ROOTS_MANAGEMENT_CONTRACTS, CONTRACTS),
      subcontracts: pathObject(ROOTS_MANAGEMENT_CONTRACTS, SUBCONTRACTS),
      subcontracts_ok: pathObject(ROOTS_MANAGEMENT_CONTRACTS, SUBCONTRACTS + "/success"),
      subcontracts_nok: pathObject(ROOTS_MANAGEMENT_CONTRACTS, SUBCONTRACTS + "/error"),
      new: pathObject(ROOTS_MANAGEMENT_CONTRACTS, NEW),
      // list: pathObject(ROOTS_MANAGEMENT_USERS, LIST),
      edit: (id) => path(ROOTS_MANAGEMENT_CONTRACTS, `${id}/edit`),
      transactions: pathObject(ROOTS_MANAGEMENT_CONTRACTS, TRANSACTIONS),
    },
    invoices: {
      root: pathObject(ROOTS_MANAGEMENT, INVOICES),
      invoices: pathObject(ROOTS_MANAGEMENT_INVOICES, INVOICES),
      new: pathObject(ROOTS_MANAGEMENT_INVOICES, NEW),
      detail: pathObject(ROOTS_MANAGEMENT_INVOICES, VIEW),
      view: (id) => path(ROOTS_MANAGEMENT_INVOICES, `${id}`),
      edit: (id) => path(ROOTS_MANAGEMENT_INVOICES, `${id}/edit`),
    },
    config: {
      root: pathObject(ROOTS_MANAGEMENT, CONFIG),
      product_definitions: pathObject(ROOTS_MANAGEMENT_CONFIG, PRODUCT_DEFINITIONS),
      recommended_contracts: pathObject(ROOTS_MANAGEMENT_CONFIG, RECOMMENDED_CONTRACTS),
      discounts: pathObject(ROOTS_MANAGEMENT_CONFIG, DISCOUNTS),
      fees: pathObject(ROOTS_MANAGEMENT_CONFIG, FEES),
    },
  },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  // },
  settings: {
    root: pathObject(ROOTS_DASHBOARD, ACCOUNT),
  },
  test_data: pathObject(ROOTS_DASHBOARD, TESTDATA_GENERATOR),
};

export const paths = {
  ...PATH_AUTH,
  ...PATH_PAGE,
  ...PATH_DASHBOARD,
}