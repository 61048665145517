import { fShortenNumber } from "src/utils/format-number";
import { Coin } from "../payments/Coin";

export default class FeeValue {
  constructor(type, value) {
    this.type = type;
    this.value = value;
  }
  static fromJSON(feeValueJSON) {
    if (!feeValueJSON) return null;

    const { Percentage, Fixed, PercentageAndFixed } = feeValueJSON;
    if (Percentage !== undefined) {
      return new FeeValue("Percentage", Percentage);
    } else if (Fixed !== undefined) {
      return new FeeValue("Fixed", Coin.fromJSON(Fixed));
    } else if (PercentageAndFixed !== undefined) {
      const { percentage, fixed } = PercentageAndFixed;
      return new FeeValue("PercentageAndFixed", {
        percentage: percentage !== null ? percentage : undefined,
        fixed: fixed !== null ? Coin.fromJSON(fixed) : undefined,
      });
    } else {
      throw new Error("Invalid FeeValue JSON format");
    }
  }

  toJSON() {
    switch (this.type) {
      case "Percentage":
        return { Percentage: parseFloat(this.value) };
      case "Fixed":
        return { Fixed: this.value };
      case "PercentageAndFixed":
        return {
          PercentageAndFixed: {
            percentage: this.value.percentage !== undefined ? parseFloat(this.value.percentage) : null,
            fixed: this.value.fixed !== undefined ? parseFloat(this.value.fixed) : null,
          },
        };
      default:
        throw new Error("Invalid FeeValue type");
    }
  }

  toString() {
    switch (this.type) {
      case "Percentage":
        return `${fShortenNumber(parseFloat(this.value))}%`;
      case "Fixed":
        return this.value.toString();
      case "PercentageAndFixed":
        const percentagePart = this.value.percentage ? `${fShortenNumber(parseFloat(this.value.percentage))}%` : "";
        const fixedPart = this.value.fixed ? this.value.fixed.toString() : "";
        return `${percentagePart} ${fixedPart}`.trim();
      default:
        return "";
    }
  }

  calculateValue(baseAmount) {
    switch (this.type) {
      case "Percentage":
        return baseAmount.multiply(this.value / 100);
      case "Fixed":
        return new Coin(this.value.amount, baseAmount.currency);
      case "PercentageAndFixed":
        const percentageValue = this.value.percentage ? baseAmount.multiply(this.value.percentage / 100) : new Coin(0, baseAmount.currency);
        const fixedValue = this.value.fixed ? new Coin(this.value.fixed.amount, baseAmount.currency) : new Coin(0, baseAmount.currency);
        return percentageValue.add(fixedValue);
      default:
        return new Coin(0, baseAmount.currency);
    }
  }

  calculateFeeCostDurationBased(contractDuration, baseAmount) {
    const feeCost = this.calculateValue(baseAmount);
    return FeeValue.calculateFeeCostFromDuration(feeCost, contractDuration);
  }

  static calculateFeeCostFromDuration(feeCost, contractDuration) {
    switch (true) {
      case contractDuration <= 29:
        const dailyPrice = feeCost.divide(365);
        return dailyPrice.multiply(1.5);
      case contractDuration <= 365:
        const numBlocks = 4;
        const numContractMonths = Math.floor(contractDuration / 30);
        const numContractBlocks = 1 + Math.floor(numContractMonths / numBlocks);
        let totalCost = feeCost.clone();
        const blockCost = totalCost.divide(numBlocks);
        if (numContractBlocks < numBlocks) {
          totalCost = blockCost.multiply(numContractBlocks);
        }
        return totalCost;
      default:
        return feeCost;
    }
  }
}
