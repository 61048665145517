import { Coin } from "../payments/Coin";

export default class ContractConfig {
  constructor(type, attributes = {}) {
    this.type = type;
    this.attributes = this._convertAttributes(attributes);
  }

  static fromJSON(contractConfigJSON) {
    // Check if the input is already an instance of ContractConfig
    if (contractConfigJSON instanceof ContractConfig) {
      return contractConfigJSON;
    }

    if (!contractConfigJSON) return null;

    let type;
    let attributes = {};
    if ("Subscription" in contractConfigJSON) {
      type = "Subscription";
      attributes = contractConfigJSON.Subscription;
    } else if ("Sale" in contractConfigJSON) {
      type = "Sale";
      attributes = contractConfigJSON.Sale;
    } else if ("Donation" in contractConfigJSON) {
      type = "Donation";
      attributes = contractConfigJSON.Donation;
    } else if ("Consult" in contractConfigJSON) {
      type = "Consult";
      attributes = contractConfigJSON.Consult;
    } else if ("type" in contractConfigJSON && "attributes" in contractConfigJSON) {
      // Handle if contractConfigJSON is already in the form of a ContractConfig instance
      type = contractConfigJSON.type;
      attributes = contractConfigJSON.attributes;
    }

    return new ContractConfig(type, attributes);
  }

  clone() {
    const clonedAttributes = JSON.parse(JSON.stringify(this.attributes));
    return new ContractConfig(this.type, clonedAttributes);
  }

  toBackendObject() {
    const backendObject = {};
    if (this.type === "Subscription") {
      backendObject.Subscription = this.attributes;
    } else if (this.type === "Sale") {
      backendObject.Sale = this.attributes;
    } else if (this.type === "Donation") {
      backendObject.Donation = this.attributes;
    } else if (this.type === "Consult") {
      backendObject.Consult = this.attributes;
    }
    return backendObject;
  }

  _convertAttributes(attributes) {
    const convertedAttributes = { ...attributes };
    Object.keys(convertedAttributes).forEach((key) => {
      if (
        convertedAttributes[key] &&
        typeof convertedAttributes[key] === "object" &&
        "currency" in convertedAttributes[key] &&
        "amount" in convertedAttributes[key]
      ) {
        convertedAttributes[key] = Coin.fromJSON(convertedAttributes[key]);
      }
    });
    return convertedAttributes;
  }

  toString() {
    return this.type;
  }

  isSubscription() {
    return this.type === "Subscription";
  }

  isSale() {
    return this.type === "Sale";
  }

  isDonation() {
    return this.type === "Donation";
  }

  isConsult() {
    return this.type === "Consult";
  }

  static subscription(attributes) {
    return new ContractConfig("Subscription", attributes);
  }

  static sale(attributes) {
    return new ContractConfig("Sale", attributes);
  }

  static donation(attributes) {
    return new ContractConfig("Donation", attributes);
  }

  static consult(attributes) {
    return new ContractConfig("Consult", attributes);
  }

  getOptionBuyDiscount() {
    return this.isSubscription() ? this.attributes.option_buy_discount : null;
  }

  getPriceSubs() {
    return this.isSubscription() ? this.attributes.price_subs : null;
  }

  getDeposit() {
    return this.isSubscription() ? this.attributes.deposit : null;
  }

  getPriceSale() {
    return this.isSale() ? this.attributes.price_sale : null;
  }

  getPriceDiscount() {
    return this.isSale() && this.attributes.price_discount.length > 0 ? this.attributes.price_discount[0] : null;
  }

  getMinDuration() {
    return this.isSubscription() ? this.attributes.min_duration : null;
  }

  getMaxDuration() {
    return (this.isDonation() || this.isSubscription()) && this.attributes.max_duration.length > 0 ? this.attributes.max_duration : null;
  }

  getAvailableUntil() {
    return this.isDonation() && this.attributes.available_until.length > 0 ? this.attributes.available_until : null;
  }
}
