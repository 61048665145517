import { getMapValueForKey } from "src/utils/typeConverters";
import { Coin } from "../payments/Coin";
import FeeValue from "./FeeValue";

export const FeeType = {
  Company: "Company",
  CompanySale: "CompanySale",
  Partners: "Partners",
  Insurance: "Insurance",
  Registration: "Registration",
  Custom: (customType) => `Custom_${customType}`,
};

export class Fee {
  constructor(id, product_price_max, service_type, fee_values = {}, ...otherProps) {
    this.id = id;
    this.product_price_max = product_price_max.length ? [Coin.fromJSON(product_price_max[0])] : [];
    this.service_type = service_type;
    // this.fee_values = convertToFrontendHashMap(fee_values); // Use an object instead of a HashMap
    this.fee_values = fee_values; // Use an object instead of a HashMap

    // this.createdAt = 0; // Value set in the backend
    //
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(feeJSON) {
    if (!feeJSON) return null;
    const { id, product_price_max, service_type, fee_values, ...otherProps } = feeJSON;

    // Parse fee_values from the specified format
    const parsedFeeValues = {};
    fee_values.forEach(([feeTypeObj, feeValueObj]) => {
      const feeType = Object.keys(feeTypeObj)[0];
      parsedFeeValues[feeType] = FeeValue.fromJSON(feeValueObj);
    });

    return new Fee(id, product_price_max, service_type, parsedFeeValues, otherProps);
  }

  addFeeValue(feeType, feeValue) {
    this.fee_values[feeType] = feeValue;
  }

  // Specific methods to add FeeValue per type
  addFeeValuePercentage(feeType, percentage) {
    this.addFeeValue(feeType, new FeeValue("Percentage", percentage));
  }

  addFeeValueFixed(feeType, coin) {
    this.addFeeValue(feeType, new FeeValue("Fixed", coin));
  }

  addFeeValuePercentageAndFixed(feeType, percentage, coin) {
    this.addFeeValue(
      feeType,
      new FeeValue("PercentageAndFixed", {
        percentage: percentage !== null ? percentage : undefined,
        fixed: coin !== null ? coin : undefined,
      })
    );
  }

  isServiceFee() {
    return this.service_type.name === "Maintenance";
  }

  // Helper function to find a metadata value by key
  // getFeeValue(key) {
  //   const value = getMapValueForKey(this.fee_values, key);
  //   // const entry = this.metadata.find(([entryKey]) => entryKey === key);
  //   return value && value.length > 0 ? value : null;
  // }

  getFeeValue(feeType) {
    return this.fee_values[feeType] || null;
  }

  // Individual getter methods for each FeeType
  getCompanyFee() {
    return this.getFeeValue(FeeType.Company);
  }

  getCompanySaleFee() {
    return this.getFeeValue(FeeType.CompanySale);
  }

  getPartnersFee() {
    return this.getFeeValue(FeeType.Partners);
  }

  getInsuranceFee() {
    return this.getFeeValue(FeeType.Insurance);
  }

  getRegistrationFee() {
    return this.getFeeValue(FeeType.Registration);
  }

  getCustomFee(customType) {
    return this.getFeeValue(FeeType.Custom(customType));
  }

  getFee(feeType) {
    return this.getFeeValue(feeType);
  }

  getCompanyFeeValue(toPrint = true) {
    const fee = this.getFeeValue(FeeType.Company);
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  getCompanySaleFeeValue(toPrint = true) {
    const fee = this.getFeeValue(FeeType.CompanySale);
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  getPartnersFeeValue(toPrint = true) {
    const fee = this.getFeeValue(FeeType.Partners);
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  getInsuranceFeeValue(toPrint = true) {
    const fee = this.getFeeValue(FeeType.Insurance);
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  getRegistrationFeeValue(toPrint = true) {
    const fee = this.getFeeValue(FeeType.Registration);
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  getCustomFeeValue(customType, toPrint = true) {
    const fee = this.getFeeValue(FeeType.Custom(customType));
    return fee ? (toPrint ? fee.toString() : fee.value) : null;
  }

  calculateTotalFee(productPrice) {
    let totalFee = new Coin(0, productPrice.currency);

    for (const feeValue of Object.values(this.fee_values)) {
      totalFee = totalFee.add(feeValue.calculateValue(productPrice));
    }

    return totalFee;
  }

  listFees() {
    return Object.entries(this.fee_values);
  }

  // Convert fee_values to the original format
  feeValuesBackendFormat() {
    return Object.entries(this.fee_values).map(([feeType, feeValue]) => {
      return [{ [feeType]: null }, feeValue.toJSON()];
    });
  }

  // Convert the whole Fee object to original format
  toBackendObject() {
    return {
      id: this.id,
      product_price_max: this.product_price_max,
      service_type: this.service_type,
      fee_values: this.feeValuesBackendFormat(),
      ...this.otherProps,
    };
  }

  // calculateFeeCostDurationBased(feeCost, contractDuration) {
  //   switch (true) {
  //     case contractDuration <= 29:
  //       const dailyPrice = feeCost.divide(365);
  //       return dailyPrice.multiply(1.5);
  //     case contractDuration <= 365:
  //       const numBlocks = 4;
  //       const numContractMonths = Math.floor(contractDuration / 30);
  //       const numContractBlocks = 1 + Math.floor(numContractMonths / numBlocks);
  //       let totalCost = feeCost.clone();
  //       const blockCost = totalCost.divide(numBlocks);
  //       if (numContractBlocks < numBlocks) {
  //         totalCost = blockCost.multiply(numContractBlocks);
  //       }
  //       return totalCost;
  //     default:
  //       return feeCost;
  //   }
  // }

  // createCompatibleFees(contractType, valuationPrice, contractIntervalCost, contractTotalCost, contractDuration) {
  //   const usedFees = [];

  //   if (contractType === ContractType.Subscription) {
  //     const companyFeeCost = this.fee_values[FeeType.Company].calculateValue(contractIntervalCost);
  //     const companyFeeTotalCost = this.fee_values[FeeType.Company].calculateValue(contractTotalCost);
  //     usedFees.push(new UsedFee(companyFeeCost, companyFeeTotalCost, FeeType.Company));
  //     usedFees.push(new UsedFee(this.fee_values[FeeType.Partners].calculateFeeCostDurationBased(contractDuration, valuationPrice), null, FeeType.Partners));
  //     usedFees.push(new UsedFee(this.fee_values[FeeType.Insurance].calculateFeeCostDurationBased(contractDuration, valuationPrice), null, FeeType.Insurance));
  //   } else {
  //     const companyFeeCost = this.fee_values[FeeType.CompanySale].calculateValue(contractTotalCost);
  //     usedFees.push(new UsedFee(companyFeeCost, companyFeeCost, FeeType.CompanySale));
  //     usedFees.push(new UsedFee(this.fee_values[FeeType.Partners].calculateValue(contractTotalCost), null, FeeType.Partners));
  //   }

  //   return usedFees;
  // }
}
