import { Button, Card, Container, Grid, IconButton, Link, Typography, useTheme } from '@mui/material';
import { AnimatePresence, m } from "framer-motion";
import { PATH_PAGE } from 'paths';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from "useLocales";
import { useLocalStorage } from '../../hooks/use-local-storage';
import { InfoIcon } from '../../theme/overrides/components/CustomIcons';
import { MotionContainer, varFade } from '../animate';
import Iconify from '../iconify';
import { defaultSettings } from '../settings/config-settings';

export default function CookiesBanner() {
  const { translate } = useLocales();
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
  const theme = useTheme();

  const [show, setShow] = React.useState(settings.cookiesStatus === "");
  const [hide, setHide] = React.useState(false);

  // React.useEffect(() => {
  // }, []);

  const handleAccept = () => {
    setHide(true);
    onChangeCookiesShown(true);
  };

  const handleReject = () => {
    setHide(true);
    onChangeCookiesShown(false);
  };

  const handleClose = () => {
    setHide(true);
    // await new Promise((resolve) => { setTimeout(resolve, 500); setShow(false); });
  };

  const onChangeCookiesShown = (accepted) => {
    const cookiesStatus = accepted ? "accepted" : "rejected";
    setSettings({ ...settings, cookiesStatus });
  };

  if (!show) {
    return (<></>)
  }

  return (
    <MotionContainer
      sx={{
        position: "fixed",
        bottom: 24,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: 10,
      }}>
      <AnimatePresence>
        {!hide && (
          <m.div
            key="banner"
            // variants={varFade().inOutUp}
            {...varFade({
              distance: 120,
              durationIn: 0.32,
              durationOut: 0.24,
              easeIn: "easeInOut",
            }).inUp}>
            <Container>
              <Card
                sx={{
                  backgroundColor: theme.palette.dark.main,
                }}>
                <Grid container spacing={3} sx={{ p: 2, color: "primary.contrastText" }} justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={9} lg={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={1} sm={1} md={0.5}>
                        <InfoIcon />
                      </Grid>
                      <Grid item xs={11} sm={11} md={11.5} direction={"column"}>
                        <Typography variant="subtitle2">{translate("cookies.banner_title")}</Typography>
                        <Typography variant="body2">
                          {translate("cookies.banner_text")}{" "}
                          <Link component={RouterLink} to={PATH_PAGE.cookies.path} color="text.disabled">
                            {translate("cookies.learn_more")}
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3} lg={2}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item>
                        <Button size="medium" color="inherit" variant="outlined" onClick={handleReject}>
                          {translate("actions.reject_all")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button size="medium" color="primary" onClick={handleAccept} variant="contained">
                          {translate("actions.accept_all")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <IconButton onClick={handleClose} sx={{ top: 4, right: 4, position: "absolute" }}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Card>
            </Container>
          </m.div>
        )}
      </AnimatePresence>
    </MotionContainer>
  );
};
