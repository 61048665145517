// ----------------------------------------------------------------------

import { createCoinFromAmount } from "./format-number";
import { getMapValueForKey } from "./typeConverters";

export function createPriceCalculation({
  contractDuration: contractDurationDays,
  interval,
  finalPrice,
  priceInterval,
  feesTotal,
  feesInterval,
  taxesTotal,
  taxesInterval,
  discountsUsed,
  otherPrices,
}) {
  // console.log("received interval " + JSON.stringify(interval));

  const priceCalculation = {
    contract_duration: contractDurationDays ? [parseInt(contractDurationDays)] : [],
    interval: interval ? [interval] : [],
    final_price: finalPrice,
    price_interval: priceInterval ? [priceInterval] : [],
    fees_total: feesTotal,
    fees_interval: feesInterval ? [feesInterval] : [],
    taxes_total: taxesTotal,
    taxes_interval: taxesInterval ? [taxesInterval] : [],
    discounts_used: discountsUsed ? discountsUsed : [],
    other_prices: otherPrices ? otherPrices : [],
  };
  return priceCalculation;
}


export function clonePriceCalculation(priceCalculation) {
  // const clonedPriceCalculation = {
  //   contract_duration: priceCalculation.contract_duration.length > 0 ? [parseInt(priceCalculation.contract_duration[0])] : [],
  //   interval: priceCalculation.interval,
  //   final_price: clonePrice(priceCalculation.final_price),
  //   price_interval: priceCalculation.price_interval.length > 0 ? [clonePrice(priceCalculation.price_interval[0])] : [],
  //   fees_total: clonePrice(priceCalculation.fees_total),
  //   fees_interval: priceCalculation.fees_interval.length > 0 ? [clonePrice(priceCalculation.fees_interval[0])] : [],
  //   taxes_total: clonePrice(priceCalculation.taxes_total),
  //   taxes_interval: priceCalculation.taxes_interval.length > 0 ? [clonePrice(priceCalculation.taxes_interval[0])] : [],
  //   discounts_used: priceCalculation.discounts_used,
  // };
  // Create a shallow copy of the priceCalculation object
  const clonedPriceCalculation = Object.assign({}, priceCalculation);

  // Iterate over the keys of the cloned object
  Object.keys(clonedPriceCalculation).forEach((key) => {
    const value = clonedPriceCalculation[key];

    // Check if the value is an object and has an 'amount' property
    if (value && typeof value === "object" && "amount" in value) {
      // Clone the price object
      clonedPriceCalculation[key] = clonePrice(value);
    }
    // Check if the value is an array with one object that has an 'amount' property
    else if (Array.isArray(value) && value.length === 1 && value[0] && typeof value[0] === "object" && "amount" in value[0]) {
      // Clone the price object inside the array
      clonedPriceCalculation[key] = [clonePrice(value[0])];
    }
  });

  return clonedPriceCalculation;
}

// 
export function clonePrice(price) {
  if (!price) {
    throw new Error('Invalid input');
  }
  return {
    amount: Number(price.amount),
    currency: price.currency,
  };
}


// Function to perform mathematical operations on a price object
export function performPriceMath(operator, price, numeric) {
  if (!price) {
    throw new Error('Invalid input');
  }

  let resultAmount;
  const numericValue = Number(numeric);
  switch (operator) {
    case '+':
      resultAmount = Number(price.amount) + numericValue;
      break;
    case '-':
      resultAmount = Number(price.amount) - numericValue;
      break;
    case '*':
      resultAmount = Number(price.amount) * numericValue;
      break;
    case '/':
      if (numericValue === 0) {
        throw new Error('Cannot divide by zero');
      }
      resultAmount = Number(price.amount) / numericValue;
      break;
    default:
      throw new Error('Invalid operator');
  }
  return {
    amount: resultAmount,
    currency: price.currency,
  };
}

// Apply the operation to all the fields of a priceCalculation
export function performPriceCalculationMath(operator, priceCalculation, numeric) {
  // Create a shallow copy of the priceCalculation object
  const clonedPriceCalculation = Object.assign({}, priceCalculation);

  // Iterate over the keys of the cloned object
  Object.keys(clonedPriceCalculation).forEach((key) => {
    const value = clonedPriceCalculation[key];

    // Check if the value is an object and has an 'amount' property
    if (value && typeof value === "object" && "amount" in value) {
      // Clone the price object
      clonedPriceCalculation[key] = performPriceMath(operator, clonePrice(value), numeric);
    }
    // Check if the value is an array with one object that has an 'amount' property
    else if (Array.isArray(value) && value.length === 1 && value[0] && typeof value[0] === "object" && "amount" in value[0]) {
      // Clone the price object inside the array
      clonedPriceCalculation[key] = [clonePrice(performPriceMath(operator, value[0], numeric))];
    }
  });

  return clonedPriceCalculation;
}

/**
 * Returns the total refund done as a Coin object
 * @param {*} paymentIntent 
 */
export function getIntentRefundsDone(paymentIntent) {
  let refundDone = getMapValueForKey(paymentIntent.metadata, "refund");
  if (refundDone) {
    refundDone = JSON.parse(refundDone);
  }
  return refundDone;
}

/**
 * Adds a refund amount to the metadata of an intent. If the metadata already contains a refund amount, the new amount is added to the existing amount.
 * @param {*} intentMetadata 
 * @param {*} price 
 */
export function addRefundToMetadata(intentMetadata, price) {
  // Convert metadata array to Map for easier manipulation
  const metadata = new Map(
    intentMetadata.map(([key, value]) => {
      let parsedValue;
      try {
        // Only try to parse if it looks like JSON (starts with '{', '[', or is a quoted string)
        if (typeof value === "string" && (value.startsWith("{") || value.startsWith("[") || value.startsWith('"'))) {
          parsedValue = JSON.parse(value);
        } else {
          parsedValue = value; // It's not JSON, leave it as is
        }
      } catch (e) {
        parsedValue = value; // Leave it as-is if parsing fails
      }
      return [key, parsedValue];
    })
  );
  if (metadata.has("refund")) {
    // If "refund" key exists, update its value
    const previousRefund = metadata.get("refund");
    const totalRefund = performPriceMath("+", previousRefund, price.amount ? price.amount : price);
    metadata.set("refund", totalRefund);
  } else {
    // If "refund" key does not exist, add it
    metadata.set("refund", createCoinFromAmount(price.amount ? price.amount : price));
  }

  const result = Array.from(metadata.entries()).map(([key, value]) => [key, JSON.stringify(value)]);
  return result;
}

/**
 * Calculates how much more can be refunded. Max is the intent final price
 * Has deposit into account
 * 
 * @param {*} paymentIntent 
 * @returns 
 */
export function getIntentRemainingRefund(paymentIntent) {
  if (!paymentIntent) {
    return null;
  }
  const totalPaid = getIntentTotalPaid(paymentIntent);
  if (totalPaid) {
    const refundDone = getIntentRefundsDone(paymentIntent);
    const remainingRefund = refundDone ? performPriceMath("-", totalPaid, refundDone.amount) : totalPaid;
    return remainingRefund;
  }
  return null;
}


/**
 * Calculates how much has been paid originally without having refunds into account
 * 
 * @param {*} paymentIntent 
 * @returns 
 */
export function getIntentTotalPaid(paymentIntent) {
  if (!paymentIntent || !paymentIntent.price) {
    return null;
  }
  let totalPaid = paymentIntent.price.length > 0 ? paymentIntent.price[0].final_price : null;
  // const deposit = paymentIntent.price.length > 0 && getMapValueForKey(paymentIntent.price[0].other_prices, "deposit") ? getMapValueForKey(paymentIntent.price[0].other_prices, "deposit") : null;
  if (totalPaid) {
    // if(deposit) {
    //   totalPaid = performPriceMath("+", totalPaid, deposit.amount);
    // }
    return totalPaid;
  }
  return null;
}