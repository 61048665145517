// @mui
import { enUS, esES } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "flagpack:gb-nir",
  },
  {
    label: "Español",
    value: "es",
    systemValue: esES,
    icon: "flagpack:es",
  },
];

export const defaultLang = allLangs[1]; // English
