// ----------------------------------------------------------------------
export const ICONS = {
  add: "mingcute:add-line", // Plus sign
  about: "icon-park-outline:family", // family
  bbva: "mdi:bank",
  cancel: "ic:round-cancel", // Cancel is an X in a circle
  close: "mingcute:close-line", // X
  checked: "ri:check-fill", // Check
  checkmark: "eva:checkmark-fill",
  calendar: "uil:schedule", // Calendar
  contact: "mdi:phone-outline", // Contact
  contracts: "mdi:contract",
  copy: "eva:copy-fill",
  deal: "mdi:deal-outline", // Handshake
  deposit: "icon-park-outline:delivery", // Hand with box
  draft: "ri:draft-fill", // Draft
  duplicate: "bxs:duplicate",
  eye: "solar:eye-bold",
  eye_closed: "solar:eye-closed-bold",
  eye_document: "hugeicons:file-view", // Eye with document
  exit: "ion:exit", // Exit arrow
  expand: "eva:arrow-ios-downward-fill",
  faq: "wpf:faq",
  google_colored: "devicon:google",
  home: "mdi:home",
  insurance: "solar:shield-check-bold", // Shield
  instruments: "mdi:violin",
  invoice: "fa6-solid:file-invoice-dollar",
  invoice_edit: "mdi:file-document-edit-outline",
  link: "eva:link-2-fill",
  list_bullet: "•",
  location: "mingcute:location-fill", // Location icon
  minus: "eva:minus-fill",
  not_verified: "eva:clock-outline",
  owner: "mdi:person-key-outline", // Person with key
  payment: "fluent:payment-24-regular", // Card
  pdf: "fluent:document-pdf-24-filled",
  play: "solar:play-broken",
  products: "ph:package-fill",
  question_circle: "eva:question-mark-circle-fill",
  refresh: "pepicons-pop:refresh", // Refresh
  refund: "gridicons:refund", // Refund
  repair: "ph:wrench-fill", // Wrench
  search: "material-symbols:search",
  search_thick: "fa:search",
  send_money: "iconoir:send-dollars",
  send_email: "mdi:email-send-outline", // 
  share: "solar:share-bold",
  shop: "solar:shop-bold-duotone", // Shop
  shop_outline: "solar:shop-outline", // Shop
  support: "bx:support",
  time: "icon-park-solid:time", // Rounded clock
  valuation: "mdi:dollar", // Money symbol
  verified: "eva:checkmark-circle-fill",
  unchecked: "fluent:checkbox-unchecked-12-filled",
  user_id: "solar:user-id-bold", // Document with person
  edit: "solar:pen-bold",
  delete: "solar:trash-bin-trash-bold",
};