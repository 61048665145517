import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import { Coin } from "../payments/Coin";
import { Discount } from "./Discount";

export class DiscountUsed {
  constructor({
    id,
    date_start = [],
    date_end = [],
    active = true,
    discount_amount = [],
    discount_percentage = [],
    discount_max_amount = [],
    discount_type,
    discount_item_ids = [],
    max_count_per_user = [],
    used_code = null,
    metadata = [],
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.date_start = date_start && date_start.length ? [BigInt(date_start[0])] : [];
    this.date_end = date_end && date_end.length ? [BigInt(date_end[0])] : [];
    this.active = active;
    this.discount_amount = discount_amount.length ? [new Coin(discount_amount[0])] : [];
    this.discount_percentage = discount_percentage.length ? [parseFloat(discount_percentage[0])] : [];
    this.discount_max_amount = discount_max_amount.length ? [new Coin(discount_max_amount[0])] : [];
    this.discount_type = discount_type;
    this.discount_item_ids = discount_item_ids.length ? discount_item_ids.map((id) => BigInt(id)) : [];
    this.max_count_per_user = max_count_per_user.length ? [BigInt(max_count_per_user[0])] : [];
    this.used_code = used_code;
    this.metadata = metadata;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(discountUsedJSON) {
    if (!discountUsedJSON) return null;
    const {
      id,
      date_start = [],
      date_end = [],
      active,
      discount_amount = [],
      discount_percentage = [],
      discount_max_amount = [],
      discount_type,
      discount_item_ids = [],
      max_count_per_user = [],
      used_code = null,
      metadata,
      ...otherProps
    } = discountUsedJSON;
    return new DiscountUsed({
      id,
      date_start,
      date_end,
      active,
      discount_amount,
      discount_percentage,
      discount_max_amount,
      discount_type,
      discount_item_ids,
      max_count_per_user,
      used_code,
      metadata,
      ...otherProps,
    });
  }

  getOtherProps() {
    const { id, date_start, date_end, active, discount_amount, discount_percentage, discount_type, discount_item_ids, used_code, ...otherProps } = this;
    return otherProps;
  }

  clone() {
    return new DiscountUsed({
      id: this.id,
      date_start: this.date_start,
      date_end: this.date_end,
      active: this.active,
      discount_amount: this.discount_amount,
      discount_percentage: this.discount_percentage,
      discount_max_amount: this.discount_max_amount,
      discount_type: this.discount_type,
      discount_item_ids: this.discount_item_ids,
      max_count_per_user: this.max_count_per_user,
      used_code: this.used_code,
      metadata: this.metadata,
    });
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  getDiscountType(toPrint = false) {
    return toPrint ? getEnumVariableText(this.discount_type) : this.discount_type;
  }

  isDeposit() {
    return this.getDiscountType(true) === "Deposit";
  }

  isSubscription() {
    return this.getDiscountType(true) === "Subscription";
  }

  isOrderDiscount() {
    return this.getDiscountType(true) === "Order";
  }

  isSaleDiscount() {
    return this.getDiscountType(true) === "Sale";
  }

  // Number of usages per user. So number of months to apply the discount
  getSubscriptionMaxCount() {
    return this.isSubscription() && this.max_count_per_user.length > 0 ? this.max_count_per_user[0] : 0;
  }

  isActive() {
    return this.active;
  }

  // New method for getting fields as array or comma-separated string
  getField(fieldName, asArray = true) {
    switch (fieldName) {
      case "discount_item_ids":
        return asArray ? this.discount_item_ids : this.discount_item_ids.join(", ");
      default:
        throw new Error(`Unknown field name: ${fieldName}`);
    }
  }

  getDiscountItemIds(asArray = true) {
    return this.getField("discount_item_ids", asArray);
  }

  // Reuse calculateAmount from utility function
  calculateAmount(price = null) {
    const period_count = this.isSubscription() ? this.getSubscriptionMaxCount() : 1;
    return Discount.calculateDiscountAmount({
      discount_amount: this.discount_amount,
      discount_percentage: this.discount_percentage,
      discount_max_amount: this.discount_max_amount,
      price,
      period_count,
    });
  }

  getUsedCode() {
    if (!this.used_code || this.used_code.length === 0) return null;
    return this.used_code[0];
  }

  hasCode(code) {
    if (!this.getUsedCode()) return false;
    return String(this.used_code[0]) === String(code);
  }

  hasId(id) {
    if (!this.id) return false;
    return String(this.id) === String(id);
  }

  matches(id, code) {
    return this.hasId(id) || this.hasCode(code);
  }

  // Based on the number of usages
  canUseAgain(previousUsages) {
    if (this.max_count_per_user.length === 0) return true;
    return previousUsages < this.max_count_per_user[0];
  }

  // Return null if no max_count_per_user is set
  getRemainingUsages(previousUsages = 0) {
    // If `max_count_per_user` is not set or empty, return null
    if (this.max_count_per_user.length === 0) {
      return null;
    }
    // Calculate the remaining uses based on `max_count_per_user` and `previousUsages`
    const maxUsages = Number(this.max_count_per_user[0]);
    const remainingUsages = maxUsages - previousUsages;
    // Return remaining uses, or 0 if it's fully used
    return remainingUsages > 0 ? remainingUsages : 0;
  }

  // Method to check if discount is valid for a product
  // {result, usagesLeft}
  isValidForSubcontract(subcontract, paymentIntents = []) {
    const productId = subcontract.product_id;
    const contractType = getEnumVariableText(subcontract.contract_type);
    const discountType = this.getDiscountType(true);
    const baseChecks =
      (this.discount_item_ids.length === 0 || this.discount_item_ids.includes(BigInt(productId))) && (discountType === contractType || this.isOrderDiscount());
    if (subcontract.isSubscription()) {
      // Check if subscription discount is valid
      // Check number of uses of the discount
      const usedCount = !paymentIntents ? 0 : paymentIntents.filter((item) => item.hasUsedDiscount(this.id, this.getUsedCode()) && item.isCompleted()).length;
      const canUseAgain = this.canUseAgain(usedCount);
      const usagesLeft = this.getRemainingUsages(usedCount);
      const result = baseChecks && canUseAgain;
      return { result, usagesLeft };
    } else {
      // Check if the productId is in discount_item_ids and if contractType matches or is "Order"
      return { result: baseChecks, usagesLeft: 1 };
    }
  }

  static fromDiscount(discount, used_code = null) {
    return new DiscountUsed({
      id: discount.id,
      date_start: discount.date_start,
      date_end: discount.date_end,
      active: discount.active,
      discount_amount: discount.discount_amount.length ? discount.discount_amount[0] : null,
      discount_percentage: discount.discount_percentage.length ? discount.discount_percentage[0] : null,
      discount_max_amount: discount.discount_max_amount.length ? discount.discount_max_amount[0] : null,
      discount_type: discount.discount_type,
      discount_item_ids: discount.discount_item_ids.length ? discount.discount_item_ids[0] : [],
      used_code,
    });
  }
}
