import { ICONS } from "src/utils/icons";
import { Coin } from "../payments/Coin";

export default class ServiceType {
  constructor(name, attributes = {}) {
    this.name = name;
    this.attributes = this._convertAttributes(attributes);
  }

  static fromJSON(serviceTypeJSON) {
    if (!serviceTypeJSON) return null;

    let name;
    let attributes = {};

    if ("Storage" in serviceTypeJSON) {
      name = "Storage";
      attributes = serviceTypeJSON.Storage;
    } else if ("Maintenance" in serviceTypeJSON) {
      name = "Maintenance";
      attributes = serviceTypeJSON.Maintenance;
    } else if ("Valuation" in serviceTypeJSON) {
      name = "Valuation";
      attributes = serviceTypeJSON.Valuation;
    } else if ("Subscription" in serviceTypeJSON) {
      name = "Subscription";
    } else if ("Sale" in serviceTypeJSON) {
      name = "Sale";
    } else if ("Shop" in serviceTypeJSON) {
      name = "Shop";
    }
    return new ServiceType(name, attributes);
  }

  clone() {
    // Create a deep copy of the attributes
    const clonedAttributes = JSON.parse(JSON.stringify(this.attributes));
    return new ServiceType(this.name, clonedAttributes);
  }

  toBackendObject() {
    const backendObject = {};
    if (this.name === "Storage") {
      backendObject.Storage = this.attributes;
    } else if (this.name === "Maintenance") {
      backendObject.Maintenance = this.attributes;
    } else if (this.name === "Valuation") {
      backendObject.Valuation = this.attributes;
    } else if (this.name === "Subscription") {
      backendObject.Subscription = null;
    } else if (this.name === "Sale") {
      backendObject.Sale = null;
    } else if (this.name === "Shop") {
      backendObject.Shop = null;
    }
    return backendObject;
  }

  _convertAttributes(attributes) {
    const convertedAttributes = { ...attributes };
    Object.keys(convertedAttributes).forEach((key) => {
      if (
        convertedAttributes[key] &&
        typeof convertedAttributes[key] === "object" &&
        "currency" in convertedAttributes[key] &&
        "amount" in convertedAttributes[key]
      ) {
        convertedAttributes[key] = Coin.fromJSON(convertedAttributes[key]);
      }
    });
    return convertedAttributes;
  }

  toString() {
    return this.name;
  }

  isStorage() {
    return this.name === "Storage";
  }

  isMaintenance() {
    return this.name === "Maintenance";
  }

  isValuation() {
    return this.name === "Valuation";
  }

  isSubscription() {
    return this.name === "Subscription";
  }

  isSale() {
    return this.name === "Sale";
  }

  isShop() {
    return this.name === "Shop";
  }

  static storage(attributes) {
    return new ServiceType("Storage", attributes);
  }

  static maintenance() {
    return new ServiceType("Maintenance");
  }

  static valuation(attributes) {
    return new ServiceType("Valuation", attributes);
  }

  static subscription() {
    return new ServiceType("Subscription");
  }

  static sale() {
    return new ServiceType("Sale");
  }

  static shop() {
    return new ServiceType("Shop");
  }

  getIcon() {
    switch (this.name) {
      case "Storage":
        return "mdi:warehouse";
      case "Maintenance":
        return "mdi:tools";
      case "Valuation":
        return ICONS.valuation;
      case "Subscription":
        return "mdi:calendar";
      case "Sale":
        return "mdi:cart";
      case "Shop":
        return "mdi:store";
      default:
        return null;
    }
  }

  getLocalizedNameKey() {
    switch (this.name) {
      case "Storage":
        return "services.storage";
      case "Maintenance":
        return "services.maintenance";
      case "Valuation":
        return "services.valuation";
      case "Subscription":
        return "services.subscription";
      case "Sale":
        return "services.sale";
      case "Shop":
        return "services.shop";
      default:
        return null;
    }
  }

  getProductTypes(printable = false) {
    return this.isValuation() || this.isMaintenance() ? (printable ? this.attributes.product_types.join(", ") : this.attributes.product_types) : [];
  }

  getPriceMax(printable = false) {
    return this.isValuation() ? (printable ? this.attributes.price_max.toString() : this.attributes.price_max) : null;
  }

  getPriceMin(printable = false) {
    return this.isValuation() ? (printable ? this.attributes.price_min.toString() : this.attributes.price_min) : null;
  }

  getCapacity() {
    return this.isStorage() ? this.attributes.capacity : null;
  }

  getQuality() {
    return this.isStorage() ? this.attributes.quality : null;
  }

  getSecurity() {
    return this.isStorage() ? this.attributes.security : null;
  }
}
