

// // Setting values using the new setter methods
// product.brand = 'NewBrand';
// product.size = 'Large';
// product.year = 2021;
// product.weight = 2.5;
// product.stateDescription = 'Excellent condition';
// Setting multiple metadata values at once
// product.setAllMetadata({
//     brand: 'NewBrand',
//     size: 'Large',
//     year: 2021,
//     weight: 2.5,
//     stateDescription: 'Excellent condition'

import { ProductContract } from "model/contracts/ProductContract";
import { ProductSubContract } from "model/contracts/ProductSubContract";
import { Coin } from "model/payments/Coin";
import TransactionWithItems from "model/payments/TransactionWithItems";
import ProductContractsService, { CONTRACT_STATUS_MAP } from "services/ProductContractsService";
import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import { truncateText } from "src/utils/uiUtils";
export const PRODUCT_STATES = [{ LikeNew: null }, { Used: null }, { New: null }];
export const PRODUCT_CATEGORY = [{ Instruments: null }, { Accesories: null }];

// limit of products and user can upload
export const DEFAULT_PRODUCT_LIMIT = 10;
export const DEFAULT_PRODUCT_LIMIT_SELLER = 20;
export const MAX_RESERVED_ITEMS = 5;

// Tranlation keys
export const DUMMY_ACCESSORIES = [
  "accessories.case",
  "accessories.bow",
  "accessories.strings",
  "accessories.mute",
  "accessories.rosin",
  "accessories.tuner",
  "accessories.metronome",
];


// });
export default class Product {
  constructor(
    id,
    owner_id,
    name,
    product_type,
    description,
    category,
    state,
    images,
    valuation_price,
    verified,
    location,
    transactions,
    subcontract,
    contracts,
    metadata = [],
    ...otherProps
  ) {
    this.id = id;
    this.owner_id = owner_id;
    this.name = name;
    this.product_type = product_type;
    this.description = description;
    this.category = category;
    this.state = state ? (typeof state === "object" ? state : { [state]: null }) : {}; // Adjust according to PRODUCT_STATES if needed
    this.metadata = metadata;
    this.images = images;
    this.valuation_price = valuation_price;
    this.verified = verified;
    this.location = location && location.lat ? [location] : [];
    this.created_at = 0; // Value set in the backend
    this.transactions = transactions && transactions.length > 0 ? transactions.map((transaction) => TransactionWithItems.fromJSON(transaction)) : transactions;
    this.subcontract = subcontract && subcontract.length > 0 ? ProductSubContract.fromJSON(subcontract[0]) : subcontract;
    this.contracts = contracts && contracts.length > 0 ? contracts.map((contract) => ProductContract.fromJSON(contract)) : contracts;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(productJSON) {
    if (!productJSON) return null;
    // Extract known properties and include the rest in otherProps
    const {
      id,
      owner_id,
      name,
      product_type,
      description,
      category,
      state,
      images,
      valuation_price,
      verified,
      location,
      transactions,
      subcontract,
      contracts,
      metadata,
      ...otherProps
    } = productJSON;
    // Return a new instance of Product including any additional properties
    return new Product(
      id,
      owner_id,
      name,
      product_type,
      description,
      category,
      state,
      images,
      valuation_price,
      verified,
      location,
      transactions,
      subcontract,
      contracts,
      metadata,
      otherProps
    );
  }

  clone() {
    // Create a deep clone of the metadata array
    const clonedMetadata = this.metadata.map(([key, value]) => [key, value]);
    // Creating a new instance of Product with the same properties
    const clonedProduct = new Product(
      this.id,
      this.owner_id,
      this.name,
      this.product_type,
      this.description,
      this.category,
      this.state,
      this.images,
      this.valuation_price,
      this.verified,
      this.location[0],
      this.transactions,
      this.subcontract,
      this.contracts,
      clonedMetadata
    );
    // Copy any additional properties that were dynamically added to the original instance
    Object.keys(this).forEach((key) => {
      if (!clonedProduct.hasOwnProperty(key)) {
        clonedProduct[key] = this[key];
      }
    });
    return clonedProduct;
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  // Setter methods for metadata properties
  set brand(value) {
    this.setMetadataValue("brand", value);
  }

  set size(value) {
    this.setMetadataValue("size", value);
  }

  set year(value) {
    this.setMetadataValue("year", value.toString());
  }

  set weight(value) {
    this.setMetadataValue("weight", value.toString());
  }

  set stateDescription(value) {
    this.setMetadataValue("state_description", value);
  }

  set extra(value) {
    this.setMetadataValue("extra", value);
  }

  getLongName(truncate = true, withId = true, withType = true, withSize = true, withName = true) {
    const name = withName? truncate ? truncateText(this.name, 20) : this.name : "";
    const id = withId ? ` (${this.id})` : "";
    const typeText = name.includes(this.product_type) ? "" : ` - ${this.product_type}`;
    const type = withType ? typeText : "";
    const size = withSize ? this.getSize(true) : "";
    return name + id + type + size;
  }

  // Methods to obtain specific fields from metadata
  getBrand() {
    return this.getMetadataValue("brand");
  }

  getSize(toPrint = false) {
    const size = this.getMetadataValue("size");
    if (toPrint) {
      return size ? ` - ${size}` : "";
    }
    return size;
  }

  getSerialNumber() {
    return this.getMetadataValue("serial_nr");
  }

  getYear() {
    const yearString = this.getMetadataValue("year");
    return yearString ? parseInt(yearString) : null; // Convert year back to integer
  }

  getWeight() {
    const weightString = this.getMetadataValue("weight");
    return weightString ? parseFloat(weightString) : null; // Convert weight back to float
  }

  getAccessories(asArray, withBullets) {
    const accessories = this.getMetadataValue("accessories");
    return accessories
      ? asArray || withBullets
        ? withBullets
          ? accessories
              .split(",")
              .map((item) => `• ${item.trim()}`)
              .join("\n")
          : accessories.split(",")
        : accessories
      : asArray
        ? []
        : null;
  }

  hasContractDocs() {
    return this.getMetadataValue("contract_docs") !== null;
  }

  getContractDocs() {
    return this.getMetadataValue("contract_docs");
  }

  getStateDescription() {
    return this.getMetadataValue("state_description");
  }

  getExtra() {
    return this.getMetadataValue("extra");
  }

  getStateText() {
    return this.state ? getEnumVariableText(this.state) : null;
  }

  getLocation() {
    return this.location.length > 0 ? this.location[0] : null;
  }

  getCover() {
    return this.images ? this.images[0] : null;
  }

  mapToDefinition(definitions) {
    return definitions.find((def) => def.product_type_localized === this.product_type || def.getDefaultProductType() === this.product_type);
  }

  getDefinitionByProductType(definitions) {
    return definitions.find((def) => def.product_type_localized === this.product_type || def.getDefaultProductType() === this.product_type);
  }

  getDefinitionSizes(definitions) {
    const definition = this.getDefinitionByProductType(definitions);
    return definition && definition.sizes && definition.sizes.length > 0 ? definition.sizes : [];
  }
  static mapToDefinition(definitions, productType) {
    return definitions.find((def) => def.product_type_localized === productType || def.getDefaultProductType() === this.getDefaultProductType());
  }

  static getDefinitionSizes(definitions, productType) {
    const definition = Product.mapToDefinition(definitions, productType);
    return definition && definition.sizes && definition.sizes.length > 0 ? definition.sizes : [];
  }

  static getProductTypeLocalized(definitions, productType) {
    const definition = Product.mapToDefinition(definitions, productType);
    return definition && definition.sizes && definition.sizes.length > 0 ? definition.sizes : [];
  }

  /// Contract things

  getCurrentContract() {
    // TODO improve this to obtain current contract
    return this.contracts && this.contracts.length > 0 ? this.contracts[0] : null;
  }

  getCurrentSubscriptionContract() {
    return this.contracts ? ProductContractsService.getCurrentContractSubscription(this.contracts) : this.subcontract;
  }

  isReservation() {
    const currentSubcontract = this.getCurrentSubscriptionContract();
    if (!currentSubcontract) {
      return false;
    }
    return getEnumVariableText(currentSubcontract.contract_status) === CONTRACT_STATUS_MAP.reserved.name;
  }

  getReservationExpiration(formatted = false, currentLang) {
    const currentSubcontract = this.getCurrentSubscriptionContract();
    if (!currentSubcontract) {
      return null;
    }
    return currentSubcontract.getReservedUntil(formatted, currentLang);
  }

  isReservationExpired() {
    // Check if the current contract is a reservation
    if (!this.isReservation()) {
      return false;
    }
    // Get the reservation expiration date
    const currentSubcontract = this.getCurrentSubscriptionContract();
    if (!currentSubcontract) {
      return false;
    }
    return currentSubcontract.isReservationExpired();
  }

  isOwnerUser(user) {
    return user.user_id.toString() === this.owner_id.toString();
  }

  /**
   * Works if the product has subcontract
   * @param {*} user
   * @returns
   */
  isRenterUser(user) {
    return this.subcontract ? user.user_id.toString() === this.subcontract.user_id.toString() : false;
  }

  /// Transaction things

  /**
   * Returns transaction for valuation if the .transactions were added
   * @returns
   */
  getValuationTransaction() {
    if (this.transactions) {
      return this.transactions.find((t) => t.isValuation());
    }
  }

  isValuationCompleted() {
    if (this.verified) {
      return true;
    }
    const valuationTransaction = this.getValuationTransaction();
    return valuationTransaction && valuationTransaction.isCompleted();
  }

  getPurchaseTransaction() {
    if (this.transactions) {
      return this.transactions.find((t) => t.isShopTransaction());
    }
  }

  updateTransaction(transaction) {
    const productToUpdate = this.clone();
    if (productToUpdate.transactions && productToUpdate.transactions.length > 0) {
      productToUpdate.transactions = productToUpdate.transactions.map((row) =>
        row.id === transaction.id ? TransactionWithItems.fromJSON({ ...transaction }) : row
      );
    } else {
      productToUpdate.transactions = [transaction];
    }
    return productToUpdate;
  }

  /**
   * If item is reserved how much has to be paid
   * @returns
   */
  getReservationPrice() {
    return Number(this.valuation_price.amount) > 250000 ? new Coin(1500) : new Coin(500);
  }
}
