import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import {DiscountCode} from "./DiscountCode";
import { Coin } from "../payments/Coin";
import { convertToDigitsDigits, ensureTwoOrOneDecimalDigits, fShortenNumber } from "src/utils/format-number";

export const DISCOUNT_TYPES = ["Order", "Sale",  "Subscription", "Service", "Deposit", "FeeAll", "FeePartner", "FeeRegistration", "FeeCompany"];


export class Discount {
  constructor({
    id,
    date_start = [],
    date_end = [],
    active = true,
    discount_amount = [],
    discount_max_amount = [],
    discount_percentage = [],
    discount_type,
    discount_item_ids = [],
    use_once = false,
    used_by = [],
    max_count_per_user = [],
    discount_codes = [],
    valid_for = [],
    metadata = [],
    ...otherProps
  }) {
    this.id = id;
    this.date_start = date_start;
    this.date_end = date_end;
    this.active = active;
    this.discount_amount = discount_amount;
    this.discount_max_amount = discount_max_amount;
    this.discount_percentage = discount_percentage;
    this.discount_type = discount_type;
    this.discount_item_ids = discount_item_ids;
    this.use_once = use_once;
    this.used_by = used_by;
    this.max_count_per_user = max_count_per_user.length > 0 ? [parseInt(max_count_per_user)] : [];
    this.discount_codes = discount_codes.map((code) => DiscountCode.fromJSON(code));
    this.valid_for = valid_for;
    this.metadata = metadata;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(discountJSON) {
    if (!discountJSON) return null;
    const {
      id,
      date_start = [],
      date_end = [],
      active,
      discount_amount = [],
      discount_max_amount = [],
      discount_percentage = [],
      discount_type,
      discount_item_ids = [],
      use_once,
      used_by = {},
      max_count_per_user = [],
      discount_codes = [],
      valid_for = [],
      metadata,
      ...otherProps
    } = discountJSON;
    return new Discount({
      id,
      date_start,
      date_end,
      active,
      discount_amount,
      discount_max_amount,
      discount_percentage,
      discount_type,
      discount_item_ids,
      use_once,
      used_by,
      max_count_per_user,
      discount_codes,
      valid_for,
      metadata,
      ...otherProps,
    });
  }

  getOtherProps() {
    const {
      id,
      date_start,
      date_end,
      active,
      discount_amount,
      discount_max_amount,
      discount_percentage,
      discount_type,
      discount_item_ids,
      use_once,
      used_by,
      max_count_per_user,
      discount_codes,
      valid_for,
      metadata,
      ...otherProps
    } = this;
    return otherProps;
  }

  clone() {
    return new Discount({
      id: this.id,
      date_start: this.date_start,
      date_end: this.date_end,
      active: this.active,
      discount_amount: this.discount_amount.length > 0 ? [Coin.fromJSON(this.discount_amount[0])] : [],
      discount_max_amount: this.discount_max_amount.length > 0 ? [Coin.fromJSON(this.discount_max_amount[0])] : [],
      discount_percentage: this.discount_percentage,
      discount_type: this.discount_type,
      discount_item_ids: this.discount_item_ids,
      use_once: this.use_once,
      used_by: this.used_by,
      max_count_per_user: this.max_count_per_user,
      discount_codes: this.discount_codes,
      valid_for: this.valid_for,
      metadata: this.metadata,
    });
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  getDiscountType(toPrint = false) {
    return toPrint ? getEnumVariableText(this.discount_type) : this.discount_type;
  }

  isActive() {
    return this.active;
  }

  isSubscription() {
    return this.getDiscountType(true) === "Subscription";
  }

  isOrderDiscount() {
    return this.getDiscountType(true) === "Order";
  }

  isDeposit() {
    return this.getDiscountType() === "Deposit";
  }

  // New method for getting fields as array or comma-separated string
  getField(fieldName, asArray = true) {
    switch (fieldName) {
      case "valid_for":
        return asArray ? this.valid_for[0] || [] : (this.valid_for[0] || []).join(", ");
      case "used_by":
        return asArray ? Object.keys(this.used_by) : Object.keys(this.used_by).join(", ");
      case "discount_item_ids":
        return asArray ? this.discount_item_ids[0] || [] : (this.discount_item_ids[0] || []).join(", ");
      case "discount_codes":
        return asArray ? this.discount_codes.map((code) => code.value) : this.discount_codes.map((code) => code.value).join(", ");
      default:
        throw new Error(`Unknown field name: ${fieldName}`);
    }
  }

  getValidFor(asArray = true) {
    return this.getField("valid_for", asArray);
  }

  getUsedBy(asArray = true) {
    return this.getField("used_by", asArray);
  }

  getDiscountItemIds(asArray = true) {
    return this.getField("discount_item_ids", asArray);
  }

  getDiscountCodes(asArray = true) {
    return this.getField("discount_codes", asArray);
  }

  containsCode(code_value) {
    return this.discount_codes.some((code) => code.value === code_value);
  }

  addCode(new_code) {
    if (this.containsCode(new_code.value)) {
      throw new Error("Discount code already exists");
    }
    this.discount_codes.push(new DiscountCode(new_code));
  }

  removeCode(code_value) {
    const index = this.discount_codes.findIndex((code) => code.value === code_value);
    if (index !== -1) {
      return this.discount_codes.splice(index, 1)[0];
    }
    return null;
  }

  updateCode(updated_code) {
    const index = this.discount_codes.findIndex((code) => code.value === updated_code.value);
    if (index !== -1) {
      this.discount_codes[index] = new DiscountCode(updated_code);
    } else {
      throw new Error("Discount code not found");
    }
  }

  isWithinValidPeriod(current_timestamp) {
    const start_valid = this.date_start.length === 0 || current_timestamp >= this.date_start[0];
    const end_valid = this.date_end.length === 0 || current_timestamp <= this.date_end[0];
    return start_valid && end_valid;
  }

  isValidForUser(user_id) {
    if (this.valid_for.length > 0) {
      return this.valid_for[0].includes(user_id);
    }
    return true;
  }

  findCode(code_value) {
    return this.discount_codes.find((c) => c.value === code_value) || null;
  }

  isDiscountValid(current_timestamp, user_id = null, code_value = null, item_ids = null) {
    if (!this.isActive()) {
      return "NotActive";
    }
    if (!this.isWithinValidPeriod(current_timestamp)) {
      return "Expired";
    }
    if (user_id) {
      const userValidity = this.isValidForUserWithUsageCheck(user_id);
      if (userValidity !== "Success") {
        return userValidity;
      }
    }
    if (code_value) {
      const code = this.findCode(code_value);
      if (!code) {
        return "NotFound";
      }
      if (!code.hasUsageLeft()) {
        return "UsageExceeded";
      }
    }
    if (item_ids) {
      if (this.discount_item_ids.length > 0) {
        const discount_item_ids = this.discount_item_ids[0];
        if (!item_ids.some((id) => discount_item_ids.includes(id))) {
          return "NoPermission";
        }
      }
    }
    return "Success";
  }

  isValidForUserWithUsageCheck(user_id) {
    if (!this.isValidForUser(user_id)) {
      return "NotAuthorized";
    }
    if (user_id in this.used_by) {
      return "AlreadyUsed";
    }
    if (this.max_count_per_user.length > 0) {
      const user_uses = this.used_by[user_id] || 0;
      if (user_uses >= this.max_count_per_user[0]) {
        return "UsageExceeded";
      }
    }
    return "Success";
  }

  recordUsage(user_id, code_value = null) {
    const current_timestamp = Date.now();
    const validity = this.isDiscountValid(current_timestamp, user_id, code_value);
    if (validity === "Success") {
      if (!(user_id in this.used_by)) {
        this.used_by[user_id] = 1;
      } else {
        if (this.max_count_per_user.length > 0) {
          this.used_by[user_id]++;
        } else {
          throw new Error("NoPermission");
        }
      }
      if (code_value) {
        const code = this.findCode(code_value);
        if (code) {
          code.useCode();
        } else {
          throw new Error("NotFound");
        }
      }
    }
    return validity;
  }

  calculateAmount(price = null) {
    const period_count = this.isSubscription() ? this.getSubscriptionMaxCount() : 1;

    return this.calculateDiscountAmount({
      discount_amount: this.discount_amount,
      discount_percentage: this.discount_percentage,
      discount_max_amount: this.discount_max_amount,
      price,
      period_count,
    });
  }

  static calculateDiscountAmount({ discount_amount, discount_percentage, discount_max_amount, price, period_count }) {
    const fixed_discount = discount_amount.length ? discount_amount[0] : new Coin({ amount: 0 });
    const percentage_discount = discount_percentage.length ? discount_percentage[0] : 0;

    // Calculate based on price and percentage
    if (price) {
      const percentage_discount_value = percentage_discount > 0 ? price.multiply(percentage_discount / 100) : new Coin(0, price.currency);
      const per_period_discount = percentage_discount_value.greaterThan(fixed_discount) ? percentage_discount_value : fixed_discount;
      const total_discount = per_period_discount.multiply(period_count);

      const capped_total_discount = discount_max_amount.length ? total_discount.min(discount_max_amount[0]) : total_discount;

      return {
        perPeriod: per_period_discount,
        total: capped_total_discount.lessThan(price.multiply(period_count)) ? capped_total_discount : price.multiply(period_count),
      };
    }

    // Fixed discount case
    const total_fixed_discount = fixed_discount.multiply(period_count);
    if (discount_max_amount.length) {
      return {
        perPeriod: fixed_discount,
        total: total_fixed_discount.min(discount_max_amount[0].multiply(period_count)),
      };
    }

    return {
      perPeriod: fixed_discount,
      total: total_fixed_discount,
    };
  }
}
