import { fEmptyPrice } from "src/utils/format-number";
import { performPriceMath } from "src/utils/price-utils";
import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import { PAYMENT_STATUS } from "services/PaymentsService";
import { Coin } from "./Coin";
import { PriceCalculation } from "./PriceCalculation";

// PaymentIntent class
export class PaymentIntent {
  constructor({
    id,
    transaction_id,
    order_id = [],
    payment_method_id = [],
    date,
    price = [],
    status,
    attempts = [],
    payment_gateway = [],
    metadata = {},
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.transaction_id = BigInt(transaction_id);
    this.order_id = order_id.length ? [order_id[0]] : [];
    this.payment_method_id = payment_method_id.length ? payment_method_id : [];
    this.date = BigInt(date);
    this.price = price.length ? [PriceCalculation.fromJSON(price[0])] : [];
    this.status = status;
    this.attempts = attempts.length ? attempts : [];
    this.payment_gateway = payment_gateway.length ? payment_gateway : [];
    this.metadata = metadata;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(paymentIntentJSON) {
    if (!paymentIntentJSON) return null;
    const {
      id,
      transaction_id,
      order_id = [],
      payment_method_id = [],
      date,
      price = [],
      status,
      attempts = [],
      payment_gateway = [],
      metadata = {},
      ...otherProps
    } = paymentIntentJSON;
    return new PaymentIntent({
      id,
      transaction_id,
      order_id,
      payment_method_id,
      date,
      price,
      status,
      attempts,
      payment_gateway,
      metadata,
      ...otherProps,
    });
  }

  clone() {
    return new PaymentIntent({
      id: this.id,
      transaction_id: this.transaction_id,
      order_id: this.order_id ? [this.order_id] : [],
      payment_method_id: this.payment_method_id ? [this.payment_method_id] : [],
      date: this.date,
      price: this.price && this.price.length > 0 ? [this.price[0].clone()] : [],
      status: this.status,
      attempts: this.attempts ? [this.attempts] : [],
      payment_gateway: this.payment_gateway ? [this.payment_gateway] : [],
      metadata: this.metadata,
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    return value && value.length > 0 ? value : null;
  }

  isReservationForSubcontract(subcontractId) {
    const confirmedSubcontracts = this.getMetadataValue("subcontracts_reserved");
    if (confirmedSubcontracts && confirmedSubcontracts.length > 0) {
      return confirmedSubcontracts.includes(subcontractId);
    }
    return false;
  }

  getPrice() {
    if (!this.price || this.price.length === 0) {
      return null;
    }
    return this.price[0];
  }

  hasUsedDiscount(discountId, discountCode) {
    const price = this.getPrice();
    if (!price) {
      return false;	
    }
    return (this.isPending() || this.isCompleted()) && price.hasUsedDiscount(discountId, discountCode);
  }

  isPending() {
    return getEnumVariableText(this.status) === getEnumVariableText(PAYMENT_STATUS.pending);
  }

  isFailed() {
    return getEnumVariableText(this.status) === getEnumVariableText(PAYMENT_STATUS.failed);
  }

  isCompleted() {
    return getEnumVariableText(this.status) === getEnumVariableText(PAYMENT_STATUS.completed);
  }

  getDeposit(isPending = false) {
    const deposit = this.price && this.price.length ? this.price[0].getDeposit() : null;
    return isPending ? this.isPending() && deposit : deposit;
  }

  setStatus(status) {
    this.status = status;
  }

  getToPay({ isCompleted = true, isPending = false, isNotCompleted = false }) {
    if (this.price.length > 0) {
      if (isCompleted) {
        return this.isCompleted() ? this.price[0].final_price : Coin.empty();
      } else if (isPending) {
        return this.isPending() ? this.price[0].final_price : Coin.empty();
      } else if (isNotCompleted) {
        return !this.isCompleted() ? this.price[0].final_price : Coin.empty();
      }
      return this.price[0].final_price;
    }
    return Coin.empty();
  }

  /**
   * Coin to pay if pending
   * @returns
   */
  getPendingToPaid() {
    if (this.price.length > 0 && this.getPendingToPaid()) {
      return this.price[0].final_price;
    }
    return Coin.empty();
  }

  /**
   * Calculate amount paid adding all payment intents
   * @param {*} paymentIntents
   * @returns Coin
   */
  static getTotalPaid = (paymentIntents) => {
    if (!paymentIntents || paymentIntents.length === 0) {
      return fEmptyPrice();
    }
    const totalPaid = paymentIntents.reduce(
      (sum, paymentIntent) => performPriceMath("+", paymentIntent.price.length > 0 ? paymentIntent.price[0].final_price : fEmptyPrice(), sum ? sum.amount : 0),
      0
    );
    return totalPaid;
  };

  /**
   * Get deposit if payment is pending
   * @param {*} paymentIntents
   * @returns
   */
  static getPendingDeposit(paymentIntents, subcontract) {
    if (paymentIntents && paymentIntents.length > 0) {
      const firstIntent = paymentIntents[0];
      if (firstIntent && firstIntent.getDeposit(true)) {
        return firstIntent.getDeposit(true);
      }
    } else if (subcontract && subcontract.price.length > 0) {
      const price = PriceCalculation.fromJSON(subcontract.price[0]);
      return price.getDeposit();
    }
  }

  /**
   * Check if the first intent is not paid, then use its price as it might include discounts
   * @param {*} paymentIntents
   * @returns
   */
  static getFirstIntentPending(paymentIntents) {
    if (paymentIntents && paymentIntents.length > 0) {
      const firstIntent = paymentIntents[0];
      if (firstIntent && firstIntent.price.length > 0 && firstIntent.isPending()) {
        return firstIntent;
      }
    }
    return null;
  }

  /**
   * Check if the first intent is not paid, then use its price as it migh include discounbts
   * @param {*} paymentIntents
   * @returns
   */
  static getFirstIntent(paymentIntents) {
    if (paymentIntents && paymentIntents.length > 0) {
      const firstIntent = paymentIntents[0];
      if (firstIntent && firstIntent.price.length > 0) {
        return firstIntent;
      }
    }
    return null;
  }

  /**
   * Calculate latest payment date based on intents.
   * Only include completed
   * 
   * @param {*} paymentIntents
   * @returns {payment_intent: lastPaymentIntent, date: lastPaymentDate};
   */
  static getLatestAndDate(paymentIntents, onlyCompleted = false) {
    // console.log("getLatestAndDate paymentIntents ", JSON.stringify(paymentIntents));
    if (!paymentIntents || paymentIntents.length === 0) {
      return null;
    }
    // Sort the payment intents by date (assuming they're already sorted)
    const sortedPaymentIntents = [...paymentIntents].sort((a, b) => Number(a.date) - Number(b.date))
    const filtered = onlyCompleted ? sortedPaymentIntents.filter((intent) => intent.isCompleted()) : sortedPaymentIntents;
    // Get the last payment intent
    if (filtered.length === 0) {
      return null;
    }
    const lastPaymentIntent = filtered[filtered.length - 1];
    // Convert the date from milliseconds to a Date object
    const lastPaymentDate = new Date(Number(lastPaymentIntent.date));
    return { payment_intent: lastPaymentIntent, date: lastPaymentDate };
  }

  // Function to calculate the next payment date based on the payment intents
  // If the payment is pending return its date instead of adding one month
  // Null if no intents, else return date
  // TODO calculate with interval
  static calculateNextPaymentDate(paymentIntents, interval, onlyCompleted = false) {
    const lastPaymentIntent = PaymentIntent.getLatestAndDate(paymentIntents, onlyCompleted);
    if (!lastPaymentIntent) {
      return null;
    }

    const lastPaymentDate = lastPaymentIntent.date;
    if (getEnumVariableText(lastPaymentIntent.payment_intent.status) === getEnumVariableText(PAYMENT_STATUS.pending)) {
      return lastPaymentDate;
    }
    // Calculate the next payment date
    const nextPaymentDate = new Date(lastPaymentDate.getFullYear(), lastPaymentDate.getMonth() + 1, 5);
    return nextPaymentDate;
  }
}
