export const idlFactory = ({ IDL }) => {
  const Permission = IDL.Variant({
    'NONE' : IDL.Null,
    'READ' : IDL.Null,
    'WRITE' : IDL.Null,
  });
  const Args = IDL.Record({
    'permission_key' : IDL.Text,
    'user_id' : IDL.Principal,
    'permission_type' : Permission,
  });
  const Response = IDL.Variant({ 'Success' : IDL.Null });
  const Currency = IDL.Variant({
    'EUR' : IDL.Null,
    'GBP' : IDL.Null,
    'USD' : IDL.Null,
  });
  const Coin = IDL.Record({ 'currency' : Currency, 'amount' : IDL.Nat64 });
  const ServiceType = IDL.Variant({
    'Valuation' : IDL.Record({
      'product_types' : IDL.Vec(IDL.Text),
      'price_max' : Coin,
      'price_min' : Coin,
    }),
    'Storage' : IDL.Record({
      'quality' : IDL.Nat32,
      'security' : IDL.Text,
      'capacity' : IDL.Nat32,
    }),
    'Sale' : IDL.Null,
    'Shop' : IDL.Null,
    'Maintenance' : IDL.Record({ 'product_types' : IDL.Vec(IDL.Text) }),
    'Subscription' : IDL.Null,
  });
  const Service = IDL.Record({
    'id' : IDL.Nat64,
    'service_type' : ServiceType,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'shop_id' : IDL.Nat64,
    'price' : IDL.Opt(Coin),
  });
  const Args_1 = IDL.Record({ 'service' : Service, 'user_id' : IDL.Principal });
  const Response_1 = IDL.Variant({
    'ServiceNotFound' : IDL.Null,
    'Success' : Service,
    'ShopNotFound' : IDL.Null,
    'NoPermission' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Location = IDL.Record({
    'lat' : IDL.Float32,
    'lng' : IDL.Float32,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'address' : IDL.Text,
  });
  const UserInfo = IDL.Record({
    'about' : IDL.Text,
    'username' : IDL.Text,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'avatar_url' : IDL.Text,
    'name' : IDL.Text,
    'id_verified' : IDL.Bool,
    'email' : IDL.Text,
    'location' : IDL.Opt(Location),
    'lastname' : IDL.Text,
    'id_card' : IDL.Opt(IDL.Text),
  });
  const AuthInfo = IDL.Record({
    'verifier' : IDL.Text,
    'verifier_id' : IDL.Text,
  });
  const SellerInfo = IDL.Record({
    'verified' : IDL.Bool,
    'tax_number' : IDL.Text,
    'invitations_sent' : IDL.Vec(IDL.Principal),
  });
  const Args_2 = IDL.Record({
    'user_info' : IDL.Opt(UserInfo),
    'user_id' : IDL.Opt(IDL.Principal),
    'auth_info' : IDL.Opt(AuthInfo),
    'seller_info' : IDL.Opt(SellerInfo),
  });
  const UserSummary = IDL.Record({
    'is_seller' : IDL.Bool,
    'about' : IDL.Text,
    'username' : IDL.Text,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'avatar_url' : IDL.Text,
    'name' : IDL.Text,
    'is_verified_seller' : IDL.Bool,
    'user_id' : IDL.Principal,
    'email' : IDL.Text,
    'location' : IDL.Opt(Location),
    'lastname' : IDL.Text,
  });
  const Response_2 = IDL.Variant({
    'EmailTaken' : IDL.Null,
    'UsernameTaken' : IDL.Null,
    'UserTaken' : UserSummary,
    'Success' : UserSummary,
    'Registered' : UserSummary,
    'UserNotFound' : IDL.Null,
  });
  const Args_3 = IDL.Record({
    'old_user_id' : IDL.Principal,
    'new_user_id' : IDL.Principal,
  });
  const Response_3 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_4 = IDL.Record({
    'username' : IDL.Opt(IDL.Text),
    'user_id' : IDL.Opt(IDL.Principal),
    'email' : IDL.Opt(IDL.Text),
  });
  const Response_4 = IDL.Variant({
    'EmailTaken' : IDL.Null,
    'UsernameTaken' : IDL.Null,
    'UsernameTooShort' : IDL.Nat16,
    'UsernameInvalid' : IDL.Null,
    'UsernameTooLong' : IDL.Nat16,
    'Success' : IDL.Null,
    'UsernameIdTaken' : IDL.Null,
  });
  const Args_5 = IDL.Record({
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'user_id' : IDL.Opt(IDL.Principal),
    'email' : IDL.Opt(IDL.Text),
    'visible' : IDL.Bool,
    'phone' : IDL.Opt(IDL.Text),
    'location' : Location,
  });
  const ContactInfo = IDL.Record({
    'email' : IDL.Opt(IDL.Text),
    'phone' : IDL.Opt(IDL.Text),
  });
  const Shop = IDL.Record({
    'id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'user_id' : IDL.Principal,
    'contact_info' : IDL.Opt(ContactInfo),
    'visible' : IDL.Bool,
    'location' : Location,
  });
  const Response_5 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : Shop,
    'UserNotFound' : IDL.Null,
  });
  const Args_6 = IDL.Record({
    'permission_key' : IDL.Text,
    'user_id' : IDL.Principal,
  });
  const Response_6 = IDL.Variant({
    'Success' : IDL.Null,
    'PermissionNotFound' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_7 = IDL.Record({ 'shop_id' : IDL.Nat64 });
  const Response_7 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : IDL.Null,
    'NoPermission' : IDL.Null,
  });
  const Args_8 = IDL.Record({
    'user_id' : IDL.Principal,
    'service_id' : IDL.Nat64,
  });
  const Args_9 = IDL.Record({ 'user_id' : IDL.Principal });
  const Response_8 = IDL.Variant({ 'Error' : IDL.Null, 'Success' : IDL.Null });
  const Canisters = IDL.Record({
    'payments_canister_id' : IDL.Opt(IDL.Principal),
    'products_canister_id' : IDL.Opt(IDL.Principal),
    'marketplace_canister_id' : IDL.Opt(IDL.Principal),
  });
  const Response_9 = IDL.Variant({ 'Success' : Canisters });
  const Response_10 = IDL.Variant({
    'Success' : IDL.Vec(IDL.Tuple(IDL.Text, Permission)),
    'UserNotFound' : IDL.Null,
  });
  const Response_11 = IDL.Variant({ 'Success' : IDL.Vec(Service) });
  const Args_10 = IDL.Record({
    'user_info' : IDL.Opt(UserInfo),
    'auth_info' : IDL.Opt(AuthInfo),
    'seller_info' : IDL.Opt(SellerInfo),
  });
  const UserWithPermissions = IDL.Record({
    'permissions' : IDL.Vec(IDL.Tuple(IDL.Text, Permission)),
    'info' : IDL.Opt(UserInfo),
    'user_id' : IDL.Principal,
    'auth_info' : IDL.Opt(AuthInfo),
    'seller_info' : IDL.Opt(SellerInfo),
  });
  const Response_12 = IDL.Variant({
    'EmailTaken' : IDL.Null,
    'UsernameTaken' : IDL.Null,
    'Success' : UserWithPermissions,
    'Registered' : UserWithPermissions,
    'UserNotFound' : IDL.Null,
  });
  const ShopSearchFilter = IDL.Record({
    'verified' : IDL.Opt(IDL.Bool),
    'username' : IDL.Opt(IDL.Text),
    'service_type' : IDL.Vec(ServiceType),
    'distance' : IDL.Opt(IDL.Float64),
    'user_id' : IDL.Opt(IDL.Principal),
    'visible' : IDL.Opt(IDL.Bool),
    'shop_ids' : IDL.Vec(IDL.Nat64),
    'shop_name' : IDL.Opt(IDL.Text),
    'location' : IDL.Opt(Location),
  });
  const Args_11 = IDL.Record({ 'filter' : ShopSearchFilter });
  const ShopExpanded = IDL.Record({
    'id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'distance' : IDL.Opt(IDL.Float64),
    'user_id' : IDL.Principal,
    'contact_info' : IDL.Opt(ContactInfo),
    'visible' : IDL.Bool,
    'location' : Location,
    'services' : IDL.Vec(Service),
  });
  const Response_13 = IDL.Variant({ 'Success' : IDL.Vec(ShopExpanded) });
  const User = IDL.Record({
    'info' : IDL.Opt(UserInfo),
    'user_id' : IDL.Principal,
    'auth_info' : IDL.Opt(AuthInfo),
    'seller_info' : IDL.Opt(SellerInfo),
  });
  const Response_14 = IDL.Variant({
    'Success' : User,
    'UserNotFound' : IDL.Null,
  });
  const UserSearchFilter = IDL.Record({
    'user_ids' : IDL.Vec(IDL.Principal),
    'username' : IDL.Opt(IDL.Text),
    'name' : IDL.Opt(IDL.Text),
    'email' : IDL.Opt(IDL.Text),
    'location' : IDL.Opt(Location),
  });
  const Args_12 = IDL.Record({ 'filter' : UserSearchFilter });
  const UserIdSummary = IDL.Record({
    'permissions' : IDL.Vec(IDL.Tuple(IDL.Text, Permission)),
    'is_seller' : IDL.Bool,
    'about' : IDL.Text,
    'username' : IDL.Text,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'avatar_url' : IDL.Text,
    'name' : IDL.Text,
    'id_verified' : IDL.Bool,
    'is_verified_seller' : IDL.Bool,
    'user_id' : IDL.Principal,
    'email' : IDL.Text,
    'location' : IDL.Opt(Location),
    'lastname' : IDL.Text,
    'id_card' : IDL.Opt(IDL.Text),
  });
  const Response_15 = IDL.Variant({ 'Success' : IDL.Vec(UserIdSummary) });
  const Response_16 = IDL.Variant({
    'Success' : UserIdSummary,
    'UserNotFound' : IDL.Null,
  });
  const Args_13 = IDL.Record({ 'filter' : UserSearchFilter });
  const Response_17 = IDL.Variant({ 'Success' : IDL.Vec(User) });
  const Response_18 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
  });
  const Response_19 = IDL.Variant({
    'Success' : UserWithPermissions,
    'UserNotFound' : IDL.Null,
  });
  const Args_14 = IDL.Record({
    'shop_id' : IDL.Nat64,
    'new_user_id' : IDL.Principal,
  });
  const Response_20 = IDL.Variant({
    'NotAuthorized' : IDL.Null,
    'Success' : IDL.Null,
    'ShopNotFound' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_15 = IDL.Record({
    'user_id' : IDL.Principal,
    'seller_info' : SellerInfo,
  });
  const Response_21 = IDL.Variant({
    'EmailExists' : IDL.Null,
    'Success' : SellerInfo,
    'UsernameExists' : IDL.Null,
    'NoPermission' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const ShopWithServices = IDL.Record({
    'id' : IDL.Nat64,
    'metadata' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'user_id' : IDL.Principal,
    'contact_info' : IDL.Opt(ContactInfo),
    'visible' : IDL.Bool,
    'location' : Location,
    'services' : IDL.Vec(Service),
  });
  const Args_16 = IDL.Record({ 'shop' : ShopWithServices });
  const Response_22 = IDL.Variant({
    'NotFound' : IDL.Null,
    'Success' : ShopWithServices,
    'NoPermission' : IDL.Null,
  });
  const Args_17 = IDL.Record({
    'id_verified' : IDL.Bool,
    'user_id' : IDL.Principal,
    'id_card' : IDL.Opt(IDL.Text),
  });
  const Response_23 = IDL.Variant({
    'EmailExists' : IDL.Null,
    'Success' : IDL.Null,
    'UsernameExists' : IDL.Null,
    'NoPermission' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  const Args_18 = IDL.Record({
    'user_info' : UserInfo,
    'user_id' : IDL.Principal,
  });
  const Response_24 = IDL.Variant({
    'EmailExists' : IDL.Null,
    'Success' : UserInfo,
    'UsernameExists' : IDL.Null,
    'NoPermission' : IDL.Null,
    'UserNotFound' : IDL.Null,
  });
  return IDL.Service({
    'add_permission' : IDL.Func([Args], [Response], []),
    'add_shop_service' : IDL.Func([Args_1], [Response_1], []),
    'add_user' : IDL.Func([Args_2], [Response_2], []),
    'change_user_id' : IDL.Func([Args_3], [Response_3], []),
    'check_user_free' : IDL.Func([Args_4], [Response_4], ['query']),
    'create_shop' : IDL.Func([Args_5], [Response_5], []),
    'delete_permission' : IDL.Func([Args_6], [Response_6], []),
    'delete_shop' : IDL.Func([Args_7], [Response_7], []),
    'delete_shop_service' : IDL.Func([Args_8], [Response_1], []),
    'delete_user' : IDL.Func([Args_9], [Response_7], []),
    'generate_users_testdata' : IDL.Func([IDL.Record({})], [Response_8], []),
    'get_canister_ids' : IDL.Func([IDL.Record({})], [Response_9], ['query']),
    'get_current_user_permissions' : IDL.Func(
        [IDL.Record({})],
        [Response_10],
        ['query'],
      ),
    'get_my_services' : IDL.Func([IDL.Record({})], [Response_11], ['query']),
    'get_or_add_user' : IDL.Func([Args_10], [Response_12], []),
    'get_shops' : IDL.Func([Args_11], [Response_13], ['query']),
    'get_user_by_id' : IDL.Func([Args_9], [Response_14], ['query']),
    'get_user_id_summaries' : IDL.Func([Args_12], [Response_15], ['query']),
    'get_user_permissions' : IDL.Func([Args_9], [Response_10], ['query']),
    'get_user_with_id_card' : IDL.Func([Args_9], [Response_16], ['query']),
    'get_users' : IDL.Func([Args_13], [Response_17], ['query']),
    'notify_user_permissions' : IDL.Func([IDL.Record({})], [Response_18], []),
    'remove_seller' : IDL.Func([Args_9], [Response_7], []),
    'reset_users_testdata' : IDL.Func([IDL.Record({})], [Response], []),
    'setup_current_user_admin' : IDL.Func([IDL.Record({})], [Response], []),
    'sign_in' : IDL.Func([IDL.Record({})], [Response_19], ['query']),
    'transfer_shop_ownership' : IDL.Func([Args_14], [Response_20], []),
    'update_canister_ids' : IDL.Func([Canisters], [Response], []),
    'update_seller_info' : IDL.Func([Args_15], [Response_21], []),
    'update_shop' : IDL.Func([Args_16], [Response_22], []),
    'update_shop_service' : IDL.Func([Args_1], [Response_1], []),
    'update_user_id_card' : IDL.Func([Args_17], [Response_23], []),
    'update_user_info' : IDL.Func([Args_18], [Response_24], []),
  });
};
export const init = ({ IDL }) => { return []; };
