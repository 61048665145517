export default class Utils {
  static flattenAndMergeProps(otherProps) {
    if (Array.isArray(otherProps)) {
      return otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    } else if (typeof otherProps === "object") {
      return otherProps;
    }
    return {};
  }

  static cloneOtherProps(instance, excludedKeys) {
    const otherPropsClone = {};
    Object.keys(instance).forEach((key) => {
      if (!excludedKeys.includes(key)) {
        otherPropsClone[key] = Array.isArray(instance[key]) ? [...instance[key]] : instance[key];
      }
    });
    return otherPropsClone;
  }
}
