import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingOverlay({ loading, children, sx }) {
  return (
    <Box sx={{ position: "relative" }}>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9999,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            ...sx, // Merge with the provided sx prop
          }}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {children}
    </Box>
  );
}

// LoadingOverlay.propTypes = {
//   loading: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired,
// };
