 import { PriceCalculation } from "./PriceCalculation";

export class OrderItemPrice {
  constructor({ product_id, price, ...otherProps }) {
    this.product_id = product_id;
    this.price = price instanceof PriceCalculation ? price : new PriceCalculation(price);

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(orderItemPriceJSON) {
    if (!orderItemPriceJSON) return null;
    const { product_id, price, ...otherProps } = orderItemPriceJSON;
    return new OrderItemPrice({
      product_id,
      price: PriceCalculation.fromJSON(price),
      ...otherProps,
    });
  }

  getOtherProps() {
    const { product_id, price, ...otherProps } = this;
    return otherProps;
  }

  clone() {
    return new OrderItemPrice({
      product_id: this.product_id,
      price: this.price.clone(),
      ...this.getOtherProps(),
    });
  }

  // Add the hasDiscount method
  hasDiscount() {
    // Use the PriceCalculation's method to check for a discount
    return this.price && this.price.hasDepositDiscount();
  }
}

export class PriceSummary {
  constructor({ items, price, ...otherProps }) {
    this.items = items.map((item) => (item instanceof OrderItemPrice ? item : OrderItemPrice.fromJSON(item)));
    this.price = price instanceof PriceCalculation ? price : PriceCalculation.fromJSON(price);

    // Assign other properties

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(priceSummaryJSON) {
    if (!priceSummaryJSON) return null;
    const { items, price, ...otherProps } = priceSummaryJSON;
    return new PriceSummary({
      items: items.map((item) => OrderItemPrice.fromJSON(item)),
      price: PriceCalculation.fromJSON(price),
      ...otherProps,
    });
  }

  getOtherProps() {
    const { items, price, ...otherProps } = this;
    return otherProps;
  }

  clone() {
    return new PriceSummary({
      items: this.items.map((item) => item.clone()),
      price: this.price.clone(),
      ...this.getOtherProps(),
    });
  }

  // Add a method to get an item by product_id
  getItemByProductId(productId) {
    return this.items.find((item) => BigInt(item.product_id) === BigInt(productId)) || null;
  }

  // getSubscriptionDiscountMaxCount() {
  //   return this.price.length > 0  && this.price[0].discounts_used.length>0 ? this.price[0].discounts_used[0].getSubscriptionMaxCount();
  // }
}

export default PriceSummary;
