import { Card } from "./Card";
import { PaymentMethodType } from "./PaymentMethodType";

// PaymentStatus enum
export const PAYMENT_STATUS = {
  Pending: 'Pending',
  Failed: 'Failed',
  WrongMethod: 'WrongMethod',
  Completed: 'Completed',
};

// PaymentMethodStatus enum
export const PAYMENT_METHOD_STATUS = {
  Valid: 'Valid',
  Error: 'Error',
  ExpiredCard: 'ExpiredCard',
};

// PaymentMethod class
export class PaymentMethod {
  constructor({
    id,
    user_id,
    authorized_transaction_id = [],
    method_type = [],
    status,
    primary,
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.user_id = user_id;
    this.authorized_transaction_id = authorized_transaction_id.length ? authorized_transaction_id : [];
    this.method_type = method_type.length ? method_type.map((mt) => new PaymentMethodType(mt)) : [];
    this.status = status;
    this.primary = primary;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(paymentMethodJSON) {
    if (!paymentMethodJSON) return null;
    const {
      id,
      user_id,
      authorized_transaction_id = [],
      method_type = [],
      status,
      primary,
      ...otherProps
    } = paymentMethodJSON;
    return new PaymentMethod({
      id,
      user_id,
      authorized_transaction_id,
      method_type,
      status,
      primary,
      ...otherProps,
    });
  }

  clone() {
    return new PaymentMethod({
      id: this.id,
      user_id: this.user_id,
      authorized_transaction_id: this.authorized_transaction_id.length ? this.authorized_transaction_id : [],
      method_type: this.method_type.length ? this.method_type.map((mt) => mt.clone()) : [],
      status: this.status,
      primary: this.primary,
    });
  }
}

// PaymentMethodExpanded class
export class PaymentMethodExpanded {
  constructor({
    id,
    user_id,
    authorized_transaction_id = [],
    card = [],
    status,
    primary,
    ...otherProps
  }) {
    this.id = BigInt(id);
    this.user_id = user_id;
    this.authorized_transaction_id = authorized_transaction_id.length ? authorized_transaction_id : [];
    this.card = card.length ? card.map((c) => new Card(c)) : [];
    this.status = status;
    this.primary = primary;

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(paymentMethodExpandedJSON) {
    if (!paymentMethodExpandedJSON) return null;
    const {
      id,
      user_id,
      authorized_transaction_id = [],
      card = [],
      status,
      primary,
      ...otherProps
    } = paymentMethodExpandedJSON;
    return new PaymentMethodExpanded({
      id,
      user_id,
      authorized_transaction_id,
      card,
      status,
      primary,
      ...otherProps,
    });
  }

  clone() {
    return new PaymentMethodExpanded({
      id: this.id,
      user_id: this.user_id,
      authorized_transaction_id: this.authorized_transaction_id.length ? this.authorized_transaction_id : [],
      card: this.card.length ? this.card.map((c) => c.clone()) : [],
      status: this.status,
      primary: this.primary,
    });
  }

  getCard() {
    return this.card.length ? this.card[0] : null;
  }
}