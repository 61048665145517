// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import { Box, Typography } from "@mui/material";
import { m } from "framer-motion";
import Header from "layouts/sections/page-sections/page-headers/Header";
import { MotionViewport, varFade } from "../../../../../ui/components/animate";
import Carousel, { CarouselDots, useCarousel } from "../../../../../ui/components/carousel";
import { useLocales } from "../../../../../ui/locales";
import { Trans } from "react-i18next";
import { primaryTextGradient } from "../../../../../ui/utils/cssStyles";
import { useTheme } from "@mui/material";

function Testimonials({smallHeader=false}) {
  const { t } = useLocales();
  const theme = useTheme();
  const carousel = useCarousel({
    infinite: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
    ...CarouselDots({
      sx: {
        // top: 16,
        // left: 16,
        // position: "absolute",
        // color: "primary.light",
      },
    }),
  });

  const TESTIMONIALS = [
    {
      id: 1,
      name: "landing.testimonial_name",
      review: "landing.testimonial",
      date: "landing.testimonial_date",
      itemReviewedName: "landing.testimonial_item_reviewed_name",
      color: "transparent",
      rating: 5,
    },
    {
      id: 2,
      name: "landing.testimonial_name_2",
      review: "landing.testimonial_2",
      date: "landing.testimonial_date_2",
      itemReviewedName: "landing.testimonial_item_reviewed_name_2",
      color: "info",
      rating: 5,
    },
    {
      id: 3,
      name: "landing.testimonial_name_3",
      review: "landing.testimonial_3",
      date: "landing.testimonial_date_3",
      itemReviewedName: "landing.testimonial_item_reviewed_name_3",
      color: "transparent",
      rating: 5,
    },
    {
      id: 4,
      name: "landing.testimonial_name_4",
      review: "landing.testimonial_4",
      date: "landing.testimonial_date_4",
      itemReviewedName: "landing.testimonial_item_reviewed_name_4",
      color: "transparent",
      rating: 5,
    },
    {
      id: 5,
      name: "landing.testimonial_name_6",
      review: "landing.testimonial_6",
      date: "landing.testimonial_date_6",
      itemReviewedName: "landing.testimonial_item_reviewed_name_6",
      color: "transparent",
      rating: 5,
    },
    {
      id: 6,
      name: "landing.testimonial_name_7",
      review: "landing.testimonial_7",
      date: "landing.testimonial_date_7",
      itemReviewedName: "landing.testimonial_item_reviewed_name_7",
      color: "info",
      rating: 5,
    },
    {
      id: 6,
      name: "landing.testimonial_name_8",
      review: "landing.testimonial_8",
      date: "landing.testimonial_date_8",
      itemReviewedName: "landing.testimonial_item_reviewed_name_8",
      color: "transparent",
      rating: 5,
    },
  ];



  return (
    <MKBox component="section" py={12}>
      <Container component={MotionViewport}>
        <Grid container item xs={12} lg={6} justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          {!smallHeader && (
            <Header
              title={t("landing.testimonials_title")}
              secondTitle={t("landing.testimonials_title2")}
              preTitle={t("landing.testimonials_pretitle")}
              subtitle={t("landing.testimonials_subtitle")}
            />
          )}
          {smallHeader && (
            <m.div variants={varFade().inUp}>
              <Typography variant="h2" align="center">
                <Trans i18nKey="about_us.they_recommend_symphy">
                  <Typography
                    variant="h2"
                    component="span"
                    sx={{
                      ...primaryTextGradient(theme.palette.gradients),
                      display: "inline-block",
                    }}>
                    {/* {translate("intro.action_get_title_b")} */}
                  </Typography>
                </Trans>
                {/* {translate("intro.action_get_title_full")} */}
              </Typography>
            </m.div>
          )}
        </Grid>
        <Box sx={{ position: "relative", mx: "auto" }}>
          <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
            {TESTIMONIALS.map((testimonial, index) => (
              <Box
                key={index}
                component={m.div}
                variants={varFade().in}
                sx={{
                  px: 1.5,
                  pt: { xs: 8, md: 10 },
                  pb: { xs: 2, md: 3 },
                }}>
                <DefaultReviewCard
                  color={testimonial.color}
                  name={t(testimonial.name)}
                  date={t(testimonial.date)}
                  review={t(testimonial.review)}
                  rating={testimonial.rating}
                  itemReviewedName={t(testimonial.itemReviewedName)}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
