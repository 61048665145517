import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
// routes
// components
import MKBox from 'components/MKBox';
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';
import { MotionViewport, varFade } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import { useLocales } from '../../../../../ui/locales';
import { PATH_PAGE } from '../../../../../ui/routes/paths';

// ----------------------------------------------------------------------

export default function InstrumentDrop() {
  const { t } = useLocales();
  const renderDescription = (
    <Box sx={{ textAlign: { xs: "center", md: "unset" }, mt: { xs: 10, md: 20, color: "white" }, mb: { xs: 8, md: 0} }}>
      <m.div variants={varFade().inUp}>
        <Typography component="div" variant="overline">
          {t("landing.intro_action_title_2b")}
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          variant="h2"
          sx={{
            mt: 3,
            // ...textGradient(`300deg, ${theme.palette.primary.main} 0%, ${theme.palette.warning.main} 100%`),
          }}>
          {t("landing.instruments_drop_title")}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            mb: 5,
          }}>
          {t("landing.instruments_drop_title2")}
        </Typography>
      </m.div>
      <m.div variants={varFade().inUp}>
        <Button color="inherit" size="large" variant="outlined" href={PATH_PAGE.faqs.path} endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}>
          {t("landing.instruments_drop_action2")}
        </Button>
      </m.div>
    </Box>
  );

  const renderImg = (
    <m.div variants={varFade().inRight}>
      <CenteredBlogCard
        sx={{mt : {xs: 2, md: 0}}}
        image="/assets/instruments/violin.avif"
        title={t("landing.instruments_drop_action_title")}
        description={t("landing.instruments_drop_action_desc")}
        action={{
          type: "internal",
          route: PATH_PAGE.seller_finder.path,
          color: "info",
          label: t("landing.instruments_drop_action"),
        }}
      />
    </m.div>
  );

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="primary"
      position="relative"
      mt={12}
      px={2}
      mx={-2}
      sx={{
        minHeight: 560,
      }}>
      <Container component={MotionViewport}>
        <Grid container pb={12}>
          <Grid xs={12} md={6}>
            {renderDescription}
          </Grid>
          <Grid md={6}>{renderImg}</Grid>
          {/* {upMd && <Grid md={6}>{renderImg}</Grid>} */}
        </Grid>
      </Container>
    </MKBox>
  );
}
