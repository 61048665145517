// @mui material components



// Images
import { Box, Container, Grid } from "@mui/material";
import { useResponsive } from "../../../../../ui/hooks/use-responsive";
import { useLocales } from "../../../../../ui/locales";
import PricingCard from "../../../../../ui/sections/pricing/pricing-card";
import MKBox from "components/MKBox";
import { m } from "framer-motion";
import { MotionViewport, varFade } from "../../../../../ui/components/animate";
import Header from "layouts/sections/page-sections/page-headers/Header";

export const _pricingPlans = [
  {
    subscription: "basic",
    title: "Elemental",
    price: 14.99,
    caption: "Ideal para los que están empezando",
    lists: [
      { title: "Instrumentos valorados en hasta 3.000€", active: true },
      { title: "Funda y arco", active: true },
      { title: "Seguro incluído", active: true },
      { title: "Revisión anual", active: true },
      { title: "Instrumentos de Luthier", active: false },
      { title: "Maderas nobles", active: false },
      { title: "Cuerdas premium", active: false },
    ],
    labelAction: "Escoger",
    data: { filter: { priceMin: 0, priceMax: 34.9 } },
  },
  {
    subscription: "starter",
    title: "Avanzado",
    price: 39.99,
    caption: "Sonido superior para conservatorios profesionales",
    lists: [
      { title: "Instrumentos valorados en hasta 10.000€", active: true },
      { title: "Funda y arco", active: true },
      { title: "Seguro incluído", active: true },
      { title: "Revisión anual", active: true },
      { title: "Instrumentos de Luthier", active: true },
      { title: "Maderas nobles", active: false },
      { title: "Cuerdas premium", active: false },
    ],
    labelAction: "Escoger avanzado",
    data: { filter: { priceMin: 35, priceMax: 84.9 } },
  },
  {
    subscription: "premium",
    title: "Profesional",
    price: 89.99,
    caption: "La música es tu vida?",
    lists: [
      { title: "Instrumentos valorados en más de 10.000€", active: true },
      { title: "Funda y arco", active: true },
      { title: "Seguro incluído", active: true },
      { title: "Revisión anual", active: true },
      { title: "Instrumentos de Luthier", active: true },
      { title: "Maderas nobles", active: true },
      { title: "Cuerdas premium", active: true },
    ],
    labelAction: "Escoger profesional",
    data: { filter: { priceMin: 85} },
  },
];

export const INSTRUMENT_CATEGORY_FILTERS = {
  violines: { productType: "violin", priceRange: [0, 350], title: "landing.violins", description: "landing.violins_desc" },
  "violines-principiante": {
    productType: "violin",
    priceRange: [0, 34.9],
    title: "landing.violins_beginner",
    description: "landing.violins_desc_beginner",
  },
  "violines-profesional": {
    productType: "violin",
    priceRange: [35, 350],
    title: "landing.violins_professional",
    description: "landing.violins_desc_professional",
  },

  violas: { productType: "viola", priceRange: [0, 350], title: "landing.violas", description: "landing.violas_desc" },
  "violas-principiante": {
    productType: "viola",
    priceRange: [0, 34.9],
    title: "landing.violas_beginner",
    description: "landing.violas_desc_beginner",
  },
  "violas-profesional": {
    productType: "viola",
    priceRange: [35, 350],
    title: "landing.violas_professional",
    description: "landing.violas_desc_professional",
  },

  violonchelos: { productType: "cello", priceRange: [0, 350], title: "landing.violoncellos", description: "landing.cellos_desc" },
  "violonchelos-principiante": {
    productType: "violonchelos_principiante",
    priceRange: [0, 34.9],
    title: "landing.cellos_beginner",
    description: "landing.cellos_desc_beginner",
  },
  "violonchelos-profesional": {
    productType: "cello",
    priceRange: [35, 350],
    title: "landing.cellos_professional",
    description: "landing.cellos_desc_professional",
  },
};


function InstrumentCategories({ withEvent =false }) {
  const { t } = useLocales();
  return (
    <MKBox
      component="section"
      // bgColor={bgColor}
      position="relative"
      pb={12}
      // py={20}
      px={{ xs: 2, lg: 0 }}
      mx={-2}>
      <Container component={MotionViewport} maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Header
              reversed
              // predivider={true}
              // preTitle={t("landing.instruments_cat_pretitle")}
              title={"SYMPHY"}
              secondTitle={t("landing.instruments_cat_title")}
              subtitle={t("landing.instruments_cat_subtitle")}
            />
          </Grid>
        </Grid>
        <m.div variants={varFade().inUp}>
          <Box
            gap={{ xs: 3, lg: 3 }}
            display="grid"
            alignItems="center"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            }}>
            {_pricingPlans.map((card, index) => (
              <PricingCard key={card.subscription} card={card} index={index} withEvent={withEvent} />
            ))}
          </Box>
        </m.div>
      </Container>
    </MKBox>
  );
}

export default InstrumentCategories;
