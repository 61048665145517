
// // Payment for providers/instrument owners

import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";


export default class Payment {
  constructor(id, subcontract_id, user_id, payment_type, price, status, metadata = [], ...otherProps) {
    this.id = id;
    this.subcontract_id = subcontract_id;
    this.user_id = user_id;
    this.price = price;
    this.payment_type = payment_type;
    this.status = status;
    this.metadata = metadata;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(transactionItemJSON) {
    if (!transactionItemJSON) return null;
    // Extract known properties
    const { id, subcontract_id, user_id, price, payment_type, status, metadata, ...otherProps } = transactionItemJSON;
    // Return a new instance of TransactionItem including any additional properties
    return new Payment(id, subcontract_id, user_id, price, payment_type, status, metadata, otherProps);
  }

  clone() {
    // Create a shallow copy of this instance, including any additional properties
    const clonedObj = new Payment(this.id, this.subcontract_id, this.user_id, this.price, this.payment_type, this.status, [...this.metadata]);
    // Copy any additional properties that were dynamically added
    Object.keys(this).forEach((key) => {
      if (!clonedObj.hasOwnProperty(key)) {
        clonedObj[key] = this[key];
      }
    });

    return clonedObj;
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    // const entry = this.metadata.find(([entryKey]) => entryKey === key);
    return value && value.length > 0 ? value : null;
  }

  isCompleted() {
    return this.getStatusText() === "Completed";
  }

  getStatus() {
    return this.status;
  }

  getStatusText() {
    return this.getStatus() ? getEnumVariableText(this.getStatus()) : null;
  }

  static createPaymentType = ({type = "BankTransfer", senderAccount, receiverAccount, confirmationDate}) => {
    return {BankTransfer: {
      sender_account: senderAccount ? [senderAccount] : [],
      receiver_account: receiverAccount,
      confirmation_date: confirmationDate ? [confirmationDate] : [],
    }};
  };
}
