import { Principal } from '@dfinity/principal';
import { createCoinFromAmount } from 'src/utils/format-number.js';
import ProductsService from "./ProductsService.js";
import { _userAbout } from 'src/_mock/_user.js';
import { users } from "../../../declarations/users";
import BackendService from './BackendService.js';
import { createLocationMetadata, createMadridLocation, createUserInfoMetadata, getEnumVariableText, getMapValueForKey } from 'src/utils/typeConverters.js';
import { validateEmail } from 'src/utils/uiUtils.js';
import { IS_PROD } from 'src/config-global.js';
import { is } from 'date-fns/locale';
import UserSummary from 'model/users/UserSummary.js';
import Shop from 'model/users/Shop.js';

// Keys for the form and db
export const KEY_USERNAME = "username";
export const KEY_NAME = "name";
export const KEY_LAST_NAME = "lastname";
export const KEY_PHONE = "phone";
export const KEY_EMAIL = "email";
export const KEY_LOCATION = "location";
export const KEY_ABOUT = "about";
export const KEY_ROLES = "roles";
export const KEY_ID_CARD = "id_card";
export const KEY_ID_VERIFIED = "id_verified";
export const KEY_IS_ADMIN= "is_admin";

/**
 * Service wrapper for the retrieved User.
 * This wrapper ensures that the user is stored in the local storage whenever it is updated, and retrieved in other
 * cases. This allows the frontend showing some information, even though it may not be up to date (a warning should be shown)
 */
// TODO convert from static to export function
export default class UserService {
  static USER_KEY = "symphy_user";

  /**
   * Convert user data from a web3 user to backend userInfo
   *
   */
  static convertFirebaseUserToInfo = (user, userInfo) => {
    // Extract values from userInfo if present, otherwise use values from user
    const name = userInfo?.name || user?.displayName || "";
    const email = userInfo?.email || user?.email || "";
    const avatar_url = userInfo?.avatar_url || user?.photoURL || "";
    const username = userInfo?.username || user?.email || "";

    // Metadata creation based on phone number from user or userInfo
    let metadata = userInfo?.metadata || null;
    if (!metadata && user.phoneNumber) {
      metadata = createUserInfoMetadata({ phone: user.phoneNumber });
    }

    // Create and return user info using createUserInfo method
    return this.createUserInfo({
      name,
      email,
      avatar_url,
      username,
      lastname: userInfo?.lastname,
      about: userInfo?.about,
      location: userInfo?.location,
      id_card: userInfo?.id_card,
      id_verified: userInfo?.id_verified,
      metadata,
    });
  };

  /**
   * Convert user data from a web3 user to backend userInfo
   *
   */
  static createUserInfo = ({ name, lastname, about, avatar_url, email, username, location, id_card, id_verified, metadata }) => {
    let info = {
      name: name ? name : "",
      lastname: lastname ? lastname : "",
      about: about ? about : "",
      avatar_url: avatar_url ? avatar_url : "",
      email: email ? email : "",
      username: username ? username : "",
      location: location ? Array.isArray(location) && location.length>0 ? location : [location] : [],
      id_card: id_card ? Array.isArray(id_card) && id_card.length> 0 ? id_card : [id_card] : [], // NIF, DNI
      id_verified: id_verified ? id_verified : false,
      metadata: metadata ? metadata : [],
    };
    return info;
  };

  /**
   * Get info about the auth method
   *
   */
  static getFirebaseAuthInfo = (user) => {
    let info = {
      verifier: "firebase",
      verifier_id: user.uid,
    };
    return info;
  };

  /**
   * Check if user has a password
   *
   */
  static userHasPassword = (user) => {
    // TODO: implement when login with web3auth is reviewed
    if (!user || !user.auth_info) return false;
    return user?.auth_info[0] ? true : false;
  };

  /**
   * Retrieves the remote user
   *
   * @param {*} symphy_backend The symphy_backend service
   * @returns An object containing the user stored in the remote canister
   */
  static getBackendUser = async (symphy_backend, userInfo, authInfo, sellerInfo) => {
    let args = {
      user_info: userInfo ? [userInfo] : [],
      auth_info: authInfo ? [authInfo] : [],
      seller_info: sellerInfo ? [sellerInfo] : [],
    };

    // First try to sigin in to avoid doing an update in the backend (slower)
    let response = await symphy_backend["users"].sign_in({});
    if (getEnumVariableText(response) === "UserNotFound") {
      response = await symphy_backend["users"].get_or_add_user(args);
      if (!response.Success && !response.Registered) {
        console.log("getRemoteUser response was not successful " + JSON.stringify(response));
        return;
      }
    }
    const user = response.Success ? response.Success : response.Registered;
    if (response.Registered) {
      user.just_registered = true; // Data asks for user data. // welcome, shows postregistration // null, when allcompleted
      if (!IS_PROD) {
        // Add dummy data for testing
        const currentInfo = user.info.length > 0 ? user.info[0] : {};
        const email =
          currentInfo && currentInfo.email && currentInfo.email.length > 0 ? currentInfo.email : "pablosone@symphy.es" + new Date().getTime().toString();
        let location = createMadridLocation(
          createLocationMetadata({
            floor: "1",
            city: "Madrid",
            region: "Madrid",
            zip_code: "28001",
            other_info: "",
          })
        );
        console.log("Add new user dummy data current " + JSON.stringify(currentInfo) + " email " + email + " location " + JSON.stringify(location));
        user.info = [
          {
            name: currentInfo.name ? currentInfo.name : "Pablo",
            lastname: currentInfo.lastname ? currentInfo.lastname : "García",
            about: "",
            avatar_url: currentInfo.avatar_url ? currentInfo.avatar_url : "",
            email: email,
            username: email,
            location: location,
            id_card: ["47377000R"], // NIF, DNI
            id_verified: false,
            metadata: [["phone", "666666666"]],
          },
        ];
        console.log("Add new user dummy data");
        const info = await UserService.updateUserInfo(symphy_backend, user.user_id, user.info[0], true);
      }
    }

    // If we get a new user, it may have the user_info field empty.
    // Initialise it in order to be able to update it.
    if (!user.info || user.info.length === 0) {
      user.info = [
        {
          name: "",
          lastname: "",
          about: "",
          avatar_url: "",
          email: "",
          username: "",
          location: [],
          id_card: [], // NIF, DNI
          id_verified: false,
          metadata: [],
        },
      ];
    }
    // const permissions = await symphy_backend["users"].get_current_user_permissions({});
    // if (!permissions.Success) {
    //   console.log("get_current_user_permissions not successful");
    //   return;
    // }
    const roles = user.permissions;
    user.roles = [];
    if (roles && this.backendRolesContainAdmin(roles)) {
      // console.log("Roles " + JSON.stringify(roles));
      user.roles.push("admin");
      user.roles = this.backendRolesContainAdmin(roles) ? ["admin"] : [];
    }
    if (this.isSeller(user, false)) {
      const isVerified = this.isSeller(user, true);
      user.roles.push(isVerified ? "seller" : "unseller");
      if (await this.canVerifyInstrument(symphy_backend, user)) {
        user.roles.push("product_verifier");
      }
    }
    // Save it local storage
    localStorage.setItem(
      UserService.USER_KEY,
      JSON.stringify(user, (_, v) => (typeof v === "bigint" ? parseInt(v.toString()) : v))
    );
    return user;
  };

  static addUser = async (symphy_backend, user) => {
    console.log("addUser " + user.user_id + " " + JSON.stringify(user));
    const response = await symphy_backend["users"].add_user({
      user_id: user.user_id,
      user_info: user.user_info,
      seller_info: user.seller_info,
      auth_info: user.auth_info,
    });
    console.log("addUser response " + JSON.stringify(response));
    if (!response.Success && !response.Registered) {
      console.log("addUser response was not successful " + JSON.stringify(response));
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  /**
   * Check backend roles to check if the user is an admin
   * @param {*} roles
   * @returns
   */
  static backendRolesContainAdmin = (roles) => {
    if (roles) {
      let isAdmin = false;
      // The roles come in an array
      roles.forEach((role) => {
        const roleKey = role[0];
        const roleValue = ProductsService.getEnumVariableText(role[1]);
        // console.log(
        //   "Is admin role full key  " + roleKey + " value " + roleValue
        // );
        if (roleKey === "Global" && roleValue === "WRITE") {
          isAdmin = true;
          return isAdmin;
        }
      });
      return isAdmin;
    }
    return false;
  };

  static isUserIdVerified = (user) => {
    return user && user.info && user.info.length > 0 && user.info[0].id_verified;
  };

  static isLocalUserAdmin = (user) => {
    return user && user.roles && user.roles.includes("admin");
  };

  static setUserAdmin = async (symphy_backend, user_id, oldValue, isAdmin) => {
    // console.log("setUserAdmin " + user_id + " " + oldValue + " " + isAdmin);
    // if (oldValue !== isAdmin) {
    if (isAdmin) {
      console.log("setUserAdmin " + user_id + " " + oldValue + " " + isAdmin);
      await symphy_backend["users"].add_permission({
        user_id: Principal.fromText(user_id.toString()),
        permission_key: "Global",
        permission_type: { WRITE: null },
      });
    } else {
      await symphy_backend["users"].delete_permission({
        user_id: Principal.fromText(user_id.toString()),
        permission_key: "Global",
      });
    }
    // }
  };

  static canVerifyInstrument = async (symphy_backend, user, instrumentType, valuationPrice) => {
    // return false;
    if (user) {
      // console.log("canVerifyInstrument admin " + this.isUserAdmin(user) + " seller " + this.sellerHasValuationService(user));
      // TODO check if seller has validation service for that instrument and price
      if (this.isLocalUserAdmin(user)) {
        return true;
      }
      const sellerHasValuationService = await this.sellerHasValuationService(symphy_backend, user);
      return sellerHasValuationService;
    }
    return false;
  };

  /**
   * Check if an user is a seller and can verify products
   * TODO check based on product type and price range
   * @param {*} user
   * @returns
   */
  static sellerHasValuationService = async (symphy_backend, user) => {
    if (!this.isSeller(user, true)) {
      return false;
    }
    // TODO store in localstorage
    // console.log("sellerHasValuationService get usershops");
    const shops = await this.getUserShops(symphy_backend, user);
    let result = false;
    shops.forEach((shop) => {
      if (this.sellerGetShopValuationService(shop)) {
        result = true;
        return;
      }
    });
    return result;
  };

  static sellerGetShopValuationService = (shop) => {
    let result = null;
    shop.services.forEach((service) => {
      const serviceType = ProductsService.getEnumVariableText(service.service_type);
      // console.log("sellerGetShopValuationService " + serviceType);
      if (serviceType === "Valuation") {
        result = service;
        return;
      }
    });
    return result;
  };

  /**
   * Retrieves the User from the local storage
   *
   * @param {*} symphy_backend The symphy_backend service
   * @returns An object containing the user stored in the local storage
   */
  static getLocalUser = () => {
    const user = localStorage.getItem(UserService.USER_KEY);
    if (user) {
      const parsed = JSON.parse(user);
      return {
        ...parsed,
        isLocal: true,
      };
    }
    return null;
  };

  /**
   * Delete the local storage user
   *
   */
  static deleteLocalUser = () => {
    localStorage.removeItem(UserService.USER_KEY);
  };

  /**
   * Updates the user information in the remote for the current logged used
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The userInfo to be updated
   * @returns The updated user
   */
  static updateUserInfo = async (symphy_backend, userId, info, local) => {
    // let args = {
    //   user_id: user.user_id,
    //   user_info: user.info[0],
    // };
    let args = {
      user_id: userId,
      user_info: info,
    };
    const response = await symphy_backend["users"].update_user_info(args);
    if (!response.Success) {
      console.log("updateUserInfo response was not successful " + JSON.stringify(response));
      return;
    }
    const updated = response.Success;
    if (local) this.updateLocalUser({ userInfo: updated });
    return updated;
  };

  /**
   * Send a notification to canister about the current users permissions
   * @param {*} symphy_backend
   * @returns
   */
  static notifyUserPermissions = async (symphy_backend) => {
    const response = await symphy_backend["users"].notify_user_permissions({});
    if (BackendService.isResponseError(response)) {
      console.log("notifyUserPermissions response was not successful " + JSON.stringify(response));
      return;
    }
    return response.Success;
  };

  static getBackendCanisterIds = async (symphy_backend) => {
    const response = await symphy_backend["users"].get_canister_ids({});
    if (BackendService.isResponseError(response)) {
      console.log("getBackendCanisterIds response was not successful " + JSON.stringify(response));
      return;
    }
    return response.Success;
  };

  static updateLocalUser = ({ user, userInfo, sellerInfo }) => {
    let localUser = JSON.parse(localStorage.getItem(UserService.USER_KEY));
    if (!localUser) return null;
    if (user) localUser = [user];
    if (userInfo) localUser.info = [userInfo];
    if (sellerInfo) localUser.seller_info = [sellerInfo];
    localStorage.setItem(
      UserService.USER_KEY,
      JSON.stringify(localUser, (_, v) => (typeof v === "bigint" ? parseInt(v.toString()) : v))
    );
    return localUser;
  };

  static createSellerInfo = (taxNumber) => {
    return {
      verified: false,
      shops: [],
      invitations_sent: [],
      tax_number: taxNumber,
    };
  };

  /**
   * Updates the seller information in the remote for the current logged used
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The sellerInfo to be updated
   * @returns The updated user
   */
  static updateSellerInfo = async (symphy_backend, user, local) => {
    let args = {
      user_id: user.user_id,
      seller_info: user.seller_info[0],
    };
    const response = await symphy_backend["users"].update_seller_info(args);
    if (!response.Success) {
      console.log("updateUserInfo response was not successful");
      return;
    }
    const updated = response.Success;
    // console.log("updateSellerInfo", JSON.stringify(response));
    if (local) this.updateLocalUser({ sellerInfo: updated });
    return updated;
  };

  static createShop = ({ id, name, visible = true, description, location, email, phone, metadata, userId, services, toUpdate = false }) => {
    // console.log("createShop " + name + " " + JSON.stringify(description) + " location " + JSON.stringify(location) + " email " + email + " phone " + phone + " metadata " + metadata + " userId " + userId );
    const shop = {
      name: name,
      visible: visible,
      description: description,
      location: location,
      metadata: metadata ? metadata : [],
      user_id: userId ? Principal.fromText(userId.toString()) : null,
    };
    if (toUpdate) {
      shop.contact_info = [
        {
          email: email ? [email] : [],
          phone: phone ? [phone] : [],
        },
      ];
      shop.services = services ? services : [];
    } else {
      shop.email = email ? [email] : [];
      shop.phone = phone ? [phone] : [];
    }
    // eslint-disable-next-line no-undef
    if (id) shop.id = BigInt(id);
    // console.log("createShop 2 " + JSON.stringify(shop));
    return shop;
  };

  static addShop = async (symphy_backend, shop) => {
    const response = await symphy_backend["users"].create_shop({
      name: shop.name,
      visible: shop.visible,
      description: shop.description,
      location: shop.location,
      email: shop.email,
      phone: shop.phone,
      metadata: shop.metadata ? shop.metadata : [],
      user_id: shop.user_id ? [shop.user_id] : [],
    });
    if (!response.Success) {
      console.log("createShop response was not successful " + JSON.stringify(response));
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  static deleteShop = async (symphy_backend, shopId) => {
    // console.log("createShop " + shop.name + " " + JSON.stringify(shop.location));
    const response = await symphy_backend["users"].delete_shop({
      shop_id: shopId,
    });
    if (BackendService.isResponseError(response)) {
      console.log("deleteShop response was not successful " + JSON.stringify(response));
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  static updateShop = async (symphy_backend, shop) => {
    // console.log("createShop " + shop.name + " " + JSON.stringify(shop.location));
    const response = await symphy_backend["users"].update_shop({
      shop: shop,
    });
    if (!response.Success) {
      console.log("Update shop response was not successful " + JSON.stringify(response));
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  /**
   * Add a service to a shop
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The user
   * @param {*} user The service
   * @returns The created service
   */
  static addShopService = async (symphy_backend, shopId, service, userId) => {
    const response = await symphy_backend["users"].add_shop_service({
      user_id: userId,
      service: service,
    });
    if (!response.Success) {
      console.log("addShopService response was not successful");
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  /**
   * Add a service to a shop
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The userId
   * @param {*} user The service
   * @returns The created service
   */
  static deleteShopService = async (symphy_backend, serviceId, userId) => {
    const response = await symphy_backend["users"].delete_shop_service({
      user_id: userId,
      service_id: serviceId,
    });
    if (!response.Success) {
      console.log("deleteShopService response was not successful");
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  /**
   * Add a service to a shop
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The user
   * @param {*} user The service
   * @returns The created service
   */
  static updateShopService = async (symphy_backend, service, userId) => {
    const response = await symphy_backend["users"].update_shop_service({
      user_id: userId,
      service: service,
    });
    console.log("updateShopService response  " + JSON.stringify(response));
    if (!response.Success) {
      console.log("updateShopService response was not successful");
      return null;
    }
    // // Force repulling of local user
    return response.Success;
  };

  /**
   * Add a valuation service to the user
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The user
   * @returns The created service
   */
  static addValuationService = async (symphy_backend, user) => {
    if (!this.isSeller(user)) {
      return;
    }
    // Add default services
    const serviceValuation = {
      // eslint-disable-next-line no-undef
      id: BigInt(1),
      name: "Valuation",
      description: "Verify instruments",
      price: [],
      last_payment: [],
      service_type: {
        Valuation: {
          product_types: [],
          price_min: parseFloat(0),
          price_max: parseFloat(0),
        },
      },
      metadata: [],
    };
    return await this.addShopService(symphy_backend, user, serviceValuation);
  };

  /**
   * Remove a valuation service to the user
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The user
   * @returns The created service
   */
  static removeValuationService = async (symphy_backend, user) => {
    if (!this.isSeller(user)) {
      return;
    }
    let shop = user.seller_info[0]?.shops[0];
    const valuationService = this.sellerGetShopValuationService(shop);
    if (!valuationService) return null;

    const response = await symphy_backend["users"].delete_shop_service({
      user_id: user.user_id,
      service_id: valuationService.id,
    });
    if (!response.Success) {
      console.log("removeValuationService response was not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Removes seller information in the remote for the current logged used
   *
   * @param {*} symphy_backend The symphy_backend service
   */
  static removeSeller = async (symphy_backend, user, local) => {
    let response = await symphy_backend["users"].remove_seller({ user_id: user.user_id });
    if (BackendService.isResponseError(response)) {
      console.log("removeSeller response was not successful " + JSON.stringify(response));
      return;
    }
    const updated = response.Success;
    if (local) {
      console.log("removeSeller local " + JSON.stringify(updated));
      let localUser = JSON.parse(localStorage.getItem(UserService.USER_KEY));
      localUser.seller_info = [];
      localStorage.setItem(
        UserService.USER_KEY,
        JSON.stringify(localUser, (_, v) => (typeof v === "bigint" ? parseInt(v.toString()) : v))
      );
    }
    return updated;
  };

  /**
   * Retrieves the name for the current user, or a placeholder if not defined yet in User Info
   *
   * @param {*} user
   * @returns The role for the user.
   */
  static getUserName(user, isAuthenticated) {
    const unnamed = "Unnamed";
    if (!user || !isAuthenticated) {
      return unnamed;
    }

    // Seller takes precedence, for display purposes.
    const info = user.info;
    if (info && info[0]?.name && info[0]?.name.length > 0) {
      return info[0]?.name;
    }
    return unnamed;
  }

  /**
   * Combine name and lastname
   * @param {*} user
   * @returns
   */
  static getUserFullName(user) {
    let name = "";
    if (user.info) {
      const info = user.info;
      if (info && info[0]?.name && info[0]?.name.length > 0) {
        name = info[0]?.name;
      }
      if (info && info[0]?.lastname && info[0]?.lastname.length > 0) {
        name += " " + info[0]?.lastname;
      }
    } else {
      if (user.name && user.name.length > 0) {
        name = user.name;
      }
      if (user.lastname && user.lastname.length > 0) {
        name += " " + user.lastname;
      }
    }
    return name;
  }

  /**
   * Retrieves the email for the current user, or null
   *
   * @param {*} user
   * @returns The role for the user.
   */
  static getAuthenticatedUserEmail(user, usePlaceholder = false) {
    const placeHolder = usePlaceholder ? "generic@email.com" : null;
    if (!user) {
      return placeHolder;
    }

    // Seller takes precedence before, for display purposes.
    const info = user.info;
    if (info && info[0]?.email && info[0]?.email.length > 0) {
      return info[0].email;
    }
    return placeHolder;
  }

  /**
   * Retrieves the name for the current authenticated user, or placeholder
   *
   * @param {*} user
   * @returns The role for the user.
   */
  static getAuthenticatedUserName(user) {
    const unnamed = "Unnamed";
    if (!user) {
      return unnamed;
    }

    // Seller takes precedence , for display purposes.
    const info = user.info;
    if (info && info[0]?.name && info[0]?.name.length > 0) {
      return info[0]?.name;
    }
    return unnamed;
  }
  /**
   * Retrieves the name for the current authenticated user, or placeholder
   *
   * @param {*} user
   * @returns The role for the user.
   */
  static getUserDisplayName(user, useName) {
    const unnamed = "Unnamed";
    if (!user) {
      return unnamed;
    }

    // Seller takes precedence before , for display purposes.
    const info = user.info;
    if (info && info.length > 0) {
      if (useName && info[0].name && info[0].name.length > 0) {
        return info[0].name;
      }
      return info[0].name ? info[0].name : info[0].username ? info[0].username : info[0].email ? info[0].email : unnamed;
    } else {
      if (useName && user.name && user.name.length > 0) {
        return user.name;
      }
      return user.name ? user.name : user.username ? user.username : user.email ? user.email : unnamed;
    }
  }

  /**
   * Retrieves the role for the current user
   *
   * @param {*} user
   * @returns The role for the user.
   */
  static getUserRole(user) {
    if (!user) {
      return null;
    }
    // Seller takes precedence before , for display purposes.
    const isAdmin = this.isLocalUserAdmin(user);
    const isSeller = this.isSeller(user);
    if (isSeller || isAdmin) {
      return isSeller && isAdmin ? "auth.role_admin_seller" : isAdmin ? "auth.role_admin" : "auth.role_seller";
    } else {
      return this.isUserIdVerified(user) ? "auth.role_user" : "auth.role_user_unverified";
    }
  }

  /**
   * True if the user is a seller
   *
   * @param {*} user
   * @returns Whether the user is seller.
   */
  static isSeller(user, verified) {
    if (!user) {
      return false;
    }
    if (verified) {
      return user.seller_info && user.seller_info[0] && user.seller_info[0].verified;
    }
    return user.seller_info && user.seller_info[0] !== undefined;
  }

  /**
   * Calculate an index based on the first letter of a word.
   * @param {string} word - The word to use for calculating the index.
   * @returns {number} - The calculated index (0-24).
   */
  static getAvatarIndex(word) {
    if (!word) return 24;
    const firstLetter = word.charAt(0).toLowerCase();
    const alphabetPosition = firstLetter.charCodeAt(0) - "a".charCodeAt(0);
    return Math.max(0, Math.min(24, alphabetPosition));
  }

  /**
   * Return the avatar url of the user if any or a generic one
   *
   * @param {*} user
   * @returns url to the user avatar
   */
  static getUserAvatarUrl(user) {
    if (!user || !user.info || !user.info[0]) {
      return _userAbout.photoURL(this.getAvatarIndex());
    }
    return user?.info[0]?.avatar_url ? user?.info[0]?.avatar_url : _userAbout.photoURL(this.getAvatarIndex(user?.info[0]?.name));
  }

  /**
   * Return the user address line from location object
   *
   * @param {*} user
   * @returns user location or empty string
   */
  static getUserLocation(user) {
    if (user && user.info[0] && user.info[0].location && user.info[0].location.length > 0) {
      return user.info[0].location[0];
    }
    return {};
  }

  /**
   * Return a field of the metadata of a user location
   *
   * @param {*} user or userWithId
   * @returns user location or empty string
   */
  static getUserLocationMetadataValue(user, key) {
    if (user && user.info && user.info[0] && user.info[0].location && user.info[0].location.length > 0 && user.info[0].location[0].metadata.length > 0) {
      return getMapValueForKey(user.info[0].location[0].metadata, key);
    } else if (user && user.location && user.location.length > 0 && user.location[0]) {
      return getMapValueForKey(user.location[0].metadata, key);
    }
    return "";
  }

  /**
   * Return a field of the metadata of a userinfo
   *
   * @param {*} user or userWithId
   */
  static getUserMetadataValue(user, key) {
    if (user && user.info && user.info[0] && user.info[0].metadata) {
      return getMapValueForKey(user.info[0].metadata, key);
    } else if (user && user.metadata) {
      return getMapValueForKey(user.metadata, key);
    }
    return "";
  }

  static createUser = ({ userId, userInfo, sellerInfo, authInfo }) => {
    const user = {
      user_id: userId ? [userId] : [],
      user_info: userInfo ? [userInfo] : [],
      seller_info: sellerInfo ? [sellerInfo] : [],
      auth_info: authInfo ? [authInfo] : [],
    };
    return user;
  };

  /**
   * Return a list of users
   *
   * @returns All users
   */
  static getUsers = async (symphy_backend, username, location, name, email, summaries, userIds) => {
    let searchFilter = {
      username: username ? [username] : [],
      location: location ? [location] : [],
      name: name ? [name] : [],
      email: email ? [email] : [],
      user_ids: userIds ? userIds : [],
    };
    let response = summaries
      ? await symphy_backend["users"].get_user_id_summaries({ filter: searchFilter })
      : await symphy_backend["users"].get_users({ filter: searchFilter });
    if (!response.Success) {
      console.log("getUsers response was not successful");
      return [];
    }
    const allUsers = response.Success;
    return summaries ? allUsers.map((user) => UserSummary.fromJSON(user)) : allUsers;
  };

  static convertUserToIdSummary = (user, permissions) => {
    return UserSummary.fromUser(user, permissions);
  };

  // Function to create a map where key is email and value is user object
  // UserList  can be full user or summary
  static createEmailUserMap = (usersList) => {
    const emailUserMap = new Map();
    usersList.forEach((user) => {
      if (user.info && user.info.length > 0) {
        const email = user.info[0].email; // Assuming email is the first element in the info array
        // Setting email as key and user object as value in the map
        emailUserMap[email] = user;
      } else if (user.email) {
        emailUserMap[user.email] = user;
      }
    });
    return emailUserMap;
  };

  /**
   * Check if an user does not exist
   *
   * @returns All users
   */
  static checkUserNotExists = async ({ symphy_backend, userId, username, email }) => {
    let searchFilter = {
      user_id: userId ? [userId] : [],
      username: username ? [username] : [],
      email: email ? [email] : [],
    };
    let response = await symphy_backend["users"].check_user_free(searchFilter);
    // console.log("checkUserNotExists " + JSON.stringify(response));
    if (BackendService.isResponseError(response)) {
      console.log("checkUserExists response was not successful");
      return false;
    }
    // Returns true
    return true;
  };

  static getInitialEmailValidation = () => {
    return { checked: false, loading: false, isValid: false };
  };

  static isUserDataComplete = (user) => {
    if (user) {
      const userInfo = user.info && user.info.length > 0 ? user.info[0] : user;
      if (!userInfo.name || userInfo.name.length === 0) {
        console.log("User is not name");
        return false;
      }
      if (!userInfo.lastname || userInfo.lastname.length === 0) {
        console.log("User is not lastname");
        return false;
      }
      if (!userInfo.email || userInfo.email.length === 0) {
        console.log("User is not email");
        return false;
      }
      if (!userInfo.id_card || userInfo.id_card.length === 0) {
        console.log("User does not have id card");
        return false;
      }
      if (getMapValueForKey(userInfo.metadata, "phone").length === 0) {
        console.log("User is not phone");
        return false;
      }
      if (UserService.getUserLocationMetadataValue(user, "city").length === 0) {
        console.log("User is not city");
        return false;
      }
      if (UserService.getUserLocationMetadataValue(user, "region").length === 0) {
        console.log("User is not region");
        return false;
      }
      if (UserService.getUserLocationMetadataValue(user, "zip_code").length === 0) {
        console.log("User is not zip_code");
        return false;
      }
    }
    return true;
  };

  /**
   *
   * @param {*} symphy_backend
   * @param {*} email
   * @returns { loading: false, isValid: false, checked: false };
   */
  static handleEmailValidation = async (symphy_backend, email) => {
    try {
      // Validate email format using Yup
      if (validateEmail(email)) {
        const canRegister = await this.checkUserNotExists({ symphy_backend: symphy_backend, email: email });
        return { loading: false, isValid: canRegister, checked: true };
      }
    } catch (error) {
      // console.error(error);
    }
    return { loading: false, isValid: false, checked: false };
  };

  static createShopSearchFilter = ({ verified, visible, location, distance, username, shopName, isValuationService, shopIds, userId, valuationMaxPrice }) => {
    return {
      verified: verified ? [verified] : [],
      visible: visible ? [visible] : [],
      location: location ? location : [],
      distance: distance ? [distance] : [],
      username: username ? [username] : [],
      user_id: userId ? [userId] : [],
      shop_name: shopName ? [shopName] : [],
      service_type: isValuationService
        ? [
            {
              Valuation: {
                product_types: [],
                price_min: createCoinFromAmount("0"),
                price_max: valuationMaxPrice ? valuationMaxPrice : createCoinFromAmount("0"),
              },
            },
          ]
        : [],
      shop_ids: shopIds ? shopIds : [],
      // service_type: [],
    };
  };

  /**
   * Return a list of all shops with a filter
   *
   * @returns All users
   */
  static getShops = async (symphy_backend, searchFilter) => {
    let response = await symphy_backend.users.get_shops({ filter: searchFilter });
    if (!response.Success) {
      console.log("getShops response was not successful");
      return [];
    }
    const shops = response.Success;
    // console.log("getUsers2 " + JSON.stringify(allUsers));
    return shops.map((shop) => Shop.fromJSON(shop));
  };

  /**
   * Return a shop from an id
   *
   * @returns A shop
   */
  static getShopById = async (symphy_backend, shopId) => {
    const searchFilter = UserService.createShopSearchFilter({ shopIds: [shopId] });
    let response = await symphy_backend.users.get_shops({ filter: searchFilter });
    if (!response.Success) {
      console.log("getShopById response was not successful");
      return [];
    }
    const shops = response.Success;
    if (shops.length === 0) {
      return null;
    }
    // console.log("getUsers2 " + JSON.stringify(allUsers));
    return shops[0];
  };

  /**
   * Return a list of all shops for an user
   *
   * @returns All users
   */
  static getUserShops = async (symphy_backend, user) => {
    const searchFilter = this.createShopSearchFilter({ userId: user.user_id });
    // let searchFilter = this.createShopSearchFilter({ username: user.info[0].username});
    let response = await symphy_backend["users"].get_shops({ filter: searchFilter });
    if (!response.Success) {
      console.log("getShops response was not successful");
      return [];
    }
    const shops = response.Success;
    return shops;
  };

  /**
   * The contracts are retrieved from the backend by id
   * Add a .contract field to the items list (e.j. list of products)
   * If to subContracts, then the shops are added to the subcontracts
   *
   * @returns All products
   */
  static addShopsToItems = async (symphy_backend, items, shopIds, options = { toSubcontracts: false, isTransaction: false }) => {
    // Remove duplicates
    const shopIdsUnique = [...new Set(shopIds)];

    let searchFilter = this.createShopSearchFilter({ shopIds: shopIdsUnique });
    const allShops = await this.getShops(symphy_backend, searchFilter);
    if (allShops.length === 0) {
      return items;
    }
    const shopsDictionary = allShops.reduce((dict, shop) => {
      dict[shop.id] = shop;
      return dict;
    }, {});

    const mapItemsWithShop = (item) => {
      if (options.isTransaction) {
        // Mapping for transactions
        const updatedItem = { ...item };
        const shopId = item.getShopId ? item.getShopId() : undefined;
        if (shopId && shopId.length > 0) {
          updatedItem.shop = shopsDictionary[shopId];
        }
        return updatedItem;
      } else {
        // Mapping for contracts/subcontracts
        if (!item.contracts) return item;
        item.contracts = item.contracts.map((contract) => {
          const updatedContract = { ...contract, shop: contract.shop_id && contract.shop_id.length > 0 ? shopsDictionary[contract.shop_id[0]] : undefined };
          if (options.toSubcontracts && updatedContract.subcontracts) {
            updatedContract.subcontracts = updatedContract.subcontracts.map((subcontract) => ({
              ...subcontract,
              shop: subcontract.shop_id && subcontract.shop_id.length > 0 ? shopsDictionary[subcontract.shop_id[0]] : undefined,
            }));
          }
          return updatedContract;
        });
        return item;
      }
    };
    return items.map(mapItemsWithShop);
  };
  /**
   * Return a user by username, null otherwise
   *
   * @returns
   */
  static getUserByUsernameOrEmailOrId = async (symphy_backend, username, email, id) => {
    let allUsers = await this.getUsers(symphy_backend, username, null, null, email, true, id ? [id] : null);
    if (!allUsers) {
      return null;
    }
    return allUsers[0];
  };

  /**
   * Return an user by id
   *
   * @returns User by id or null
   */
  static getUserById = async (symphy_backend, id) => {
    let response = await symphy_backend["users"].get_user_by_id({ user_id: Principal.fromText(id.toString()) });
    if (!response.Success) {
      console.log("getUserById response was not successful");
      return [];
    }
    return response.Success;
  };

  /**
   * Return an user by id
   *
   * @returns User by id or null
   */
  static getUserWithIdCard = async (symphy_backend, id) => {
    let response = await symphy_backend["users"].get_user_with_id_card({ user_id: Principal.fromText(id.toString()) });
    if (!response.Success) {
      console.log("getUserWithIdCard response was not successful");
      return [];
    }
    return UserSummary.fromJSON(response.Success);
  };

  /**
   * Updates the id card of the user and sets to verified
   *
   * @param {*} symphy_backend The symphy_backend service
   * @param {*} user The userInfo to be updated
   * @returns The updated user
   */
  static setUserIdCardVerified = async (symphy_backend, user, idCard) => {
    let args = {
      user_id: user.user_id,
      id_verified: true,
      id_card: [idCard],
    };
    const response = await symphy_backend["users"].update_user_id_card(args);
    if (BackendService.isResponseError(response)) {
      console.log("setUserIdCardVerified response was not successful " + JSON.stringify(response));
      return;
    }
    return response;
  };

  /**
   * Deletes a user by id
   *
   */
  static deleteUser = async (symphy_backend, id) => {
    console.log("deleteUser " + id);
    let response = await symphy_backend["users"].delete_user({ user_id: id });
    if (BackendService.isResponseError(response)) {
      console.log("deleteUser response was not successful " + JSON.stringify(response));
      return [];
    }
    return response.Success;
  };

  /**
   * Return roles of an user by id
   *
   * @returns Userroles or null
   */
  static getUserRoles = async (symphy_backend, id, currentUser) => {
    let response;
    if (currentUser) {
      response = await symphy_backend["users"].get_current_user_permissions({ user_id: id });
    } else {
      response = await symphy_backend["users"].get_user_permissions({ user_id: id });
    }
    if (!response.Success) {
      console.log("getUserRoles response was not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * The transactions are retrieved from the backend by id
   * Add a .transaction field to the items list (e.j. list of subcontracts)
   *
   * @returns All products
   */
  static addUserSummaryToItems = async (symphy_backend, items, ids, isOwner) => {
    if (!items || items.length === 0) {
      return items;
    }
    const allUsers = await this.getUsers(symphy_backend, null, null, null, null, true, ids);
    const userIdUsers = new Map(allUsers.map((user) => [user.user_id.toString(), user]));
    // Helper function to map each item or subcontract to its corresponding user
    const mapEntityWithUser = (entity, userId) => {
      const user = userIdUsers.get(userId.toString());
      if (user) entity.user = UserSummary.fromJSON(user);
      return entity;
      // return { ...entity, user: UserSummary.fromJSON(user) };
    };

    // Helper function to map valid_for user IDs to user summaries
    const mapValidForUsers = (validForIds) => {
      return validForIds
        .map((userId) => {
          const user = userIdUsers.get(userId.toString());
          return user ? UserSummary.fromJSON(user) : null;
        })
        .filter((user) => user !== null); // Filter out null values in case some user IDs are not found
    };

    // Function to process each item, including its subcontracts if they exist
    const mapItemWithUser = (item) => {
      // Map valid_for field if it exists
      const validFor = item.valid_for;
      if (validFor) {
        if (validFor.length > 0 && validFor[0].length > 0) {
          item.users = mapValidForUsers(validFor[0]);
          // return { ...item, users: mapValidForUsers(validFor[0]) };
        }
        return item;
      }

      // Map the primary item
      const mappedItem = mapEntityWithUser(item, isOwner ? item.owner_id : item.user_id);

      // Check and map subcontracts if they exist
      if (mappedItem.subcontracts && mappedItem.subcontracts.length > 0) {
        mappedItem.subcontracts = mappedItem.subcontracts.map((subcontract) => mapEntityWithUser(subcontract, subcontract.user_id));
      }
      // Also map subcontracts in contracts
      if (mappedItem.contracts && mappedItem.contracts.length > 0) {
        mappedItem.contracts = mappedItem.contracts.map((contract) => {
          if (contract.subcontracts && contract.subcontracts.length > 0) {
            contract.subcontracts = contract.subcontracts.map((subcontract) => mapEntityWithUser(subcontract, subcontract.user_id));
          }
          return contract;
        });
      }
      return mappedItem;
    };

    return items.map(mapItemWithUser);
  };
}