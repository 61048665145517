import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// assets
// import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from 'src/assets/icons';
// components
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { SHOW_PROD_UI } from 'src/config-global';
import { useLocales } from 'src/locales';
import { PATH_PAGE } from 'src/routes/paths';
import { ICONS } from 'src/utils/icons';
import { useFacebookPixel } from 'src/components/facebook-pixel/facebook-pixel-provider';

// ----------------------------------------------------------------------

export default function PricingCard({ card, sx, withEvent, ...other }) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const { trackCTA } = useFacebookPixel();

  const { subscription, title, price, caption, lists, labelAction, data } = card;

  const stateData = data && data.filter? {filter : data.filter} : null;

  const basic = subscription === 'basic';

  const starter = subscription === 'starter';

  const premium = subscription === 'premium';

  const renderIcon = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {/* <Box sx={{ width: 48, height: 48 }}>
        {basic && <PlanFreeIcon />}
        {starter && <PlanStarterIcon />}
        {premium && <PlanPremiumIcon />}
      </Box> */}

      {starter && <Label color="info">POPULAR</Label>}
    </Stack>
  );

  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{caption}</Typography>
    </Stack>
  );

  const renderPrice = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{"Desde:"}</Typography>
      <Stack direction="row">
        <Typography variant="h4">€</Typography>

        <Typography variant="h2">{price}</Typography>

        <Typography
          component="span"
          sx={{
            alignSelf: "center",
            color: "text.disabled",
            ml: 1,
            typography: "body2",
          }}>
          / {t("month")}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderList = (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="span" sx={{ typography: "overline" }}>
          {t("landing.features")}
        </Box>
        {/* <Link
          variant="body2"
          color="inherit"
          underline="always"
          sx={{
            textDecoration: "underline !important",
          }}>
          {t("landing.all")}
        </Link> */}
      </Stack>

      {lists.map((item) => (
        <Stack
          key={item.title}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            typography: "body2",
            ...(!item.active && {
              color: "text.disabled",
            }),
          }}>
          <Iconify  icon={item.active ? ICONS.checked : ICONS.minus} width={16} sx={{ mr: 1 }} />
          {t(item.title)}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack
      spacing={3.5}
      sx={{
        backgroundColor: "background.paper",
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
          md: "none",
        }),
        // ...(starter && {
        //   borderTopRightRadius: { md: 0 },
        //   borderBottomRightRadius: { md: 0 },
        // }),
        ...((starter || premium) && {
          boxShadow: (theme) => ({
            xs: theme.customShadows.card,
            md: `-40px 40px 80px 0px ${alpha(theme.palette.mode === "light" ? theme.palette.grey[500] : theme.palette.common.black, 0.15)}`,
          }),
        }),
        ...sx,
      }}
      {...other}>
      {renderIcon}

      {renderSubscription}

      {renderPrice}

      <Divider sx={{ borderStyle: "dashed" }} />

      {renderList}

      <Button
        fullWidth
        size="large"
        variant={basic ? "outlined" : "contained"}
        // disabled={basic}
        color={premium ? "primary" : "inherit"}
        onClick={() => {
          if (withEvent && trackCTA) {
            trackCTA("PricingCard", subscription);
          }
          navigate(SHOW_PROD_UI ? PATH_PAGE.contact.path : PATH_PAGE.marketplace.root.path, { state: stateData });
        }}>
        {labelAction}
      </Button>
    </Stack>
  );
}
