// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { RouterLink } from 'src/routes/components';
// _mock
// import { _socials } from 'src/_mock';
// components
import footerRoutes from "footer.routes";
import MKBox from 'landing_page/components/MKBox';
import MKTypography from 'landing_page/components/MKTypography';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function Footer({simple = false}) {
  // const pathname = usePathname();

  // const isHome = PATHNAME === '/';

  const { company, socials, links, copyright, copyright_dark, menus } = footerRoutes;
  
  const { t } = useLocales();

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: { xs: 17, md: 5 },
        textAlign: "center",
        position: "relative",
        // bgcolor: 'background.default',
      }}>
      <Container>
        {/* <Logo sx={{ mb: 2, mx: "auto" }} /> */}
        <MKBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          // justifyContent="space-between"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          sx={{ listStyle: "none" }}>
          {links.map((link, key) => (
            <MKBox
              key={link.name}
              component="li"
              // pl={key === 0 ? 0 : 2} pr={key === links.length - 1 ? 0 : 2}
              pl={1} pr={1}
              lineHeight={1}>
              <Link to={link.href} underline="hover" component={RouterLink}>
                <MKTypography variant="button" fontWeight="regular" color={"text"}>
                  {t(link.name)}
                </MKTypography>
              </Link>
            </MKBox>
          ))}
        </MKBox>
        {/* <Grid item xs={12} sx={{ textAlign: "center" }}> */}
          {copyright}
        {/* </Grid> */}
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      mt={8}
      sx={
        {
          // position: "relative",
          // bgcolor: "background.default",
        }
      }>
      {/* <Divider /> */}

      <Container
      // spacing={3}
      // sx={{
      //   pt: 10,
      //   pb: 5,
      //   // ml: "auto",
      //   // mb: 3,
      //   // textAlign: { xs: "center", md: "unset" },
      // }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems={{
            xs: "center",
            md: "flex-start",
          }}
          // sx={{ ml: "auto", mb: 3 }}
          // justifyContent={{
          //   xs: "center",
          //   md: "center",
          // }}
        >
          <Grid xs={12} md={3} sx={{ mb: 3 }}>
            <Stack
              justifyContent="center"
              alignItems={{
                xs: "center",
                md: "flex-start",
              }}>
              <Box>{company.image({ sx: { mb: 0 } })}</Box>
              <Stack
                direction="row"
                // display="flex"
                alignItems="center"
                sx={{
                  mt: 2,
                  // mb: { xs: 5, md: 0 },
                }}>
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="dark"
                    opacity={0.8}
                    mr={key === socials.length - 1 ? 0 : 2.5}>
                    {icon}
                  </MKTypography>
                ))}
              </Stack>
              <Box>
                <Typography component="div" variant="overline" mt={2} mb={1} textTransform={"capitalize"}>
                  {t("secure_payment")}:
                </Typography>
                <Iconify icon="logos:mastercard" width={28} mr={0.5} />
                <Iconify icon="logos:visa" width={28} ml={0.5} />
              </Box>
            </Stack>
          </Grid>

          {menus.map(({ name: title, items }) => (
            <Grid key={title} xs={6} md={2} sx={{ mb: 3 }}>
              <Stack
                key={title}
                spacing={2}
                alignItems={{ xs: "center", md: "flex-start" }}
                justifyContent={{ xs: "flex-start", md: "center" }}
                sx={{ width: 1 }}>
                <Typography component="div" variant="overline">
                  {t(title)}
                </Typography>
                {items.map(({ name, href }) => (
                  <Link
                    key={name}
                    component={RouterLink}
                    href={href}
                    color="inherit"
                    variant="body2"
                    textTransform={"capitalize"}
                    textAlign={{ xs: "center", md: "start" }}>
                    {t(name)}
                  </Link>
                ))}
              </Stack>
            </Grid>
          ))}
          <Grid xs={12} sx={{ textAlign: "center" }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return simple ? simpleFooter : mainFooter;
}
