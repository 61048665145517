// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

import MKTypography from "components/MKTypography";
import { PATH_PAGE } from 'paths';
import Iconify from '../ui/components/iconify';
import { Typography } from "@mui/material";
import Logo from "src/components/logo";


const date = new Date().getFullYear();

const footerConfig = {
  company: {
    name: "SYMPHY",
    href: "/",
    image: ({ sx }) => <Logo sx={{ mb: 3, ...sx }} />,
  },
  socials: [
    // {
    //   name: "Facebook",
    //   icon: <FacebookIcon />,
    //   link: "#",
    // },
    {
      name: "TikTok",
      icon: <Iconify icon="akar-icons:tiktok-fill" width={24} height={24} />,
      link: "https://www.tiktok.com/@symphyofficial?_t=8Vo1DPi6pvx&_r=1",
    },
    {
      name: "Instagram",
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/symphy_official",
    },
    {
      name: "Twitter",
      icon: <TwitterIcon />,
      link: "https://mobile.twitter.com/Symphy_official",
    },
    {
      name: "Discord",
      icon: <Iconify icon="akar-icons:discord-fill" width={24} height={24} />,
      link: "https://discord.gg/GqRzDbdZ",
    },
  ],
  // For the Centered Fototer
  links: [
    { href: PATH_PAGE.home, name: "SYMPHY" },
    // { href: PATH_PAGE.marketplace.root.path, name: "Marketplace" },
    { href: "/" + PATH_PAGE.about.name, name: "navigation.about_us" },
    { href: "/" + PATH_PAGE.contact.name, name: "navigation.contact" },
    { href: "/" + PATH_PAGE.privacy.name, name: "privacy_policy" },
    { href: "/" + PATH_PAGE.cookies.name, name: "cookies.title_policy" },
    // { href: "/" + PATH_PAGE.payments_policy.name, name: "payments_policy" },
    // { href: "/" + PATH_PAGE.shipping_policy.name, name: "shipping_policy" },
  ],
  menus: [
    {
      name: "about_us.team",
      items: [
        { name: "navigation.about_us", href: "/" + PATH_PAGE.about.name },
        // { name: "our vision", href: "/" },
        // { name: "blog", href: "#" },
      ],
    },
    {
      name: "navigation.help_support",
      items: [
        { name: "navigation.contact", href: "/" + PATH_PAGE.contact.name },
        { name: "faqs", href: "/" + PATH_PAGE.faqs.name },
      ],
    },
    {
      name: "navigation.legal",
      items: [
        // { name: "terms_and_conditions", href: "#" },
        { name: "payments_policy", href: "/" + PATH_PAGE.payments_policy.name },
        { name: "shipping_policy", href: "/" + PATH_PAGE.shipping_policy.name },
        { name: "privacy_policy", href: "/" + PATH_PAGE.privacy.name },
        { name: "cookies.title_policy", href: "/" + PATH_PAGE.cookies.name },
      ],
    },
  ],
  copyright: (
    <Typography variant="body2" color={"text"} sx={{ mt: 2, pb: 2 }}>
      &copy; {date}, made with ♥ by
      <Typography component="a" href={"/"} rel="noreferrer" variant="body2" fontWeight="medium">
        &nbsp;SYMPHY&nbsp;
      </Typography>
      for a better world.
    </Typography>
  ),
  copyright_dark: (
    <MKTypography variant="body2" color={"text"}>
      &copy; {date}, made with ♥ by
      <MKTypography component="a" href={"/"} rel="noreferrer" variant="body2" fontWeight="medium" color={"text"}>
        &nbsp;SYMPHY&nbsp;
      </MKTypography>
      for a better world.
    </MKTypography>
  ),
};

export default footerConfig;
