import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';
import { useLocation } from 'react-router';
import { AuthConsumer } from '../context/web3auth';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AuthGuard({ redirect = true, children }) {
  const router = useRouter();

  const { isAuthenticated, loading } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const location = useLocation();
  // if (locationSearch.startsWith("?")) {
  //   const stringWithoutFirstChar = locationSearch.substring(1);
  //   console.log(stringWithoutFirstChar); // Output: "example"
  // } else {
    // console.log("Auth guard " + locationSearch); // Output: "?example" (unchanged)
  // }
  const check = useCallback(() => {
    // console.log("Authguard authen " + isAuthenticated + " loading " + loading);
    // if (!isAuthenticated && !loading) {
    if (!isAuthenticated) {
      // console.log("Auth guard search " + searchParams); // Output: "?example" (unchanged)

      const loginPath = PATH_AUTH.login.path;

      // console.log("AuthGuard: href = " + href + " pathname = " + window.location.pathname + " PATH Welcome = " + PATH_PAGE.postregistration.path);
      // let href = `${loginPath}?${searchParams}`;
      const searchParams = new URLSearchParams({ returnTo: window.location.pathname, search: location.search }).toString();
      // let href = `${loginPath}`;
      let href = `${loginPath}?${searchParams}`;

      // if (redirect) {
      //   router.replace(loginPath);
      // } else {
      //   if (window.location.pathname.includes(PATH_PAGE.postregistration.path)) {
      //     router.replace(PATH_PAGE.postregistration.path);
      //   } if (window.location.pathname.includes(PATH_PAGE.marketplace.path)) {
      //     router.replace(PATH_PAGE.marketplace.path);
      //   } else {
      //     router.replace(loginPath);
      //   }
      // }
      // console.log("Authguard has searchParams " + searchParams);
      // const searchParamsOriginal = new URLSearchParams(location.search);
      // // If searchParamsOriginal has content, append it to href
      if (location.search) {
        // const searchParams = new URLSearchParams({ returnTo: window.location.pathname, search: location.search }).toString();
        // console.log("Authguard has params " + searchParams.toString());
        // router.navigate(href, {search : searchParams}, true);
        // href += `&${searchParamsOriginal.toString()}`;
      } else {
        // console.log("Authguard no params" + href);
        // router.replace(href);
      }
      // href += `?${searchParams}`;
      // console.log("Authguard href  " + href);
      router.replace(href);
    } else if(!loading) {
      // console.log("Authguard checked");
      setChecked(true);
    }
  }, [isAuthenticated, loading, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <AuthConsumer>{children}</AuthConsumer>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
