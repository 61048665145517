import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
// components
import BaseLayoutWrapper from 'landing_page/layouts/sections/components/BaseLayoutWrapper';
import { AuthGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import CompactLayout from 'src/layouts/compact/layout';
import SimpleLayout from 'src/layouts/simple/layout';
import { LandingWrapper } from '..';
import { PATH_PAGE } from '../paths';

// ----------------------------------------------------------------------

// export const HomePage = lazy(() => import('../../../landing_page/pages/Landing/Landing'));
// export const HomePage = import("../../../landing_page/pages/Landing/Landing");

const AboutUs = lazy(() => import('../../../landing_page/pages/Landing/AboutUs'));
const ContactUs = lazy(() => import('../../../landing_page/pages/Landing/ContactUs'));
const PoliciesPage = lazy(() => import('../../../landing_page/pages/Landing/Policies'));
const Page404 = lazy(() => import('../../pages/Page404'));
const FaqsPage = lazy(() => import('../../pages/faqs'));
const SellersIntro = lazy(() => import('../../pages/dashboard/seller/sellers-intro'));
const SellerFinder = lazy(() => import("../../pages/dashboard/seller/seller-finder"));

// LANDINGS
const LandingWantInstrumentPage = lazy(() => import("../../../landing_page/pages/Landing/LandingInstrument"));


// MAINTENANCE
const MaintenancePage = lazy(() => import('../../pages/MaintenancePage'));

// POST REGISTRATION
const PostRegistrationPage = lazy(() => import('../../pages/PostRegistrationPage'));

// MARKETPLACE
const ShopPage = lazy(() => import('../../pages/product/list'));
const ProductCheckoutPage = lazy(() => import('../../pages/product/checkout'));
export const ProductDetailsPage = lazy(() => import('../../pages/product/details'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    // TODO use MainLayout
    // element: (
    //   <MainLayout>
    //     <Suspense fallback={<SplashScreen />}>
    //       <Outlet />
    //     </Suspense>
    //   </MainLayout>
    // ),
    element: (
      <LandingWrapper>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </LandingWrapper>
    ),
    children: [
      { path: PATH_PAGE.contact.name, element: <ContactUs /> },
      { path: PATH_PAGE.about.name, element: <AboutUs /> },
      { path: PATH_PAGE.privacy.name, element: <PoliciesPage type={"privacy"} /> },
      { path: PATH_PAGE.cookies.name, element: <PoliciesPage type={"cookies"} /> },
      { path: PATH_PAGE.payments_policy.name, element: <PoliciesPage type={"payments"} /> },
      { path: PATH_PAGE.shipping_policy.name, element: <PoliciesPage type={"shipping"} /> },

      { path: PATH_PAGE.quiero_un_instrumento.name, element: <LandingWantInstrumentPage /> },
      { path: PATH_PAGE.alquilar_un_instrumento.name, element: <LandingWantInstrumentPage /> },
    ],
  },
  {
    element: (
      <BaseLayoutWrapper>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </BaseLayoutWrapper>
    ),
    children: [
      { path: PATH_PAGE.sellers.name, element: <SellersIntro /> },
      { path: PATH_PAGE.product.checkout_ok.name, element: <ProductCheckoutPage /> },
      { path: PATH_PAGE.product.checkout_nok.name, element: <ProductCheckoutPage /> },
      { path: PATH_PAGE.seller_finder.name, element: <SellerFinder /> },
    ],
  },
  {
    element: (
      <BaseLayoutWrapper transparentNavbar={true}>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </BaseLayoutWrapper>
    ),
    children: [{ path: PATH_PAGE.faqs.name, element: <FaqsPage /> }],
  },
  {
    element: (
      // <AuthGuard redirect={false}>
      <BaseLayoutWrapper transparentNavbar={true}>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </BaseLayoutWrapper>
      // </AuthGuard>
    ),
    children: [
      { path: PATH_PAGE.product.product_name + "/:id", element: <ProductDetailsPage edit={false} /> },
      { path: PATH_PAGE.product.checkout.name, element: <ProductCheckoutPage /> },
      { path: PATH_PAGE.marketplace.root.name, element: <ShopPage /> },
      { path: `${PATH_PAGE.marketplace.root.name}/:category`, element: <ShopPage /> },
      { path: `${PATH_PAGE.marketplace.root.name}/:category/:subcategory`, element: <ShopPage /> },
    ],
  },
  {
    element: (
      <AuthGuard redirect={false}>
        <SimpleLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      </AuthGuard>
    ),
    children: [{ path: PATH_PAGE.postregistration.name, element: <PostRegistrationPage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: PATH_PAGE.maintenance.name, element: <MaintenancePage /> },
      { path: PATH_PAGE.page404.name, element: <Page404 /> },

      { path: PATH_PAGE.link.path + "/1", element: <Navigate to={PATH_PAGE.home} replace /> },
      { path: PATH_PAGE.link.path + "/2", element: <Navigate to={PATH_PAGE.contact.path} state={{ subject: "other" }} replace /> },
      { path: PATH_PAGE.link.path + "/3", element: <Navigate to={PATH_PAGE.contact.path} state={{ subject: "drop" }} replace /> },
      { path: PATH_PAGE.link.path + "/4", element: <Navigate to={PATH_PAGE.contact.path} state={{ subject: "request" }} replace /> },
      { path: PATH_PAGE.link.path + "/5", element: <Navigate to={PATH_PAGE.contact.path} state={{ subject: "partner" }} replace /> },
      { path: PATH_PAGE.link.path + "/6", element: <Navigate to={PATH_PAGE.about.path} replace /> },
      { path: PATH_PAGE.link.path + "/7", element: <Navigate to={PATH_PAGE.faqs.path} replace /> },
    ],
  },
];
