import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import UserService from "../../../services/UserService";
import { Principal } from "@dfinity/principal";
import Shop from "model/users/Shop";
import Service from "model/marketplace/Service";
import UserSummary from "model/users/UserSummary";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  shops: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      console.log("startLoading");
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      // console.log("Get users suscc " + JSON.stringify(action.payload));
      // Suport user and ussersummary
      if (action.payload.length > 0) {
        state.users = action.payload.slice().sort((a, b) => {
          return a.username ? a.username.localeCompare(b.username) : a.info ? a.info[0].username.localeCompare(b.info[0].username) : 1;
        });
      } else {
        state.users = [];
      }

    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    deleteUser(state, action) {
      state.isLoading = false;
      const updateUsers = state.users.filter((user) => user.user_id.toString() !== action.payload);

      state.users = updateUsers;
    },

    addShop(state, action) {
      state.isLoading = false;
      const length = state.shops.push(Shop.fromJSON(action.payload));
    },

    deleteShop(state, action) {
      state.isLoading = false;
      const updatedShops = state.shops.filter((shop) => shop.id !== action.payload);
      state.shops = updatedShops;
    },

    updateShop(state, action) {
      state.isLoading = false;
      const index = state.shops.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.shops[index] = Shop.fromJSON(action.payload);
      }
    },

    // GET USER SHOPA
    getUserShopsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length === 0) {
        state.shops = [];
        return;
      }
      state.shops = action.payload.map((shop) => Shop.fromJSON(shop));
      // console.log("getUserShopsSuccess " + JSON.stringify(action.payload));
    },

    // Shops
    addShopService(state, action) {
      const { shopId, service } = action.payload;
      // const shop = state.shops.find((s) => s.id === shopId);
      // if (shop) {
      //   shop.services.push(service);
      // }
      const index = state.shops.findIndex((o) => o.id === shopId);
      if (index > -1) {
        let services = state.shops[index].services;
        if (!services)
          state.shops[index].services = [];
        state.shops[index].services = [...services, service];
      }
    },
    updateShopService(state, action) {
      const { shopId, service } = action.payload;
      const shop = state.shops.find((s) => s.id === shopId);
      if (shop) {
        const index = shop.services.findIndex((s) => s.id === service.id);
        if (index > -1) {
          shop.services[index] = service;
        }
      }
    },
    deleteShopService(state, action) {
      const { shopId, serviceId } = action.payload;
      const shop = state.shops.find((s) => s.id === shopId);
      if (shop) {
        shop.services = shop.services.filter((s) => s.id !== serviceId);
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers(symphy_backend, username, location, name, email, summaries) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await UserService.getUsers(symphy_backend, username, location, name, email, summaries);
      if (summaries && response && response.length > 0) {
        response = response.map((user) => UserSummary.fromJSON(user));
      }
      dispatch(slice.actions.getUsersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(symphy_backend, id, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await UserService.getUserById(symphy_backend, Principal.fromText(id));
      dispatch(slice.actions.getUserSuccess(response));
      if (listener) {
        listener(response);
      }
    } catch (error) {
      // console.log("getUserid error " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await UserService.deleteUser(symphy_backend, Principal.fromText(id.toString()));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteUser(id.toString()));
    } catch (error) {
      console.log("deleteUser error " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserShops(symphy_backend, user) {
  return async () => {
    console.log("getUserShops");
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await UserService.getUserShops(
        symphy_backend,
        user
      );
      dispatch(slice.actions.getUserShopsSuccess(response));
    } catch (error) {
      console.log("getUserShops error " + JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addShop(symphy_backend, shop, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
      // eslint-disable-next-line no-undef
      const response = await UserService.addShop(symphy_backend, shop);
      dispatch(slice.actions.addShop(response));
      if (listener) {
        listener(response);
      }
    // } catch (error) {
    //   console.log("Error adding " + JSON.stringify(error) + " " + JSON.stringify(shop));
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function deleteShop(symphy_backend, id, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await UserService.deleteShop(symphy_backend, BigInt(id));
      if (listener) {
        listener();
      }
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteShop(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateShop(symphy_backend, shop, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    // console.log("Updating product a" + product.id);
    // eslint-disable-next-line no-undef
    const response = await UserService.updateShop(symphy_backend, shop);
    // eslint-disable-next-line no-undef
    dispatch(slice.actions.updateShop(response));
    // if (isCurrent) {
    //   dispatch(slice.actions.getProductSuccess(response));
    // }
    if (listener) {
      listener();
    }
    // } catch (error) {
    //   console.log("Error updating " + error);
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}


// Add these functions for service operations

export function addShopService(symphy_backend, shopId, service, userId, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await UserService.addShopService(symphy_backend, shopId, service, userId);
    if (response) dispatch(slice.actions.addShopService({ shopId, service: Service.fromJSON(response) }));
    if (listener) {
      listener(response);
    }
  };
}

export function updateShopService(symphy_backend, shopId, service, userId, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await UserService.updateShopService(symphy_backend, service, userId);
    if(response)
      dispatch(slice.actions.updateShopService({ shopId, service: Service.fromJSON(response) }));
    if (listener) {
      listener(response);
    }
  };
}

export function deleteShopService(symphy_backend, shopId, serviceId, userId, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await UserService.deleteShopService(symphy_backend, serviceId, userId);
      dispatch(slice.actions.deleteShopService({ shopId, serviceId }));
      if (listener) {
        listener();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}