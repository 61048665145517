import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { useAuthContext } from 'src/auth/hooks';
import UserService from 'services/UserService';
import { bgcolor, borderRadius } from '@mui/system';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  // const { user } = useAuthContext()

  // const forceHorizontal = !UserService.isLocalUserAdmin(user);
  const forceHorizontal = false;

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = forceHorizontal || settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const useGradientBg = lgUp;
  const cardStyle = useGradientBg ? { bgcolor: "rgba(255,255,255,0.85)", borderRadius: "16px 16px 0 0", mt: "8px", mr: "8px", boxShadow: "0 1px 3px 0 #0000001a" } : null;

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} isTransparent={useGradientBg} />

        <Box
          sx={{
            minHeight: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            background: (theme) => (useGradientBg ? theme.palette.background.gradient : null),
          }}>
          {lgUp ? renderNavMini : renderNavVertical}

          <Main
            sx={{ ...cardStyle }} // For gradient
          >
            {children}
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} isTransparent={useGradientBg} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          background: (theme) => (useGradientBg ? theme.palette.background.gradient : null),
        }}>
        {renderNavVertical}

        <Main
          sx={{ ...cardStyle }} // For gradient
        >
          {children}
        </Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
