
// ----------------------------------------------------------------------

export function useScrollToRef(componentRef, offset = 1) {
  const scrollToRef = () => {
    if (componentRef.current) {
      const yOffset = componentRef.current.getBoundingClientRect().top * offset + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return scrollToRef;
}
