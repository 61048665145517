import colors from "assets/theme/base/colors";

import pxToRem from "assets/theme/functions/pxToRem";

const { dark } = colors;

const primaryFont = 'Public Sans, sans-serif'; // Google Font

const baseProperties = {
  fontFamily: primaryFont,
  // fontFamily: '"Inder", "Segoe UI","Roboto","Helvetica","Arial"',
  fontFamily2: '"Segoe UI","Roboto","Helvetica","Arial"',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(22),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30),
  fontSize4XL: pxToRem(48),
  fontSize6XL: pxToRem(200),
};

const baseHeadingProperties = {
  // color: dark.main,
  fontWeight: baseProperties.fontWeightBold,
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  // color: dark.main,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2,
};

export function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    "@media (min-width:0px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(48),
    // lineHeight: 1.25,
    // ...baseHeadingProperties,
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },

  h2: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(36),
    // lineHeight: 1.3,
    // ...baseHeadingProperties,
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ xs: 28, sm: 32, md: 34, lg: 38 }),
  },

  h3: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(30),
    // ...baseHeadingProperties,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },

  h4: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(24),
    // lineHeight: 1.375,
    // ...baseHeadingProperties,
    // fontWeight: 700,
    // lineHeight: 1.5,
    // fontSize: pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },

  h5: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(20),
    // lineHeight: 1.375,
    // ...baseHeadingProperties,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },

  h6: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: pxToRem(16),
    // lineHeight: 1.625,
    // ...baseHeadingProperties,
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    // ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily2,
    // fontSize: baseProperties.fontSizeXL,
    // fontWeight: baseProperties.fontWeightMedium,
    // lineHeight: 1.625,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 18, sm: 20, md: 22, lg: 22 }),
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily2,
    // fontSize: baseProperties.fontSizeMD,
    // fontWeight: baseProperties.fontWeightMedium,
    // lineHeight: 1.6,
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },

  body1: {
    fontFamily: baseProperties.fontFamily2,
    // fontSize: baseProperties.fontSizeLG,
    // fontWeight: baseProperties.fontWeightRegular,
    // lineHeight: 1.625,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },

  body2: {
    fontFamily: baseProperties.fontFamily2,
    // fontSize: baseProperties.fontSizeMD,
    // fontWeight: baseProperties.fontWeightLight,
    // lineHeight: 1.6,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },

  caption: {
    // fontFamily: baseProperties.fontFamily,
    // fontSize: baseProperties.fontSizeXS,
    // fontWeight: baseProperties.fontWeightLight,
    // lineHeight: 1.25,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
    // fontWeight: baseProperties.fontWeightBold,
    // lineHeight: 1.5,
    // fontSize: pxToRem(12),
    // letterSpacing: 1.1,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },

  // button: {
  //   fontFamily: baseProperties.fontFamily,
  //   fontSize: baseProperties.fontSizeSM,
  //   fontWeight: baseProperties.fontWeightLight,
  //   lineHeight: 1.5,
  //   textTransform: "uppercase",
  //   letterSpacing: pxToRem(2),
  // },
  // DASHBOARD
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "unset",
  },

  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties,
  },

  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties,
  },

  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties,
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    "2xl": baseProperties.fontSize2XL,
    "3xl": baseProperties.fontSize3XL,
    "6xl": baseProperties.fontSize6XL,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },

  header: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize4XL,
    lineHeight: 1.25,
    ...baseHeadingProperties,
    ...responsiveFontSizes({ xs: 30, sm: 36, md: 38, lg: 42 }),
  },
};

export default typography;
