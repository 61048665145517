export class DiscountCode {
  constructor({ value, count = 0, max_count = null, ...otherProps }) {
    this.value = value;
    this.count = count;
    this.max_count = max_count;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(codeJSON) {
    if (!codeJSON) return null;
    const { value, count = 0, max_count = null, ...otherProps } = codeJSON;
    return new DiscountCode({
      value,
      count,
      max_count,
      ...otherProps,
    });
  }

  toJSON() {
    return JSON.stringify(this.toObject());
  }

  toObject() {
    return {
      value: this.value,
      count: this.count,
      max_count: this.max_count,
      ...this.getOtherProps(), // Include additional properties in the object
    };
  }

  getOtherProps() {
    const { value, count, max_count, ...otherProps } = this;
    return otherProps;
  }

  getMaxCount() {
    return this.max_count !== null && this.max_count.length > 0 ? this.max_count[0] : 0;
  }

  hasMaxCount() {
    return this.getMaxCount() > 0;
  }

  clone() {
    return new DiscountCode({ value: this.value, count: this.count, max_count: this.max_count });
  }

  hasUsageLeft() {
    if (this.max_count !== null) {
      return this.count < this.max_count;
    }
    return true;
  }

  useCode() {
    if (this.max_count !== null && this.count >= this.max_count) {
      throw new Error("Discount code usage limit exceeded.");
    }
    this.count++;
  }

  toString() {
    return `${this.value} (Count: ${this.count}, Max Count: ${this.max_count[0] || "∞"})`;
  }
}
