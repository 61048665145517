import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
//   500_8: alpha('#919EAB', 0.08),
//   500_12: alpha('#919EAB', 0.12),
//   500_16: alpha('#919EAB', 0.16),
//   500_24: alpha('#919EAB', 0.24),
//   500_32: alpha('#919EAB', 0.32),
//   500_48: alpha('#919EAB', 0.48),
//   500_56: alpha('#919EAB', 0.56),
//   500_80: alpha('#919EAB', 0.8),
// };
const GREY = {
  100: "#f8f9fa",
  200: "#f0f2f5",
  300: "#dee2e6",
  400: "#ced4da",
  500: "#adb5bd",
  600: "#6c757d",
  700: "#495057",
  800: "#343a40",
  900: "#212529",
  500_8: alpha('#adb5bd', 0.08),
  500_12: alpha('#adb5bd', 0.12),
  500_16: alpha('#adb5bd', 0.16),
  500_24: alpha('#adb5bd', 0.24),
  500_32: alpha('#adb5bd', 0.32),
  500_48: alpha('#adb5bd', 0.48),
  500_56: alpha('#adb5bd', 0.56),
  500_80: alpha('#adb5bd', 0.8),
};

const PRIMARY = {
  lighter: '#d275ff',
  light: '#9c43fd',
  main: '#6500c9',
  dark: '#260097',
  darker: '#000068',
  contrastText: '#fff',
  state: '#260097',
};

const SECONDARY = {
  lighter: '#ff8fe1',
  light: '#ff5aaf',
  main: '#F10080',
  dark: '#b80054',
  darker: '#82002c',
  contrastText: '#fff',
  state: '#b80054',
};


const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
  state: '#0C53B7',
};

// const SUCCESS = {
//   lighter: '#E9FCD4',
//   light: '#AAF27F',
//   main: '#54D62C',
//   dark: '#229A16',
//   darker: '#08660D',
//   contrastText: GREY[800],
//   state: '#229A16',
// };

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
  state: '#118D57',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
  state: '#B78103',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
  state: '#B72136',
};


const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

// LANDING PAGE
const PAPER = '#FBF8FF';
// const BACKGROUND = {
//   default: PAPER,
// };

const TRANSPARENT = {
  main: "rgba(0, 0, 0, 0)",
};
const WHITE = {
  main: "#ffffff",
  focus: "#ffffff",
};
const BLACK = {
  light: "#000000",
  main: "#000000",
  focus: "#000000",
};

const BACKGROUND = {
  paper: WHITE.main,
  default: PAPER,
  neutral: GREY[200],
  gradient: "linear-gradient(175deg, #f2eaea, #e7ecf0 1300px);", // MS Colors
  // gradient: "linear-gradient(175deg, #f9eefe, #fef9ee 1300px);", // Set in layout.js// rosa f9eefe, amarillo fbfeee
  // gradient: "linear-gradient(175deg, #f9eefe, #fef9ee 1200px);", // Set in layout.js// rosa f9eefe, amarillo fbfeee
  // gradient: "linear-gradient(175deg, #008cff29, #fcff7e29 900px);", // Set in layout.js
};

const LIGHT = {
  main: "#EBEFF4",
  state: "#CED4DA",
};
const DARK = {
  main: "#42424a",
  state: "#191919",
};
const COLOREDSHADOWS = {
  primary: "#e91e62",
  secondary: "#110e0e",
  info: "#9c43fd",
  success: "#4caf4f",
  warning: "#ff9900",
  error: "#f44336",
  light: GREY[500],
  dark: "#404040",
};

const INPUTBORDERCOLOR = "#d2d6da";

const TABS = {
  indicator: { boxShadow: "#ddd" },
};
// LANDING PAGE

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  secondary: createGradient(SECONDARY.light, SECONDARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  light: createGradient(LIGHT.light, LIGHT.main),
  dark: createGradient(DARK.light, DARK.main),
};

const COMMON = {
  common: { black: BLACK.main, white: WHITE.main },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  // gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },

  // LANDING
  transparent: TRANSPARENT,
  white: WHITE,
  black: BLACK,
  light: LIGHT,
  dark: DARK,
  coloredShadows: COLOREDSHADOWS,
  inputBorderColor: INPUTBORDERCOLOR,
  tabs: TABS,

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },
  gradients: {
    primary: {
      main: PRIMARY.lighter,
      state: PRIMARY.light,
    },

    secondary: {
      main: "#747b8a",
      state: "#495361",
    },

    info: {
      main: "#F10080",
      state: "#6500c9",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },
};

// const colors = {
//   common: { black: BLACK.main, white: WHITE.main },
//   primary: { ...PRIMARY },
//   secondary: { ...SECONDARY },
//   info: { ...INFO },
//   success: { ...SUCCESS },
//   warning: { ...WARNING },
//   error: { ...ERROR },
//   grey: GREY,
//   // gradients: GRADIENTS,
//   chart: CHART_COLORS,
//   divider: GREY[500_24],
//   text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], main: "#7b809a", focus: "#7b809a" },
//   background: { paper: '#fff', default: GREY[200], neutral: GREY[200] },
//   action: {
//     active: GREY[600],
//     hover: GREY[500_8],
//     selected: GREY[500_16],
//     disabled: GREY[500_80],
//     disabledBackground: GREY[500_24],
//     focus: GREY[500_24],
//     hoverOpacity: 0.08,
//     disabledOpacity: 0.48,
//   },

// };

export function palette(themeMode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], main: "#7b809a", focus: "#7b809a" },
    background: BACKGROUND,
    action: { active: GREY[600], ...COMMON.action },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600], main: "#7b809a", focus: "#7b809a" },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  };

  return themeMode === 'light' ? light : dark;
}

// export const palette = {
//   light: {
//     ...COMMON,
//     mode: 'light',
//     text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], main: "#7b809a", focus: "#7b809a" },
//     background: BACKGROUND,
//     action: { active: GREY[600], ...COMMON.action },
//   },
//   dark: {
//     ...COMMON,
//     mode: 'dark',
//     text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600], main: "#7b809a", focus: "#7b809a" },
//     background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
//     action: { active: GREY[500], ...COMMON.action },
//   },
// };

export const colors = {
  ...COMMON,
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], main: "#7b809a", focus: "#7b809a" },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
};

export default colors;
