// prop-types is a library for typechecking of props

// @mui material components
import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultInfoCard({ color, icon, title, description, direction, small, removePadding = false }) {
  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
      {typeof icon === "string" ? (
        <MKTypography
          display="block"
          variant={direction === "center" ? "h2" : "h3"}
          color={color}
          textGradient
        >
          {" "}
          <Icon>{icon}</Icon>{" "}
        </MKTypography>
      ) : (
        icon
      )}
      <MKTypography
        display="block"
        variant="h4"
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        variant={small ? "button" : "body1"}
        color="text"
        pr={direction === "left" && !removePadding ? 6 : 0}
        pl={direction === "right" && !removePadding ? 6 : 0}
      >
        {description}
      </MKTypography>
    </MKBox>
  );
}

// // Setting default props for the DefaultInfoCard
// DefaultInfoCard.defaultProps = {
//   color: "info",
//   direction: "left",
//   removePadding: false,
//   small: false,
// };

// // Typechecking props for the DefaultInfoCard
// DefaultInfoCard.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]),
//   icon: PropTypes.node.isRequired,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   // direction: PropTypes.oneOf(["left", "right", "center"]),
//   small: PropTypes.bool,
//   removePadding: PropTypes.bool,
// };

export default DefaultInfoCard;
