
// // Setting values using the new setter methods

import { ProductSubContract } from "model/contracts/ProductSubContract";
import ProductContractsService, { CONTRACT_STATUS_MAP } from "services/ProductContractsService";
import { getEnumVariableText, getMapValueForKey } from "src/utils/typeConverters";
import { Coin } from "./Coin";
export const TRANSACTION_STATUS = [
  { enum: { Requested: null }, name: "Requested" },
  { enum: { Open: null }, name: "Open" },
  { enum: { Accepted: null }, name: "Accepted" },
  { enum: { Rejected: null }, name: "Rejected" },
  { enum: { Ready: null }, name: "Ready" },
  { enum: { Completed: null }, name: "Completed" },

  { enum: { Reserved: null }, name: "Reserved" },
  { enum: { ReservationExpired: null }, name: "ReservationExpired" },
  { enum: { Cancelled: null }, name: "Cancelled" },
  { enum: { Changed: null }, name: "Changed" },
];

export const TRANSACTION_STATUS_MAP = Object.fromEntries(TRANSACTION_STATUS.map((item) => [item.name.toLowerCase(), item]));

export const TRANSACTION_TYPES = [
  { enum: { Shop: { subcontract_id: null } }, name: "Shop" },
  { enum: { Service: { shop_id: null, seller_id: null, price: null, status: null } }, name: "Service" },
];

// export const TRANSACTION_TYPES_ARRAY = Object.fromEntries(TRANSACTION_TYPES.map((item) => item.enum));

// });
// Product id only present if Some
export default class TransactionItem {
  constructor(id, transaction_id, transaction_type, service_name, product_id, status, subcontract, metadata = [], ...otherProps) {
    this.id = id;
    this.transaction_id = transaction_id;
    this.transaction_type = transaction_type;
    this.service_name = service_name;
    if (product_id) this.product_id = product_id.length > 0 ? product_id[0] : product_id;
    this.status = status;
    this.subcontract = subcontract && subcontract.length > 0 ? [ProductSubContract.fromJSON(subcontract[0])] : [];
    this.metadata = metadata;
    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static fromJSON(transactionItemJSON) {
    if (!transactionItemJSON) return null;
    // Extract known properties
    const { id, transaction_id, transaction_type, service_name, product_id, status, subcontract, metadata, ...otherProps } = transactionItemJSON;
    // Return a new instance of TransactionItem including any additional properties
    return new TransactionItem(id, transaction_id, transaction_type, service_name, product_id, status, subcontract, metadata, otherProps);
  }

  clone() {
    // Create a shallow copy of this instance, including any additional properties
    const clonedObj = new TransactionItem(this.id, this.transaction_id, this.transaction_type, this.service_name, this.product_id, this.status, this.subcontract, [
      ...this.metadata,
    ]);
    // Copy any additional properties that were dynamically added
    Object.keys(this).forEach((key) => {
      if (!clonedObj.hasOwnProperty(key)) {
        clonedObj[key] = this[key];
      }
    });

    return clonedObj;
  }

  // Method to add or update a metadata value
  setMetadataValue(key, value) {
    const index = this.metadata.findIndex((item) => item[0] === key);
    if (index !== -1) {
      // Key exists, update its value
      this.metadata[index][1] = value;
    } else {
      // Key doesn't exist, add new key-value pair
      this.metadata.push([key, value]);
    }
  }

  setAllMetadata(metadataObject) {
    // Iterate over each key-value pair in the metadataObject
    Object.entries(metadataObject).forEach(([key, value]) => {
      if (value) this.setMetadataValue(key, value.toString());
    });
  }

  // Helper function to find a metadata value by key
  getMetadataValue(key) {
    const value = getMapValueForKey(this.metadata, key);
    // const entry = this.metadata.find(([entryKey]) => entryKey === key);
    return value && value.length > 0 ? value : null;
  }

  // Helper function to find a metadata value by key
  isMaintenanceService() {
    return this.service_name.name === "Maintenance";
  }

  isValuationService() {
    return this.service_name.name === "Valuation";
  }

  isShopTransaction() {
    return getEnumVariableText(this.transaction_type) === "Shop";
  }

  isServiceTransaction() {
    return getEnumVariableText(this.transaction_type) === "Service";
  }

  getSubcontractId() {
    return this.isShopTransaction() ? this.transaction_type.Shop.subcontract_id : null;
  }

  // Setter methods
  /**
   * @param {any} value
   */
  set shop(value) {
    this.shop = value;
  }

  getShopId() {
    return this.isServiceTransaction() ? this.transaction_type.Service.shop_id : null;
  }

  getSellerId() {
    return this.isServiceTransaction() ? this.transaction_type.Service.seller_id : null;
  }
  getPrice(finalPrice = true) {
    return this.isServiceTransaction()
      ? this.transaction_type.Service.price.length > 0
        ? finalPrice
          ? this.transaction_type.Service.price[0].final_price
          : this.transaction_type.Service.price[0]
        : null
      : null;
  }

  getStatus() {
    return this.status;
  }

  getStatusText() {
    return this.getStatus() ? getEnumVariableText(this.getStatus()) : null;
  }

  setStatus(status) {
    // if (this.isServiceTransaction()) {
    this.status = status;
    // } else {
    //   throw new Error("Cannot set status for Shop transactions");
    // }
  }

  // If shop transaction then return completed if not the status
  isCompleted() {
    return this.getStatusText() === TRANSACTION_STATUS_MAP.completed.name;
  }

  isRequested() {
    return this.getStatusText() === TRANSACTION_STATUS_MAP.requested.name;
  }

  isOnGoing() {
    return this.getStatusText() !== TRANSACTION_STATUS_MAP.rejected.name && this.getStatusText() !== TRANSACTION_STATUS_MAP.completed.name;
  }

  isCancelled() {
    return this.getStatusText() === TRANSACTION_STATUS_MAP.cancelled.name;
  }

  isReserved() {
    return this.getStatusText() === TRANSACTION_STATUS_MAP.reserved.name;
  }

  getSubcontract() {
    if (!this.subcontract || this.subcontract.length === 0) {
      return null;
    }
    // TODO
    return this.subcontract[0];
    // return ProductSubContract.fromJSON(this.subcontract[0]);
  }

  /** If the transaction item contains a subcontract, check if its status is reserved */
  isSubcontractReserved() {
    if (!this.getSubcontract()) {
      return null;
    }
    return this.subcontract[0].isReserved();
  }

  setCancelled() {
    this.setStatus(TRANSACTION_STATUS_MAP.reservationexpired.enum);
    if (!this.getSubcontract()) {
      return null;
    }
    this.subcontract[0].contract_status = CONTRACT_STATUS_MAP.cancelled.enum;
  }

  getReservedUntil() {
    if (!this.getSubcontract()) {
      return null;
    }
    return this.subcontract[0].getReservedUntil();
  }
  /**
   * If the transaction item contains a product, chekc the reservation price, TODO this should come from the backend payment intent
   * @returns
   */
  getReservationPrice() {
    const metadataPrice = this.getMetadataValue("reservation_price");
    if (metadataPrice) {
      return Coin.fromJSON(metadataPrice);
    }
    // // Deprecated
    // if (!this.product) {
    //   return null;
    // }
    // return this.product.getReservationPrice();
  }

  static createTransactionType = (type = "Service", subcontractId, shopId, sellerId, price) => {
    if (type === "Shop") {
      return {
        Shop: { subcontract_id: subcontractId },
      };
    } else {
      return {
        Service: {
          shop_id: BigInt(shopId),
          seller_id: sellerId,
          price: price ? [price] : [],
          // status: status,
        },
      };
    }
  };

  setProduct(product) {
    this.product = product; // Add or update the product field in the transaction item
  }
}
