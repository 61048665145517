/* eslint-disable no-undef */
import { createCoinFromAmount } from "src/utils/format-number";
import { clonePriceCalculation } from "src/utils/price-utils";
import { getArrayObjectByKey, getEnumVariableText } from "../ui/utils/typeConverters";
import BackendService from "./BackendService";
import PaymentsService from "./PaymentsService";
import ProductsService from "./ProductsService";
import UserService from "./UserService";
import { ProductContract } from "model/contracts/ProductContract";
import EmailService from "./EmailService";
import { ProductSubContract } from "model/contracts/ProductSubContract";

// export const CONTRACT_TYPES = [{ 'Subscription': null }, { 'Donation': null }, { 'Sale': null }];
export const CONTRACT_TYPES = [
  { enum: { 'Subscription': null }, name: "Subscription" },
  { enum: { 'Sale': null }, name: "Sale" },
  { enum: { 'Donation': null }, name: "Donation" },
  { enum: { 'Consult': null }, name: "Consult" },
];

export const CONTRACT_TYPES_NAMES = ["Subscription", "Sale", "Donation", "Consult" ];

export const CONTRACT_STATUS = [
  { enum: { Active: null }, name: "Active" },
  { enum: { Completed: null }, name: "Completed" },
  { enum: { Cancelled: null }, name: "Cancelled" },
  { enum: { Paused: null }, name: "Paused" },
  { enum: { PendingPickup: null }, name: "PendingPickup" },
  { enum: { PendingPayment: null }, name: "PendingPayment" },
  { enum: { PendingVerification: null }, name: "PendingVerification" },
  { enum: { Reserved: null }, name: "Reserved" },
];

export const CONTRACT_TYPES_MAP = Object.fromEntries(CONTRACT_TYPES.map(item => [item.name.toLowerCase(), item]));
export const CONTRACT_STATUS_MAP = Object.fromEntries(CONTRACT_STATUS.map(item => [item.name.toLowerCase(), item]));

export const STORE_LISTING_STATUS = [
  { enum: { 'Active': null }, name: "Active" },
  { enum: { 'Reserved': null }, name: "Reserved" },
  { enum: { 'Draft': null }, name: "Draft" },
  { enum: { 'Completed': null }, name: "Completed" },
];
export const STORE_LISTING_STATUS_MAP = Object.fromEntries(STORE_LISTING_STATUS.map(item => [item.name.toLowerCase(), item]));


/**
 * Provides methods related to product contracts
 */
export default class ProductContractsService {
  /**
   * Create a product request
   *
   * @returns
   */
  static addProductRequest = async (symphy_backend, productRequest) => {
    const newProductRequest = await symphy_backend["marketplace"].add_product_request({ product_request: productRequest });
    // console.log("productRequest", JSON.stringify(productRequest));

    if (BackendService.isResponseError(newProductRequest)) {
      console.log("addProductRequest not successful " + JSON.stringify(newProductRequest));
      return null;
    }
    return newProductRequest.Success;
  };

  static createProductRequest = (user, email, id, location, contractType, status, minDuration, priceMin, priceMax, type, sizes, phone, comments) => {
    const productRequest = {
      // eslint-disable-next-line no-undef
      id: BigInt(id),
      user_id: user ? [user.user_id] : [],
      email: email ? [email] : [],
      phone: phone ? [phone] : [],
      created_at: 0,
      location: location ? [location] : [],
      contract_type: contractType ? [{ [contractType]: null }] : [],
      status: status ? [{ [status]: null }] : [],
      min_duration: minDuration ? [Number(minDuration)] : [],
      price_min: priceMin ? [priceMin] : [],
      price_max: priceMax ? [priceMax] : [],
      product_type: type,
      product_sizes: sizes ? sizes : [],
      comments: comments ? [comments] : [],
    };
    return productRequest;
  };

  static cloneProductRequest = (productRequest) => {
    return { ...productRequest };
  };

  /**
   * Update product request
   *
   * @returns
   */
  static updateProductRequest = async (symphy_backend, productRequest) => {
    let updateRequest = await symphy_backend["marketplace"].update_product_request({ product_request: productRequest });
    if (!updateRequest.Success) {
      console.log("updateProductRequest not successful");
      return null;
    }
    return updateRequest.Success;
  };

  /**
   * Delete productRequest
   *
   * @returns
   */
  static deleteProductRequest = async (symphy_backend, requestId) => {
    const updated = await symphy_backend["marketplace"].delete_product_request({
      product_request_id: requestId,
    });
    if (BackendService.isResponseError(updated)) {
      console.log("deleteProductRequest not successful");
      return null;
    }
    return updated.Success;
  };

  /**
   * Fetch product requests from db
   *
   * @returns
   */
  static getProductRequests = async (symphy_backend) => {
    const productRequests = await symphy_backend["marketplace"].get_product_requests({});
    if (BackendService.isResponseError(productRequests)) {
      console.log("getProductRequests not successful");
      return [];
    }
    return productRequests.Success;
  };

  /**
   * Return a product request by id
   *
   * @returns Product request
   */
  static getProductRequest = async (symphy_backend, id) => {
    const productRequest = await symphy_backend["marketplace"].get_product_request({ product_request_id: id });
    if (!productRequest.Success) {
      console.log("getProductRequests not successful");
      return null;
    }
    return productRequest.Success;
  };

  static findClosestRecommendedContract = (recommendedContracts, valuationPrice) => {
    try {
      if (!recommendedContracts || recommendedContracts.length === 0) {
        return null; // No recommended contracts found
      }

      // Filter contracts with a price_subs_optimal that is less than or equal to the provided valuation price
      const validContracts = recommendedContracts.filter((contract) => {
        return contract.product_valuation_price.length > 0 && Number(contract.product_valuation_price[0].amount) <= Number(valuationPrice.amount);
      });

      if (validContracts.length === 0) {
        // No contracts below the valuation price, return the lowest available contract
        return recommendedContracts.reduce((lowest, contract) =>
          Number(contract.product_valuation_price[0].amount) < Number(lowest.product_valuation_price[0].amount) ? contract : lowest
        );
      }

      // Find the closest lower or equal product_valuation_price
      const closestLowerOrEqualPrice = validContracts.reduce((closest, contract) =>
        Number(contract.product_valuation_price[0].amount) > Number(closest.product_valuation_price[0].amount) ? contract : closest
      );

      return closestLowerOrEqualPrice;
    } catch (error) {
      console.error("Error:", error);
      return null; // Handle error
    }
  };
  /**
   * Create a product request
   *
   * @returns
   */
  static addRecommendedContract = async (symphy_backend, recommendedContract) => {
    const productRequest = {
      // eslint-disable-next-line no-undef
      id: BigInt(1),
      product_valuation_price: recommendedContract.product_valuation_price ? [recommendedContract.product_valuation_price] : [],
      payments_upfront: recommendedContract.payments_upfront ? [recommendedContract.payments_upfront] : [],
      deposit: recommendedContract.deposit ? [recommendedContract.deposit] : [],
      price_increase_factor: recommendedContract.price_increase_factor ? [recommendedContract.price_increase_factor] : [],
      price_subs_optimal: recommendedContract.price_subs_optimal ? [recommendedContract.price_subs_optimal] : [],
      price_subs_min: recommendedContract.price_subs_min ? [recommendedContract.price_subs_min] : [],
      price_subs_max: recommendedContract.price_subs_max ? [recommendedContract.price_subs_max] : [],
    };
    const newRecommendedContract = await symphy_backend["marketplace"].add_product_recommended_contract({ recommended_contract: productRequest });
    if (!newRecommendedContract.Success) {
      console.log("addRecommendedContract not successful");
      return null;
    }
    return newRecommendedContract.Success;
  };

  static updateRecommendedContract = async (symphy_backend, recommendedContract) => {
    const productRequest = {
      // eslint-disable-next-line no-undef
      id: BigInt(recommendedContract.id),
      product_valuation_price: recommendedContract.product_valuation_price ? [recommendedContract.product_valuation_price] : [],
      payments_upfront: recommendedContract.payments_upfront ? [recommendedContract.payments_upfront] : [],
      deposit: recommendedContract.deposit ? [recommendedContract.deposit] : [],
      price_increase_factor: recommendedContract.price_increase_factor ? [recommendedContract.price_increase_factor] : [],
      price_subs_optimal: recommendedContract.price_subs_optimal ? [recommendedContract.price_subs_optimal] : [],
      price_subs_min: recommendedContract.price_subs_min ? [recommendedContract.price_subs_min] : [],
      price_subs_max: recommendedContract.price_subs_max ? [recommendedContract.price_subs_max] : [],
    };
    const newRecommendedContract = await symphy_backend["marketplace"].update_product_recommended_contract({ recommended_contract: productRequest });
    if (!newRecommendedContract.Success) {
      console.log("updateRecommendedContract not successful");
      return null;
    }
    return newRecommendedContract.Success;
  };

  /**
   * Delete productRequest
   *
   * @returns
   */
  static deleteRecommendedContract = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].delete_product_recommended_contracts({ recommended_contract_id: id });
    if (!response.Success) {
      console.log("deleteRecommendedContract not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Fetch product requests from db
   *
   * @returns
   */
  static getRecommendedContracts = async (symphy_backend) => {
    const response = await symphy_backend["marketplace"].get_product_recommended_contracts({});
    if (!response.Success) {
      console.log("getRecommendedContracts not successful");
      return [];
    }
    return response.Success;
  };

  static createStoreListingFilter({
    shopId,
    status,
    creatorId,
    verified,
    productIds,
    productType,
    productSize,
    valuationPrices,
    salePrices,
    monthlyRentalPrices,
    optionToBuy,
    minDuration,
    location,
    locationRange = 150,
  }) {
    const filter = {
      shop_id: shopId ? [shopId] : [],
      status: status ? [status] : [],
      creator_id: creatorId ? [creatorId] : [],
      verified: verified ? [verified] : [],
      product_ids: productIds ? productIds : [],
      product_type: productType ? [productType] : [],
      product_size: productSize ? [productSize] : [],
      valuation_prices: valuationPrices ? valuationPrices : [],
      sale_prices: salePrices ? salePrices : [],
      monthly_rental_prices: monthlyRentalPrices ? monthlyRentalPrices : [],
      option_to_buy: optionToBuy ? [optionToBuy] : [],
      min_duration: minDuration ? [minDuration] : [],
      location: location ? [location] : [],
      location_range: locationRange ? [locationRange] : [],

      order_by: [],
      page: [],
      page_size: [],
    };
    return filter;
  }

  /**
   * Return a list of all products for sale
   *
   * @returns All products
   */
  static getActiveStoreListings = async (symphy_backend) => {
    const response = await symphy_backend["marketplace"].get_store_listings({
      ...this.createStoreListingFilter({ status: STORE_LISTING_STATUS_MAP.active.enum, verified: true }),
    });
    // console.log(
    //   "getActiveStoreListings",
    //   JSON.stringify({
    //     ...this.createStoreListingFilter({ status: STORE_LISTING_STATUS_MAP.active.enum, verified: true }),
    //   })
    // );
    // console.log("getActiveStoreListings response", JSON.stringify(response));
    if (!response.Success) {
      console.log("getActiveStoreListings not successful");
      return [];
    }
    return response.Success;
  };

  /**
   * Return a list of all store listings
   *
   * @returns All products
   */
  static getStoreListings = async (symphy_backend, status, shopId) => {
    const response = await symphy_backend["marketplace"].get_store_listings({
      ...this.createStoreListingFilter({ status: status, shopId }),
    });
    if (!response.Success) {
      console.log("getStoreListings not successful");
      return [];
    }
    return response.Success;
  };

  /**
   * Return a listing by store listing id
   *
   * @returns All listings
   */
  static getStoreListing = async (symphy_backend, id) => {
    let response = await symphy_backend["marketplace"].get_store_listing({
      store_listing_id: id,
    });
    if (!response.Success) {
      // if (response.length > 0) {
      //   let productIds = response.map(item => item.product_id);
      //   response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);
      // }
      console.log("getStoreListing not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product for sale
   *
   * @returns All listings
   */
  static getStoreListingByProductId = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_store_listing_by_product_id({ product_id: id });
    if (!response.Success) {
      console.log("getStoreListingByProductId not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product for sale
   *
   * @returns All listings
   */
  static getStoreListingsByProductIds = async (symphy_backend, ids) => {
    // console.log("getStoreListingsByProductIds ", ids);
    if (!ids || ids.length === 0) {
      return [];
    }
    const idFiltered = ids.filter((id) => id !== null && id !== undefined && id !== "");
    if (idFiltered.length === 0) {
      return [];
    }
    const response = await symphy_backend["marketplace"].get_store_listings_by_product_ids({ product_ids: idFiltered });
    if (!response.Success) {
      console.log("getStoreListingByProductIds not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product ids that are not for sale
   *
   * @returns All listings
   */
  static getUnavailableProducts = async (symphy_backend, ids) => {
    // console.log("getStoreListingsByProductIds ", ids);
    const response = await symphy_backend["marketplace"].get_unavailable_store_listings({ product_ids: ids });
    if (!response.Success) {
      console.log("getUnavailableProducts not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * The contracts are retrieved from the backend by id
   * Add a .contract field to the items list (e.j. list of products)
   *
   * @returns All products
   */
  static addStoreListingsToItems = async (symphy_backend, items, ids) => {
    let allListings;
    // if (ids.length === 1) {
    //   allListings = await this.getStoreListing(symphy_backend, ids[0]);
    //   allListings = allListings ? [allListings] : [];
    // } else {
    allListings = await this.getStoreListingsByProductIds(symphy_backend, ids);
    // }

    if (allListings.length === 0) {
      return items;
    }

    const listingsDictionary = allListings.reduce((dict, listing) => {
      dict[listing.product_id] = listing;
      return dict;
    }, {});

    const mapItemWithListing = (item) => {
      const itemId = item.id;
      const listing = listingsDictionary[itemId];
      item.store_listing = listing;
      return item; // Add listing field
    };
    // console.log("mapItemWithListing", mapItemWithListing);
    if (Array.isArray(items)) {
      return items.map(mapItemWithListing);
    } else {
      return mapItemWithListing(items);
    }
  };

  /**
   * Deletes a product by id
   *
   */
  static deleteStoreListing = async (symphy_backend, id) => {
    let response = await symphy_backend["marketplace"].delete_store_listing({
      store_listing_id: id,
    });
    if (BackendService.isResponseError(response)) {
      console.log("deleteStoreListing not successful");
      return null;
    }
    return response.Success;
  };

  static createStoreListing(
    id,
    user,
    shopId,
    name,
    description,
    views,
    likedBy,
    productId,
    status,
    contractId
    // price,
    // priceSale,
    // priceDiscount,
  ) {
    if (!name || !description || !status) {
      return null;
    }
    const listing = {
      id: BigInt(id),
      creator_id: user.user_id,
      shop_id: shopId ? [shopId] : [], // TODO
      name: name,
      description: description,
      created_at: 0,
      // views: views,
      // liked_by: likedBy,
      product_id: BigInt(productId),
      status: status,
      contract_id: contractId ? [BigInt(contractId)] : [],
      metadata: [],
      // price: isNaN(price) ? [] : [price],
      // price_sale: isNaN(priceSale) ? [] : [priceSale],
      // price_sale_discount: isNaN(priceDiscount) ? [] : [priceDiscount],
    };
    // console.log("createStoreListing" + JSON.stringify(listing));
    return listing;
  }

  static addStoreListing = async (symphy_backend, storeListing) => {
    let response = await symphy_backend["marketplace"].add_store_listing({
      store_listing: storeListing,
    });
    if (!response.Success) {
      console.log("addStoreListing not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Update store listing
   *
   * @returns
   */
  static updateStoreListing = async (symphy_backend, storeListing) => {
    // If the storeListing is a response it doesnt have a contract id
    const storeListingToUse = storeListing.contract_id ? storeListing : { ...storeListing, contract_id: [storeListing.contract && storeListing.contract.length > 0  ? BigInt(storeListing.contract[0].id) : []]};
    let response = await symphy_backend["marketplace"].update_store_listing({
      store_listing: storeListingToUse,
    });
    if (!response.Success) {
      console.log("addStoreListing not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Check if a product is displayed in the shop
   *
   * @returns
   */
  static isListingInShop = (storeListing, onlyActive) => {
    if (!storeListing || (Array.isArray(storeListing) && storeListing.length === 0)) return false;
    const status = String(getEnumVariableText(Array.isArray(storeListing) ? storeListing[0].status : storeListing.status));
    if (onlyActive) {
      return status === STORE_LISTING_STATUS_MAP.active.name;
    }
    return status === STORE_LISTING_STATUS_MAP.active.name || status === STORE_LISTING_STATUS_MAP.reserved.name;
  };

  /**
   * Transfer a product store listing and contract to another user
   *
   * @returns
   */
  static transferProductToUser = async (symphy_backend, storeListingId, userId) => {
    // console.log("getStoreListingsByProductIds ", ids);
    const response = await symphy_backend["marketplace"].transfer_product({ store_listing_id: storeListingId, new_user_id: userId, transfer_contract: true });
    if (!response.Success) {
      console.log("transferProductToUser not successful");
      return null;
    }
    return response.Success;
  };

  /// ------------------------------------------------
  /// ------------------------------------------------
  /// ------------------ CONTRACTS  ------------------
  /// ------------------------------------------------
  /// ------------------------------------------------

  /**
   * The contracts are retrieved from the backend by id
   * Add a .contract field to the items list (e.j. list of products)
   *
   * @returns All products
   */
  static addContractsToItems = async (symphy_backend, items, productIds) => {
    const allContracts = await this.getProductContractByProductIds(symphy_backend, productIds);
    // console.log("addContractsToItems productdis " + JSON.stringify(productIds));
    // console.log("addContractsToItems" + JSON.stringify(allContracts));
    // }
    if (allContracts.length === 0) {
      return items;
    }
    // Create a dictionary to map product_id to an array of contracts
    const contractDictionary = allContracts.map((contract) => ProductContract.fromJSON(contract)).reduce((dict, contract) => {
      if (!dict[contract.product_id]) {
        dict[contract.product_id] = [];
      }
      dict[contract.product_id].push(contract);
      return dict;
    }, {});

    // Function to map an item to include its contracts
    const mapItemWithContracts = (item) => {
      const itemId = item.id;
      const contracts = contractDictionary[itemId] || [];
      item.contracts = contracts;
      return item; // Add contracts field
    };
    if (Array.isArray(items)) {
      return items.map(mapItemWithContracts);
    } else {
      return mapItemWithContracts(items);
    }
  };

  /**
   * Return a list of all product contracts
   *
   * @returns All products
   */
  static getProductContracts = async (symphy_backend) => {
    const response = await symphy_backend["marketplace"].get_product_contracts({});
    if (!response.Success) {
      console.log("getProductContracts not successful");
      return [];
    }
    return response.Success;
  };

  /**
   * Return a list of all product contracts expanded (contain all the info)
   *
   * @returns All products
   */
  static getProductExpandedContracts = async (symphy_backend) => {
    const response = await symphy_backend["marketplace"].get_product_contracts_expanded({});
    if (!response.Success) {
      return [];
    }
    return response.Success;
  };

  /**
   * Return a productContract by product contract id
   *
   * @returns All productContracts
   */
  static getProductContract = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_product_contract({
      product_contract_id: id,
    });
    if (!response.Success) {
      console.log("getProductContract not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product for sale
   *
   * @returns All productContracts
   */
  static getProductContractByProductId = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_product_contract_by_product_id({ product_id: id });
    if (!response.Success) {
      console.log("getProductContractByProductId not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product contracts by product id
   *
   * Also include subcontracts
   *
   * @returns
   */
  static getProductContractByProductIds = async (symphy_backend, ids) => {
    const response = await symphy_backend["marketplace"].get_product_contracts_with_subcontracts_by_product_ids({
      product_ids: ids,
    });
    if (!response.Success) {
      console.log("getProductContractByProductIds not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Deletes a product by id
   *
   */
  static deleteProductContract = async (symphy_backend, id, withSubcontracts = false) => {
    let response = await symphy_backend["marketplace"].delete_product_contract({
      product_contract_id: id,
      with_subcontracts: withSubcontracts,
    });
    if (BackendService.isResponseError(response)) {
      console.log("deleteProductContract not successful");
      return null;
    }
    return response.Success;
  };

  static createProductContract(
    id = 0,
    productId,
    ownerId,

    active,
    startDate = 0,
    endDate = 0,
    ownerContracts,
    shopId,
    metadata
  ) {
    if (!productId || !ownerId) {
      console.log("creaating error id " + id + " productId " + productId + " ownerId " + ownerId + " active " + active + " startDate " + startDate);
      return null;
    }
    const productContract = {
      id: BigInt(id),
      product_id: BigInt(productId),
      owner_id: ownerId,
      shop_id: shopId ? [shopId] : [],

      active: Boolean(active),
      start_date: startDate ? [startDate] : [],
      end_date: endDate ? [endDate] : [],
      max_end_date: [],
      owner_contracts: ownerContracts ? ownerContracts : [],
      metadata: metadata ? metadata : [],
    };
    return productContract;
  }

  static createSubscriptionContractConfig(active, optionBuy, optionBuyDiscount, autorenew, minDuration, maxDuration, priceSubs, deposit) {
    const productContractConfig = {
      Subscription: {
        active: Boolean(active),
        option_buy: Boolean(optionBuy),
        option_buy_discount: optionBuyDiscount, // percentage of money payed that will be discounted form buying price
        autorenew: Boolean(autorenew),
        min_duration: minDuration, // days
        max_duration: maxDuration ? [maxDuration] : [], // days
        price_subs: priceSubs, // monthly price in a 1 year subscription
        deposit: deposit ? deposit : createCoinFromAmount(0),
        metadata: [],
      },
    };
    return productContractConfig;
  }

  static createSaleContractConfig(active, priceSale, priceDiscount) {
    const productContractConfig = {
      Sale: {
        active: Boolean(active),
        price_sale: priceSale,
        price_discount: priceDiscount && isNaN(priceDiscount) ? [priceDiscount] : [], // percentage of money payed that will be discounted form buying price
      },
    };
    return productContractConfig;
  }

  static createDonationContract(active, maxDuration, availableUntil) {
    const productContractConfig = {
      Donation: {
        active: Boolean(active),
        max_duration: maxDuration ? [maxDuration] : [], // months
        available_until: availableUntil ? [availableUntil] : [], // date after which the product is not available
      },
    };
    return productContractConfig;
  }

  /**
   * Add product contract
   *
   * @returns All products
   */
  static addProductContract = async (symphy_backend, productContract) => {
    let response = await symphy_backend["marketplace"].add_product_contract({
      product_contract: productContract,
    });
    if (!response.Success) {
      console.log("addProductContract not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Update product contract
   *
   * @returns
   */
  static updateProductContract = async (symphy_backend, productContract) => {
    let response = await symphy_backend["marketplace"].update_product_contract({
      product_contract: productContract,
    });
    if (!response.Success) {
      console.log("updateProductContract not successful");
      return null;
    }
    return response.Success;
  };

  static getProductContractSubsConfig(productContract) {
    let singleContract = productContract && productContract.length > 0 ? productContract[0] : productContract;
    if (singleContract && singleContract.owner_contracts && singleContract.owner_contracts.length > 0) {
      return getArrayObjectByKey(singleContract.owner_contracts, "Subscription");
    }
    return null;
  }

  static getProductContractSaleConfig(productContract) {
    let singleContract = productContract && productContract.length > 0 ? productContract[0] : productContract;
    if (singleContract && singleContract.owner_contracts && singleContract.owner_contracts.length > 0) {
      return getArrayObjectByKey(singleContract.owner_contracts, "Sale");
    }
    return null;
  }

  /// ---- SUBCONTRACTS

  /**
   * Return a list of all product subcontracts
   *
   * @returns All products
   */
  static getProductSubContracts = async (symphy_backend, startDate, endDate, userId, productIds, removeCancelled) => {
    const response = await symphy_backend["marketplace"].get_product_subcontracts({
      start_date: startDate ? [startDate] : [],
      end_date: endDate ? [endDate] : [],
      user_id: userId ? [userId] : [],
      product_ids: productIds ? [productIds] : [],
      remove_cancelled: removeCancelled ? [removeCancelled] : [],
    });
    if (!response.Success) {
      console.log("getProductSubContracts not successful");
      return [];
    }
    return response.Success;
  };

  /**
   * Return a productSubContract by product subcontract id
   *
   * @returns All productSubContracts
   */
  static getProductSubContract = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_product_subcontract({ product_subcontract_id: id });
    if (!response.Success) {
      console.log("getProductSubContract not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Return a list of a product for sale
   *
   * @returns All productSubContracts
   */
  static getProductSubContractsByContractId = async (symphy_backend, id) => {
    const response = await symphy_backend["marketplace"].get_product_subcontracts_by_contract_id({ contract_id: id });
    // console.log("getProductSubContractsByContractId " + JSON.stringify(response));
    // console.log("getProductSubContractsByContractId contract_id" + JSON.stringify(contract_id));
    if (!response.Success) {
      console.log("getProductSubContractByContractId not successful");
      return null;
    }
    return response.Success;
  };

  /**
   * Deletes a product contract by id
   *
   */
  static deleteProductSubContract = async (symphy_backend, id, activateListing = false) => {
    let response = await symphy_backend["marketplace"].delete_product_subcontract({ product_subcontract_id: id, activate_listing: activateListing });
    if (BackendService.isResponseError(response)) {
      console.log("deleteProductSubContract not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  static createProductSubContract(id, parentContractId, userId, shopId, startDate, endDate, price, duration, contractType, status, metadata) {
    const productSubContract = {
      id: BigInt(id),
      parent_contract_id: BigInt(parentContractId),
      user_id: userId,
      shop_id: shopId ? [BigInt(shopId)] : [],
      start_date: startDate,
      end_date: endDate ? [endDate] : [],
      price: price ? [clonePriceCalculation(price)] : [],
      duration: duration ? [{ duration: parseInt(duration) }] : [],
      contract_type: contractType ? contractType : CONTRACT_TYPES_MAP.subscription.enum,
      contract_status: status ? status : { PendingVerification: null },
      metadata: metadata ? metadata : [],
    };
    return productSubContract;
  }

  /**
   * Add product subcontract
   *
   * @returns All products
   */
  static addProductSubContract = async (symphy_backend, productSubContract) => {
    let response = await symphy_backend["marketplace"].add_product_subcontract({
      product_subcontract: productSubContract,
    });
    // console.log("addProductSubContract response " + JSON.stringify(response));
    if (!response.Success && !response.ActiveContract) {
      console.log("addProductSubContract not successful " + JSON.stringify(response));
      return null;
    }
    if (response.ActiveContract) {
      if (response.ActiveContract.length > 0) {
        // If active contract return the previously created one, it could be pending payment
        console.log("addProductSubContract successful active" + JSON.stringify(response.ActiveContract[0]));
        return response.ActiveContract[0];
      }
      console.log("addProductSubContract not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Update product subcontract
   *
   * @returns
   */
  static updateProductSubContract = async (symphy_backend, productSubContract, newId) => {
    let response = await symphy_backend["marketplace"].update_product_subcontract({ product_subcontract: productSubContract , new_id: newId ? [BigInt(newId)] : []});
    if (!response.Success) {
      console.log("updateProductSubContract not successful " + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Update product subcontract
   * If the status is set to cancelled, the product owner should be updated
   *
   * @returns
   */
  static updateProductSubContractStatus = async (symphy_backend, subcontract, contractStatus) => {
    const id = subcontract.id;
    let statusToUse = contractStatus;
    if (CONTRACT_STATUS_MAP.cancelled.name === getEnumVariableText(contractStatus)) {
      if (this.isSaleContract(subcontract.contract_type)) {
        // On sales revert owner to the one from the parent contract
        const parentContract = await this.getProductContract(symphy_backend, BigInt(subcontract.parent_contract_id));
        if (parentContract) {
          const updateOwnerResult = await ProductsService.updateProductOwner(symphy_backend, parentContract.product_id, parentContract.owner_id);
        }
      }
      // Listing status is updated in the backend
      statusToUse = CONTRACT_STATUS_MAP.pendingpickup.enum;
    }
    let response = await symphy_backend["marketplace"].update_product_subcontract_status({
      id: BigInt(id),
      contract_status: statusToUse,
      // payment_date: paymentDate ? [paymentDate] : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("updateProductSubContractStatus not successful" + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  /**
   * Refresh payments. Triggers the backend to check if the payments are up to date
   *
   * @returns
   */
  static syncPayments = async (symphy_backend) => {
    let response = await symphy_backend["marketplace"].update_product_subcontract_status({
      id: BigInt(0),
      contract_status: CONTRACT_STATUS_MAP.active.enum,
      // payment_date: paymentDate ? [paymentDate] : [],
    });
    if (BackendService.isResponseError(response)) {
      console.log("syncPayments not successful" + JSON.stringify(response));
      return null;
    }
    return response.Success;
  };

  // PRICE

  /**
   * Extract a list of all product shop ids from a list of contracts and their subcontracts
   * @param {*} productsWithContracts
   * @returns
   */
  static getProductContractsShopIds = (productsWithContracts) => {
    return productsWithContracts.flatMap((product) => {
      if (!product.contracts || product.contracts.length === 0) {
        return [];
      }
      return product.contracts.flatMap((contract) => {
        const shopIds = [];

        if (contract.shop_id && contract.shop_id.length > 0) {
          shopIds.push(contract.shop_id[0]);
        }

        contract.subcontracts.forEach((subcontract) => {
          if (subcontract.shop_id && subcontract.shop_id.length > 0) {
            shopIds.push(subcontract.shop_id[0]);
          }
        });

        return shopIds;
      });
    });
  };

  /**
   * Return a list of all product subcontracts
   *
   * @returns Subscription price with fees and discounts
   */
  static getCompleteSubscriptionPrice = async (symphy_backend, userId, productId, valuationPrice, contractDuration) => {
    const response = await symphy_backend["marketplace"].get_complete_subscription_price({
      user_id: userId,
      product_id: productId,
      valuation_price: valuationPrice,
      contract_duration: contractDuration,
    });
    if (!response.Success) {
      console.log("getCompleteSubscriptionPrice not successful");
      return null;
    }
    return response.Success;
  };

  static isSubscriptionContract = (subcontract) => {
    if (subcontract.contract_type) {
      return getEnumVariableText(subcontract.contract_type) === CONTRACT_TYPES_MAP.subscription.name;
    }
    return getEnumVariableText(subcontract) === CONTRACT_TYPES_MAP.subscription.name;
  };

  static isSaleContract = (subcontract) => {
    if (subcontract.contract_type) {
      return getEnumVariableText(subcontract.contract_type) === CONTRACT_TYPES_MAP.sale.name;
    }
    return getEnumVariableText(subcontract) === CONTRACT_TYPES_MAP.sale.name;
  };

  /**
   * Filter all the contracts of a products by active and order by start_date
   * @param {*} productContractsExpanded
   * @returns
   */
  static getCurrentContractSubscription(productContractsExpanded) {
    if (!productContractsExpanded || productContractsExpanded.length === 0) {
      return null;
    }
    // Filter active contracts done later, as if status is pending we have to retur nit
    // const activeContracts = productContractsExpanded.filter((contract) => contract.active);
    // if (activeContracts.length === 0) {
    //   return null;
    // }
    const activeContracts = [...productContractsExpanded];
    // Sort active contracts by start_date in descending order
    const sortedContracts = activeContracts.sort((a, b) => {
      const dateA = a.start_date.length > 0 ? new Date(Number(a.start_date[0])) : 0;
      const dateB = b.start_date.length > 0 ? new Date(Number(b.start_date[0])) : 0;
      return dateB - dateA;
    });

    // console.log("getCurrentContractSubscription: productContractsExpanded " + JSON.stringify(productContractsExpanded));

    // Find the first contract with a subscription (assuming subcontracts is an array)
    const currentSubcontract = sortedContracts.reduce((acc, contract) => {
      // const subscription = ProductSubContract.getLastSubscription(contract.subcontracts ? contract.subcontracts : contract);
      // TODO the fromJSON should not be necesary. When calling this from overview-app-view, the contract is not a ProductContract object
      const subscription = contract.getLastSubscription();
      // const subscription = ProductContract.fromJSON(contract).getLastSubscription();
      if (subscription !== null) {
        const subscriptionStatus = getEnumVariableText(subscription.contract_status);
        if (
          contract.active ||
          subscriptionStatus === CONTRACT_STATUS_MAP.active.name ||
          subscriptionStatus === CONTRACT_STATUS_MAP.paused.name ||
          subscriptionStatus === CONTRACT_STATUS_MAP.pendingpickup.name ||
          subscriptionStatus === CONTRACT_STATUS_MAP.reserved.name ||
          subscriptionStatus === CONTRACT_STATUS_MAP.pendingpayment.name
        ) {
          return subscription;
        }
      }
      return acc;
    }, null);
    return currentSubcontract ? currentSubcontract : null;
  }


  static getContractSubscriptions(productContractExpanded) {
    const subscriptionSubContracts = [];

    if (productContractExpanded && productContractExpanded.subcontracts && productContractExpanded.subcontracts.length > 0) {
      productContractExpanded.subcontracts.forEach((subContract) => {
        if (getEnumVariableText(subContract.contract_type) === "Subscription") {
          subscriptionSubContracts.push(subContract);
        }
      });
    }
    return subscriptionSubContracts;
  }

  /**
   * Get contracts to be paid based on payment intents and interval
   * Add next_payment_date and pending_payments to the subcontract
   * @param {Array} products - Array of products with .subcontract with payment intents if there is no .subcontract then the list is of subcontracts
   * @param {number} interval - Payment interval in months
   * @returns {Array} - List of contracts to be paid
   */
  static getContractsToBePaid(products, interval) {
    if (!products || products.length === 0) {
      return [[], []];
    }
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1); // First day of next month
    const oneWeekBeforeNextMonth = new Date(nextMonthDate.getTime() - 7 * 24 * 60 * 60 * 1000); // One week before next month
    const contractsOneWeekBeforeNextMonth = [];
    const latePaymentContracts = [];
    products.forEach((product) => {
      const subcontract = product.subcontract ? product.subcontract : product;
      const paymentIntents = subcontract.transaction ? subcontract.transaction.payment_intents : [];
      const pendingPayments = subcontract.getPendingPayments(paymentIntents);
      if (pendingPayments > 0) {
        const nextPaymentDate = subcontract.next_payment_date;
        if (nextPaymentDate && nextPaymentDate.getTime() < currentDate.getTime()) {
          latePaymentContracts.push(ProductSubContract.fromJSON({ ...subcontract, pending_payments: pendingPayments, isLate: true }));
        } else if (nextPaymentDate && nextPaymentDate.getTime() <= oneWeekBeforeNextMonth.getTime()) {
          contractsOneWeekBeforeNextMonth.push(ProductSubContract.fromJSON({ ...subcontract, pending_payments: pendingPayments }));
        }
      }
    });
    return [contractsOneWeekBeforeNextMonth, latePaymentContracts];
  }


  static onUpdateSubContractStatus = (symphy_backend, row, subContract, status, t, listener, enqueueSnackbar) => {
    return async () => {
      // TODO instead of forcing list to refresh, update only current
      const updateResult = await this.updateProductSubContractStatus(symphy_backend, subContract, status, false);
      if (updateResult) {
        // Send statusUpdate email
        const user = await UserService.getUserWithIdCard(symphy_backend, subContract.user_id);
        // console.log("user ", JSON.stringify(user));
        if (user.email.length === 0) {
          enqueueSnackbar(t("contact.invalid_email"), { variant: "error" });
          return;
        }
        const orderIds = await PaymentsService.getSubcontractOrderIds(symphy_backend, subContract.id);
        if (orderIds.length === 0) {
          enqueueSnackbar(t("emails.order_not_found"), { variant: "error" });
          return;
        }
        const orderId = orderIds[0];
        EmailService.sendOrderEmail({
          // response.email,
          // to: "pablosone@hotmail.com",
          to: user.email,
          subject: t("checkout.order_collected"),
          description: t("emails.order_email_collected"),
          headline: t("emails.headline_thanks", { name: UserService.getUserDisplayName(user, true) }),
          orderTitle: t("emails.order_id"),
          orderId: orderId,
          items: [EmailService.subcontractToEmailItem(t, subContract, row)],
          totalPrice: null,
        });
        // if (!emailResult) {
        //   enqueueSnackbar(t("emails.error_sending_email"), { variant: "error" });
        //   return;
        // } else {
        //   console.log("emailResult " + JSON.stringify(emailResult));
        // }
      }
      if (listener) listener(updateResult);
    };
  };
  // /**
  //  * Calculate end date.
  //  * @param {*} subcontract
  //  * @returns
  //  */
  // static calculateSubontractEndDate(subcontract) {
  //   if (subcontract.duration.length === 0) {
  //     return subcontract.start_date;
  //   }
  //   const startDate = Number(subcontract.start_date);
  //   const durationInMs = Number(subcontract.duration[0].duration * 24 * 60 * 60 * 1000); // Convert duration from days to milliseconds

  //   // Check if the contract has a specified end date
  //   const contractEnded = subcontract.end_date.length > 0;
  //   // Calculate the estimated or actual end date
  //   const endDate = contractEnded ? Number(subcontract.end_date[0]) : startDate + durationInMs;
  //   return endDate;
  // }
}

