import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
import productReducer from './slices/product';
import productDefinitionReducer from './slices/productDefinition';
import storeListingReducer from './slices/storeListing';
import userReducer from "./slices/users";
import productContractReducer from "./slices/productContracts";
import discountsContractReducer from "./slices/discounts";
import feesContractReducer from "./slices/fees";
import transactionReducer from "./slices/transactions";


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'create'],
};

const productDefinitionPersistConfig = {
  key: "productDefinition",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const storeListingPersistConfig = {
  key: "storeListing",
  storage,
  keyPrefix: "redux-",
  whitelist: ['checkout'],
};

const productContractPersistConfig = {
  key: "productContract",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const discountsPersistConfig = {
  key: "discounts",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const feesPersistConfig = {
  key: "fees",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const transactionsPersistConfig = {
  key: "transactions",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  productDefinition: productDefinitionReducer,
  // productDefinition: persistReducer(productDefinitionPersistConfig, productDefinitionReducer),
  // recommendedContract: productReducer,
  storeListing: persistReducer(storeListingPersistConfig, storeListingReducer),
  user: persistReducer(userPersistConfig, userReducer),
  productContract: persistReducer(productContractPersistConfig, productContractReducer),
  discounts: persistReducer(discountsPersistConfig, discountsContractReducer),
  fees: persistReducer(feesPersistConfig, feesContractReducer),
  transaction: persistReducer(transactionsPersistConfig, transactionReducer),
});

export { rootPersistConfig, rootReducer };
