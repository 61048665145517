import numeral from "numeral";
import { ensureTwoOrOneDecimalDigits } from "src/utils/format-number";
import { getEnumVariableText } from "src/utils/typeConverters";

export class Coin {
  constructor(amount, currency = Coin.defaultCurrency(), ...otherProps) {
    if (amount instanceof Coin) return amount;
    if (amount instanceof Object) return Coin.fromJSON(amount);
    this.amount = BigInt(amount);
    this.currency = Coin.formatCurrency(currency);

    // Flatten otherProps if it's an array and then assign its properties to this instance
    if (Array.isArray(otherProps)) {
      // Assuming each element in the array is an object, flatten and merge them
      const flattenedProps = otherProps.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      Object.assign(this, flattenedProps);
    } else if (typeof otherProps === "object") {
      Object.assign(this, otherProps);
    }
  }

  static formatCurrency(currency) {
    return typeof currency === "string" ? { [currency]: null } : currency;
  }

  static fEmptyPrice(removeCurrency = false) {
    return Coin.fPrice(Coin.empty(), removeCurrency);
  }

  static empty() {
    return new Coin(0, Coin.defaultCurrency());
  }

  isZero() {
    return this.amount === 0;
  }

  /**
   *  The price string should be an integer without decimals coming from a Coin object amount
   * @param {*} amount
   * @param {*} currency
   * @returns
   */
  static createCoinFromAmount(amount, currency) {
    let priceString = String(amount).replace(/[^0-9-]+/g, "");
    const currencyObject = currency ? (typeof currency === "object" ? currency : { [currency]: null }) : { EUR: null };
    const numericValue = Number(priceString);

    return new Coin(numericValue, currencyObject);
  }

  static defaultCurrency() {
    return { EUR: null }; // Default currency
  }

  static fromJSON(coinJSON) {
    if (!coinJSON) return null;
    // Parse if `coinJSON` is a string
    const coinData = typeof coinJSON === "string" ? JSON.parse(coinJSON) : coinJSON;
    // Destructure the parsed object
    const { amount, currency, ...otherProps } = coinData;
    return new Coin(amount, Coin.formatCurrency(currency), otherProps);
  }

  static fromString(input) {
    const parts = input.split(" ");
    if (parts.length !== 2) {
      return null; // Invalid format
    }

    const amountStr = parts[0];
    const currencyStr = parts[1];

    const amount = parseFloat(amountStr);
    if (isNaN(amount)) {
      return null; // Failed to parse amount
    }

    const currency = ["USD", "EUR", "GBP"].includes(currencyStr) ? currencyStr : null;
    if (!currency) {
      return null; // Invalid currency
    }

    const decimals = Coin.getDecimals(currency);
    const amountInCents = Math.round(amount * Math.pow(10, decimals));

    return new Coin(amountInCents, Coin.formatCurrency(currency));
  }

  // Convert a price string to a Coin. The decimals will be taken into account
  // The decimals can be both . or ,
  // Use it to convert and input string price t oa price object
  static fromStringPrice(inputPrice, currency) {
    let priceString = ensureTwoOrOneDecimalDigits(inputPrice);
    const currencyObject = currency ? (typeof currency === "object" ? currency : { [currency]: null }) : { EUR: null };
    const numericValue = Number(priceString.replace(/[^0-9-]+/g, ""));

    return new Coin(numericValue, currencyObject);
  }

  static getDecimals(currency) {
    switch (currency) {
      case "USD":
      case "EUR":
      case "GBP":
        return 2;
      default:
        return 2; // Default decimals
    }
  }

  static getDecimalFactor(currency) {
    switch (currency) {
      case "USD":
      case "EUR":
      case "GBP":
        return 100; // 2 decimal places
      default:
        return 100; // Default multiplication factor
    }
  }

  static getCurrencySymbol(currency) {
    switch (currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "JPY":
        return "¥";
      // Add symbols for other currencies as needed
      default:
        return currency; // Default to empty string if currency symbol is not available
    }
  }

  // Additional methods from the provided code
  static getDefaultCurrencySymbol() {
    return Coin.getCurrencySymbol(Coin.defaultCurrency());
  }

  clone() {
    const clonedObj = new Coin({ amount: this.amount, currency: this.currency });
    // Copy any additional properties that were dynamically added
    Object.keys(this).forEach((key) => {
      if (!clonedObj.hasOwnProperty(key)) {
        clonedObj[key] = this[key];
      }
    });
    return clonedObj;
  }

  add(other) {
    if (getEnumVariableText(this.currency) !== getEnumVariableText(other.currency)) {
      throw new Error("Cannot add prices with different currencies");
    }
    return new Coin(Number(this.amount) + Number(other.amount), this.currency);
  }

  subtract(other) {
    if (getEnumVariableText(this.currency) !== getEnumVariableText(other.currency)) {
      throw new Error("Cannot subtract prices with different currencies");
    }
    return new Coin(Number(this.amount) - Number(other.amount), this.currency);
  }

  multiply(factor) {
    return new Coin(Math.round(Number(this.amount) * factor), this.currency);
  }

  divide(divisor) {
    return new Coin(Math.round(Number(this.amount) / divisor), this.currency);
  }

  equals(other) {
    return Number(this.amount) === Number(other.amount) && this.currency === other.currency;
  }

  greaterThan(other) {
    if (this.currency !== other.currency) {
      throw new Error("Cannot compare prices with different currencies");
    }
    return Number(this.amount) > Number(other.amount);
  }

  lessThan(other) {
    if (getEnumVariableText(this.currency) !== getEnumVariableText(other.currency)) {
      throw new Error("Cannot compare prices with different currencies");
    }
    return Number(this.amount) < Number(other.amount);
  }

  // New instance method to find the minimum of the current Coin and another
  min(other) {
    if (getEnumVariableText(this.currency) !== getEnumVariableText(other.currency)) {
      throw new Error("Cannot compare prices with different currencies");
    }
    return this.lessThan(other) ? this : other;
  }

  formattedAmount() {
    const decimals = Coin.getDecimals(this.currency);
    const amountStr = (Number(this.amount) / Math.pow(10, decimals)).toFixed(decimals);
    return `${amountStr} ${Coin.getCurrencySymbol(getEnumVariableText(this.currency))}`;
  }

  toString() {
    return this.formattedAmount();
  }

  static totalCoinValue(coinList) {
    if (!Array.isArray(coinList) || coinList.length === 0) {
      throw new Error("The coin list must be a non-empty array");
    }

    const baseCurrency = getEnumVariableText(coinList[0].currency);
    let totalAmount = 0;
    console.log("coinList", JSON.stringify(coinList));
    for (const coin of coinList) {
      const coinCurrency = getEnumVariableText(coin.currency);

      if (coinCurrency !== baseCurrency) {
        throw new Error("All coins must have the same currency");
      }

      totalAmount += Number(coin.amount);
    }

    return new Coin(totalAmount, coinList[0].currency);
  }

  /* Format a Backend price to a string with proper number of decimals and currency
   */
  static fPrice(price, removeCurrency = false, removeDecimals = false) {
    if (!price) return "";

    const currency = getEnumVariableText(price.currency);
    let decimalFormat;
    if (removeDecimals) {
      decimalFormat = "0,0";
    } else if (currency === "USD" || currency === "EUR" || currency === "GBP") {
      decimalFormat = "0,0.00";
    } else if (currency === "JPY") {
      decimalFormat = "0,0.0";
    } else {
      decimalFormat = "0,0.0000";
    }

    const decimalFactor = Coin.getDecimalFactor(currency);
    const dividedValue = Number(price.amount) / decimalFactor;
    const formattedNumber = numeral(dividedValue).format(decimalFormat);

    return !removeCurrency ? `${formattedNumber} ${Coin.getCurrencySymbol(currency)}` : formattedNumber;
  }

  static fPriceToNumber(price) {
    if (!price) return "";

    const currency = getEnumVariableText(price.currency);
    const decimalFactor = Coin.getDecimalFactor(currency);
    const dividedValue = Number(price.amount) / decimalFactor;

    return parseFloat(dividedValue);
  }
}
