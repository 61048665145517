// @mui
import { Box, Button, Container, Typography } from '@mui/material';
// _mock
// sections
import MKBox from 'components/MKBox';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from '../../../../ui/components/animate';
import { useLocales } from '../../../../ui/locales';
import { PATH_PAGE } from '../../../../ui/routes/paths';

// ----------------------------------------------------------------------
export default function StillQuestions() {
  const { translate } = useLocales();

  return (
    <MKBox component="section" mb={12}>
      <Container component={MotionViewport}>
        <m.div variants={varFade().in}>
          <Box
            sx={{
              textAlign: 'center',
              mt: {
                xs: 5,
                md: 10,
              },
            }}
          >
            <m.div variants={varFade().inDown}>
              <Typography variant="h4">{translate("about_us.still_questions")}</Typography>
            </m.div>

            <m.div variants={varFade().inDown}>
              <Typography sx={{ mt: 2, mb: 5, color: 'text.secondary' }}>
                {translate("about_us.still_questions_description")}
              </Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
              <Button
                color="inherit"
                size="large"
                variant="contained"
                href={PATH_PAGE.contact.path}
                sx={{
                  bgcolor: 'text.primary',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'text.primary',
                  },
                }}
              >
                {translate("contact.contact_us")}
              </Button>
            </m.div>
          </Box>
        </m.div>
      </Container>
    </MKBox >
  );
}
