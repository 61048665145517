import { Coin } from "../payments/Coin";
import ServiceType from "./ServiceType";
import Utils from "../Utils";

class Service {
  constructor(id, shopId, name, description, price = [], serviceType, metadata = [], ...otherProps) {
    this.id = BigInt(id);
    this.shop_id = BigInt(shopId);
    this.name = name;
    this.description = description;
    this.price = price.length > 0 ?price.map((p) => Coin.fromJSON(p)) : [];
    this.service_type = serviceType;
    this.metadata = metadata;

    Object.assign(this, Utils.flattenAndMergeProps(otherProps));
  }

  static fromJSON(serviceJSON) {
    const { id, shop_id, name, description, price, service_type, metadata, ...otherProps } = serviceJSON;
    return new Service(id, shop_id, name, description, price, ServiceType.fromJSON(service_type), metadata, otherProps);
  }

  clone() {
    // Create a deep clone of the metadata array
    const clonedMetadata = this.metadata.map(([key, value]) => [key, value]);

    return new Service(
      this.id,
      this.shop_id,
      this.name,
      this.description,
      this.price,
      this.service_type.clone(),
      clonedMetadata,
      Utils.cloneOtherProps(this, ["id", "shop_id", "name", "description", "price", "service_type", "metadata"])
    );
  }

  toBackendObject() {
    return {
      id: this.id,
      shop_id: this.shop_id,
      name: this.name,
      description: this.description,
      price: this.price,
      service_type: this.service_type.toBackendObject(),
      metadata: this.metadata,
    };
  }

  setMetadataValue(key, value) {
    this.metadata[key] = value;
  }

  getMetadataValue(key) {
    return this.metadata[key] || null;
  }

  isStorage() {
    return this.service_type.name === "Storage";
  }

  isMaintenance() {
    return this.service_type.name === "Maintenance";
  }

  isValuation() {
    return this.service_type.name === "Valuation";
  }

  isSubscription() {
    return this.service_type.name === "Subscription";
  }

  isSale() {
    return this.service_type.name === "Sale";
  }

  isShop() {
    return this.service_type.name === "Shop";
  }

  getIcon() {
    return this.service_type.getIcon();
  }
  getDisplayName(t) {
    return t(this.service_type.getLocalizedNameKey()) + (this.name && this.name.length > 0 ? ": " + this.name : "");
  }

  getPrice(printable = false) {
    return this.price.length > 0 ? (printable ? this.price[0].toString() : this.price[0]) : null;
  }
}

export default Service;
