import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
// locales
import { useLocales } from '..//locales';
// components
import { useSettingsContext } from '../components/settings';
//
// import palette from './palette';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import { shadows } from './shadows';

// options
import { contrast } from './options/contrast';
import { darkMode } from './options/dark-mode';
import { presets } from './options/presets';
import RTL, { direction } from './options/right-to-left';

import themeLanding from "assets/theme";

// Landing
import { palette } from "assets/theme/base/colors";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

function loadLandingTheme(theme, themeLanding) {
  // Add landing functionality
  theme.functions = themeLanding.functions;
  theme.boxShadows = themeLanding.boxShadows;
  theme.borders = themeLanding.borders;
  theme.typography = themeLanding.typography;
  // Merge the two themes priority to dashboard
  theme.components = componentsOverrides(theme);
}

export default function ThemeProvider({ children }) {
  const { currentLang } = useLocales();

  const settings = useSettingsContext();

  const darkModeOption = darkMode(settings.themeMode);

  const presetsOption = presets(settings.themeColorPresets);

  const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

  const directionOption = direction(settings.themeDirection);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      // typography,
      shape: { borderRadius: 8 },
    }),

    []
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme
      ),
    [baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme]
  );



  // const { themeMode, themeDirection } = useSettingsContext();

  // const themeOptions = useMemo(
  //   () => ({
  //     palette: palette(themeMode),
  //     // typography,
  //     shape: { borderRadius: 8 },
  //     direction: themeDirection,
  //     shadows: shadows(themeMode),
  //     customShadows: customShadows(themeMode),
  //   }),
  //   [themeDirection, themeMode]
  // );

  const theme = createTheme(memoizedValue);
  // theme.components = componentsOverride(theme);
  theme.components = merge(componentsOverrides(theme), contrastOption.components);
  loadLandingTheme(theme, themeLanding);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );


  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}
