import { createSlice } from "@reduxjs/toolkit";
//
import { dispatch } from "../store";
// ----------------------------------------------------------------------
// ADDED
import PaymentsService from "services/PaymentsService";
import UserService from "services/UserService";
import { getUniqueFieldValues } from "src/utils/typeConverters";
import ProductContractsService from "../../../services/ProductContractsService";
import ProductsService from "../../../services/ProductsService";
import { ProductSubContract } from "model/contracts/ProductSubContract";
import { ProductContract } from "model/contracts/ProductContract";
// ----------------------------------------------------------------------
export const REQUEST_STATUS = ["Created", "Cancelled", "Completed"];

const initialState = {
  isLoading: false,
  isLoadingSubContract: false,
  isLoadingRequest: false,
  error: null,
  productContracts: [],
  productContract: null,
  productSubContract: null,
  productSubContracts: [],
  subsConfig: null,
  saleConfig: null,
  productRequest: null,
  productRequests: [],
};

const slice = createSlice({
  name: "productContract",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // RESET STATE
    resetState(state) {
      state.isLoading = false;
      state.isLoadingSubContract = false;
      state.isLoadingRequest = false;
      state.error = null;
      state.productContracts = []; // Reset the product contracts
      state.productContract = null; // Reset single product contract
      state.productSubContract = null; // Reset single product sub contract
      state.productSubContracts = []; // Reset the list of sub contracts
      state.subsConfig = null;
      state.saleConfig = null;
      state.productRequest = null;
      state.productRequests = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS DEFS
    getProductContractsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.length === 0) {
        state.productContracts = [];
        return;
      }
      state.productContracts = action.payload.map((contract) => ProductContract.fromJSON(contract));
      // .slice()
      // .sort((a, b) => a.product_type.localeCompare(b.product_type));
    },

    // GET PRODUCT DEF
    addProductContract(state, action) {
      state.isLoading = false;
      const length = state.productContracts.push(ProductContract.fromJSON(action.payload));
    },

    getProductContractSuccess(state, action) {
      state.isLoading = false;
      state.productContract = ProductContract.fromJSON(action.payload);
      state.subsConfig = ProductContractsService.getProductContractSubsConfig(action.payload);
      state.saleConfig = ProductContractsService.getProductContractSaleConfig(action.payload);
    },

    deleteProductContract(state, action) {
      state.isLoading = false;
      const updateProductContracts = state.productContracts.filter((product) => product.id !== action.payload);

      state.productContracts = updateProductContracts;
    },

    updateProductContract(state, action) {
      state.isLoading = false;
      const index = state.productContracts.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.productContracts[index] = ProductContract.fromJSON(action.payload);
      }
    },

    // ======== Sub contracts

    // START LOADING
    startLoadingSubContract(state) {
      state.isLoadingSubContract = true;
    },

    // HAS ERROR
    hasErrorSubContract(state, action) {
      state.isLoadingSubContract = false;
      state.error = action.payload;
    },

    // GET PRODUCT SUBCONTRACT
    getProductSubContractSuccess(state, action) {
      state.isLoadingSubContract = false;
      state.productSubContract = ProductSubContract.fromJSON(action.payload);
    },

    // GET PRODUCTS DEFS
    getProductSubContractsSuccess(state, action) {
      state.isLoadingSubContract = false;
      if (action.payload.length === 0) {
        state.productSubContracts = [];
        return;
      }
      state.productSubContracts = action.payload.map((subcontract) => ProductSubContract.fromJSON(subcontract));
    },

    deleteProductSubContract(state, action) {
      state.isLoadingSubContract = false;
      const updateProductSubContracts = state.productSubContracts.filter((product) => product.id !== action.payload);

      state.productSubContracts = updateProductSubContracts;
    },

    updateProductSubContract(state, action) {
      state.isLoadingSubContract = false;
      const index = state.productSubContracts.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        let user = state.productSubContracts[index].user;
        let latest_payment_date = state.productSubContracts[index].latest_payment_date;
        let next_payment_date = state.productSubContracts[index].next_payment_date;
        state.productSubContracts[index] = ProductSubContract.fromJSON(action.payload);

        state.productSubContracts[index].user = user;
        state.productSubContracts[index].latest_payment_date = latest_payment_date;
        state.productSubContracts[index].next_payment_date = next_payment_date;
      }
    },

    // ======== Product request

    // START LOADING
    startLoadingRequest(state) {
      state.isLoadingRequest = true;
    },

    // HAS ERROR
    hasErrorRequest(state, action) {
      console.log(action.payload);
      state.isLoadingRequest = false;
      state.error = action.payload;
    },

    getProductRequestSuccess(state, action) {
      state.isLoadingRequest = false;
      state.productRequest = action.payload;
    },

    getProductRequestsSuccess(state, action) {
      state.isLoadingRequest = false;
      if (action.payload.length === 0) {
        state.productRequests = [];
        return;
      }
      state.productRequests = action.payload.slice().sort((a, b) => a.created_at > b.created_at);
    },

    deleteProductRequest(state, action) {
      state.isLoadingRequest = false;
      const updateProductRequests = state.productRequests.filter((product) => product.id !== action.payload);

      state.productRequests = updateProductRequests;
    },

    updateProductRequest(state, action) {
      state.isLoadingRequest = false;
      const index = state.productRequests.findIndex((o) => o.id === action.payload.id);
      if (index > -1) {
        state.productRequests[index] = action.payload;
      }
    },
  },
});

// Reducer
export default slice.reducer;
export const { resetState: resetContractsState } = slice.actions;

// ----------------------------------------------------------------------

export function getProductContracts(symphy_backend) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductContractsService.getProductContracts(symphy_backend);
      dispatch(slice.actions.getProductContractsSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Expanded includes subcontracts and products
export function getProductExpandedContracts(symphy_backend) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await ProductContractsService.getProductExpandedContracts(symphy_backend);
      if (response.length > 0) {
        let productIds = getUniqueFieldValues(response, "product_id");
        response = await ProductsService.addProductsToItems(symphy_backend, response, productIds);
      }
      if (response.length > 0) {
        const userIds = new Set();
        // Loop through each productContract to access both owner_id and subcontracts
        response.forEach((productContract) => {
          // Add owner_id if not null
          if (productContract.owner_id) {
            userIds.add(productContract.owner_id);
          }
          if (BigInt(productContract.product_id )=== BigInt(274)) {
            console.log("productContract", JSON.stringify(productContract));
          }
          // Iterate over subcontracts if they exist
          if (productContract.subcontracts && productContract.subcontracts.length > 0) {
            
            productContract.subcontracts.forEach((subcontract) => {
              if (subcontract.user_id) {
                userIds.add(subcontract.user_id);
              }
            });
          }
        });

        // Convert the Set back to an array
        const uniqueUserIds = Array.from(userIds);
        response = await UserService.addUserSummaryToItems(symphy_backend, response, uniqueUserIds, true);
      }
      dispatch(slice.actions.getProductContractsSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductContract(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getProductContract(symphy_backend, BigInt(id));
      dispatch(slice.actions.getProductContractSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductContractByProductId(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getProductContractByProductId(
        symphy_backend,
        // eslint-disable-next-line no-undef
        BigInt(id)
      );
      dispatch(slice.actions.getProductContractSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deleteProductContract(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      await ProductContractsService.deleteProductContract(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteProduct(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProductContract(symphy_backend, productContract, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.updateProductContract(symphy_backend, productContract);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.updateProductContract(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductContractSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error updating " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductContract(symphy_backend, productContract, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.addProductContract(
        symphy_backend,
        productContract
      );
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.addProductContract(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductContractSuccess(response));
      }
      if (listener) {
        listener(response);
      }
    } catch (error) {
      console.log("Error adding " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function productContractRefresh(productContract, isUpdate, listener) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-undef
      if (isUpdate) {
        dispatch(slice.actions.updateProductContract(productContract));
      } else {
        dispatch(slice.actions.addProductContract(productContract));
      }
      dispatch(slice.actions.getProductContractSuccess(productContract));
      if (listener) {
        listener(productContract);
      }
    } catch (error) {
      console.log("Error adding " + error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// Method without dispatch
export async function getProductSubContractsResponse(symphy_backend, startDate, endDate, userId, addProducts, providedProducts, addPaymentIntents, isUser, addShops, productIds) {
  let response = await ProductContractsService.getProductSubContracts(symphy_backend, startDate, endDate, userId, productIds, isUser);
  if (response.length > 0) {
      response = response.map((subcontract) => ProductSubContract.fromJSON(subcontract));
  }
    if (addPaymentIntents && response.length > 0) {
      const subcontractIds = getUniqueFieldValues(response, "id");
      response = await PaymentsService.addTransactionsToItems(symphy_backend, response, subcontractIds, isUser);
    }

    if (addProducts && response.length > 0) {
      const productIds = getUniqueFieldValues(response, "product_id");
      response = await ProductsService.addProductsToItems(symphy_backend, response, productIds, providedProducts);
    }

    if (addShops && response.length > 0) {
      const shopIds = [];
      response.forEach((subcontract) => {
        if (subcontract.shop_id && subcontract.shop_id.length > 0) {
          shopIds.push(subcontract.shop_id[0]);
        }
      });
      response = await UserService.addShopsToItems(symphy_backend, response, shopIds, { toSubcontracts: true, isTransaction: false });
    }
  return response;
}

export function getProductSubContracts(symphy_backend, startDate, endDate, userId, addProducts, providedProducts, addPaymentIntents, listener, isUser, addShops) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      const response = await getProductSubContractsResponse(symphy_backend, startDate, endDate, userId, addProducts, providedProducts, addPaymentIntents, isUser, addShops);
      if (listener) {
        listener();
      }
      dispatch(slice.actions.getProductSubContractsSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function getProductSubContractsExpanded(symphy_backend, addPaymentIntents, addProducts) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    // try {
      let response = await ProductContractsService.getProductSubContracts(symphy_backend);
      // response.forEach(element => {
      //     console.log("getProductSubContractsExpanded response id " + JSON.stringify(element.id) + " " + JSON.stringify(element.contract_status));
    // });
      if (response.length > 0) {
        response = response.map((subcontract) => ProductSubContract.fromJSON(subcontract));
      }
      if (addPaymentIntents && response.length > 0) {
        let subcontractIds = getUniqueFieldValues(response, "id");
        response = await PaymentsService.addTransactionsToItems(symphy_backend, response, subcontractIds);
        response = await PaymentsService.addProviderPaymentsToItems(symphy_backend, response, subcontractIds);
      }
      if (addProducts && response.length > 0) {
        let productIds = getUniqueFieldValues(response, "product_id");
        // console.log("getProductSubContractsExpanded " + JSON.stringify(productIds));
        response = await ProductsService.addProductsToItems(symphy_backend, response, productIds, null);
      }
      if (response.length > 0) {
        let userIds = getUniqueFieldValues(response, "user_id");
        response = await UserService.addUserSummaryToItems(symphy_backend, response, userIds);
      }
    
      // console.log("getProductSubContractsExpanded " + JSON.stringify(response));
      dispatch(slice.actions.getProductSubContractsSuccess(response));
    // } catch (error) {
    //   console.log(error);
    //   dispatch(slice.actions.hasErrorSubContract(error));
    // }
  };
}

export function getProductSubContract(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getProductSubContract(symphy_backend, BigInt(id));
      dispatch(slice.actions.getProductSubContractSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function getProductSubContractsByContractId(symphy_backend, id, addTransactions) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      let response = await ProductContractsService.getProductSubContractsByContractId(
        symphy_backend,
        // eslint-disable-next-line no-undef
        BigInt(id)
      );
      if (addTransactions && response.length > 0) {
        response = response.map((subcontract) => ProductSubContract.fromJSON(subcontract));
        let subcontractIds = getUniqueFieldValues(response, "id");
        response = await PaymentsService.addTransactionsToItems(symphy_backend, response, subcontractIds, false);
      }

      dispatch(slice.actions.getProductSubContractsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function deleteProductSubContract(symphy_backend, id, activateListing=false, listener) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      await ProductContractsService.deleteProductSubContract(symphy_backend, BigInt(id), activateListing);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteProductSubContract(BigInt(id)));
      if (listener) {
        listener();
      }
    } catch (error) {
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function updateProductSubContract(symphy_backend, productSubContract, newId, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.updateProductSubContract(symphy_backend, productSubContract, newId);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.updateProductSubContract(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductSubContractSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error updating " + error);
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function updateProductSubContractStatus(symphy_backend, productSubContract, status, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.updateProductSubContractStatus(symphy_backend, productSubContract, status);
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.updateProductSubContract(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductSubContractSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error updating " + error);
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}

export function addProductSubContract(symphy_backend, productSubContract, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoadingSubContract());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.addProductSubContract(
        symphy_backend,
        productSubContract
      );
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.getProductSubContractSuccess(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductSubContractSuccess(response));
      }
      if (listener) {
        listener(response);
      }
    } catch (error) {
      console.log("Error adding " + error);
      dispatch(slice.actions.hasErrorSubContract(error));
    }
  };
}


// ----------------------------------------------------------------------
// ----------------------------------------------------------------------


export function getProductRequests(symphy_backend) {
  return async () => {
    dispatch(slice.actions.startLoadingRequest());
    try {
      const response = await ProductContractsService.getProductRequests(symphy_backend);
      dispatch(slice.actions.getProductRequestsSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasErrorRequest(error));
    }
  };
}

export function getProductRequest(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoadingRequest());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.getProductRequest(symphy_backend, BigInt(id));
      dispatch(slice.actions.getProductRequestSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasErrorRequest(error));
    }
  };
}

export function deleteProductRequest(symphy_backend, id) {
  return async () => {
    dispatch(slice.actions.startLoadingRequest());
    try {
      // eslint-disable-next-line no-undef
      await ProductContractsService.deleteProductRequest(symphy_backend, BigInt(id));
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.deleteProductRequest(BigInt(id)));
    } catch (error) {
      dispatch(slice.actions.hasErrorRequest(error));
    }
  };
}

export function addProductRequest(symphy_backend, productRequest, isCurrent, listener) {
  return async () => {
    dispatch(slice.actions.startLoadingRequest());
    try {
      // eslint-disable-next-line no-undef
      const response = await ProductContractsService.addProductRequest(
        symphy_backend,
        productRequest
      );
      // eslint-disable-next-line no-undef
      dispatch(slice.actions.getProductRequestSuccess(response));
      if (isCurrent) {
        dispatch(slice.actions.getProductRequestSuccess(response));
      }
      if (listener) {
        listener();
      }
    } catch (error) {
      console.log("Error adding " + error);
      dispatch(slice.actions.hasErrorRequest(error));
    }
  };
}



